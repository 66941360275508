'use strict'

import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import Modal from 'react-modal'

import dashboardActions from '../../../actions/dashboardActions'

import SelectedQuestionDetails from './SelectedQuestionDetails'

const customStyles = {
    content: {
        padding: 0,
        position: 'relative',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: 376,
        width: 600,
        margin: '14% auto',
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
    overlay: {
        zIndex: 1,
        overflow: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
}

@connect((store) => {
    return {
        activeQuestion: store.dashboardReducer.activeQuestion,
    }
})

export default class QuestionDetailsContainer extends React.Component {
    static propTypes = {
        activeQuestion: PropTypes.bool.isRequired,
    }

    constructor(props) {
        super(props)

        this.closeModal = this.closeModal.bind(this)
    }

    closeModal() {
        this.props.dispatch(dashboardActions.closeQuestionDetails())
    }

    render() {
        const {activeQuestion} = this.props
        return <Modal
            isOpen={activeQuestion}
            closeTimeoutMS={10}
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={true}
            style={customStyles}
            contentLabel="QuestionDetailsContainer">
            <SelectedQuestionDetails closeModal={this.closeModal}/>
        </Modal>
    }
}
