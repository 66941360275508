'use strict'

import {checkVisibility} from './utils'

export const convertListenSaveData = (scorecard, qaWhisper, clerkData, generalComment, copyToCali, qaStart, callLength) => {
    const sessionIdItem = scorecard.metaData.find(item => item.name === 'SESSION_ID')
    const xidItem = scorecard.metaData.find(item => item.name === 'X_ID')
    const FQR = []
    const FQS = []
    const FQSO = []
    scorecard.scorecardInfo.sections.forEach(section => {
        section.questions.forEach(q => {
            if (q.isVisible) {
                if (q.isComposite) {
                    const notChecked = q.compositeQuestionInfo.comments.some(c => !c.checked)
                    const answer = q.compositeQuestionInfo.answers.find(a => notChecked ? !a.isRightAnswer : a.isRightAnswer)
                    let pos = 0
                    q.compositeQuestionInfo.comments.forEach(c => {
                        if (c.checked) {
                            if (!pos || pos > c.position) pos = c.position
                            FQSO.push({
                                option_pos  : parseInt(c.position, 10).toString(),
                                option_value: c.optionText,
                                orig_id     : c.optionId.toString(),
                                question_id : q.questionId.toString(),
                            })
                        }
                    })
                    let secs = pos % 60
                    if (answer) FQS.push({
                        click_text: parseInt(pos/60) + ':' + ((secs < 10) ? '0' + secs : secs),
                        other_answer_text: q.compositeQuestionInfo.customComment || '',
                        q_position: parseInt(pos, 10).toString(),
                        question_answered: answer.answerId.toString(),
                        question_id: q.questionId.toString(),
                        view_link: "",
                    })
                    if (q.compositeQuestionInfo.customComment) FQR.push({
                        question_id      : q.questionId.toString(),
                        answer_id        : '0',
                        other_answer_text: q.compositeQuestionInfo.customComment,
                        answer_position  : parseInt(q.compositeQuestionInfo.customCommentPosition, 10).toString(),
                    })
                } else {
                    const answer = q.simpleQuestionInfo.answers.find(a => a.isAnswered)
                    if (answer) {
                        let pos = parseInt(answer.position, 10)
                        let secs = pos % 60
                        if (answer.customComment.length !==0 && typeof(answer.customComment) != "string") {
                            answer.customComment.map(com => {
                                FQR.push({
                                    question_id      : q.questionId.toString(),
                                    answer_id        : com.ddlValue !==0 ? com.ddlValue.toString() : '0',
                                    other_answer_text: com.value,
                                    answer_position  : parseInt(answer.customCommentPosition, 10).toString(),
                                })
                            })
                            FQS.push({
                                click_text: parseInt(pos/60) + ':' + ((secs < 10) ? '0' + secs : secs),
                                other_answer_text: '',
                                q_position: parseInt(pos, 10).toString(),
                                question_answered: answer.answer.answerId.toString(),
                                question_id: q.questionId.toString(),
                                view_link: "",
                            })
                        } else {
                             if (answer.customComment && typeof(answer.customComment) == "string" && answer.customComment !== "") {
                                const DDLItem = answer.DDLItems && answer.DDLItems.find(item => item.checked)
                                FQR.push({
                                    question_id      : q.questionId.toString(),
                                    answer_id        : DDLItem ? DDLItem.DDLValue.toString() : '0',
                                    other_answer_text: answer.customComment,
                                    answer_position  : parseInt(answer.customCommentPosition, 10).toString(),
                                })
                            }
                            if (answer.comments.length) answer.comments.forEach(c => {
                                const DDLItem = answer.DDLItems && answer.DDLItems.find(item => item.checked)
                                if (!DDLItem && c.checked) FQR.push({
                                    question_id      : q.questionId.toString(),
                                    answer_id        : c.commentId.toString(),
                                    other_answer_text: '',
                                    answer_position  : parseInt(c.position, 10).toString(),
                                })
                            })
                            FQS.push({
                                click_text: parseInt(pos/60) + ':' + ((secs < 10) ? '0' + secs : secs),
                                other_answer_text: answer.customComment || '',
                                q_position: parseInt(pos, 10).toString(),
                                question_answered: answer.answer.answerId.toString(),
                                question_id: q.questionId.toString(),
                                view_link: "",
                            })
                        }
                    }
                }
            }
        })
    })
    return {
        CD   : clerkData,
        FQR  : FQR,
        FQS  : FQS,
        FQSO : FQSO,
        SC   : generalComment || '',
        LD   : {
            session_id: sessionIdItem ? sessionIdItem.value : '',
            review_ID: xidItem ? xidItem.value : '',
            Comments: '',
            appname: scorecard.scorecardInfo.scorecard.scorecardApp,
            whisperID: '1',
            QAwhisper: qaWhisper || '',
            qa_start: qaStart,
            qa_last_action: Math.floor(Date.now() / 1000).toString(),
            call_length: callLength,
            copy_to_cali: copyToCali ? 1 : 0
        },
    }
}

const displayTexts = {
    First_Name: 'First Name',
    Last_Name: 'Last Name',
    Email: 'Email',
    phone: 'Phone',
    website: 'Website',
    EducationLevel: 'Education level',
    HighSchoolGradYear: 'High school grad year',
    DegreeStartTimeframe: 'Degree start timeframe',
    CAMPAIGN: 'Campaign',
    CALL_TYPE: 'Call type',
    DISPOSITION: 'Disposition',
    disposition: 'Notes',
    SESSION_ID: 'Session ID',
    compliance_sheet: 'Compliance Sheet',
    review_date: 'Review Date',
    call_date: 'Call date',
    profile_id: 'Profile ID',
    Zip: 'ZIP',
    State: 'State',
    City: 'City',
    address: 'Address',
    ANI: 'ANI',
    DNIS: 'DNIS',
    scorecard_name: 'Scorecard name',
    agent: 'Agent',
    AGENT: 'Agent',
    LIST_NAME: 'Client Name',
    X_ID: 'review ID',
    // other data keys
    // BestTimeToContact: 'Best time to contact',
    // BirthYear: 'Birth Year',
    // MilitaryStatus: 'Military Status',
    // USMilitaryAffiliation: 'US Military Affiliation',
    // StartMonth: 'Start Month',
    // StartRange: 'Start Range',
    // TeachingLicense: 'Teaching License',
    // USCitizen: 'US Citizen',
    // RNLicense: 'RN License',
    // LevelOfEducation: 'Level Of Education',
    // YearObtainedHSGED: 'Year Obtained HSGED',
    // schools keys
    // DegreeOfInterest: 'DEGREE',
    // AOI1: 'AREA 1',
    // AOI2: 'AREA 2',
    // L1_SubjectName: 'SUBJECT 1',
    // L2_SubjectName: 'SUBJECT 2',
    // Portal: 'PORTAL',
    // TCPA: 'TCPA'
}

const getCompositeQuestionInfo = (q) => {
    return isComposite(q)
    ? {
        comments:    q.TemplateItems.map(c => {
            return {
                optionId:      c.option_id,
                optionText:    c.option_text,
                checked:       false,
                position:      0,
            }
        }),
        answers:     q.answers.map(a => {
            return {
                answerText:       a.Answer,
                answerId:         a.AnswerID,
                points:           a.Points, // should be added to agent score if isAnswered
                isRightAnswer:    a.RightAnswer,
                answerAlert:      a.AnswerAlert,
            }
        }),
        customComment: '',
        customCommentPosition: 0,
    }
    : null
}

const getSimpleQuestionInfo = (q) => {
    return isComposite(q)
    ? null
    : {
        singleComment: q.SingleComment,
        answers: q.answers.map(a => {
            return {
                answer: {
                    answerText:       a.Answer,
                    answerId:         a.AnswerID,
                    points:           a.Points, // should be added to agent score if isAnswered
                    isRightAnswer:    a.RightAnswer,
                    answerAlert:      a.AnswerAlert,
                },
                commentRequired:    a.acp_required,
                comments:           a.Comments.map(c => {
                    return {
                        commentId:       c.CommentID,
                        commentText:     c.CommentText,
                        points:          c.CommentPoints, // should be added to agent score if comment is checked
                        checked:         false,
                        position:        0,
                        answerStatement: c.AnswerStatement,
                    }
                }),
                dropDownItems:      a.DropdownItems,
                DDLItems:           a.DDLItems,
                isAnswered:         !!a.autoselect,
                customComment:      '',
                customCommentPosition: 0,
                position: 0,
                customCommentRequired: q.RequireCustomComment,//<- old a.RequireCustomComment,
                answerRequired: a.AnswerRequired
            }
        })
    }
}

const isComposite = (q) => {
    return q.TemplateItems && q.TemplateItems.length && q.TemplateItems.length > 1
    ? true : false
}

const getFAQs = (data) => {
    return data && data.length
    ? data.map(item => {
        return {
            answerText:      item.AnswerText,
            questionText:    item.QuestionText,
        }
    })
    : null
}

const getInstructions = (data) => {
    return data && data.length
    ? data.map(item => {
        return {
            answerText: item.AnswerText,
            instructionText: item.InstructionText,
        }
    })
    : null
}

const getQuestionInfo = (q, index) => {
    const isLinked = q.LinkedRules && q.LinkedRules.length
    return {
        faqs:                     getFAQs(q.FAQs),
        instructions:             getInstructions(q.instructions),
        questionId:               q.QID,
        isWide:                   q.WideQuestion,
        // isVisible:                true,
        isComposite:              isComposite(q),
        singleComment:            q.SingleComment,
        isLeftColumn:             q.LeftColumnQuestion,
        isLinked:                 isLinked,
        // linkedAnswers:            q.LinkedAnswers || (q.LinkedAnswer && q.LinkedAnswer !== '0' ? [parseInt(q.LinkedAnswer)] : null),
        // linkedComments:           q.LinkedComments || (q.LinkedComment && q.LinkedComment !== '0' ? [parseInt(q.LinkedComment)] : null),
        // linkedAnswerId:           parseInt(q.LinkedAnswer) || null,
        // linkedCommentId:          parseInt(q.LinkedComment) || null,
        linkedRules:              q.LinkedRules,
        linkedVisible:            q.LinkedVisible,
        questionShortName:        q.QuestionShort,
        commentAllowed:           q.comments_allowed || q.RequireCustomComment,
        answerRequired:           q.AnswerRequired,
        customCommentRequired:    q.RequireCustomComment,
        simpleQuestionInfo:       getSimpleQuestionInfo(q),
        compositeQuestionInfo:    getCompositeQuestionInfo(q),
        dropDownType:             q.DropDownType || '',
        dropDownEndpoint:         q.DropDownEndpoint || '',
        position:                 0,
        sentence:                 q.sentence,
        isAnswered:               q.answers.some(a => a.autoselect === 1),
        questionType:             q.QuestionType,
    }
}

const getSection = (section, index) => {
    return {
        sectionInfo: {
            sectionOrder: section.order || index,
            sectionId:    section.sectionId || index,
            sectionName:  section.section,
        },
        questions: section.questions.map((q, i) => getQuestionInfo(q, i))
    }
}

const getScorecardInfo = (data) => {
    return {
        scorecardId:    parseInt(data.ID) || null,
        scorecardName:  data.ScorecardName,
        scorecardApp:   data.Appname,
        scorecardType:  data.ReviewType,
    }
}

const getOtherData = (data) => {
    return data && data.length
    ? data.map(item => {
        return {
            dataKey: item.key,
            dataType: item.type,
            dataValue: item.value,
            id: item.id,
            label: item.label,
            schoolName: item.school,
        }
    })
    : null
}

const getMetaData = (data) => {
    const metaData = []
    Object.keys(displayTexts).forEach(key => {
        if (data[key]) metaData.push({
            name: key,
            value: data[key],
            displayText: displayTexts[key]
        })
    })
    return metaData
}


// This mess is here because of BE is returning a mess and no way to make it done by BE guys
export const convertScorecardData = (data) => {
    return {
        scorecardInfo: {
            scorecard: getScorecardInfo(data.Scorecard),
            sections:  checkVisibility(data.Scorecard.Sections.map((s, i) => getSection(s, i))),
        },
        otherData: getOtherData(data.ListenData.OtherData),
        schoolData: data.ListenData.SchoolData,
        metaData: getMetaData(data.ListenData),
    }
}