'use strict';

import * as R from 'ramda'

import {niModules} from './utils'

const statObj = {
    totalCalls:        0,
    noOpportunity:     0,
    noOvercomeOne:     0,
    noOvercomeTwo:     0,
}

const initialState = {
    modules: niModules,
    stats: {
        rangeStats:      R.clone(statObj),
        comparisonStats: R.clone(statObj)
    },
    statsLoading:             false,
    statsLoadingError:        false,
    statsTimeoutError:        false,

    passedFailed: {
        passed: 0,
        failed: 0,
    },
    passedFailedLoading:      false,
    passedFailedError:        false,
    passedFailedTimeoutError: false,

    objections: {
        reasons: [],
        total:   0,
    },
    objectionsLoading:        false,
    objectionsError:          false,
    objectionsTimeoutError:   false,

    groups:                   null,
    groupsLoading:            false,
    groupsError:              false,
    groupsTimeoutError:       false,
}

export default function reducer(state=initialState, action) {

    switch (action.type) {
        case "GET_STATS_FULFILLED": {
            return {
                ...state,
                stats: action.payload,
                statsLoading: false,
                statsLoadingError: false
            }
        }
        case "STATS_LOADING": {
            return {
                ...state,
                statsLoadingError: false,
                statsLoading: action.payload
            }
        }
        case "STATS_LOADING_ERROR": {
            return {
                ...state,
                statsLoading: false,
                statsLoadingError: action.payload
            }
        }
        case "STATS_TIMEOUT_ERR": {
            return {
                ...state,
                statsTimeoutError: action.payload
            }
        }
        case "GET_PASSED_FAILED_FULFILLED": {
            return {
                ...state,
                passedFailed: action.payload,
                passedFailedLoading: false,
                passedFailedError: false
            }
        }
        case "PASSED_FAILED_LOADING": {
            return {
                ...state,
                passedFailedError: false,
                passedFailedLoading: action.payload
            }
        }
        case "PASSED_FAILED_ERROR": {
            return {
                ...state,
                passedFailedLoading: false,
                passedFailedError: action.payload
            }
        }
        case "PASSED_FAILED_TIMEOUT_ERR": {
            return {
                ...state,
                passedFailedTimeoutError: action.payload
            }
        }
        case "GET_OBJECTIONS_FULFILLED": {
            return {
                ...state,
                objections: action.payload,
                objectionsLoading: false,
                objectionsError: false
            }
        }
        case "OBJECTIONS_LOADING": {
            return {
                ...state,
                objectionsError: false,
                objectionsLoading: action.payload
            }
        }
        case "OBJECTIONS_ERROR": {
            return {
                ...state,
                objectionsLoading: false,
                objectionsError: action.payload
            }
        }
        case "OBJECTIONS_TIMEOUT_ERR": {
            return {
                ...state,
                objectionsTimeoutError: action.payload
            }
        }
        case "GET_NI_GROUPS_FULFILLED": {
            return {
                ...state,
                groups: action.payload,
                groupsLoading: false,
                groupsError: false
            }
        }
        case "NI_GROUPS_LOADING": {
            return {
                ...state,
                groupsError: false,
                groupsLoading: action.payload
            }
        }
        case "NI_GROUPS_ERROR": {
            return {
                ...state,
                groupsLoading: false,
                groupsError: action.payload
            }
        }
        case "NI_GROUPS_TIMEOUT_ERR": {
            return {
                ...state,
                groupsTimeoutError: action.payload
            }
        }
    }

    return state
}
