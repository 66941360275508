'use strict'

export const emptyUser = {
    active            : true,
    userId            : null,
    userName          : '',
    firstName         : '',
    lastName          : '',
    email             : '',
    defaultPage       : '',
    userRole          : '',
    userManager       : '',
    nonEdit           : false,
    nonCalibrating    : false,
    nondashboardAccess: false,
    updateOlderData   : false,
    forceRewiew       : false,
    excludeCalls      : false,
    lastLogin         : '',
    whoAdded          : '',
    dateAdded         : '',
    appName           : '',
    scorecardId       : null,
    groupId           : null,
    password          : '',
}

export function getDefaultUserInfo() {
    return {
        user     : {
            userId             : '',
            dateAdded          : '',
            defaultPage        : '',
            email              : '',
            firstName          : '',
            forceRewiew        : false,
            lastLogin          : '',
            lastName           : '',
            nonCalibrating     : false,
            nonEdit            : false,
            nondashboardAccess : false,
            updateOlderData    : false,
            userManager        : '',
            userName           : '',
            userRole           : '',
            whoAdded           : '',
            excludeCalls       : false,
         }
    }
}

export function multipleGroupInfo() {
    return {
        userId: 0,
        scorecardsInGroups: []
    }
}
