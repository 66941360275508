'use strict'

import {connect} from 'react-redux'
import numeral from 'numeral'
import classNames from 'classnames'

import SimpleComment from './SimpleComment'
import Answer from './Answer'

import dashboardActions from '../../../actions/dashboardActions'

@connect((store) => {
    return {
        answers: store.filtersReducer.answers,
        comments: store.filtersReducer.comments
    }
})

export default class SimpleQuestion extends React.Component {
    constructor(props) {
        super(props)

        this.addCommentToFilters = this.addCommentToFilters.bind(this)
        this.addAnswerToFilters = this.addAnswerToFilters.bind(this)
    }

    addAnswerToFilters(id, inFilters, isRight, total) {
        const {data, dispatch} = this.props
        if (inFilters) return dispatch(dashboardActions.addAnswerToFilters(null))
        dispatch(dashboardActions.addAnswerToFilters({
            questionText: data.questionName,
            id          : id,
            isRight     : isRight,
            total       : total,
        }))
    }

    addCommentToFilters(id, answerText, inFilters, isRight, total) {
        const {data, dispatch} = this.props
        if (inFilters) return dispatch(dashboardActions.addCommentToFilters(null))
        dispatch(dashboardActions.addCommentToFilters({
            id          : id,
            answerText  : answerText,
            questionText: data.questionName,
            isRight     : isRight,
            total       : total,
        }))
    }

    renderComments(answ) {
        const {comments} = this.props
        const customInFilters = !!comments.find(fc => fc.id === -666)
        return (
            <div class='comments'>
                {
                    answ.comments.map((c, i) => {
                        const inFilters = !!comments.find(fc => fc.id === c.commentId)
                        return <SimpleComment
                            key={'simple-comment-' + c.commentId + i}
                            data={c}
                            answerText={answ.answerText}
                            isRight={answ.isRightAnswer}
                            addToFilters={this.addCommentToFilters}
                            inFilters={inFilters}
                            total={answ.total}
                            percent={numeral(answ.total ? c.total/answ.total : 0).format('0.00%')} />
                    })
                }
                {
                    answ.totalCustomComments > 0 &&
                    <SimpleComment
                        data={{commentId: -666, total: answ.totalCustomComments}}
                        answerText={answ.answerText}
                        isRight={answ.isRightAnswer}
                        addToFilters={this.addCommentToFilters}
                        inFilters={customInFilters}
                        total={answ.totalCustomComments}
                        percent={null} />
                }
            </div>
        )
    }
    renderAnswers() {
        const {answers, data} = this.props
        const {simpleQuestionStat, total} = data
        return simpleQuestionStat.answerList.map((answ, i) => {
            const answerClass = classNames({
                "answer"        : true,
                "is-wrong"      :!answ.isRightAnswer
            })
            const inFilters = !!answers.find(fa => fa.id === answ.answerId)
            return (
                <div key={'question-details-answer-' + i} class='answer-section'>
                    <div class='answer-title'>
                        <Answer
                            id={answ.answerId}
                            answerText={answ.answerText}
                            isRight={answ.isRightAnswer}
                            total={answ.total}
                            answerClass={answerClass}
                            addToFilters={this.addAnswerToFilters}
                            inFilters={inFilters} />

                        <div class='numbers'>
                            <div class='calls'>{answ.total} of {total}</div>
                            <div class='percent'>{numeral(total ? answ.total/total : 0).format('0.00%')}</div>
                        </div>
                    </div>
                    {this.renderComments(answ)}
                </div>
            )
        })
    }
    render() {
        const {
            total,
            isLinked,
            questionId,
            questionName,
            simpleQuestionStat,
            scorecard
        } = this.props.data
        return (
            <div class='box-context'>
                {this.renderAnswers()}
            </div>
        )
    }
}
