'use strict'

import { connect } from 'react-redux'
import {systemDataPropType} from './propTypes'

const isNoResult = (status) => {
    return status === 'bad call' || status === 'pending' || status === 'transcript only'
}

@connect((store) => {
    return {
        filters: store.filtersReducer.newFilters,
    }
})

export default class ResultCell extends React.Component {
    static propTypes = {
        systemData: systemDataPropType.isRequired,
    }

    render() {
        const {systemData, filters} = this.props
        const {pendingOnly} = filters
        const noresult = isNoResult(systemData.callReviewStatus)
        const fail = systemData.callFailed
        return (
            <td class='result-cell'>
                {
                    !pendingOnly ?
                    <div title={fail ? 'Failed' : 'Passed'}>
                        {
                            !noresult && <div class={'result-icon icon-' + (fail ? 'ic-close' : 'ic-check')}></div>
                        }
                    </div> : null
                }
            </td>
        )
    }
}
