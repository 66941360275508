'use strict'

// import '../css/notification.scss'

import { Provider } from "react-redux"

import StartedLayout from './components/StartedLayout'

import LoginPage from './components/loginPage/Layout'

import store from './store/store'
import actions from './components/loginPage/actions'
import {startApp} from './actions/userActions'

window.lasoft = window.lasoft || {}

const start = {
    login: (config) => {
        const app = document.getElementById(config.mounting_element_id)
        // store.dispatch(startApp(config))
        // store.dispatch(actions.start(store))
        ReactDOM.render(
            <Provider store={store}>
                <StartedLayout content={<LoginPage />} />
            </Provider>, app)
    },
}

window.lasoft.login_app = {}

window.lasoft.login_app.start = start.login
