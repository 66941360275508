'use strict'

import *as R from 'ramda'
import axios from 'axios'

import { sendRequest, sendDataRequest } from '../APIGateway/gateway'
import { showError } from './errorActions'
import loader from './loaderActions'
import modalActions from './modalActions'
import playerActions from './playerActions'
import recordActions from './recordActions'
import listenActions from './listenActions'
import { convertUserData } from '../utils/dataConvertor'
import { getDefaultColumns, setModules } from '../utils/dashboard'

export const getUserInfo = () => {
    return sendRequest('getUserSession', {payload: ''}, 'USERAPI')
        .then(resp => resp.data)
}

const getAllUserSettings = () => {
    return sendRequest('getAllUserSettings', {payload: ''}, 'USERAPI')
        .then(resp => resp.data)
}

export const startApp = (config) => {
    return dispatch => {
        dispatch(loader.run({action: 'Hide'}))
        getAllUserSettings().then(initialData => {
            if (initialData && initialData.hasOwnProperty('session')) dispatch({
                type: 'GET_HEADER_DATA_FULFILLED',
                payload: {
                    userData: convertUserData(initialData.session)
                }
            })
            if (initialData && initialData.hasOwnProperty('columns')) dispatch(setAvailableColumns(initialData.columns))
            if (initialData && initialData.hasOwnProperty('settings')) dispatch(setUserSettings(initialData.settings))
        })
        // const {initialData} = config
        // if (initialData && initialData.hasOwnProperty('session')) dispatch({
        //     type: 'GET_HEADER_DATA_FULFILLED',
        //     payload: {
        //         userData: convertUserData(initialData.session)
        //     }
        // })
        // if (initialData && initialData.hasOwnProperty('columns')) dispatch(setAvailableColumns(initialData.columns))
        // if (initialData && initialData.hasOwnProperty('settings')) dispatch(setUserSettings(initialData.settings))
        // if (initialData && initialData.hasOwnProperty('session')) return
        // getUserInfo().then(resp => {
        //     return dispatch({
        //         type: 'GET_HEADER_DATA_FULFILLED',
        //         payload: {
        //             userData: convertUserData(resp)
        //         }
        //     })
        // })
    }
}

export const startAppReset = (params) => {
    return dispatch => {
        dispatch({
            type: 'PASSWORD_RESET_GET_PAGE_LOADING',
            payload: true
        })
        return sendRequest('verifyToken', {payload: params}, 'CALIBRATION')
                .then(resp => {
                    dispatch(getUserInfoAuthorize(params.username))
                    dispatch({
                        type: 'PASSWORD_RESET_GET_PAGE',
                        payload: resp.data
                    })
                }, err => {
                    dispatch({
                        type: 'PASSWORD_RESET_GET_PAGE_ERROR',
                        payload: true
                    })
                })
    }
}

const getUserInfoAuthorize = (userName) => {
     return dispatch => {
        dispatch({
            type: 'PASSWORD_RESET_GET_USER_INFO_LOADING',
            payload: true
        })
        return sendRequest('getUserInfoAuthorize', {payload: '"' + userName + '"'}, 'CALIBRATION')
                .then(resp => {
                    dispatch({
                        type: 'PASSWORD_RESET_GET_USER_INFO',
                        payload: resp.data
                    })
                }, err => {
                    dispatch({
                        type: 'PASSWORD_RESET_GET_USER_INFO_ERROR',
                        payload: true
                    })
                })
    }
}


const setAvailableColumns = (columns) => {
    const defaultColumns = getDefaultColumns()
    return dispatch => {
        if (!columns || !columns.length) return dispatch({
            type: 'TABLE_COLUMNS_CHANGED',
            payload: defaultColumns,
        })
        columns.forEach(c => c.sortable = true)
        const lastColumn = defaultColumns.splice(-1, 1)[0]
        const concatColumns = defaultColumns.concat(columns)
        concatColumns.push(lastColumn)
        dispatch({
            type: 'TABLE_COLUMNS_CHANGED',
            payload: concatColumns,
        })
        dispatch({
            type: 'AVAILABLE_COLUMNS_UPDATED',
            payload: true,
        })
    }
}

const setUserSettings = (settings) => {
    return (dispatch, getState) => {
        const state = getState()
        const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
        const payload = ['dates', 'filters', 'columns', 'dashboardModules', 'tableSorting']
        const availableColumns = R.clone(R.path(['dashboardReducer', 'tableColumns'], state))

        const converted = R.converge(R.zipObj, [R.pluck('name'), R.pluck('value')])(settings)
        console.log("converted", converted)
        const filters = converted.filters ? JSON.parse(converted.filters) : false
        if (filters) {
            if (!filters.hasOwnProperty('reviewType')) filters.reviewType = ''
            if (!filters.hasOwnProperty('pendingOnly')) filters.pendingOnly = false
            dispatch({
                type: 'SET_INITIAL_FILTERS',
                payload: filters
            })
        }
        const columns = converted.columns ? JSON.parse(converted.columns) : false
        if (columns) {
            availableColumns.forEach(ac => {
                ac.checked = false
                const exist = columns.find(c => c.id === ac.id)
                if (!exist) {
                    columns.push(ac)
                }
            })
            dispatch({
                type: 'TABLE_COLUMNS_CHANGED',
                payload: columns
            })
        }
        const dates = converted.dates ? JSON.parse(converted.dates) : false
        if (dates) dispatch({
            type: 'SET_DATE_SETTINGS',
            payload: dates
        })
        const notificationDates = converted.notificationDates ? JSON.parse(converted.notificationDates) : dates
        if (notificationDates) dispatch({
            type: 'SET_NOTIFICATION_DATE_SETTINGS',
            payload: notificationDates
        })
        const notificationFilters = converted.notificationFilters ? JSON.parse(converted.notificationFilters) : false
        if (notificationFilters) dispatch({
            type: 'SET_NOTIFICATION_FILTER_BY_DATE_CLOSED',
            payload: notificationFilters.filterByDateClosed
        })
        const tableSorting = converted.tableSorting ? JSON.parse(converted.tableSorting) : false
        if (tableSorting) dispatch({
            type: 'SET_TABLE_SORTING',
            payload: tableSorting
        })
        const modules = converted.dashboardModules ? JSON.parse(converted.dashboardModules) : false
        dispatch({
            type: 'SET_DASHBOARD_MODULES_SETTINGS',
            payload: setModules(modules, userData.modules, userData.modulesRequired)
        })
        const stopWorking = converted.stopWorking ? JSON.parse(converted.stopWorking) : false
        dispatch({
            type: 'GET_HEADER_DATA_STOP_WORKING',
            payload: stopWorking
        })
        dispatch({
            type: 'USER_SETTINGS_UPDATED',
            payload: true,
        })
        // const coachingFilters = converted.coachingFilters ? JSON.parse(converted.coachingFilters) : false
        // dispatch({
        //     type: 'COACHING_PAGE_SET_BY_AGENT_FILTERS',
        //     payload: coachingFilters,
        // })
    }
}

export const getTranscript = (transcriptData) => {
    return sendDataRequest('getTranscript', transcriptData, 'CDSERVICE')
        .then(resp => resp.data.d)
}

export const manipulateAudio = (options, addedToSend) => {
    let data = new FormData()
    data.append('mediaoptions', JSON.stringify(options))
    addedToSend.forEach((item) => {
        data.append(item.name, item)
    })

    return function(dispatch) {
        dispatch({
            type: 'UPLOAD_PROGRESS',
            payload: {
                percent: 0,
                uploading: true,
                message: 'Uploading',
                error: false
            }
        })

        const progress = function(progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100)/progressEvent.total);
            dispatch({
                type: 'UPLOAD_PROGRESS',
                payload: {
                    percent: percentCompleted,
                    uploading: true
                }
            })
        }
        return sendDataRequest('manipulateAudio', data, 'RECORDPOST', progress)
            .then(function() {
                dispatch(modalActions.modal('close'))
                dispatch({
                    type: 'UPLOAD_PROGRESS',
                    payload: {
                        percent: 100,
                        uploading: true
                    }
                })
                setTimeout(() => {
                    dispatch({
                        type: 'UPLOAD_PROGRESS',
                        payload: {
                            percent: 100,
                            uploading: false
                        }
                    })
                }, 500)
                return dispatch({
                    type: 'GET_NEW_AUDIO',
                    payload: response.data
                })
            }, function(err) {
                dispatch({
                    type: 'UPLOAD_PROGRESS',
                    payload: {
                        percent: 0,
                        uploading: false
                    }
                })
                showError(err, dispatch)
            })
    }
}

export const uploadMergeFiles = (options, addedToSend, dispatch) => {
    let data = new FormData()
    data.append('mediaoptions', JSON.stringify(options))
    addedToSend.forEach((item) => {
        data.append(item.name, item)
    })
    dispatch({
        type: 'UPLOAD_PROGRESS',
        payload: {
            percent: 0,
            uploading: true,
            message: 'Uploading',
            error: false
        }
    })

    const progress = function(progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100)/progressEvent.total);
        dispatch({
            type: 'UPLOAD_PROGRESS',
            payload: {
                percent: percentCompleted,
                uploading: true,
                message: 'Uploading',
                error: false
            }
        })
    }
    return sendDataRequest('uploadFile', data, 'RECORDPOST', progress)
        .then(function(resp) {
            if (resp.data.IsSuccess) {
                dispatch({
                    type: 'UPLOAD_PROGRESS',
                    payload: {
                        percent: 100,
                        uploading: true,
                        message: 'Uploaded',
                        error: false
                    }
                })
                setTimeout(() => {
                    dispatch({
                        type: 'UPLOAD_PROGRESS',
                        payload: {
                            percent: 100,
                            uploading: false,
                            message: 'Uploaded',
                            error: false
                        }
                    })
                }, 500)
            } else {
                dispatch({
                    type: 'UPLOAD_PROGRESS',
                    payload: {
                        percent: 0,
                        uploading: false,
                        message: 'Error',
                        error: true
                    }
                })
            }
            return resp
        }, function(err) {
            return dispatch({
                type: 'UPLOAD_PROGRESS',
                payload: {
                    percent: 0,
                    uploading: false,
                    message: 'Error',
                    error: true
                }
            })
        })
}

export const manipulateAudio1 = (options, addedToSend) => {
    let data = new FormData()
    data.append('mediaoptions', JSON.stringify(options))
    addedToSend.forEach((item) => {
        data.append(item.name, item)
    })
    return sendDataRequest('manipulateAudio', data, 'RECORDPOST')
}

export const manipulateAudioBackup = (options, page) => {
    return function(dispatch) {
        dispatch(loader.run({action: 'Show'}))

        return sendDataRequest('manipulateAudio', options, 'RECORDPOST')
            .then(function(response) {
                dispatch(loader.run({action: 'Hide'}))
                dispatch(modalActions.modal('close'))
                return dispatch({
                    type: 'GET_NEW_AUDIO',
                    payload: response.data
                })
            }.bind(this)).catch(function(err) {
                showError(err, dispatch)
            }.bind(this))
    }
}


//old version 
// 'use strict'

// import * as R from 'ramda'
// import axios from 'axios'

// import { sendRequest, sendDataRequest } from '../APIGateway/gateway'
// import { showError } from './errorActions'
// import loader from './loaderActions'
// import modalActions from './modalActions'
// import playerActions from './playerActions'
// import recordActions from './recordActions'
// import listenActions from './listenActions'
// import { convertUserData } from '../utils/dataConvertor'
// import { getDefaultColumns, setModules } from '../utils/dashboard'

// export const getUserInfo = () => {
//     return sendRequest('getUserSession', {payload: ''}, 'USERAPI')
//         .then(resp => resp.data)
// }

// const getAllUserSettings = () => {
//     return sendRequest('getAllUserSettings', {payload: ''}, 'USERAPI')
//         .then(resp => resp.data)
// }

// export const startApp = (config) => {
//     return dispatch => {
//         dispatch(loader.run({action: 'Hide'}))
//         dispatch({
//             type: 'REVIEW_MOUNTING_ELEMENT',
//             payload: config.mounting_element_id
//         })
//         getAllUserSettings()
//         const {initialData} = config
//         if (initialData && initialData.hasOwnProperty('session')) dispatch({
//             type: 'GET_HEADER_DATA_FULFILLED',
//             payload: {
//                 userData: convertUserData(initialData.session)
//             }
//         })
//         if (initialData && initialData.hasOwnProperty('columns')) dispatch(setAvailableColumns(initialData.columns))
//         if (initialData && initialData.hasOwnProperty('settings')) dispatch(setUserSettings(initialData.settings))
//         if (initialData && initialData.hasOwnProperty('session')) return
//         getUserInfo().then(resp => {
//             return dispatch({
//                 type: 'GET_HEADER_DATA_FULFILLED',
//                 payload: {
//                     userData: convertUserData(resp)
//                 }
//             })
//         })
//     }
// }

// const setAvailableColumns = (columns) => {
//     const defaultColumns = getDefaultColumns()
//     return dispatch => {
//         if (!columns || !columns.length) return dispatch({
//             type: 'TABLE_COLUMNS_CHANGED',
//             payload: defaultColumns,
//         })
//         columns.forEach(c => c.sortable = true)
//         const lastColumn = defaultColumns.splice(-1, 1)[0]
//         const concatColumns = defaultColumns.concat(columns)
//         concatColumns.push(lastColumn)
//         dispatch({
//             type: 'TABLE_COLUMNS_CHANGED',
//             payload: concatColumns,
//         })
//         dispatch({
//             type: 'AVAILABLE_COLUMNS_UPDATED',
//             payload: true,
//         })
//     }
// }

// const setUserSettings = (settings) => {
//     return (dispatch, getState) => {
//         const state = getState()
//         const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
//         const payload = ['dates', 'filters', 'columns', 'dashboardModules', 'tableSorting']
//         const availableColumns = R.clone(R.path(['dashboardReducer', 'tableColumns'], state))

//         const converted = R.converge(R.zipObj, [R.pluck('name'), R.pluck('value')])(settings)
//         const filters = converted.filters ? JSON.parse(converted.filters) : false
//         if (filters) {
//             if (!filters.hasOwnProperty('reviewType')) filters.reviewType = ''
//             if (!filters.hasOwnProperty('pendingOnly')) filters.pendingOnly = false
//             dispatch({
//                 type: 'SET_INITIAL_FILTERS',
//                 payload: filters
//             })
//         }
//         const columns = converted.columns ? JSON.parse(converted.columns) : false
//         if (columns) {
//             availableColumns.forEach(ac => {
//                 ac.checked = false
//                 const exist = columns.find(c => c.id === ac.id)
//                 if (!exist) {
//                     columns.push(ac)
//                 }
//             })
//             dispatch({
//                 type: 'TABLE_COLUMNS_CHANGED',
//                 payload: columns
//             })
//         }
//         const dates = converted.dates ? JSON.parse(converted.dates) : false
//         if (dates) dispatch({
//             type: 'SET_DATE_SETTINGS',
//             payload: dates
//         })
//         const tableSorting = converted.tableSorting ? JSON.parse(converted.tableSorting) : false
//         if (tableSorting) dispatch({
//             type: 'SET_TABLE_SORTING',
//             payload: tableSorting
//         })
//         const modules = converted.dashboardModules ? JSON.parse(converted.dashboardModules) : false
//         dispatch({
//             type: 'SET_DASHBOARD_MODULES_SETTINGS',
//             payload: setModules(modules, userData.modules, userData.modulesRequired)
//         })
//         dispatch({
//             type: 'USER_SETTINGS_UPDATED',
//             payload: true,
//         })
//     }
// }

// export const getTranscript = (transcriptData) => {
//     return sendDataRequest('getTranscript', transcriptData, 'CDSERVICE')
//         .then(resp => resp.data.d)
// }

// export const manipulateAudio = (options, addedToSend) => {
//     let data = new FormData()
//     data.append('mediaoptions', JSON.stringify(options))
//     addedToSend.forEach((item) => {
//         data.append(item.name, item)
//     })

//     return function(dispatch) {
//         dispatch({
//             type: 'UPLOAD_PROGRESS',
//             payload: {
//                 percent: 0,
//                 uploading: true,
//                 message: 'Uploading',
//                 error: false
//             }
//         })

//         const progress = function(progressEvent) {
//             let percentCompleted = Math.round((progressEvent.loaded * 100)/progressEvent.total);
//             dispatch({
//                 type: 'UPLOAD_PROGRESS',
//                 payload: {
//                     percent: percentCompleted,
//                     uploading: true
//                 }
//             })
//         }
//         return sendDataRequest('manipulateAudio', data, 'RECORDPOST', progress)
//             .then(function() {
//                 dispatch(modalActions.modal('close'))
//                 dispatch({
//                     type: 'UPLOAD_PROGRESS',
//                     payload: {
//                         percent: 100,
//                         uploading: true
//                     }
//                 })
//                 setTimeout(() => {
//                     dispatch({
//                         type: 'UPLOAD_PROGRESS',
//                         payload: {
//                             percent: 100,
//                             uploading: false
//                         }
//                     })
//                 }, 500)
//                 return dispatch({
//                     type: 'GET_NEW_AUDIO',
//                     payload: response.data
//                 })
//             }, function(err) {
//                 dispatch({
//                     type: 'UPLOAD_PROGRESS',
//                     payload: {
//                         percent: 0,
//                         uploading: false
//                     }
//                 })
//                 showError(err, dispatch)
//             })
//     }
// }

// export const uploadMergeFiles = (options, addedToSend, dispatch) => {
//     let data = new FormData()
//     data.append('mediaoptions', JSON.stringify(options))
//     addedToSend.forEach((item) => {
//         data.append(item.name, item)
//     })
//     dispatch({
//         type: 'UPLOAD_PROGRESS',
//         payload: {
//             percent: 0,
//             uploading: true,
//             message: 'Uploading',
//             error: false
//         }
//     })

//     const progress = function(progressEvent) {
//         let percentCompleted = Math.round((progressEvent.loaded * 100)/progressEvent.total);
//         dispatch({
//             type: 'UPLOAD_PROGRESS',
//             payload: {
//                 percent: percentCompleted,
//                 uploading: true,
//                 message: 'Uploading',
//                 error: false
//             }
//         })
//     }
//     return sendDataRequest('uploadFile', data, 'RECORDPOST', progress)
//         .then(function(resp) {
//             if (resp.data.IsSuccess) {
//                 dispatch({
//                     type: 'UPLOAD_PROGRESS',
//                     payload: {
//                         percent: 100,
//                         uploading: true,
//                         message: 'Uploaded',
//                         error: false
//                     }
//                 })
//                 setTimeout(() => {
//                     dispatch({
//                         type: 'UPLOAD_PROGRESS',
//                         payload: {
//                             percent: 100,
//                             uploading: false,
//                             message: 'Uploaded',
//                             error: false
//                         }
//                     })
//                 }, 500)
//             } else {
//                 dispatch({
//                     type: 'UPLOAD_PROGRESS',
//                     payload: {
//                         percent: 0,
//                         uploading: false,
//                         message: 'Error',
//                         error: true
//                     }
//                 })
//             }
//             return resp
//         }, function(err) {
//             return dispatch({
//                 type: 'UPLOAD_PROGRESS',
//                 payload: {
//                     percent: 0,
//                     uploading: false,
//                     message: 'Error',
//                     error: true
//                 }
//             })
//         })
// }

// export const manipulateAudio1 = (options, addedToSend) => {
//     let data = new FormData()
//     data.append('mediaoptions', JSON.stringify(options))
//     addedToSend.forEach((item) => {
//         data.append(item.name, item)
//     })
//     return sendDataRequest('manipulateAudio', data, 'RECORDPOST')
// }

// export const manipulateAudioBackup = (options, page) => {
//     return function(dispatch) {
//         dispatch(loader.run({action: 'Show'}))

//         return sendDataRequest('manipulateAudio', options, 'RECORDPOST')
//             .then(function(response) {
//                 dispatch(loader.run({action: 'Hide'}))
//                 dispatch(modalActions.modal('close'))
//                 return dispatch({
//                     type: 'GET_NEW_AUDIO',
//                     payload: response.data
//                 })
//             }.bind(this)).catch(function(err) {
//                 showError(err, dispatch)
//             }.bind(this))
//     }
// }
