'use strict'

export default class ShowHide extends React.Component {

    render() {
        const {hide, hidden} = this.props
        return (
            <div onClick={hide} class='audio-player-show-hide'>
                <div class='hide-player'>
                    <span class='material-icons'>{hidden ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                    {hidden ? 'Show' : 'Hide'}
                </div>
                <div class='show-player'>
                    <span class='material-icons'>arrow_drop_up</span>
                    Show
                </div>
            </div>
        )
    }
}
