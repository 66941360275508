export function getDefaultColumns() {
    return [
        // from system data
        {id: "callType",          value: "Type",          checked: true, isRequired: true,  sortableValue: false, sortable: false, searchable: false},
        // {id: "callId",            value: "Call ID",       checked: true, isRequired: false, sortableValue: false, sortable: false, searchable: false},
        {id: "callAudioLength",   value: "Duration",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "scorecardName",     value: "Scorecard",     checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "reviewDate",        value: "Review Date",   checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        // {id: "receivedDate",      value: "Received Date", checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "reviewerName",      value: "Reviewer",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "agentScore",        value: "Score",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "missedItemsCount",  value: "Missed Items",  checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        // from meta data
        {id: "callDate",          value: "Call Date",     checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "agentName",         value: "Agent",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "agentGroup",        value: "Group",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "campaign",          value: "Campaign",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "sessionId",         value: "Session ID",    checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        // {id: "profileId",         value: "Profile ID",    checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "prospectFirstName", value: "First Name",    checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "prospectLastName",  value: "Last Name",     checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "prospectPhone",     value: "Phone",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "prospectEmail",     value: "Email",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        // {id: "agentId",           value: "Agent ID",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        // required sortable
        {id: "callReviewStatus",  value: "Review Status", checked: true, isRequired: true, sortableValue: false,  sortable: true, searchable: false},
        {id: "callFailed",        value: "Result",        checked: true, isRequired: true, sortableValue: false,  sortable: true, searchable: false}, //sortable was false for some reason
        // icons
        {id: "icons",             value: "<icons>",       checked: true, isRequired: true, sortableValue: false,  sortable: false, searchable: false}
    ]
}

export function getDefaultColumnsChartType() {
    return [
        {id: "id",                                  value: "Name",                                className: 'table-box title-box agent-name', checked: true, isRequired: true,  sortableValue: true,  sortable: true, searchable: false},
        {id: "totalCalls",                          value: "Total Calls",                         className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "answeringService",                    value: "Answering Service",                   className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "notTheDecisionMaker",                 value: "Not The Decision Maker",              className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "priceTooHigh",                        value: "Price Too High",                      className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "callerRequiresOverThePhonePricing",   value: "Caller Req. Over The Phone Pr.",      className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "objectionToTripChargeDiagnosticFee",  value: "Obj. to trip charge diagnostic fee",  className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "schedulingConflict",                  value: "Scheduling Conflict",                 className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "AgentDoesNotAskForAPPT",              value: "Agent Does Not Ask For APPT",         className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "aPPTObjection",                       value: "APPT Objection",                      className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "mustContactLandlord",                 value: "Must Contact Landlord",               className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "callerRequestsCallbackLater",         value: "Caller Req. Callback Later",          className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "callerSaysTheyWillCallback",          value: "Caller Says They Will Callback",      className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "other",                               value: "Other",                               className: 'table-box title-box',            checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
    ]
}