'use strict'

import { connect } from 'react-redux'

import Word from './Word'

import playerActions from '../../../actions/playerActions'

@connect((store) => {
    return {
        transcript: store.listenReducer.transcript
    }
})

export default class Transcript extends React.Component {
    constructor(props) {
        super(props)

        this.searchText = this.searchText.bind(this)
        this.jumpPos = this.jumpPos.bind(this)
        this.setSearchValue = this.setSearchValue.bind(this)

        this.state = {
            searchString: '',
            searchRes: 0
        }
    }

    jumpPos(position) {
        this.props.dispatch(playerActions.jumpPos(position))
    }

    setSearchValue(word) {
        this.refs.searchInput.value = word
        this.searchText()
    }

    searchText() {
        const text = this.refs.searchInput.value
        this.setState({
            searchString: text
        }, () => {
            const scrolled = this.refs.scrolled
            const searchMatch = scrolled.getElementsByClassName('search-match-parent')[0]
            if (!searchMatch) return
            scrolled.scrollTop = searchMatch.offsetTop - scrolled.offsetTop
        })
    }

    searchMatch(transcript, transArray) {
        let highlightedWords = []
        let firstWordMatch = []
        let indexMatched = false
        const searchString = this.state.searchString
        const searchArray = searchString.split(' ').map((word) => {
            return word.toLowerCase()
        })
        const transLowCase = transArray.map((word) => {
            return word.toLowerCase()
        })
        if (!searchArray || !searchArray.length || searchArray[0] === '') return indexMatched
        transLowCase.forEach((searchWord, i) => {
            let word = searchWord
            if (~word.indexOf('<b>')) word = word.substring(3)
            if (word === searchArray[0]) {
                firstWordMatch.push(i)
            } else if (searchArray.length === 1 && word.substring(0, searchArray[0].length) === searchArray[0]) {
                firstWordMatch.push(i)
            }
        })

        if (searchArray && searchArray.length === 1 && searchArray[0] !== '') {
            return {
                highlightedWords: firstWordMatch,
                searchRes: firstWordMatch.length
            }
        }
        const searchArrays = []
        firstWordMatch.forEach((id, index) => {
            let match = true
            let array = [id]
            for (var i=1; i<searchArray.length-1; i++) {
                array.push(id + i)
                if (transLowCase[id + i] !== searchArray[i]) match = false
            }
            const lastWord = searchArray[searchArray.length - 1]
            if (lastWord) array.push(id + searchArray.length - 1)
            if (transLowCase[id + searchArray.length - 1].substring(0, lastWord.length).toLowerCase() !== lastWord) {
                match = false
            }
            if (match) searchArrays.push(array)
        })
        searchArrays.forEach((array) => {
            highlightedWords = highlightedWords.concat(array)
        })
        return {
            highlightedWords: highlightedWords,
            searchRes: searchArrays.length
        }
    }

    getRenderData(transc) {
        if (!(transc &&
            transc.Keyword &&
            transc.Words &&
            transc.Words.length)) return false
        const words = transc.Words
        const keywords = transc.Keyword
        let transcript = ''
        let formattedTranscript = ''
        let searchRes = ''

        words.forEach((wordObj, index) => {
            transcript += wordObj.word + ' '
        })

        keywords.forEach((kwObj, index) => {
            if (kwObj.required == "Banned") {
                transcript = transcript.replace(new RegExp(kwObj.keyword, 'ig'), "<b>" + kwObj.keyword + "</b>")
            }
        })

        const transArray = transcript.split(' ')
        const search = this.searchMatch(transcript, transArray)

        if (search && search.searchRes) {
            searchRes = search.searchRes
        }

        formattedTranscript = words.map((wordObj, index) => {
            const displayWord = transArray[index]
            let highlighted
            if (search && search.highlightedWords ) {
                highlighted = search.highlightedWords.find(id => {
                    return id === index
                })
            }

            let additionalClass = (typeof highlighted === 'undefined') ? '' : 'search-match'

            return <Word
                index={index}
                id={'word-' + index}
                key={index}
                wordObj={wordObj}
                displayWord={displayWord}
                additionalClass={additionalClass}
                forSubHeader={false}
                jumpPos={this.jumpPos} />
        })
        return {
            transcript: formattedTranscript,
            searchRes: searchRes ? searchRes : ''
        }
    }

    renderWords(words, className, clickable) {
        return words.map((w, i) => <div
            key={'word-' + className + i}
            onClick={clickable ? () => this.setSearchValue(w.keyword) : () => {}}
            class={className + ' word'}>
            {w.keyword}
        </div>)
    }

    renderRightPart() {
        const {transcript} = this.props
        const bannedWords = []
        const requiredWords = []
        const notPronounced = []
        transcript.Keyword.forEach(kwObj => {
            if (kwObj.required.toUpperCase() === 'REQUIRED') {
                const pronounced = transcript.Words.find((wordObj) => {
                    return wordObj.word === kwObj.keyword
                })
                if (pronounced) {
                    requiredWords.push(kwObj)
                } else {
                    notPronounced.push(kwObj)
                }
            }
            if (kwObj.required.toUpperCase() === 'BANNED') {
                const flagged = transcript.Words.find((wordObj) => {
                    return wordObj.word === kwObj.keyword
                })
                if (flagged) bannedWords.push(kwObj)
            }
        })
        return (
            <div class='right-part'>
                <div class='banned-required-words'>
                    <div class='title'>Banned {bannedWords.length ? ('(' + bannedWords.length + ')') : ''}</div>
                    <div class='words-container'>
                        {
                            bannedWords.length ?
                            this.renderWords(bannedWords, 'banned', true) :
                            'No banned words'
                        }
                    </div>
                </div>
                <div class='banned-required-words'>
                    <div class='title'>Required {requiredWords.length ? ('(' + requiredWords.length + ')') : ''}</div>
                    <div class='words-container'>
                        {
                            requiredWords.length && notPronounced.length ? null : 'No required words'
                        }
                        {
                            requiredWords.length ?
                            this.renderWords(requiredWords, 'required', true) :
                            null
                        }
                        {
                            notPronounced.length ?
                            this.renderWords(notPronounced, 'not-pronounced', false) :
                            null
                        }
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {transcript} = this.props
        const renderData = this.getRenderData(transcript)
        return (
            <div class='transcription-container'>
                {
                    renderData ?
                    <div class='transcript-inner'>
                        <div class='left-part'>
                            <div class='search-container'>
                                <div class='transcript-search-input'>
                                    <span class='search-icon icon-ic-search' />
                                    <input
                                        ref='searchInput'
                                        onChange={this.searchText}
                                        type="text"
                                        placeholder="Search transcript" />
                                    {
                                        renderData.searchRes &&
                                        <span class='search-result'>
                                            {renderData.searchRes + ' result' + (renderData.searchRes > 1 ? 's' : '')}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div ref='scrolled' class='transcript-wrapper'>
                                <div class='transcript-content'>
                                    {renderData.transcript}
                                </div>
                            </div>
                        </div>
                        {this.renderRightPart()}
                    </div> : null
                }
            </div>
        )
    }
}
