'use strict'

import { connect } from 'react-redux'
import Modal from 'react-modal'

import dashboardActions from '../actions/dashboardActions'

import InfoModal from './modal/InfoModalSuccess'
import SelectedCallDetails from './ui/miniReview/SelectedCallDetails'
import QuestionDetailsContainer from './ui/questionDetails/QuestionDetailsContainer'

const callDetailsStyles = {
    content: {
        padding: 0,
        position: 'relative',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: 'calc(100% - 100px)',
        maxHeight: '900px',
        minHeight: '600px',
        width: 'calc(100% - 60px)',
        minWidth: '1260px',
        maxWidth: '1800px',
        margin: '50px auto',
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
    overlay: {
        zIndex: 1,
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.5)'
    }
}

@connect((store) => {
    return {
        expanded: store.headerReducer.leftPanelExpanded,
        activeCall: store.dashboardReducer.activeCall
    }
})

export default class StartedLayout extends React.Component {
    constructor(props) {
        super(props)

        this.closeModal = this.closeModal.bind(this)
    }

     componentWillMount() {
        Modal.setAppElement('body');
     }

    closeModal() {
        this.props.dispatch(dashboardActions.closeCallMiniReview())
    }

    render() {
        const {expanded, activeCall} = this.props
        return (
            <div class="lasoft-outer-wrapper">
                <div class="lasoft-content">
                    {this.props.content}
                </div>
                <InfoModal />
                <Modal
                    isOpen={activeCall}
                    onRequestClose={this.closeModal}
                    closeTimeoutMS={10}
                    shouldCloseOnOverlayClick={true}
                    style={callDetailsStyles}
                    contentLabel="Mini-review">
                    <SelectedCallDetails fromForm={false} />
                </Modal>
                <QuestionDetailsContainer />
            </div>
        )
    }
}
