'use strict'

import appConfig from '../../../appConfig'

import {systemDataPropType} from './propTypes'

export default class CallIDCell extends React.Component {
    static propTypes = {
        systemData: systemDataPropType.isRequired,
    }

    constructor(props) {
        super(props)

        this.stopPropagation = this.stopPropagation.bind(this)
    }

    stopPropagation(e) {
        e.stopPropagation()
        if (this.props.callback) this.props.callback()
    }

    render() {
        const {callType, callId, xId, callReviewStatus} = this.props.systemData
        const web = callType === 'website' ? true : false
        let title = 'Open Review'
        let iconClassName = 'icon-ic-call'
        switch (callType) {
            case 'website':
                iconClassName = 'icon-ic-web'
                title = 'Open Website Review'
                break;
            case 'audio':
                title = 'Open Call Review'
                break;
            case 'chat':
                iconClassName = 'icon-ic-comment'
                title = 'Open Chat Review'
                break;
            default:
        }
        const naStatus = callReviewStatus === 'transcript only' || callReviewStatus === 'pending'
        return (
            <td class='call-id-cell'>
                {
                    naStatus ?
                    <div class='icon-container'>
                        <div title={title} class={iconClassName}></div>
                    </div> :
                    <a class='icon-container' onClick={this.stopPropagation} href={appConfig.loadUrl + '/review/' + callId} target='_blank'>
                        <div title={title} class={iconClassName}></div>
                    </a>
                }
            </td>
        )
    }
}
