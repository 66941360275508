'use strict'

import {questionInfoPropType} from './propTypes'

import SimpleQuestion from './SimpleQuestion'
import CompositeQuestion from './CompositeQuestion'

export default class Content extends React.Component {
    static propTypes = {
        activeQuestion: questionInfoPropType
    }

    render() {
        const {activeQuestion} = this.props
        const {isComposite} = activeQuestion
        return isComposite ?
        <CompositeQuestion data={activeQuestion} /> :
        <SimpleQuestion data={activeQuestion} />
    }
}
