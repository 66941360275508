'use strict'

import moment from 'moment'

export default class SistemCommentsList extends React.Component {

    render() {
		const {systemComments} = this.props
        return (
    		<div class='comments-list'>
    			{
    				systemComments && systemComments.map((c, i) => {
    					return <div class='comment-noti-row' key={'com-' + i}>
    						<div class='com-rev-info'>
		    					<div class='user-name'>
			    					<div class='name'>{c.user.userData.userName}</div>
			    					<div class='role'>{`(${c.user.userRole.userRoleName})`}</div>
			    				</div>
			    				<div class='date'>{
			    					moment(c.commentDate).format("MMM D, YYYY, hh:ss a")
			    				}</div>
			    				<div class='line' />
		    				</div>
		    				{
		    					c.text ?
		    					<div class='comment' dangerouslySetInnerHTML={{__html: c.text}}></div> : 
		    					<div class='comment'>No comments</div>
		    				}
    					</div>
    				})
    			}
    		</div>
        )
    }
}
