'use strict'

import moment from 'moment'

const details = [
    {id: 'sessionId', value: 'Session ID'},
    {id: 'prospectFirstName', value: 'First Name'},
    {id: 'prospectLastName', value: 'Last Name'},
    {id: 'prospectEmail', value: 'Email'},
    {id: 'prospectPhone', value: 'Phone'},
    {id: 'prospectAddress', value: 'Address'},
    {id: 'reviewDate', value: 'Review Date'},
    {id: 'callDate', value: 'Call Date'},
    {id: 'receivedDate', value: 'Received Date'},
    {id: 'profileId', value: 'Profile ID'},
    {id: 'reviewerName', value: 'Reviewer'},
    {id: 'callAudioLength', value: 'Duration'},
]

import {systemDataPropType, metaDataPropType} from './propTypes'
import {getCallDetailsColumnLabel, fromSecsToMinsAndSecs} from '../../../utils/globalHelpers'

export default class Details extends React.Component {
    static propTypes = {
        systemData: systemDataPropType.isRequired,
        metaData: metaDataPropType.isRequired
    }

    renderContent() {
        const {systemData, metaData} = this.props
        const web = systemData.callType === 'website' || systemData.callType === 'chat' ? true : false
        return details.map((item, i) => {
            if (!systemData[item.id] && !metaData[item.id]) return
            const label = item.value
            if (label === 'Duration' && web) return
            const value = label === 'Duration' ?
            fromSecsToMinsAndSecs(Math.round(systemData[item.id])) :
            item.id === 'callDate' || item.id === 'reviewDate' || item.id === 'receivedDate' ? moment(systemData[item.id] || metaData[item.id]).format("MMM D, YYYY") :
            systemData[item.id] || metaData[item.id]
            return (
                <div key={i + Math.random()} class='details-item'>
                    <div class='details-item-label'>{label || 'Address'}:</div>
                    <div class='details-item-value'>{value}</div>
                </div>
            )
        })
    }

    render() {
        return (
            <div class='details-content'>{this.renderContent()}</div>
        )
    }
}
