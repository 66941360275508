'use strict';

import * as R from 'ramda'

const initialState = {
    loading                 : false,
    error                   : false,
    activeScorecard         : null,
    emptyScorecard          : null,
    nodata                  : false,
    isAudio                 : false,
    audioLink               : null,
    clerkData               : [],
    generalComment          : [],  // one item array of {comment:"comment text",comment_header:"general comment",comment_pos:""}
    qaWhisper               : '',
    qaStart                 : '',
    activeQuestion          : null,
    activeQuestionSection   : null,
    maxHeightAvailable      : window.innerHeight - 300,
    focusedQuestion         : null,
    onHold                  : false,
    newAgentDealerShips     : null,
    newDealerShips          : null,
    heartbeatInterval       : 120000,
    heartbeatUpdater        : false,
    selectedDropdownAnswers : [],
}

export default function reducer(state=initialState, action) {
    switch (action.type) {

        case "NEW_LISTEN_HEARTBEAT_UPDATE": {
            return {
                ...state,
                heartbeatUpdater: !state.heartbeatUpdater,
            }
        }

        case "LISTEN_SET_NEW_AGENTS_DEALERSHIP_SPEC_QUESTION": {
            return {
                ...state,
                newAgentDealerShips: action.payload,
            }
        }
        case "LISTEN_SET_NEW_DEALERSHIP_SPEC_QUESTION": {
            return {
                ...state,
                newDealerShips: action.payload,
            }
        }
        case "LISTEN_NEXT_CALL_LOADING": {
            return {
                ...state,
                loading: true,
                error: false,
                nodata: false,
            }
        }
        case "LISTEN_NEXT_CALL_ERROR": {
            return {
                ...state,
                loading: false,
                error: true,
                nodata: false,
            }
        }
        case "LISTEN_NEXT_CALL_FULFILLED": {
            return {
                ...state,
                activeScorecard: R.clone(action.payload),
                emptyScorecard: R.clone(action.payload),
                loading: false,
                error: false,
                nodata: false,
            }
        }
        case "LISTEN_NEXT_CALL_NODATA": {
            return {
                ...state,
                nodata: true,
            }
        }
        case "SET_AUDIO_LINK": {
            return {
                ...state,
                audioLink: action.payload,
            }
        }
        case "SET_IS_AUDIO": {
            return {
                ...state,
                isAudio: action.payload,
            }
        }
        case "LISTEN_SET_ACTIVE_SCORECARD": {
            return {
                ...state,
                activeScorecard: action.payload,
            }
        }
        case "LISTEN_SET_QA_START": {
            return {
                ...state,
                qaStart: action.payload,
            }
        }
        case "LISTEN_SET_ACTIVE_QUESTION": {
            return {
                ...state,
                activeQuestion: action.payload,
            }
        }
        case "LISTEN_SET_ACTIVE_QUESTION_SECTION": {
            return {
                ...state,
                activeQuestionSection: action.payload,
            }
        }
        case "NEW_LISTEN_SET_MAX_HEIGHT_AVAILABLE": {
            return {
                ...state,
                maxHeightAvailable: action.payload,
            }
        }
        case "NEW_LISTEN_SET_FOCUSED_QUESTION": {
            return {
                ...state,
                focusedQuestion: action.payload,
            }
        }
        case "SET_ON_HOLD": {
            return {
                ...state,
                onHold: action.payload,
            }
        }
        case "NEW_LISTEN_SET_ANSWERS_FOR_DROPDOWN": {
            const answers = action.payload.answers ? action.payload.answers : null
            return {
                ...state,
                selectedDropdownAnswers: answers
            }
        }
        case "NEW_LISTEN_SET_GENERAL_COMMENT": {
            return {
                ...state,
                generalComment: action.payload
            }
        }
    }

    return state
}
