'use strict'

import * as R from 'ramda'
import classNames from 'classnames'
import { connect } from 'react-redux'

import {fromSecsToMinsAndSecs} from '../../../utils/globalHelpers'
import PlayerMarker from './PlayerMarker'

@connect((store) => {
    return {
        leftPanelExpanded: store.headerReducer.leftPanelExpanded,
    }
})

export default class PlayerDiagram extends React.Component {
    constructor(props) {
        super(props)

        const {duration} = props
        const devided = Math.floor(duration / 75)
        const maxMag = devided < 2 ? 2 : devided
        this.state = {
            position: props.position || 0,
            sliderWidth: 500,
            audioLength: 300,
            text: '',
            canvasHover: false,
            hoverMouseX: false,
            hoverMouseY: false,
            hoverMarker: null,
            ctrlKey: false,
            magnyfying: maxMag > 5 ? maxMag / 2 : 2 ,
            minMag: 1.5,
            maxMag: maxMag
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.setSliderWidth, false)
        const canvas = this.refs.audiogram
        const container = this.refs.diagramcontainer
        if (!canvas) return
        canvas.focus()
        container.removeEventListener('wheel', this.onWheel, true)
        container.addEventListener('wheel', this.onWheel, true)
        canvas.addEventListener('click', this.onPositionChange, false)
        canvas.addEventListener('mousemove', this.onMouseMove, false)
        canvas.addEventListener('mouseout', this.onMouseOut, false)
        document.addEventListener('keydown', this.onKeyDown, false)
        document.addEventListener('keyup', this.onKeyUp, false)
        canvas.oncontextmenu = (e) => {
            e.preventDefault()
            this.onPositionChange(e)
        }
        setTimeout(() => {
            this.imitateResize()
        }, 500)
    }

    onWheel = (e) => {
        const glass = this.refs.magnifying
        if (!glass) return
        if(e.ctrlKey == true) {
            e.preventDefault()
            e.stopPropagation()
            let {magnyfying, minMag, maxMag} = this.state
            const delta = e.deltaY
            if (delta < 0) {
                magnyfying += 0.5
                if (magnyfying > maxMag) magnyfying = maxMag
            } else if (delta > 0) {
                magnyfying -= 0.5
                if (magnyfying < minMag) magnyfying = minMag
            }
            this.setState({
                magnyfying: magnyfying
            })
            return false
        }
    }

    onKeyDown = (e) => {
        if (e.ctrlKey == true) {
            const {hoverMouseX, hoverMouseY} = this.state
                if (hoverMouseX !== false && 
                    hoverMouseY !== false) {
                this.setState({
                    ctrlKey: true
                })
            }
        }
    }
    onKeyUp = (e) => {
        if (e.ctrlKey == false && e.keyCode == 17) {
            this.setState({
                ctrlKey: false
            })
        }
    }

    onMouseMove = (e) => {
        const canvas = this.refs.audiogram
        const rect = canvas.getBoundingClientRect();
        const mouseX = e.clientX - rect.left
        const mouseY = e.clientY - rect.top
        this.setState({
            canvasHover: true,
            hoverMouseX: mouseX,
            hoverMouseY: mouseY,
            ctrlKey: e.ctrlKey == true
        })
    }

    onMouseOut = (e) => {
        if (!e.ctrlKey && !this.state.ctrlKey) {
            this.setState({
                canvasHover: false,
                hoverMouseX: false,
                hoverMouseY: false,
                ctrlKey: false
            })
        }
    }

    onPositionChange = (e) => {
        const {audiogramData, positionChanged} = this.props
        const canvas = this.refs.audiogram
        const rect = canvas.getBoundingClientRect();
        const mouseX = e.clientX - rect.left

        const width = canvas.width
        const barWidth = width / audiogramData.length
        const newPosition = Math.floor(mouseX / barWidth)
        if (audiogramData[newPosition] === -1) return
        positionChanged(newPosition)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setSliderWidth, false)
        const canvas = this.refs.audiogram
        canvas.removeEventListener('click', this.onPositionChange, false)
        canvas.removeEventListener('mousemove', this.onMouseMove, false)
        canvas.removeEventListener('mouseout', this.onMouseOut, false)
        document.removeEventListener('keydown', this.onKeyDown, false)
        document.removeEventListener('keyup', this.onKeyUp, false)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.leftPanelExpanded !== this.props.leftPanelExpanded) {
            setTimeout(() => {
                this.imitateResize()
            }, 300)
        }
        if (nextProps.error) return
    }

    imitateResize() {
        let event = new Event('resize')
        window.dispatchEvent(event)
    }

    setSliderWidth = () => {
        this.forceUpdate()
    }

    getMarkers() {
        const {duration, position, markers} = this.props
        const container = this.refs.diagramcontainer
        if (!container) return null
        const rect = container.getBoundingClientRect()
        const sliderWidth = rect.width
        const audioLength = duration || this.state.audioLength
        if (!sliderWidth || !audioLength || !markers.length) return
        return markers.map((marker, index) => {
            return <PlayerMarker
                marker={marker}
                position={position}
                showHideText={this.showHideText}
                key={index}
                sliderWidth={sliderWidth}
                audioLength={audioLength} />
        })
    }

    showHideText = (text) => {
        this.setState({
            text: text
        })
    }

    getPosition() {
        return fromSecsToMinsAndSecs(this.props.position || 0)
    }

    formatDuration() {
        return fromSecsToMinsAndSecs(parseInt(this.props.duration || 0, 10))
    }

    getAudioDiagram() {
        const {audiogramData, position = 0, playing} = this.props
        let {canvasHover, hoverMouseX, ctrlKey} = this.state
        if (!audiogramData) return null
        const canvas = this.refs.audiogram
        if (!canvas) return null
        const container = this.refs.diagramcontainer
        const rect = container.getBoundingClientRect()
        canvas.width = rect.width
        const width = rect.width
        const ctx = canvas.getContext('2d')
        const maxHeight = 30
        const length = audiogramData.length
        const barWidth = width / length
        const defaultColor = '#B0BEC5'
        const playedColor = '#039BE5'
        const hoverColor = 'rgba(3,155,229, 0.3)'
        const positiveColor = '#149BDF'
        const negativeColor = '#c0392b'
        const hoverPosition = canvasHover ? Math.floor(hoverMouseX / barWidth) : 0
        let gap = 1
        const delta = width / length
        if (delta < 3) {
            gap = 0
        } else if (delta > 6){
            gap = 3
        }
        for(let i = 0; i < audiogramData.length; i++) {
            const posValue = audiogramData[i]
            let height
            if (posValue === -1) {
                height = maxHeight / 4
                ctx.fillStyle = negativeColor
            } else {
                height = Math.round(posValue * maxHeight)
                if (canvasHover) {
                    if (hoverPosition > position) {
                        if (i <= position) {
                            ctx.fillStyle = playedColor
                        } else if (i > position && i <= hoverPosition) {
                            ctx.fillStyle = hoverColor
                        } else if (i > hoverPosition) {
                            ctx.fillStyle = defaultColor
                        }
                    } else if (hoverPosition <= position) {
                        if (i <= hoverPosition) {
                            ctx.fillStyle = playedColor
                        } else if (i > hoverPosition && i <= position) {
                            ctx.fillStyle = hoverColor
                        } else if (i > position) {
                            ctx.fillStyle = defaultColor
                        }
                    }
                } else {
                    if (i > position || (position === 0 && !playing)) {
                        ctx.fillStyle = defaultColor
                    } else {
                        ctx.fillStyle = playedColor
                    }
                }
            }
            ctx.fillRect(i * barWidth, 10 + maxHeight - height, barWidth - gap, height)
        }
        if (canvasHover) {
            const positionOnPlayer = hoverPosition * barWidth
            const positionTime = fromSecsToMinsAndSecs(hoverPosition)
            const posValue = audiogramData[hoverPosition]
            const timeColor = posValue === -1 ? negativeColor : positiveColor
            return (
                ctrlKey ?
                <div class='magnifying-glass-container' style={{left: positionOnPlayer - 80}}>
                    {this.getMagnifyingGlass(hoverPosition)}
                </div>
                :
                <div class='proposed-position' style={{left: positionOnPlayer}}>
                    <div class='position-time' style={{color: timeColor}}>{positionTime}</div>
                    <div class='position-line'></div>
                </div>
            )
        }
    }

    componentDidUpdate() {
        if (this.magnyfyingGlass) {
            this.setMagnifyingGlass()
        }
    }

    getMagnifyingGlass(hoverPosition) {
        const {hoverMarker} = this.state
        const width = '160px'
        const height = '90px'
        this.magnyfyingGlass = true
        this.magnyfyingPosition = hoverPosition
        const markerClass = classNames({
            'hover-marker-text': true,
            'is-negative': hoverMarker && hoverMarker.negative
        })
        return (
            <>
                {hoverMarker && 
                    <div class='hover-marker'>
                        <span class={markerClass}>{hoverMarker.text}</span>
                    </div>
                }
                <canvas ref='magnifying' class='magnifying-canvas' width={width} height={height} />
            </>
        )
    }

    onGlassMove = (e) => {
        const glass = this.refs.magnifying
        if (!glass) return
        const {audiogramData} = this.props
        let {hoverMouseX} = this.state
        const {magnyfying: coefficient, maxMag} = this.state
        const container = this.refs.diagramcontainer
        const cRect = container.getBoundingClientRect()
        const width = cRect.width
        const length = audiogramData.length
        const barWidth = width / length
        const delta = barWidth * (maxMag / coefficient) / 2 //delta depending on zoom
        if (e.movementX >= 1) {
            hoverMouseX += delta
        } else if (e.movementX <= -1) {
            hoverMouseX -= delta
        }
        if (hoverMouseX < 0) hoverMouseX = 0
        if (hoverMouseX > width - 1) hoverMouseX = width - 1
        this.setState({
            canvasHover: true,
            hoverMouseX: hoverMouseX,
            ctrlKey: e.ctrlKey == true
        })
    }

    inGlassPositionChange = (e) => {
        const {audiogramData, positionChanged, markers} = this.props
        const glass = this.refs.magnifying
        if (!glass) return
        const pos = this.magnyfyingPosition
        if (audiogramData[pos] === -1) return
        const marker = markers.find(marker => marker.position === pos)
        
        if (e.button === 0) {
            positionChanged(pos)
        } else if (e.button === 2){
            if (marker.onclick) marker.onclick(marker.QID, marker.position)
        }
    }

    setMagnifyingGlass() {
        const glass = this.refs.magnifying
        if (!glass) return
        glass.removeEventListener('click', this.inGlassPositionChange, false)
        glass.oncontextmenu = (e) => {
            e.preventDefault()
            this.inGlassPositionChange(e)
        }
        glass.addEventListener('click', this.inGlassPositionChange, false)
        glass.removeEventListener('mousemove', this.onGlassMove, false)
        glass.addEventListener('mousemove', this.onGlassMove, false)
        glass.requestPointerLock()
        glass.focus()
        const {magnyfying: coefficient, hoverMarker} = this.state
        const {audiogramData, position = 0, markers} = this.props
        const pos = this.magnyfyingPosition
        const ctx = glass.getContext('2d')
        const container = this.refs.diagramcontainer
        const rect = container.getBoundingClientRect()
        const width = rect.width
        const maxHeight = 80
        const maxWidth = 160
        ctx.clearRect(0, 0, maxWidth, maxHeight + 10);
        const length = audiogramData.length
        const barWidth = width / length
        if (barWidth/coefficient > maxWidth) {
            this.magnyfyingGlass = false
            console.log('error')
            this.setState({
                ctrlKey: false
            })
            return
        }
        const magBarWidth = barWidth * coefficient
        const defaultColor = '#B0BEC5'
        const playedColor = '#039BE5'
        const currentColor = '#2f3234'
        const hoverColor = 'rgba(3,155,229, 0.3)'
        const positiveColor = '#149BDF'
        const negativeColor = '#c0392b'
        const numberOfBars = Math.floor(maxWidth / magBarWidth)
        const leftIndent = Math.floor(numberOfBars/2)
        for (let i = 0; i < numberOfBars; i++) {
            const index = pos + i - leftIndent
            const posValue = audiogramData[index]
            let height
            if (posValue === -1) {
                height = maxHeight / 4
                ctx.fillStyle = negativeColor
            } else {
                height = Math.round(posValue * maxHeight)
                if (index == pos) {
                    //Mark bar position
                    ctx.fillStyle = currentColor
                } else {
                    if (pos > position) {
                        if (index <= position) {
                            ctx.fillStyle = playedColor
                        } else if (index > position && index <= pos) {
                            ctx.fillStyle = hoverColor
                        } else if (index > pos) {
                            ctx.fillStyle = defaultColor
                        }
                    } else if (pos <= position) {
                        if (index <= pos) {
                            ctx.fillStyle = playedColor
                        } else if (index > pos && index <= position) {
                            ctx.fillStyle = hoverColor
                        } else if (index > position) {
                            ctx.fillStyle = defaultColor
                        }
                    }
                }
            }
            const gap = Math.abs(magBarWidth - 1) > 0.5 ? 1 : 0
            ctx.fillRect(i * magBarWidth, maxHeight - height, magBarWidth - gap, height)
        }
        markers.forEach(marker => {
            const mPos = marker.position
            const negative = marker.negative
            const inside = mPos >= (pos - leftIndent) && mPos <= (pos + numberOfBars - leftIndent)
            if (inside) {
                let posx = magBarWidth * (mPos - pos + leftIndent) + (magBarWidth / 2)
                let posy = 85
                //Draw marker
                ctx.beginPath()
                let radius = magBarWidth / 2 - 0.1
                radius = radius > 2 ? (radius > 4 ? 4 : radius) : 2
                ctx.arc(posx, posy, radius , 0, 2 * Math.PI)
                ctx.fillStyle = negative ? negativeColor : positiveColor
                ctx.fill()
            }
            return inside
        })
        //Draw time 
        ctx.fillStyle = 'white'
        ctx.fillRect(60, 0, 40, 12) //background
        const posValue = audiogramData[pos]
        const timeColor = posValue === -1 ? negativeColor : positiveColor
        ctx.fillStyle = timeColor
        ctx.fillRect(78, 0, 3, 2) //mark
        const positionTime = fromSecsToMinsAndSecs(pos)
        ctx.font = '11px Arial'
        ctx.fillText(positionTime, 65, 10)
        //dashed line
        ctx.beginPath();
        ctx.setLineDash([5, 10]);
        ctx.moveTo(79, 15);
        ctx.strokeStyle = timeColor
        ctx.lineTo(79, 80);
        ctx.stroke();

        const marker = markers.find(marker => marker.position === pos)
        if (marker) {
            setTimeout(() => {
                this.setState({
                    hoverMarker: marker
                })
            }, 1)
        } else if (hoverMarker) {
            setTimeout(() => {
                this.setState({
                    hoverMarker: null
                })
            }, 1)
        }
    }

    render() {
        const {error} = this.props
        const pos = this.getPosition()
        const durationFormated = this.formatDuration()
        const markers = this.getMarkers()
        return(
            error ? 
            <div class='player-error'>
                <div title='Audio loading error' class='icon-ic-error'/>
                <div class='err-massage'>
                    Couldn’t load the audio. Try again later.
                </div>
            </div>
            :
            <div class='timeline-section diagram-timeline'>
                <div class='player-position'>{pos}</div>
                <div class='range-options'>
                    <div ref='diagramcontainer' class='audio-diagram' id='audio-diagram'>
                        <canvas ref='audiogram' class='diagram-canvas' tabIndex='1' width='1000px' height='65px' />
                        {this.getAudioDiagram()}
                    </div>
                    <div class='markers-container'>
                        {markers}
                    </div>
                    <span class='marker-text'>{this.state.text}</span>
                </div>
                <div class='section-title'>
                    <span class='player-timer'>{durationFormated}</span>
                </div>
            </div>
        )
    }
}
