'use strict'

import * as R from 'ramda'

import { getEmptyNotiFilters, getDefaultDateSettings } from '../../utils/filters'

const initialState = {
    countsFiltered                : 0,
    countsTotal                   : 0,
    dateSettings                  : getDefaultDateSettings(),
    setFilters                    : getEmptyNotiFilters(),
    newFilters                    : getEmptyNotiFilters(),
    filtersLoading                : false,
    filtersError                  : false,
    availableFilters              : null,
    filtersFormVisible            : false,
    dayCalls                      : [],
    dayCallsLoading               : false,
    dayCallsLoadingError          : false,
    availableNotiFiltersTimeoutErr: false,
    notiFilters                   : null,
    filterByDateClosed            : false,
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "SET_NOTIFICATION_FILTER_BY_DATE_CLOSED": {
            return {
                ...state,
                filterByDateClosed: action.payload,
            }
        }
        case "SET_ADDITIONAL_NOTIFICATION_FILTERS": {
            return {
                ...state,
                notiFilters: R.clone(action.payload)
            }
        }
        case "SET_NOTIFICATION_FILTERS": {
            return {
                ...state,
                newFilters: R.clone(action.payload)
            }
        }
        case "SET_NOTIFICATION_DATE_SETTINGS": {
            return {
                ...state,
                dateSettings: action.payload
            }
        }
        case "SET_INITIAL_NOTIFICATION_FILTERS": {
            return {
                ...state,
                newFilters: R.clone(action.payload),
                setFilters: R.clone(action.payload),
            }
        }
        case "AVAILABLE_NOTIFICATION_FILTERS_LOADED": {
            return {
                ...state,
                availableFilters: action.payload,
                filtersLoading: false,
                filtersError: false
            }
        }
        case "NOTIFICATION_COUNTS_TOTAL": {
            return {
                ...state,
                countsTotal: action.payload,
            }
        }
        case "NOTIFICATION_COUNTS_FILTERED": {
            return {
                ...state,
                countsFiltered: action.payload,
            }
        }
        case "AVAILABLE_NOTIFICATION_FILTERS_LOADING": {
            return {
                ...state,
                filtersLoading: action.payload
            }
        }
        case "AVAILABLE_NOTIFICATION_FILTERS_ERROR": {
            return {
                ...state,
                filtersLoading: false,
                filtersError: true
            }
        }
        case "AVAILABLE_NOTIFICATION_FILTERS_TIMEOUT_ERR": {
            return {
                ...state,
                availableNotiFiltersTimeoutErr: action.payload,
            }
        }
        case "NOTIFICATION_CALENDAR_COUNTS_LOADED": {
            return {
                ...state,
                dayCalls: action.payload,
                dayCallsLoadingError: false
            }
        }
        case "NOTIFICATION_CALENDAR_COUNTS_LOADING": {
            return {
                ...state,
                dayCallsLoading: action.payload,
                dayCallsLoadingError: false
            }
        }
        case "NOTIFICATION_CALENDAR_COUNTS_ERROR": {
            return {
                ...state,
                dayCallsLoading: false,
                dayCallsLoadingError: action.payload
            }
        }
        case "SHOW_HIDE_FILTERS_FORM": {
            return {
                ...state,
                filtersFormVisible: action.payload
            }
        }
    }
    return state
}
