'use strict'

import * as R from 'ramda'
import ReactSlider from 'react-slider'

export default class PlayerSpeed extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            speed: parseInt(props.settings.speed*100, 10) || 100
        }
        this.setPlaybackRate = this.setPlaybackRate.bind(this)
        this.decreaseSpeed = this.decreaseSpeed.bind(this)
        this.onChangePlaybackRate = this.onChangePlaybackRate.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (!R.equals(nextProps.settings, this.props.settings)) {
            const speed = parseInt(nextProps.settings.speed*100, 10) || 100
            this.refs.speed.setState({
                value: [speed]
            })
			this.setPlaybackRate(speed)
		}
    }


    decreaseSpeed() {
        this.refs.speed.setState({
            value: [100]
        })
        this.setPlaybackRate(100)
    }

    changeSpeed = (add) => {
        const currentSpeed = this.refs.speed.getValue()
        let val = currentSpeed + add
        if (val < 70) val = 70
        if (val > 300) val = 300
        this.refs.speed.setState({
            value: [val]
        })
        this.setPlaybackRate(val)
    }

    onChangePlaybackRate() {
        const val = this.refs.speed.getValue()
        this.setPlaybackRate(val)
    }

    setPlaybackRate(val) {
        this.props.setPlaybackRate(val/100)
        window.localStorage.playerSpeed = val
        this.setState({
            speed: val
        })
    }

    render() {
        const speed = this.state.speed
        const {speedInc} = this.props.settings
        const error = this.props.error
        return (
            <div class={'speed-section' + (error ? ' error' : '')}>
                <div class='speed-options'>
                    <div onClick={this.decreaseSpeed} class='speed-button speed-dimen' title='Reset speed'>
                        <span class='icon-ic-play-arrow'/>
                        <span class='icon-ic-play-arrow'/>
                    </div>
                    <span class='slider-sign minus' onClick={() => this.changeSpeed(-10)}>-</span>
                    <ReactSlider
                        ref='speed'
                        min={70}
                        max={300}
                        step={speedInc}
                        defaultValue={speed}
                        onChange={this.onChangePlaybackRate}
                        className='speed-slider' withBars>
                        <div class='slider-trigger speed-triger'></div>
                    </ReactSlider>
                    <span class='slider-sign plus' onClick={() => this.changeSpeed(10)}>+</span>
                </div>
                <div class='section-title'>{speed/100}x</div>
                <div class='tooltip-hot-key'>
                    <div>
                        <div class='name'>Speed down (5 points)</div>
                        <div class='key'>F9</div>
                    </div>
                    <div>
                        <div class='name'>Speed up (5 points)</div>
                        <div class='key'>F10</div>
                    </div>
                </div>
            </div>
        )
    }
}
