'use strict'

import * as R from 'ramda'
import moment from 'moment'

const initialState = {
    apps: null,
    periods: null,
    initialDataLoading: false,
    initialDataError: false,
    activeApp: null,
    activeScorecard: null,
    startDate: moment().isoWeekday(7).format('YYYY-MM-DD'),
    requiredQAs: null,
    requiredQAsLoading: false,
    requiredQAsError: false,
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "SCHEDULING_APPS_FULFILLED": {
            return {
                ...state,
                apps: action.payload,
                initialDataLoading: false,
                initialDataError: false,
            }
        }
        case "SCHEDULING_PERIODS_FULFILLED": {
            return {
                ...state,
                periods: action.payload,
                initialDataLoading: false,
                initialDataError: false,
            }
        }
        case "SCHEDULING_INITIAL_DATA_LOADING": {
            return {
                ...state,
                initialDataLoading: true,
                initialDataError: false,
            }
        }
        case "SCHEDULING_INITIAL_DATA_ERROR": {
            return {
                ...state,
                initialDataLoading: false,
                initialDataError: true,
            }
        }
        case "SCHEDULING_REQUIRED_QAS_FULFILLED": {
            return {
                ...state,
                requiredQAs: action.payload,
                requiredQAsLoading: false,
                requiredQAsError: false,
            }
        }
        case "SCHEDULING_REQUIRED_QAS_LOADING": {
            return {
                ...state,
                requiredQAsLoading: true,
                requiredQAsError: false,
            }
        }
        case "SCHEDULING_REQUIRED_QAS_ERROR": {
            return {
                ...state,
                requiredQAsLoading: false,
                requiredQAsError: true,
            }
        }
        case "SCHEDULING_ACTIVE_APP": {
            return {
                ...state,
                activeApp: action.payload,
            }
        }
        case "SCHEDULING_ACTIVE_SCORECARD": {
            return {
                ...state,
                activeScorecard: action.payload,
            }
        }
    }
    return state
}
