'use strict';

export default {
    modal: (action) => {
        return {
            type: 'MODAL_ACTION',
            payload: action
        }
    },
}
