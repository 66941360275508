'use strict';

export default function reducer(state={
    playerAction: '',
    error: null,
    startPosition: 0,
    markers: [],
    audioReady: false,
    audioAnalyzing: false
  }, action) {
    switch (action.type) {
      case "PLAYER_ERROR": {
        return {
            ...state,
            error: action.payload
        }
      }
      case "PLAYER_INIT": {
        return {
          ...state,
          playerAction: action.payload,
        }
      }
      case "PLAYER_JUMPPOS": {
          return {
              ...state,
              playerAction: action.payload,
          }
      }
      case "SET_PLAYER_MARKERS": {
          return {
              ...state,
              markers: action.payload
          }
      }
      case "PLAYER_MARKERS": {
          return {
              ...state,
              playerAction: action.payload,
          }
      }
      case "PLAYER_STOP": {
          return {
              ...state,
              playerAction: action.payload,
          }
      }
      case "GET_NEW_AUDIO": {
          return {
              ...state,
              newSrc: action.payload
          }
      }
      case "SHOW_HIDE_PLAYER": {
          return {
              ...state,
              playerHidden: action.payload
          }
      }
      case "REMOVE_PLAYER": {
          return {
              ...state,
              noPlayer: action.payload
          }
      }
      case "PAUSE_PLAYING": {
          return {
              ...state,
              pause: action.payload
          }
      }
      case "SET_PLAYER_START_POSITION": {
          return {
              ...state,
              startPosition: action.payload
          }
      }
      case "AUDIO_READY": {
          return {
              ...state,
              audioReady: action.payload
          }
      }
      case "AUDIO_ANALYZING": {
        return {
            ...state,
            audioAnalyzing: action.payload
        }
    }
      
    }

    return state
}
