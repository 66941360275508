'use strict'

import {setTextareaHeight} from '../../../../utils/globalHelpers'

export default class MergeError extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.data.text
        }
    }

    onChange = (e) => {
        this.setState({
            value: e.currentTarget.value
        })
    }

    onClick = () => {
        const value = this.state.value
        this.props.callback(value, this.props.data.id)
    }

    render() {
        const {data, cancel}= this.props
        return (
            <div class='tread-new-content'>
                <div class='title'>
                    <div class='title-name'>Edit comment</div>
                    <div class='icon-ic-close' onClick={cancel}/>
                </div>
                <div class='content'>
                    <textarea
                        onKeyUp={setTextareaHeight}
                        defaultValue={data.text}
                        onChange={this.onChange} />
                    <div class='modal-centered-btn' onClick={this.onClick}>SAVE</div>
                </div>
            </div>
        )
    }
}
