'use strict'

export default class Word extends React.Component {
    constructor(props) {
        super(props)

        this.jumpPos = this.jumpPos.bind(this)
    }

    jumpPos() {
        let position = this.props.wordObj.position || 0
        this.props.jumpPos(position)
    }

    render() {
        const {wordObj, displayWord, index, additionalClass, forSubHeader} = this.props
        const additional = (index === 0 || displayWord === '.') ? '': ' '
        return (
            <span class={additionalClass ? additionalClass + '-parent' : ''}>{additional}
                <span
                    onClick={this.jumpPos}
                    class={additionalClass}
                    style={{opacity: forSubHeader ? 1 : (wordObj.confidence * .75 + .25)}}
                    dangerouslySetInnerHTML={{__html: displayWord}}>
                </span>
            </span>
        )
    }
}
