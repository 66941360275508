'use strict'

import axios from 'axios'

import config from '../appConfig'
import {getAddressMap} from '../APIGateway/addressMap'

const resolveMethod = (name) => {
    return getAddressMap()[name][0]
}

const resolveAddress = (name) => {
    return config.recordPost + getAddressMap()[name][1]
}

const resolveCDAddress = (name) => {
    return config.cdservice + getAddressMap()[name][1]
}

const resolveFileAddress = (name) => {
    return config.recordPostFile + getAddressMap()[name][1]
}

const resolveDashboardAddress = (name) => {
    return config.dashboardApi + getAddressMap()[name][1]
}

const resolveCalibrationAddress = (name) => {
    return config.calibrationApi + getAddressMap()[name][1]
}

const resolveTranscriptAddress = (name) => {
    return config.transcriptApi + getAddressMap()[name][1]
}

const resolveUserApiAddress = (name) => {
    return config.userApi + getAddressMap()[name][1]
}

const resolveGuidelinesAddress = (name) => {
    return config.calibrationApi + getAddressMap()[name][1]
}

const resolveNotificationAddress = (name) => {
    return config.notificationApi + getAddressMap()[name][1]
}
const resolveNotification2Address = (name) => {
    return config.notificationApi2 + getAddressMap()[name][1]
}

const resolveSchedulingAddress = (name) => {
    return config.schedulingApi + getAddressMap()[name][1]
}

const resolveSettingsAddress = (name) => {
    return config.settingsApi + getAddressMap()[name][1]
}

const resolveSalesAddress = (name) => {
    return config.salesApi + getAddressMap()[name][1]
}

export const sendRequest = (name, options, service, progress, requestId, query) => {
    options = options || {}
    let url, method
    try {
        switch (service) {
            case 'DEPARTMENT' :
                url = resolveCalibrationAddress(name) + (query ? '?' + query : '')
                break;
            case 'CDSERVICE':
                url = resolveCDAddress(name) + (query ? '?' + query : '')
                break;
            case 'RECORDPOST':
                url = resolveAddress(name) + (query ? '?' + query : window.location.search)
                break;
            case 'RECORDPOSTFILE':
                url = resolveFileAddress(name) + (query ? '?' + query : '')
                break;
            case 'DASHBOARDPOST':
                url = resolveDashboardAddress(name) + (query ? '?' + query : '')
                break;
            case 'CALIBRATION':
                url = resolveCalibrationAddress(name) + (query ? '?' + query : '')
                break;
            case 'TRANSCRIPT':
                url = resolveTranscriptAddress(name) + (query ? '?' + query : '')
                break;
            case 'USERAPI':
                url = resolveUserApiAddress(name) + (query ? '?' + query : '')
                break;
            case 'GUIDELINES':
                url = resolveGuidelinesAddress(name) + (query ? '?' + query : '')
                break;
            case 'NOTIFICATION':
                url = resolveNotificationAddress(name) + (query ? '?' + query : '')
                break;
            case 'NOTIFICATIONv2':
                url = resolveNotification2Address(name) + (query ? '?' + query : '')
                break;
            case 'SCHEDULING':
                url = resolveSchedulingAddress(name) + (query ? '?' + query : '')
                break;
            case 'SETTINGS':
                url = resolveSettingsAddress(name) + (query ? '?' + query : '')
                break;
            case 'SALES':
                url = resolveSalesAddress(name) + (query ? '?' + query : '')
                break;
            default:

        }
        method = resolveMethod(name)
    } catch(e) {
        console.error('request "' + name + '" could not be mapped to any api call')
        const promise =  new Promise((resolve, reject) => {
            () => {
                resolve(name)
            }
        })
        return promise
    }

    // This is for all the rest
    const payload = options.payload
    delete options.payload
    return axios[method](url, payload, {
        requestId: requestId,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        },
        onUploadProgress: progress || function() {}
    })
}

export const sendDataRequest = (name, options, service, progress) => {
    options = options || {}
    let url, method

    try {
        switch (service) {
            case 'CDSERVICE':
                url = resolveCDAddress(name)
                break;
            case 'RECORDPOST':
                url = resolveAddress(name)
                break;
            case 'CALIBRATION':
                url = resolveCalibrationAddress(name)
                break;
            case 'RECORDPOSTFILE':
                url = resolveFileAddress(name)
                break;
            default:

        }
        method = resolveMethod(name)
    } catch(e) {
        console.error('request "' + name + '" could not be mapped to any api call')
        const promise =  new Promise((resolve, reject) => {
            () => {
                resolve(name)
            }
        })
        return promise
    }

    return axios[method](url, options, {
        withCredentials: true,
        onUploadProgress: progress || function() {}
    })
}

export const sendActionButtonRequest = (endpoint, options) => {
    options = options || {}
    // let url = config.recordPost + endpoint
    let url = config.recordPost + '/' + endpoint

    return axios.post(url, options)
}


export const getUrlByName = (name) => {
    return resolveAddress(name)
}
