import * as R from 'ramda'

const initialState = {
    accountInfoLoading    : false,
    accountInfoError      : false,
    accountInfo           : null,
    accountHistoryLoading : null,
    accountHistoryError   : false,
    accountHistory        : false,
    historySorting             : {
        'sortBy': 'dateChange',
        'sortOrder': 'desc'
    },
    historyPagination           : {
        pagenum: 1,
        pagerows: 10
    },
    historySearch              : {
        text: '',
        columns: [
            'dateChange',
            'ipAddress',
            'username',
            'changeAction',
            'changeFrom',
            'changeTo',
        ]
    },
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "ACCOUNT_SETTINGS_GET_ACCOUNT_INFO_LOADING": {
            return {
                ...state,
                accountInfoLoading   : action.payload,
                accountInfoError     : false
            }
        }
        case "ACCOUNT_SETTINGS_GET_ACCOUNT_INFO": {
            return {
                ...state,
                accountInfoLoading   : false,
                accountInfoError     : false,
                accountInfo          : action.payload                   
            }
        }
        case "ACCOUNT_SETTINGS_GET_ACCOUNT_INFO_ERROR": {
            return {
                ...state,
                accountInfoLoading   : false,
                accountInfoError     : action.payload
            }
        }
        case "ACCOUNT_SETTINGS_GET_ACCOUNT_HISTORY_LOADING": {
            return {
                ...state,
                accountHistoryLoading   : action.payload,
                accountHistoryError     : false
            }
        }
        case "ACCOUNT_SETTINGS_GET_ACCOUNT_HISTORY": {
            return {
                ...state,
                accountHistoryLoading   : false,
                accountHistoryError     : false,
                accountHistory          : action.payload                   
            }
        }
        case "ACCOUNT_SETTINGS_GET_ACCOUNT_HISTORY_ERROR": {
            return {
                ...state,
                accountHistoryLoading   : false,
                accountHistoryError     : action.payload
            }
        }
        case "SET_HISTORY_SORTING": {
            return {
                ...state,
                historySorting: action.payload
            }
        }
        case "SET_HISTORY_SEARCH": {
            return {
                ...state,
                historySearch: {
                    text: action.payload,
                    columns: state.historySearch.columns
                }
            }
        }
        case "SET_HISTORY_PAGINATION": {
            return {
                ...state,
                historyPagination: {
                    pagenum: action.payload,
                    pagerows: state.historyPagination.pagerows
                }
            }
        }
    }
    return state
}
