'use strict'

const initialState = {
    reviewId: null,
    record: false,
    scorecard: false,
    transcript: false,
    playerSettings: {
        volume: parseInt(window.localStorage.playerVolume, 10)/100 || 0.5,
        speed: parseInt(window.localStorage.playerSpeed, 10)/100 || 1,
        mute: (window.localStorage.mute === 'true') ? true : false
    }
}

export default function reducer(state=initialState, action) {

    switch (action.type) {
        case 'GET_REVIEW_ID': {
            return {
                ...state,
                reviewId: action.payload
            }
        }
        case 'RECORD_FULFILLED': {
            return {
                ...state,
                record: action.payload
            }
        }
        case 'SCORECARD_FULFILLED': {
            return {
                ...state,
                scorecard: action.payload
            }
        }
        case 'TRANSCRIPT_FULFILLED': {
            return {
                ...state,
                transcript: action.payload
            }
        }
    }

    return state
}
