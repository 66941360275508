'use strict'

import { combineReducers } from 'redux'

import layoutReducer from './layoutReducer'
import reviewReducer from './reviewReducer'
import recordReducer from './recordReducer'
import listenReducer from './listenReducer'
import loaderReducer from './loaderReducer'
import modalReducer from './modalReducer'
import playerReducer from './playerReducer'
import dashboardReducer from './dashboardReducer'
import headerReducer from './headerReducer'
import filtersReducer from './filtersReducer'
import calibrationReducer from './calibrationReducer'
import guidelinesReducer from './guidelinesReducer'
import badcallsReducer from './badcallsReducer'
import billingReducer from './billingReducer'
import notiFiltersReducer from '../components/notificationPage/filtersReducer'
import notificationReducer from '../components/notificationPage/reducer'
import nidashboardReducer from '../components/niPage/reducer'
import schedulingReducer from '../components/scheduling/reducer'
import settingsReducer from '../components/settingsPage/reducer'
import newListenReducer from '../components/newListenPage/reducer'
import clientUsersReducer from '../components/clientUsers/reducer'
import conversionReducer from '../components/prospectConversion/reducer'
import salesReducer from '../components/salesPerformance/reducer'
import reportsReducer from '../components/reportsPage/reducer'
import coachingReducer from '../components/coachingDashboard/reducers/rootReducer'
import accountReducer from '../components/accountSettings/reducer'
import notesReducer from '../components/notesPage/reducer'
import callTypeReducer from '../components/callTypeBreakdown/reducer'
import passwordResetReducer from '../components/passwordReset/reducer'
import loginPageReducer from '../components/loginPage/reducer'
import alertReasonsReducer from '../components/alertReasons/reducer'
import alertManagerReducer from '../components/alertManager/reducer'



const rootReducer = combineReducers({
    layoutReducer,
    reviewReducer,
    recordReducer,
    listenReducer,
    loaderReducer,
    modalReducer,
    playerReducer,
    dashboardReducer,
    headerReducer,
    filtersReducer,
    calibrationReducer,
    guidelinesReducer,
    badcallsReducer,
    billingReducer,
    notiFiltersReducer,
    notificationReducer,
    nidashboardReducer,
    schedulingReducer,
    settingsReducer,
    newListenReducer,
    clientUsersReducer,
    conversionReducer,
    salesReducer,
    reportsReducer,
    coachingReducer,
    accountReducer,
    notesReducer,
    callTypeReducer,
    passwordResetReducer,
    loginPageReducer,
    alertReasonsReducer,
    alertManagerReducer,
});

export default rootReducer
