'use strict'

export const niModules = [
    {
        id: "TODAYSTATS",
        value: "Today Stats",
        api: "getStats",
        checked: true,
        isRequired: true,
        sortable: false,
        description: "",
    },
    {
        id: "SCOREPERFORMANCE",
        value: "Score Performance",
        api: "getScorePerformance",
        checked: true,
        isRequired: true,
        sortable: false,
        description: "Agent or group performance data over a given period of time",
    },
    {
        id: "PASSEDFAILED",
        value: "Passed & Failed",
        api: "getPassedFailed",
        checked: true,
        isRequired: true,
        sortable: false,
        description: "",
    },
    {
        id: "GROUPSANDAGENTS",
        value: "Groups & Agents",
        api: "getGroups",
        checked: true,
        isRequired: true,
        sortable: false,
        description: "",
    },
    {
        id: "OBJECTIONS",
        value: "Objection 1",
        api: "getObjections",
        checked: true,
        isRequired: true,
        sortable: false,
        description: "",
    },
]
