'use strict'

import { connect } from 'react-redux'
import classNames from 'classnames'

import actions from '../../../actions/dashboardActions'
import {getRoleShortName} from '../../../utils/dashboard'

@connect((store) => {
    return {
        activeCall: store.dashboardReducer.activeCall
    }
})

export default class NotificationIcons extends React.Component {
    constructor(props) {
        super(props)

        this.openMiniReviewNotification = this.openMiniReviewNotification.bind(this)
        this.openMiniReviewReview = this.openMiniReviewReview.bind(this)
    }

    openMiniReviewNotification(e) {
        const {canSetTab} = this.props
        const {
            notificationStatus,
            callId
        } =  this.props.data
        if (notificationStatus === null || notificationStatus === 'none' || !canSetTab) return
        e.stopPropagation()
        this.props.dispatch({
            type: 'SET_MINI_REVIEW_ACTIVE_TAB',
            payload: 'notification'
        })
        this.openMiniReview()
    }

    openMiniReviewReview(e) {
        const {canSetTab} = this.props
        const {
            notificationCommentsPresent,
            reviewCommentsPresent,
            callId
        } =  this.props.data
        if ((!reviewCommentsPresent && !notificationCommentsPresent) || !canSetTab) return
        e.stopPropagation()
        this.props.dispatch({
            type: 'SET_MINI_REVIEW_ACTIVE_TAB',
            payload: 'review'
        })
        this.openMiniReview()
    }

    openMiniReview() {
        const {fromCoaching} = this.props
        const {callId} = this.props.data
        return fromCoaching ?
        this.props.dispatch(actions.getSelectedCallDetailsFromCoaching(callId)) :
        this.props.dispatch(actions.getSelectedCallDetails(callId))
    }

    render() {
        const {title} = this.props
        const {
            notificationStatus, // "none" or "notification" or "dispute"
            isNotificationOwner,
            reviewCommentsPresent,
            notificationCommentsPresent
        } =  this.props.data
        const {notification} = this.props
        const typeOfNotification = classNames({
            'no-notification': notificationStatus === null || notificationStatus === 'none' || !notificationStatus,
            'notification': notificationStatus === 'notification',
            'dispute': notificationStatus === 'dispute',
            'pending-dispute': notificationStatus === 'pending dispute',
            'closed': notificationStatus === 'notification closed',
            'notification-opacity': !isNotificationOwner,
        })
        const notificationIcon = classNames({
            'notification-icon': true,
            'icon-ic-1-dispute': notificationStatus === 'notification' || notificationStatus === 'notification closed',
            'icon-ic-3-dispute': notificationStatus === 'dispute',
            'icon-ic-2-dispute': notificationStatus === 'pending dispute',
        })
        const noteBtn = classNames({
            'review-comments': reviewCommentsPresent === true && notificationCommentsPresent !== true,
            'notification-comments' : notificationCommentsPresent === true,
            'comments-none': reviewCommentsPresent !== true && notificationCommentsPresent !== true
        })
        const userRoleIcon = classNames({
            'role-icon': true,
            'no-notification-color': notificationStatus === null || notificationStatus === 'none' || !notificationStatus,
            'notification-color': notificationStatus === 'notification',
            'dispute-color': notificationStatus === 'dispute',
            'pending-dispute-color': notificationStatus === 'pending dispute',
            'closed-color': notificationStatus === 'notification closed',
            'notification-opacity-color': !isNotificationOwner,
        })
        return (
            <div class='notification-icons'>
                <div
                    onClick={this.openMiniReviewNotification}
                    class={typeOfNotification}
                    title={title === 'simple' ? 'Notification Comments Present' : 'Open Notification Comments'}>
                    <div class={notificationIcon } />
                </div>
                {
                    notification && notification.assignedTo && notification.assignedTo.userRoleId ?
                    <div class={userRoleIcon}>{getRoleShortName(notification.assignedTo.userRoleId)}</div> :
                    null
                }
                <div
                    onClick={this.openMiniReviewReview}
                    class={noteBtn}
                    title={title === 'simple' ? 'Review Comments Present' : 'Open review comments'}>
                    <div class='icon-ic-comment'/>
                </div>
            </div>
        )
    }
}
