import * as R from 'ramda'

const initialState = {
    agentsWithPoints: null,
    agentsWithPointsLoading: false,
    agentsWithPointsError: false,
    activeMissedPoint: null,
    progressChartData: null,
    progressChartDataLoading: false,
    detailedInfo: null,
    detailedInfoLoading: false,
    questionNotes: [],
}

export default function pointsReducer(state=initialState, action) {
    switch (action.type) {
        case "COACHING_PAGE_GET_AGENTS_WITH_POINTS_LOADING": {
            return {
                ...state,
                agentsWithPointsLoading: action.payload,
                agentsWithPointsError: false
            }
        }
        case "COACHING_PAGE_GET_AGENTS_WITH_POINTS_ERROR": {
            return {
                ...state,
                agentsWithPointsLoading: false,
                agentsWithPointsError: true
            }
        }
        case "COACHING_PAGE_SET_AGENTS_WITH_POINTS": {
            return {
                ...state,
                agentsWithPoints: action.payload,
                agentsWithPointsLoading: false,
                agentsWithPointsError: false
            }
        }
        case "COACHING_PAGE_SET_ACTIVE_MISSED_POINT": {
            return {
                ...state,
                activeMissedPoint: action.payload
            }
        }
        case "COACHING_PAGE_SET_PROGRESS_CHART_LOADING": {
            return {
                ...state,
                progressChartDataLoading: true
            }
        }
        case "COACHING_PAGE_SET_PROGRESS_CHART": {
            return {
                ...state,
                progressChartData: action.payload,
                progressChartDataLoading: false
            }
        }
        case "COACHING_PAGE_SET_SESSION_DETAILED_INFO_LOADING": {
            return {
                ...state,
                detailedInfoLoading: true
            }
        }
        case "COACHING_PAGE_SET_SESSION_DETAILED_INFO": {
            return {
                ...state,
                detailedInfo: action.payload,
                detailedInfoLoading: false
            }
        }
        case "COACHING_PAGE_SET_QUESTION_NOTES": {
            return {
                ...state,
                questionNotes: action.payload
            }
        }
    }
    return state
}