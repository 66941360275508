'use strict'

import { PropTypes } from 'prop-types'

export const simpleAnswerCommentPropType = PropTypes.shape({
    callId:                         PropTypes.number.isRequired,
    commentId:                      PropTypes.number.isRequired,
    commentText:                    PropTypes.string,
})

export const simpleQuestionAnswerPropType = PropTypes.shape({
    answerId:                       PropTypes.number,
    answerText:                     PropTypes.string,
    position:                       PropTypes.number, // seconds
    comments:                       PropTypes.arrayOf(simpleAnswerCommentPropType), // optional for some requests
    customComment:                  PropTypes.string,
})

export const compositeAnswerCommentPropType = PropTypes.shape({
    callId:                         PropTypes.number.isRequired,
    questionId:                     PropTypes.number.isRequired,
    commentId:                      PropTypes.number.isRequired,
    commentText:                    PropTypes.string,
    isChecked:                      PropTypes.bool.isRequired,
    position:                       PropTypes.number, // seconds
})

export const compositeQuestionAnswerPropType = PropTypes.shape({
    comments:                       PropTypes.arrayOf(compositeAnswerCommentPropType),
    customComment:                  PropTypes.string,
    position:                       PropTypes.number, // seconds
})

export const missedItemPropType = PropTypes.shape({
    callId:                         PropTypes.number.isRequired,
    itemDescription:                PropTypes.string,
    position:                       PropTypes.number,
    questionId:                     PropTypes.number,
    questionShortName:              PropTypes.string,
    questionSectionName:            PropTypes.string,
    questionType:                   PropTypes.string, // "simple" or "dynamic" or "composite"
    isRightAnswer:                  PropTypes.bool,
    isLinked:                       PropTypes.bool,
    isComposite:                    PropTypes.bool,
    simpleQuestionAnswer:           simpleQuestionAnswerPropType,
    compositeQuestionAnswer:        compositeQuestionAnswerPropType,
})

export const systemDataPropType = PropTypes.shape({
    callId:                         PropTypes.number,
    callType:                       PropTypes.string.isRequired, //"call" or "website"
    callReviewStatus:               PropTypes.string.isRequired, // "pending" or "reviewed" or "calibrated" or "edited" or "bad" or "disqualified"
    callAudioUrl:                   PropTypes.string,
    callAudioLength:                PropTypes.number, // seconds
    websiteUrl:                     PropTypes.string,
    scorecardId:                    PropTypes.number.isRequired,
    scorecardName:                  PropTypes.string.isRequired,
    scorecardFailScore:             PropTypes.number,
    reviewDate:                     PropTypes.string,
    reviewerUserRole:               PropTypes.string,
    reviewerName:                   PropTypes.string, // QA
    calibratorId:                   PropTypes.string.isRequired,
    calibratorName:                 PropTypes.string,
    agentScore:                     PropTypes.number,
    callFailed:                     PropTypes.bool.isRequired,
    missedItemsCount:               PropTypes.number,
    missedItems:                    PropTypes.arrayOf(missedItemPropType),
    reviewCommentsPresent:          PropTypes.bool.isRequired,
    notificationCommentsPresent:    PropTypes.bool.isRequired,
    notificationId:                 PropTypes.number,
    notificationStatus:             PropTypes.string, // "none" or "notification" or "dispute"
    isNotificationOwner:            PropTypes.bool.isRequired,
    badCallReason:                  PropTypes.string
})

export const metaDataPropType = PropTypes.shape({
    callDate:             PropTypes.string.isRequired,
    agentName:            PropTypes.string.isRequired,
    agentGroup:           PropTypes.string,
    campaign:             PropTypes.string,
    sessionId:            PropTypes.string,
    profileId:            PropTypes.string,
    prospectFirstName:    PropTypes.string,
    prospectLastName:     PropTypes.string,
    prospectPhone:        PropTypes.string,
    prospectEmail:        PropTypes.string,
})
