import * as R from 'ramda'

const initialState = {
    agentsListLoading : false,
    agentsListError   : false,        
    currentSession    : null,
    savedSessionAgents: [],
    newSessionAgents  : [],
    selectedSessions  : [],
    sesssionLoading   : false
}

export default function sessionReducer(state=initialState, action) {
    switch (action.type) {
        case "COACHING_PAGE_SET_CURRENT_SESSION": {
            return {
                ...state,
                currentSession: action.payload
            }
        }
        case "COACHING_PAGE_SAVE_SESSION_AGENTS_DATA": {
            return {
                ...state,
                savedSessionAgents: action.payload,
            }
        }
        case "COACHING_PAGE_SET_NEW_SESSION_AGENTS_DATA": {
            return {
                ...state,
                newSessionAgents: action.payload,
            }
        }
        case "COACHING_PAGE_SET_SELECTED_SESSIONS": {
            return {
                ...state,
                selectedSessions: action.payload,
            }
        }
        case "COACHING_PAGE_SESSION_LOADING": {
            return {
                ...state,
                sesssionLoading: action.payload,
            }
        }
    }
    return state
}
