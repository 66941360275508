import axios from 'axios';

const instance = axios.create({
    baseURL: 'callrevuapi-dev-partner.azurewebsites.net/account/api/v1.0/transcription/'
});

function getHeaders() {
    return JSON.parse(localStorage.getItem('access-tokens'));
}

function sendRequest(method, url, data, params) {
    return instance({
        method: method,
        url: url,
        data: data || {},
        params: params || {},
    }).then(response => {
        if (response && (response.status === 200 || response.status === 201)) {
            return response;
        } else {
            throw new Error(`wrong response code: ${response.status} (expedted 200 or 201).`);
        }
    });
}

function sendAuthRequest(method, url, data, params) {
    return instance({
        method: method,
        url: url,
        data: data || {},
        params: params || {},
        headers: getHeaders()
    }).then(response => {
        if (response && (response.status === 200 || response.status === 201 || response.status === 304)) {
            const token = response.headers['access-token'];
            if (token) {
                const accessTokens = {
                    'access-token': response.headers['access-token'],
                    'client': response.headers.client,
                    'uid': response.headers.uid
                };
                localStorage.setItem('access-tokens', JSON.stringify(accessTokens));
            }
            return response;
        } else {
            throw new Error(`wrong response code: ${response.status} (expedted 200 or 201).`);
        }
    });
}

export function getAllUserVacancy() {
    axios.get(`https://callrevuapi-dev-partner.azurewebsites.net/account/api/v1.0/transcription/GetDNTByDepartment?accountNumber=127840&department=Service`)
      .then(res => {
        console.log(res);
        console.log(res.data);
      })
    // return sendRequest('get', `GetDNTByDepartment?accountNumber=127840&department=Service`, null);
}