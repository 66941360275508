'use strict'

import * as R from 'ramda'
import moment from 'moment'
import Modal from 'react-modal'
import classNames from 'classnames'
import { connect } from 'react-redux'

import AddNewTread from './AddNewTread'

import DeleteComment from './DeleteComment'
import EditComment from './MergeError'

import actions from '../../../../actions/dashboardActions'
import {hasRole} from '../../../../utils/globalHelpers.js'

const customStyles = {
    content: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        padding: 0,
        width: '70%',
        maxWidth: '680px',
        position: 'relative',
        width: '700px',
        margin: '100px auto',
        overflow: 'inherit',
    },
    overlay: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'inherit',
        outline: 0,
        textAlign: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    }
}

@connect((store) => {
    return {
        activeTread      : store.dashboardReducer.activeTread,
        userData         : store.headerReducer.headerData.userData,
        shortCallDetails : store.dashboardReducer.shortCallDetails,
        openNewTread     : store.dashboardReducer.openNewTread,
    }
})

export default class CommentsList extends React.Component {
	constructor(props) {
        super(props)
        this.state = {
            exportQueueModalOpened: false,
            newTreadContent: false,
            editCommentContent: false,
            deleteCommentContent: false,
            notification: '',
        }
    }

	openNewNotiModal = () => {
		this.props.dispatch(actions.openNewTread(true))
		const form = document.getElementById('myTextField')
        form.scrollIntoView({behavior: 'smooth'})
        form.focus()
	}

	hideModal = () => {
        this.setState({
            exportQueueModalOpened: false,
            newTreadContent: false,
            editCommentContent: false,
            deleteCommentContent: false,
        })
    }

 	backToCallDatails = () => {
        this.props.dispatch(actions.getSelectedCallDetails(callId))
    }

	setActiveTread = (tread) => {
		this.props.dispatch(actions.setActiveTread(tread))
		this.props.dispatch(actions.openNewTread(false))
	}

	editComment = (n) => {
		this.setState({
            exportQueueModalOpened: true,
            editCommentContent: true,
            notification: n,
        })
	}

	deleteComment = (n) => {
		this.setState({
            exportQueueModalOpened: true,
            deleteCommentContent: true,
            notification: n,
        })
	}

	deleteCommentRequest = (id) => {
		const {shortCallDetails, dispatch} = this.props
        dispatch(actions.deleteComment({ID: id}, shortCallDetails.systemData.callId))
    }

    editCommentRequest = (comment, id) => {
    	const {shortCallDetails, dispatch} = this.props
        dispatch(actions.updateCallComment({ID: id, comment: comment}, shortCallDetails.systemData.callId))
    }

    render() {
		const {
			threads,
			activeTread,
			userData,
			openNewTread
		} = this.props
		const {
			exportQueueModalOpened,
			newTreadContent,
			editCommentContent,
			deleteCommentContent,
			notification,
		} = this.state
        return (
        	<>
		        <div class='comments-list grey'>
		            {
		            	threads && threads.length !== 0 ?
		            	<>
			            	<div class='navigation-notification'>
			            		<div
				        		class={openNewTread ? 'thread-name new-noti-btn active' : 'thread-name new-noti-btn'}
				        		onClick={this.openNewNotiModal}>
				        			<div class='tread'>
				        				<div class='icon-ic-add-1'/>Open new notification
				        			</div>
				        		</div>
			            		{
			            			threads.map((t, i) => {
			            				const threadStyles = classNames({
											'thread-name' : true,
											'active'      : activeTread && activeTread.threadInfo.id === t.threadInfo.id && !openNewTread,
											'closed'      : !t.threadInfo.currentlyOpen,
										})
			            				return <div
			            					key={i + '-thred-name-' + t.threadInfo.startedReason}
			            					class={threadStyles}
			            					onClick={(e) => this.setActiveTread(t)}>
			            					<div class='reason'>Reason:</div>
			            					<div class='tread'>{t.threadInfo.startedReason}</div>
			            					<div class='date'>{
			            						moment(t.threadInfo.firstOpened).format("MMM D, YYYY, hh:ss a")
			            					}</div>
			            					<div class='status'>
			            						<div>{t.threadInfo.currentlyOpen ? 'Open' : 'Close'}</div>
			            					</div>
			            				</div>
			            			})
			            		}
			            	</div>
			            	<div class='notification'>
			            		{
			            			activeTread ?
			            			<div class='tread-tab-open'>
			            				{
			            					activeTread.notificationComments && activeTread.notificationComments.length !==0 ?
			            					activeTread.notificationComments.map((n, i) => {
			            						const role = n.notificationRole && n.notificationRole.userRoleName && n.notificationRole.userRoleName.toUpperCase()
												const supervisor = role && hasRole(role, 'SUPERVISOR')
												const agent = role && hasRole(role, 'AGENT')
												const manager = role && hasRole(role, 'MANAGER')
												const client = role && hasRole(role, 'CLIENT')
												const admin = role && hasRole(role, "ADMIN")
												const qa = role && hasRole(role, "QA")
												const qaLead = role && hasRole(role, "QA LEAD")
												const calibrator = role && hasRole(role, "CALIBRATOR")
												const tgLead = role && hasRole(role, "TANGO TL")
												const acMn = role && hasRole(role, "ACCOUNT MANAGER")
												
												const roleStyles = classNames({
													'role-icon' : true,
													'qa'        : qa,
													'sv'        : supervisor,
													'tl'        : qaLead,
													'ca'        : calibrator,
													'ag'        : agent,
													'tgtl'      : tgLead,
													'ad'        : admin || acMn,
													'cl'        : client,
													'mn'        : manager,
												})

												const notesOnly = n.closedBy.userData.userName ===  n.openedBy.userData.userName && n.text && n.text !== '' &&  !n.openNotes ? true : false
			            						
			            						return <div class='notification-row' key={'rev-noti-' + n.id}>
			            							{
			            								notesOnly ?
			            								<>
			            									<div class='noti-info'>
					            								<div class='user-info'>
						            								<div class='user-name'>
						            									<div class='name'>{n.openedBy && n.openedBy.userData && n.openedBy.userData.userName}</div>
						            									<div class='role'>{n.openedBy && n.openedBy.userRole && n.openedBy.userRole.userRoleName ? `(${n.openedBy.userRole.userRoleName})` : null}</div>
						            								</div>
						            							</div>
						            						</div>
						            						<div class='comment only-comment'>
					            								<div>
					            									<span>Notes only:</span>{n.text}
					            								</div>
					            								<div class={n.closedBy.userData.userName.toUpperCase() === userData.UserName.toUpperCase() ? 'comment-edit' : 'hidden'}>
																	<div onClick={(e) => this.editComment(n)} class='edit-comment-btn'>
																	    <i class='material-icons'>edit</i>
																	</div>
																	<div onClick={(e) => this.deleteComment(n)} class='edit-comment-btn'>
																	    <i class='material-icons'>delete</i>
																	</div>
																</div>
				            								</div> 
			            								</> :
			            								<>
			            									<div class='noti-info'>
					            								<div class='user-info'>
						            								<div class='user-name'>
						            									<div class='name'>{n.openedBy && n.openedBy.userData && n.openedBy.userData.userName}</div>
						            									<div class='role'>{n.openedBy && n.openedBy.userRole && n.openedBy.userRole.userRoleName ? `(${n.openedBy.userRole.userRoleName})` : null}</div>
						            								</div>
						            								<div class={!n.closedDate ? 'icon-ic-arrow-forward blue' : 'icon-ic-arrow-forward'}/>
					            								</div>		
					            								<div class='user-info'  style={{width: '40px'}}>
							            							<div class={roleStyles}>
							            							{
							            								supervisor ? 'SV' :
										                                manager    ? 'MN' :
										                                client     ? 'CL' :
										                                agent      ? 'AG' :
										                                admin      ? 'AD' :
										                                qaLead     ? 'TL' :
										                                qa         ? 'QA' :
										                                calibrator ? 'CA' :
										                                tgLead     ? 'TG' : 
										                                acMn       ? 'AC' : n.role
							            							}
							            							</div>
							            						</div>
							            							<div class='user-info'>
						            									<div class='user-name'>
							            									<div class='name'>{n.closedBy && n.closedBy.userData && n.closedBy.userData.userName}</div>
							            									<div class='role'>{n.closedBy && n.closedBy.userRole && n.closedBy.userRole.userRoleName ? `(${n.closedBy.userRole.userRoleName})` : null}</div>
							            								</div>
						            								</div>
						            						</div>
						            						<div class='long-comments'>
						            							{
						            								n.openNotes ?
							            							<div class='comment'>
							            								<div>{n.openNotes}</div>
							            								<div class={n.openedBy.userData.userName.toUpperCase() === userData.UserName.toUpperCase() ? 'comment-edit' : 'hidden'}>
																			<div onClick={(e) => this.editComment(n)} class='edit-comment-btn'>
																			    <i class='material-icons'>edit</i>
																			</div>
																			<div onClick={(e) => this.deleteComment(n)} class='edit-comment-btn'>
																			    <i class='material-icons'>delete</i>
																			</div>
																		</div>
							            							</div> : <div class='comment'>No comments</div>
							            						}
							            						{
							            							n.text ?
							            							<div class='comment'>
							            								<div>{n.text}</div>
							            								<div class={n.closedBy.userData.userName.toUpperCase() === userData.UserName.toUpperCase() ? 'comment-edit' : 'hidden'}>
																			<div onClick={(e) => this.editComment(n)} class='edit-comment-btn'>
																			    <i class='material-icons'>edit</i>
																			</div>
																			<div onClick={(e) => this.deleteComment(n)} class='edit-comment-btn'>
																			    <i class='material-icons'>delete</i>
																			</div>
																		</div>
							            							</div> : <div class='comment'>No comments</div>
							            						}
						            						</div>
				            								<div class='date-info'>{n.closedDate ? moment(n.closedDate).format("MMM D, YYYY, hh:ss a") : null}</div>
			            								</>
			            							}

			            						</div>
			            					}) : null
			            				}
			            			</div> : <div>No Information</div>
			            		}
			            	</div>
			            </> : <div class='no-review-noti'>No notifications</div>
		            }
		        </div>
		        <Modal
                    isOpen={exportQueueModalOpened}
                    closeTimeoutMS={10}
                    style={customStyles}
                    onRequestClose={this.hideModal}
                    shouldCloseOnOverlayClick={true}
                    contentLabel="AddNewTread">
                    {
                    	newTreadContent ?
                    	<AddNewTread cancel={this.hideModal} /> :
                    	editCommentContent ?
						<EditComment
							callback={this.editCommentRequest}
							cancel={this.hideModal}
							data={notification} /> :
						deleteCommentContent ?
						<DeleteComment
							callback={this.deleteCommentRequest}
							cancel={this.hideModal}
							data={notification} /> : null
                    }
                </Modal>
		    </>
        )
    }
}
