'use strict'

import classNames from 'classnames'
import {PropTypes} from 'prop-types'

import {systemDataPropType} from './propTypes'

export default class ScoreCell extends React.Component {
    static propTypes = {
        systemData: systemDataPropType.isRequired
    }

    render() {
        const {agentScore, scorecardFailScore, scoreChanged} = this.props.systemData
        const fSc = parseInt(scorecardFailScore) ? scorecardFailScore : 80
        const colorSpan = classNames({
            "red"    : agentScore < fSc,
            "yellow" : agentScore >= fSc && agentScore < 100 - (100 - fSc) / 2,
            "green"  : agentScore >= 100 - (100 - fSc) / 2,
        })
        return (
            <td class='simple-cell'>
                <div class={colorSpan} title={agentScore + '%'}>
                    {(agentScore || agentScore === 0) ? agentScore.toFixed(2) + '%' : ''}
                </div>
            </td>
        )
    }
}
