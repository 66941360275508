'use strict'

import { PropTypes } from 'prop-types'

export const answerCommentPropType = PropTypes.shape({
    commentId:      PropTypes.number.isRequired,
	commentText:    PropTypes.string.isRequired
})

export const answerDetailsPropType = PropTypes.shape({
	answerId:         PropTypes.number.isRequired,
	answerText:       PropTypes.string.isRequired,
	isRightAnswer:    PropTypes.bool.isRequired,
	position:         PropTypes.number.isRequired, // seconds
})

const simpleAnswerComment = PropTypes.shape({
    commentId:      PropTypes.number,
	commentText:    PropTypes.string,
})

const simpleQuestionAnswerPropType = PropTypes.shape({
    answerId:         PropTypes.number.isRequired,
	answerText:       PropTypes.string.isRequired,
	position:         PropTypes.number, // seconds
	comments:         PropTypes.arrayOf(simpleAnswerComment), // optional for some requests
	customComment:    PropTypes.string,
})

const compositeAnswerCommentPropType = PropTypes.shape({
    commentId:      PropTypes.number,
	commentText:    PropTypes.string.isRequired,
	checked:        PropTypes.bool.isRequired,
	position:       PropTypes.number, // seconds
})

const compositeQuestionAnswerPropType = PropTypes.shape({
    comments:         PropTypes.arrayOf(compositeAnswerCommentPropType).isRequired,
	customComment:    PropTypes.string,
})

export const questionDetailsPropType = PropTypes.shape({
	questionId:                 PropTypes.number.isRequired,
	questionShortName:          PropTypes.string.isRequired,
	questionSectionName:        PropTypes.string.isRequired,
    questionType:               PropTypes.string.isRequired, // "simple" or "dynamic" or "composite"
    isRightAnswer:              PropTypes.bool.isRequired,
    simpleQuestionAnswer:       simpleQuestionAnswerPropType, // for "simple" or "dynamic"
	compositeQuestionAnswer:    compositeQuestionAnswerPropType, // for "composite"
})

const missedItemPropType = PropTypes.shape({
	callId:             PropTypes.number.isRequired,
	itemDescription:    PropTypes.string.isRequired,
	position:           PropTypes.number.isRequired
})

export const systemDataPropType = PropTypes.shape({
	callId:                         PropTypes.number,
	callType:                       PropTypes.string.isRequired, //"call" or "website"
	callReviewStatus:               PropTypes.string.isRequired, // "pending" or "reviewed" or "calibrated" or "edited" or "bad" or "disqualified"
    callAudioUrl:                   PropTypes.string,
    callAudioLength:                PropTypes.number, // seconds
    websiteUrl:                     PropTypes.string,
    scorecardId:                    PropTypes.number.isRequired,
    scorecardName:                  PropTypes.string.isRequired,
    scorecardFailScore:             PropTypes.number,
    reviewDate:                     PropTypes.string,
    reviewerUserRole:               PropTypes.string,
    reviewerName:                   PropTypes.string, // QA
    calibratorId:                   PropTypes.string,
    calibratorName:                 PropTypes.string,
    agentScore:                     PropTypes.number,
    callFailed:                     PropTypes.bool.isRequired,
    missedItemsCount:               PropTypes.number,
	missedItems:                    PropTypes.arrayOf(missedItemPropType),
    reviewCommentsPresent:          PropTypes.bool.isRequired,
    notificationCommentsPresent:    PropTypes.bool.isRequired,
    notificationId:                 PropTypes.number,
	notificationStatus:             PropTypes.string.isRequired, // "none" or "notification" or "dispute"
	isNotificationOwner:            PropTypes.bool.isRequired,
	badCallReason:                  PropTypes.string
})

export const metaDataPropType = PropTypes.shape({
    callDate:             PropTypes.string.isRequired,
	agentName:            PropTypes.string.isRequired,
	agentGroup:           PropTypes.string,
	campaign:             PropTypes.string,
	sessionId:            PropTypes.string,
	profileId:            PropTypes.string,
	prospectFirstName:    PropTypes.string,
	prospectLastName:     PropTypes.string,
	prospectPhone:        PropTypes.string,
	prospectEmail:        PropTypes.string,
})

const rolePropType = PropTypes.shape({
	userRoleName:    PropTypes.string.isRequired,
	userRoleId:      PropTypes.string.isRequired,
})

const userPropType = PropTypes.shape({
	userName: PropTypes.string.isRequired,
	userId:   PropTypes.string.isRequired,
})

const userInformationPropType = PropTypes.shape({
	userRole: rolePropType.isRequired,
	userData: userPropType.isRequired,
})

const notificationCommentPropType = PropTypes.shape({
    openDate:            PropTypes.string.isRequired, // ISO format
    closedDate:          PropTypes.string, // ISO format
	openedBy:            userInformationPropType.isRequired,
	closedBy:            userInformationPropType,
	text:                PropTypes.string,
	id:                  PropTypes.number.isRequired,
	notificationRole:    rolePropType.isRequired,
})

const systemComment = PropTypes.shape({
    user:           userInformationPropType.isRequired,
	commentDate:    PropTypes.string.isRequired,
	text:           PropTypes.string,
	id:             PropTypes.number,
})

const notificationInfoPropType = PropTypes.shape({
    assignedTo:              rolePropType,
	notificationStatus:      PropTypes.string, // "none" or "notification" or "dispute"
	notificationComments:    PropTypes.arrayOf(notificationCommentPropType),
	reassignOptions:         PropTypes.arrayOf(rolePropType),
    systemComments:          PropTypes.arrayOf(systemComment),
	canClose:                PropTypes.bool.isRequired,
})

export const callShortInfoPropType = PropTypes.shape({
	systemData:           systemDataPropType.isRequired,
	metaData:             metaDataPropType.isRequired,
	callMissedItems:      PropTypes.arrayOf(questionDetailsPropType),
    notificationInfo:     notificationInfoPropType
})
