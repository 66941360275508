'use strict'

import * as R from 'ramda'
import moment from 'moment'

export function getRoleShortName(id) {
    const roles = {
        'SUPERVISOR':     'SV',
        'ADMIN':          'AD',
        'AGENT':          'AG',
        'CALIBRATOR':     'CA',
        'CENTER MANAGER': 'CM',
        'CLIENT':         'CL',
        'MANAGER':        'MG',
        'PARTNER':        'PR',
        'QA':             'QA',
        'QA LEAD':        'TL',
        'RECALIBRATOR':   'RC',
    }
    return roles[id.toUpperCase()]
}

export function getDefaultColumns() {
    return [
        // from system data
        {id: "callType",          value: "Type",          checked: true, isRequired: true,  sortableValue: false, sortable: false, searchable: false},
        // {id: "callId",            value: "Call ID",       checked: true, isRequired: false, sortableValue: false, sortable: false, searchable: false},
        {id: "callAudioLength",   value: "Duration",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "scorecardName",     value: "Scorecard",     checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "reviewDate",        value: "Review Date",   checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        // {id: "receivedDate",      value: "Received Date", checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "reviewerName",      value: "Reviewer",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "agentScore",        value: "Score",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "missedItemsCount",  value: "Missed Items",  checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        // from meta data
        {id: "callDate",          value: "Call Date",     checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "agentName",         value: "Agent",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "agentGroup",        value: "Group",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "campaign",          value: "Campaign",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "sessionId",         value: "Session ID",    checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        // {id: "profileId",         value: "Profile ID",    checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "prospectFirstName", value: "First Name",    checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "prospectLastName",  value: "Last Name",     checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "prospectPhone",     value: "Phone",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "prospectEmail",     value: "Email",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        // {id: "agentId",           value: "Agent ID",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        // required sortable
        {id: "callReviewStatus",  value: "Review Status", checked: true, isRequired: true, sortableValue: false,  sortable: true, searchable: false},
        {id: "callFailed",        value: "Result",        checked: true, isRequired: true, sortableValue: false,  sortable: true, searchable: false}, //sortable was false for some reason
        // icons
        {id: "icons",             value: "<icons>",       checked: true, isRequired: true, sortableValue: false,  sortable: false, searchable: false}
    ]
}

export function getDefaultCoachingColumns() {
    return [
        // from system data
        {id: 0,   value: "Agent Name",                     checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 1,   value: "Call Date",                      checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 2,   value: "Call Type",                      checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 3,   value: "Call Id",                        checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 4,   value: "Call Review Status",             checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 5,   value: "Call Audio Url",                 checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 6,   value: "Call Audio Length",              checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 7,   value: "Scorecard Id",                   checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 8,   value: "Scorecard Name",                 checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 9,   value: "scorecardFailScore",             checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 10,  value: "Review Date",                    checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 11,  value: "Reviewer User Role",             checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 12,  value: "Reviewer Name",                  checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 13,  value: "Calibrator Id",                  checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 14,  value: "CalibratorName",                 checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 15,  value: "Missed Items Count",             checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 16,  value: "Agent Score",                    checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 17,  value: "Call Failed",                    checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 18,  value: "Review Comments Present",        checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 19,  value: "Notification Comments Present",  checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 20,  value: "Agent Group",                    checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 21,  value: "Campaign",                       checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 22,  value: "Session Id",                     checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 23,  value: "Profile Id",                     checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 24,  value: "Prospect First Name",            checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 25,  value: "Prospect Last Name",             checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 26,  value: "Prospect Phone",                 checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 27,  value: "Prospect Email",                 checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 28,  value: "Calibration id",                 checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 29,  value: "Was Edited",                     checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 30,  value: "Notification Status",            checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 31,  value: "Notification Step",              checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 32,  value: "Notification ID",                checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 33,  value: "Is Owned Notification",          checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 34,  value: "Owned Notification",             checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: 35,  value: "Assigned To Role",               checked: true,  isRequired: false, sortableValue: true,  sortable: true, searchable: false},
    ]
}

export function getSearchableColumns(columns) {
    const isSearchableChecked = item => item.searchable && item.checked
    const getId = item => item.id
    return R.map(getId, R.filter(isSearchableChecked, columns || getDefaultColumns()))
}

// Section Scores – Section and question level accuracy percentages
// Question Details – Answer comment filter capabilities for a particular question.
// Website Stats – Compliant vs non-compliant website data
// Calls Left – A visual representation of uploaded calls, calls pending review and bad call dispositions

export const availableModules = [
    {
        id: "TODAYSTATS",
        value: "Today Stats",
        api: "getStats",
        checked: true,
        isRequired: true,
        sortable: false,
        description: "",
    },
    {
        id: "SECTIONSCORES",
        value: "Section Scores",
        api: "getSectionsInfo",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "",
    },
    {
        id: "CALIBRATIONSECTIONSCORES",
        value: "Calibration Section Scores",
        api: "getCalibrationSectionsInfo",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "",
    },
    {
        id: "WEBSITESTATS",
        value: "Website Stats",
        api: "getWebsiteStats",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "",
    },
    {
        id: "MYPAY",
        value: "My Pay",
        api: "getMyPay",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "",
    },
    {
        id: "COACHINGQUEUE",
        value: "Coaching Queue",
        api: "getCoachingQueue",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "Notification manager for calls that require action to be taken",
    },
    {
        id: "TOPMISSED",
        value: "Top Missed Points",
        api: "getTopMissed",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "Ranking of the most frequently missed scorecard evaluation criteria by agents",
    },
    {
        id: "TOPQAMISSED",
        value: "Top QA Missed Points",
        api: "getTopQAMissed",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "Ranking of the most frequently missed scorecard evaluation criteria by QAs",
    },
    {
        id: "TOPCALIBRATORMISSED",
        value: "Top Calibrator Missed Points",
        api: "getTopCalibratorMissed",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "Ranking of the most frequently missed scorecard evaluation criteria by calibrators",
    },
    {
        id: "AGENTRANKING",
        value: "Agent Ranking",
        api: "getAgentRanking",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "Agent performance data highlighting top missed points and relative to overall center performance",
    },
    {
        id: "GROUPPERFORMANCE",
        value: "Group Performance",
        api: "getGroupPerformance",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "Historical analysis of group performance data.",
    },
    {
        id: "CAMPAIGNPERFORMANCE",
        value: "Campaign Performance",
        api: "getCampaignPerformance",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "Historical analysis of campaign performance data.",
    },
    {
        id: "CALLSLEFT",
        value: "Calls Left",
        api: "getCallsLeft",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "A visual representation of uploaded calls, calls pending review and bad call dispositions",
    },
    {
        id: "SCOREPERFORMANCE",
        value: "Score Performance",
        api: "getScorePerformance",
        checked: false,
        isRequired: false,
        sortable: false,
        description: "Agent or group performance data over a given period of time",
    },
    {
        id: "PENDINGCALIBRATIONS",
        value: "Pending Calibrations",
        api: "getPendingCalibrations",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "",
    },
    {
        id: "PENDINGINTERNALCALIBRATIONS",
        value: "Pending Internal Calibration",
        api: "getPendingCalibrationsInternal",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "",
    },
    {
        id: "AVGCALLTIME",
        value: "Avg Call Time",
        api: "getAvgCallTime",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "",
    },
    {
        id: "SCORECARDDETAILS",
        value: "Scorecard Counts",
        api: "getScorecardDetailedInfo",
        checked: false,
        isRequired: false,
        sortable: true,
        description: "",
    },
]

export function getDefaultModules() {
    return availableModules
}

export function getModulesAPI(modules) {
    if (!modules) return []
    const isChecked = item => item.checked && item.api
    const getAPI = item => item.api
    return R.map(getAPI, R.filter(isChecked, modules))
}

export function getModules(modules) {
    if (!modules) return []
    const isChecked = item => item.checked
    const getId = item => item.id
    return R.map(getId, R.filter(isChecked, modules))
}

export const mapedModules = {
    'AGENTRANKING'               : 'AGENTRANKING',
    'AVGSCOREMODULE'             : 'AVGSCOREMODULE',
    'CALIBRATIONLISTEN'          : 'CALIBRATIONLISTEN',
    'CALLSLEFT'                  : 'CALLSLEFT',
    'CAMPAIGNMODULE'             : 'CAMPAIGNPERFORMANCE',
    'CLCALIBRANKING'             : 'CLCALIBRANKING',
    'CLDISPUTERANKING'           : 'CLDISPUTERANKING',
    'CLEFFRANKING'               : 'CLEFFRANKING',
    'CLIENTCALIBRATION'          : 'CLIENTCALIBRATION',
    'CLIENTUPDATE'               : 'CLIENTUPDATE',
    'COACHING'                   : 'COACHING',
    'COACHINGQUEUE'              : 'COACHINGQUEUE',
    'GETACTIVEQA'                : 'GETACTIVEQA',
    'GROUPPERFORMANCE'           : 'GROUPPERFORMANCE',
    'OTHERDATAFILTER'            : 'OTHERDATAFILTER',
    'QACALIBRANKING'             : 'QACALIBRANKING',
    'QAPAYMODULE'                : 'MYPAY',
    'QASCORES'                   : 'QASCORES',
    'QUESTIONDETAILS'            : 'QUESTIONDETAILS',
    'SCOREPERFORMANCE'           : 'SCOREPERFORMANCE',
    'SECTIONSCORES'              : 'SECTIONSCORES',
    'TODAYSTATS'                 : 'TODAYSTATS',
    'TOPMISSED'                  : 'TOPMISSED',
    'TOPQAMISSED'                : 'TOPQAMISSED',
    'TOPCALIBRATORMISSED'        : 'TOPCALIBRATORMISSED',
    'TRAINTOPMISSED'             : 'TRAINTOPMISSED',
    'WEBSITESTATS'               : 'WEBSITESTATS',
    'PENDINGCALIBRATION'         : 'PENDINGCALIBRATIONS', //PendingCalibration client
    'PENDINGINTERNALCALIBRATION' : 'PENDINGINTERNALCALIBRATIONS',
    'CALIBRATIONSECTIONSCORES'   : 'CALIBRATIONSECTIONSCORES',
    'AVGCALLTIME'                : 'AVGCALLTIME',
    'SCORECARDDETAILEDINFO'      : 'SCORECARDDETAILS',
}

export function setModules(userModules, allowedModules, requiredModules) {
    // Temporary hardcoded today stats module
    const todayStatsPresent = requiredModules.find(m => mapedModules[m.module_title.toUpperCase()] === 'TODAYSTATS')
    if (!todayStatsPresent) requiredModules.push({module_title: 'TODAYSTATS'})
    // end

    const getAllowed = m => {
        const existAllowed = allowedModules.find(am => mapedModules[am.module_title.toUpperCase()] === m.id)
        return !!existAllowed
    }
    const allowedAvailable = R.filter(getAllowed, R.clone(availableModules))
    requiredModules.forEach(m => {
        const existRequired = allowedAvailable.find(aam => aam.id === mapedModules[m.module_title.toUpperCase()])
        if (existRequired) {
            existRequired.isRequired = true
            existRequired.checked = true
            return
        }
        const existAvailable = availableModules.find(am => am.id === mapedModules[m.module_title.toUpperCase()])
        if (existAvailable) {
            existAvailable.isRequired = true
            existAvailable.checked = true
            allowedAvailable.push(existAvailable)
        }
    })
    let modules = []
    if (userModules && typeof userModules[0] === 'string') {
        userModules.forEach(id => {
            const userModule = allowedAvailable.find(m => m.id === id)
            if (!userModule) return
            userModule.checked = true
            modules.push(userModule)
        })
        allowedAvailable.forEach(m => {
            const userModule = modules.find(um => m.id === um.id)
            if (userModule) return
            m.checked = m.isRequired ? true : false
            modules.push(m)
        })
    } else {
        modules = allowedAvailable
    }
    const sp = modules.find(m => m.id === 'SCOREPERFORMANCE')
    if (sp) {
        modules.splice(modules.indexOf(sp), 1)
        modules.push(sp)
    }
    const stats = modules.find(m => m.id === 'TODAYSTATS')
    if (stats) {
        modules.splice(modules.indexOf(stats), 1)
        modules.unshift(stats)
    }
    return modules
}

export function convertFiltersToSave(filters) {
    const getShort = (data) => {
        if (typeof data === 'boolean') return data
        if (typeof data === 'object' && data.hasOwnProperty('length')) {
            return data.map(function(item) {
                return {id: item.id}
            })
        }
        return data
    }
    const mapped = R.map(getShort, R.clone(filters))
    mapped.missedItems = filters.missedItems
    return mapped
}

export function sortFilterCallsLeft(calls, dateSettings) {
    return
}

export function groupCallsLeft(calls) {
    if (!calls || !calls.length) return []
    const groups = []
    calls.forEach(call => {
        const name = call.scorecard.scorecardName
        if (groups.indexOf(name) === -1) groups.push(name)
    })
    return groups.map(name => {
        const group = []
        calls.forEach(call => {
            if (name === call.scorecard.scorecardName) group.push(call)
        })
        group.sort((a, b) => new Date(a.callDate) - new Date(b.callDate))
        return group
    })
}

export function sort(items, sorting, module) {
    let getData = function() {
        return items
    }
    switch (module) {
        case 'scorecardDetailedInfo':
            getData = sortScorecardDetailedInfo
            break;
        case 'coachingQueue':
            getData = sortCoachingQueue
            break;
        case 'agentRanking':
            getData = sortAgentRanking
            break;
        case 'topMissed':
            getData = sortTopMissed
            break;
        case 'topCalibratorMissed':
            getData = sortTopMissed
            break;
        case 'topQAMissed':
            getData = sortTopMissed
            break;
        case 'groupPerformance':
            getData = sortGroupPerformance
            break;
        case 'campaignPerformance':
            getData = sortCampaignPerformance
            break;
    }
    return getData(items, sorting)
}

const sortScorecardDetailedInfo = (items, sorting) => {
    return items.sort((a, b) => {
        if (a.scorecard.scorecardName < b.scorecard.scorecardName) return sorting.sortOrder === 'asc' ? -1 : 1;
        if (a.scorecard.scorecardName > b.scorecard.scorecardName) return sorting.sortOrder === 'asc' ? 1 : -1;
        return 0;
    })
}

const sortCoachingQueue = (items, sorting) => {
    const calls = items.calls
     switch (sorting.sortBy) {
        case 'date':
            calls.sort((a, b) => {
                return sorting.sortOrder === 'asc' ?
                    new Date(b.metaDataCallDate) - new Date(a.metaDataCallDate) :
                    new Date(a.metaDataCallDate) - new Date(b.metaDataCallDate)
            })
            break;
        case 'agent':
            calls.sort((a, b) => {
                if (a.metaDataAgentName < b.metaDataAgentName) return sorting.sortOrder === 'asc' ? -1 : 1;
                if (a.metaDataAgentName > b.metaDataAgentName) return sorting.sortOrder === 'asc' ? 1 : -1
                return 0;
            })
            break;
        case 'score':
            calls.sort((a, b) => {
                return sorting.sortOrder === 'asc' ?
                b.systemData.agentScore - a.systemData.agentScore :
                a.systemData.agentScore - b.systemData.agentScore
            })
            break;
        default:

    }
    return items
}

const sortAgentRanking = (items, sorting) => {

    switch (sorting.sortBy) {
        case 'agent':
            items.sort((a, b) => {
                var nameA = a.name.toLowerCase()
                var nameB = b.name.toLowerCase()
                if (nameA < nameB) return sorting.sortOrder === 'asc' ? -1 : 1;
                if (nameA > nameB) return sorting.sortOrder === 'asc' ? 1 : -1
                return 0;
            })
            break;
        case 'score':
            items.sort((a, b) => {
                return sorting.sortOrder === 'asc' ?
                b.averageScore - a.averageScore :
                a.averageScore - b.averageScore
            })
            break;
        case 'start-date':
            items.sort((a, b) => {
                const ad = new Date(a.earliestCallDate)
                const bd = new Date(b.earliestCallDate)
                return sorting.sortOrder === 'asc' ?
                new Date(b.earliestCallDate) - new Date(a.earliestCallDate) :
                new Date(a.earliestCallDate) - new Date(b.earliestCallDate)
            })
            break;
        case 'delta':
            items.sort((a, b) => {
                return sorting.sortOrder === 'asc' ?
               (b.previousAverageScore - b.averageScore) - (a.previousAverageScore - a.averageScore):
                (a.previousAverageScore - a.averageScore) - (b.previousAverageScore - b.averageScore)
            })
            break;
        default:

    }
    return items
}

const sortTopMissed = (items, sorting) => {
    switch (sorting.sortBy) {
        case 'missed-point':
            items.sort((a, b) => {
                if (a.questionShortName < b.questionShortName) return sorting.sortOrder === 'asc' ? -1 : 1;
                if (a.questionShortName > b.questionShortName) return sorting.sortOrder === 'asc' ? 1 : -1
                return 0;
            })
            break;
        case 'occurrence':
            items.sort((a, b) => {
                return sorting.sortOrder === 'asc' ?
                (b.missedCalls/b.totalCalls) - (a.missedCalls/a.totalCalls):
                (a.missedCalls/a.totalCalls) - (b.missedCalls/b.totalCalls)
            })
            break;
        case 'section-name':
            items.sort((a, b) => {
                if (a.questionSectionName < b.questionSectionName) return sorting.sortOrder === 'asc' ? -1 : 1;
                if (a.questionSectionName > b.questionSectionName) return sorting.sortOrder === 'asc' ? 1 : -1
                return 0;
            })
            break;
        case 'delta':
            items.sort((a, b) => {
                const aDelta = (a.totalCalls ? a.missedCalls / a.totalCalls : 0)
                - (a.comparedTotalCalls ? a.comparedMissedCalls / a.comparedTotalCalls : 0)
                const bDelta = (b.totalCalls ? b.missedCalls / b.totalCalls : 0)
                - (b.comparedTotalCalls ? b.comparedMissedCalls / b.comparedTotalCalls : 0)
               return sorting.sortOrder === 'asc'
               ? bDelta - aDelta
                : aDelta - bDelta
            })
            break;
        default:
    }
    return items
}

const sortGroupPerformance = (items, sorting) => {
    switch (sorting.sortBy) {
        case 'name-group':
            items.sort((a, b) => {
                if (a.groupInfo.name < b.groupInfo.name) return sorting.sortOrder === 'asc' ? -1 : 1;
                if (a.groupInfo.name > b.groupInfo.name) return sorting.sortOrder === 'asc' ? 1 : -1
                return 0;
            })
            break;
        case 'score':
            items.sort((a, b) => {
                return sorting.sortOrder === 'asc' ?
                b.currentPeriod.score - a.currentPeriod.score:
                a.currentPeriod.score - b.currentPeriod.score
            })
            break;
        case 'delta':
            items.sort((a, b) => {
                return sorting.sortOrder === 'asc' ?
               (b.previousPeriod.score - b.currentPeriod.score) - (a.previousPeriod.score - a.currentPeriod.score):
                (a.previousPeriod.score - a.currentPeriod.score) - (b.previousPeriod.score - b.currentPeriod.score)
            })
            break;
        default:

    }
    return items
}

const sortCampaignPerformance = (items, sorting) => {
    switch (sorting.sortBy) {
        case 'name-campaing':
            items.sort((a, b) => {
                if (a.campaignInfo.name < b.campaignInfo.name) return sorting.sortOrder === 'asc' ? -1 : 1;
                if (a.campaignInfo.name > b.campaignInfo.name) return sorting.sortOrder === 'asc' ? 1 : -1
                return 0;
            })
            break;
        case 'score':
            items.sort((a, b) => {
                return sorting.sortOrder === 'asc' ?
                b.currentPeriod.score - a.currentPeriod.score:
                a.currentPeriod.score - b.currentPeriod.score
            })
            break;
        case 'delta':
            items.sort((a, b) => {
                return sorting.sortOrder === 'asc' ?
               (b.previousPeriod.score - b.currentPeriod.score) - (a.previousPeriod.score - a.currentPeriod.score):
                (a.previousPeriod.score - a.currentPeriod.score) - (b.previousPeriod.score - b.currentPeriod.score)
            })
            break;
        default:

    }
    return items
}

export const sortCalls = (calls, sortBy, sortOrder) => {
    let items = calls.slice()
    switch (sortBy) {
        case 'agentGroup':
            return items.sort((a, b) => {
                if (a.metaData.agentGroup < b.metaData.agentGroup) return sortOrder === 'asc' ? -1 : 1;
                if (a.metaData.agentGroup > b.metaData.agentGroup) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;
        case 'agentName':
            return items.sort((a, b) => {
                if (a.metaData.agentName < b.metaData.agentName) return sortOrder === 'asc' ? -1 : 1;
                if (a.metaData.agentName > b.metaData.agentName) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;
        case 'campaign':
            return items.sort((a, b) => {
                if (a.metaData.campaign < b.metaData.campaign) return sortOrder === 'asc' ? -1 : 1;
                if (a.metaData.campaign > b.metaData.campaign) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;
        case 'callDate':
            return items.sort((a, b) => {
                return sortOrder === 'asc' ?
                    new Date(b.metaData.callDate) - new Date(a.metaData.callDate) :
                    new Date(a.metaData.callDate) - new Date(b.metaData.callDate)
            })
            break;
        case 'reviewDate':
            return items.sort((a, b) => {
                return sortOrder === 'asc' ?
                    new Date(b.systemData.reviewDate) - new Date(a.systemData.reviewDate) :
                    new Date(a.systemData.reviewDate) - new Date(b.systemData.reviewDate)
            })
            break;
        case 'missedItems':
            return items.sort((a, b) => {
                const bm = b.callMissedItems.length || 0
                const am = a.callMissedItems.length || 0
                return sortOrder === 'asc' ? bm - am : am - bm
            })
            break;
        case 'agentScore':
            return items.sort((a, b) => {
                const bs = b.systemData.agentScore || 0
                const as = a.systemData.agentScore || 0
                return sortOrder === 'asc' ? bs - as : as - bs
            })
            break;
        case 'scorecardName':
            return items.sort((a, b) => {
                if (a.systemData.scorecardName < b.systemData.scorecardName) return sortOrder === 'asc' ? -1 : 1;
                if (a.systemData.scorecardName > b.systemData.scorecardName) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;

        case 'prospectPhone':
            return items.sort((a, b) => {
                if (a.metaData.prospectPhone < b.metaData.prospectPhone) return sortOrder === 'asc' ? -1 : 1;
                if (a.metaData.prospectPhone > b.metaData.prospectPhone) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;
        case 'prospectEmail':
            return items.sort((a, b) => {
                if (a.metaData.prospectEmail < b.metaData.prospectEmail) return sortOrder === 'asc' ? -1 : 1;
                if (a.metaData.prospectEmail > b.metaData.prospectEmail) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;
        case 'prospectFirstName':
            return items.sort((a, b) => {
                if (a.metaData.prospectFirstName < b.metaData.prospectFirstName) return sortOrder === 'asc' ? -1 : 1;
                if (a.metaData.prospectFirstName > b.metaData.prospectFirstName) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;
        case 'prospectLastName':
            return items.sort((a, b) => {
                if (a.metaData.prospectLastName < b.metaData.prospectLastName) return sortOrder === 'asc' ? -1 : 1;
                if (a.metaData.prospectLastName > b.metaData.prospectLastName) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;
        case 'reviewerName':
            return items.sort((a, b) => {
                if (a.systemData.reviewerName < b.systemData.reviewerName) return sortOrder === 'asc' ? -1 : 1;
                if (a.systemData.reviewerName > b.systemData.reviewerName) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;
        case 'calibratorName':
            return items.sort((a, b) => {
                if (a.systemData.calibratorName < b.systemData.calibratorName) return sortOrder === 'asc' ? -1 : 1;
                if (a.systemData.calibratorName > b.systemData.calibratorName) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;
        case 'sessionId':
            return items.sort((a, b) => {
                if (a.metaData.sessionId < b.metaData.sessionId) return sortOrder === 'asc' ? -1 : 1;
                if (a.metaData.sessionId > b.metaData.sessionId) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            })
            break;
        default:

    }
    return items
}

export const sectionScoresHardcodedData = {
    scorecard: {
        scorecardId:292,
        scorecardName:'ACM Warm Transfer'
    },
    sections: [
        {
            sectionName:'Qualifiers',
            questions: [
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
            ]
        },
        {
            sectionName:'Test',
            questions: [
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
                {"questionID":12696,"questionName":"2.8 Spoken to Schools","sectionName":"Qualifiers","isComposite":false,"questionType":"regular","isLinked":false,"simpleQuestionStat":{"answerList":[{"answerText":"No","total":1,"isRightAnswer":false,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0},{"answerText":"Yes","total":1,"isRightAnswer":true,"comments":[{"commentText":"","commentId":null,"total":1}],"totalCustomComments":0}]},"compositeQuestionStat":null,"scorecard":{"scorecardId":292,"scorecardName":"ACM Warm Transfer"},"total":3},
            ]
        }
    ]
}

export function getCheckedColumnsIds(columns) {
    const filtered = columns.filter(c => c.checked && c.id !== 'icons')
    return filtered.map(c => c.id)
}

export function getCheckedCoachingColumnsIds(columns) {
    const filtered = columns.filter(c => c.checked && c.id !== 'icons')
    return filtered.map((item, i) => {
        return {
            id   : item.id,
            Name : item.value
        }
    })

}

export function sortExportQueue(data) {
    return data.sort((a, b) => new Date(b.date) - new Date(a.date))
}
