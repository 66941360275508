'use strict';

import * as R from 'ramda'

const initialState ={
    scorecardsList            : null,
    getScorecardInfo          : null,
    activeScorecard           : null,
    emptyScorecard            : null,
    activeScorecardLoading    : false,
    activeScorecardError      : false,
    activeQuestion            : null,
    activeAudioLink           : null,
    reviewLoading             : false,
    reviewError               : false,
    activeRecordLoading       : false,
    activeRecordError         : false,
    highlightedQuestionType   : null,
    errorRecordMessage        : null,
    metaDataUpdating          : false,
    metaDataItemUpdating      : null,
    modalOptions              : null,
    changelogLoading          : false,
    changelogError            : false,
    changelog                 : [],
    changelogQuestionLoading  : false,
    changelogQuestionError    : false,
    changelogQuestion         : [],
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "GUIDELINES_PAGE_ERROR_RECORD_MESSAGE": {
            return {
                ...state,
                activeRecordLoading: false,
                errorRecordMessage: action.payload,
            }
        }
        case "GUIDELINES_PAGE_GET_SCORECARDS_LIST": {
            return {
              ...state,
              scorecardsList: action.payload,
            }
          }
        case "GUIDELINES_PAGE_HIGHLIGHTED_QUESTION_TYPE": {
            return {
                ...state,
                highlightedQuestionType: action.payload,
            }
        }
        case "GUIDELINES_PAGE_ACTIVE_RECORD_ERROR": {
            return {
                ...state,
                activeAudioLink: null,
                activeRecordLoading: false,
                activeRecordError: true,
            }
        }
        case "GUIDELINES_PAGE_ACTIVE_RECORD_LOADING": {
            return {
                ...state,
                activeAudioLink: null,
                activeRecordLoading: true,
                activeRecordError: false,
                errorRecordMessage: null,
            }
        }
        case "GUIDELINES_PAGE_SET_ACTIVE_RECORD": {
            return {
                ...state,
                activeScorecard: R.clone(action.payload),
                activeRecordLoading: false,
                activeRecordError: false,
            }
        }
        case "GUIDELINES_PAGE_SET_ACTIVE_QUESTION": {
            return {
                ...state,
                activeQuestion: action.payload,
            }
        }
        case "GUIDELINES_PAGE_SET_ACTIVE_SCORECARD": {
            return {
                ...state,
                activeScorecard: R.clone(action.payload),
                activeScorecardLoading: false,
                activeScorecardError: false,
            }
        }
        case "GUIDELINES_PAGE_SET_EMPTY_SCORECARD": {
            return {
                ...state,
                emptyScorecard: R.clone(action.payload),
            }
        }
        case "GUIDELINES_RESET_SCORECARD": {
            return {
                ...state,
                activeScorecard: R.clone(action.payload),
            }
        }
        case "GUIDELINES_PAGE_ACTIVE_SCORECARD_LOADING": {
            return {
                ...state,
                activeAudioLink: null,
                emptyScorecard: null,
                activeScorecard: null,
                activeScorecardLoading: true,
                activeScorecardError: false,
                errorRecordMessage: null,
                activeRecordLoading: false,
                activeRecordError: false,
            }
        }
        case "GUIDELINES_PAGE_ACTIVE_SCORECARD_ERROR": {
            return {
                ...state,
                activeAudioLink: null,
                activeScorecard: null,
                activeScorecardLoading: false,
                activeScorecardError: true,
            }
        }
        case "GUIDELINES_ACTIVE_AUDIO_LINK": {
            return {
                ...state,
                activeAudioLink: action.payload
            }
        }
        case "GUIDELINES_METADATA_UPDATING": {
            return {
                ...state,
                metaDataUpdating: action.payload
            }
        }
        case "GUIDELINES_METADATA_ITEM": {
            return {
                ...state,
                metaDataItemUpdating: action.payload
            }
        }
        case "GUIDELINES_METADATA_UPDATED": {
            return {
                ...state,
                metaDataItemUpdating: null,
                metaDataUpdating: false
            }
        }
        case "SET_QUESTION_CHANGELOG_MODAL_OPTIONS": {
            return {
                ...state,
                modalOptions: action.payload,
            }
        }
        case "GUIDELINES_QUESTIONS_CHANGELOG_LOADING": {
            return {
                ...state,
                changelogLoading: action.payload,
                changelogError: false
            }
        }
        case "GUIDELINES_QUESTIONS_CHANGELOG": {
            return {
                ...state,
                changelogLoading: false,
                changelogError: false,
                changelog: action.payload
            }
        }
        case "GUIDELINES_QUESTIONS_CHANGELOG_ERROR": {
            return {
                ...state,
                changelogLoading: false,
                changelogError: action.payload,
            }
        }
        case "GUIDELINES_CHANGELOG_FOR_QUESTION_LOADING": {
            return {
                ...state,
                changelogQuestionLoading: action.payload,
                changelogQuestionError: false
            }
        }
        case "GUIDELINES_CHANGELOG_FOR_QUESTION": {
            return {
                ...state,
                changelogQuestionLoading: false,
                changelogQuestionError: false,
                changelogQuestion: action.payload
            }
        }
        case "GUIDELINES_CHANGELOG_FOR_QUESTION_ERROR": {
            return {
                ...state,
                changelogQuestionLoading: false,
                changelogQuestionError: action.payload,
            }
        }
    }
    return state
}
