'use strict'

import * as R from 'ramda'
import moment from 'moment'

import {getDates} from './filters'

export const convertCalibrationCallsResp = (data) => {
    if (!data) return data
    const checkAssignee = (opts) => {
        opts.assignedTo = opts.assignedTo
        ? R.isEmpty(opts.assignedTo)
        ? []
        : R.isEmpty(opts.assignedTo.filter(item => !R.isEmpty(item)))
        ? []
        : opts.assignedTo.filter(item => !R.isEmpty(item))
        : []
        opts.whoProcessed = opts.whoProcessed
        ? R.isEmpty(opts.whoProcessed)
        ? []
        : R.isEmpty(opts.whoProcessed.filter(item => !R.isEmpty(item)))
        ? []
        : opts.whoProcessed.filter(item => !R.isEmpty(item))
        : []
        return opts
    }
    const convertCall = (call) => {
        call.ccSide = checkAssignee(call.ccSide)
        call.clientSide = checkAssignee(call.clientSide)
        return call
    }
    if (data.pending && data.pending.length) data.pending.forEach(convertCall)
    if (data.completed && data.completed.length) data.completed.forEach(convertCall)
    return data
}

export const convertFilterRequestData = (filters, dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
        .map(dateStr => moment(dateStr).format("YYYY-MM-DD"));
    return {
        filters: {
            scorecards:         R.pluck("id", filters.scorecards),
            campaigns:          R.pluck("id", filters.campaigns),
            groups:             R.pluck("id", filters.groups),
            agents:             R.pluck("id", filters.agents),
            QAs:                R.pluck("id", filters.QAs),
            teamLeads:          R.pluck("id", filters.teamLeads),
            missedItems:        R.pluck("id", filters.missedItems),
            failedOnly:         filters.failedOnly,
            badCallsOnly:       filters.badCallsOnly,
            passedOnly:         filters.passedOnly,
            filterByReviewDate: filters.filterByReviewDate,
            reviewType:         filters.reviewType,
            missedBy:           filters.missedBy,
            pendingOnly:        filters.pendingOnly,
            callType:           filters.callType,
        },
        range:      {start: rangeStart,      end: rangeEnd},
        comparison: {start: comparisonStart, end: comparisonEnd},
    };
}

export const convertFilterRequestDataIsConversion = (filters, dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
        .map(dateStr => moment(dateStr).format("YYYY-MM-DD"));
    return {
        filters: {
            scorecards:         R.pluck("id", filters.scorecards),
            campaigns:          R.pluck("id", filters.campaigns),
            groups:             R.pluck("id", filters.groups),
            agents:             R.pluck("id", filters.agents),
            QAs:                R.pluck("id", filters.QAs),
            teamLeads:          R.pluck("id", filters.teamLeads),
            missedItems:        R.pluck("id", filters.missedItems),
            failedOnly:         filters.failedOnly,
            badCallsOnly:       filters.badCallsOnly,
            passedOnly:         filters.passedOnly,
            filterByReviewDate: filters.filterByReviewDate,
            reviewType:         filters.reviewType,
            missedBy:           filters.missedBy,
            pendingOnly:        filters.pendingOnly,
            isConversion:       true,
            callType:           filters.callType,
        },
        range:      {start: rangeStart,      end: rangeEnd},
        comparison: {start: comparisonStart, end: comparisonEnd},
    };
}

export const convertFilterRequestDataIsSales = (filters, dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
        .map(dateStr => moment(dateStr).format("YYYY-MM-DD"));
    return {
        filters: {
            scorecards:         R.pluck("id", filters.scorecards),
            campaigns:          R.pluck("id", filters.campaigns),
            groups:             R.pluck("id", filters.groups),
            agents:             R.pluck("id", filters.agents),
            QAs:                R.pluck("id", filters.QAs),
            teamLeads:          R.pluck("id", filters.teamLeads),
            missedItems:        R.pluck("id", filters.missedItems),
            failedOnly:         filters.failedOnly,
            badCallsOnly:       filters.badCallsOnly,
            passedOnly:         filters.passedOnly,
            filterByReviewDate: false,
            reviewType:         filters.reviewType,
            missedBy:           filters.missedBy,
            pendingOnly:        filters.pendingOnly,
            callType:           filters.callType,
        },
        range:      {start: rangeStart,      end: rangeEnd},
        comparison: {start: comparisonStart, end: comparisonEnd},
    };
}

export const convertFilterBreakdownReport = (filters, dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
        .map(dateStr => moment(dateStr).format("YYYY-MM-DD"));
    return {
        filters: {
            scorecards:         R.pluck("id", filters.scorecards),
            campaigns:          R.pluck("id", filters.campaigns),
            groups:             R.pluck("id", filters.groups),
            agents:             R.pluck("id", filters.agents),
            failedOnly:         filters.failedOnly,
            badCallsOnly:       filters.badCallsOnly,
            passedOnly:         filters.passedOnly,
            filterByReviewDate: false,
            missedBy:           filters.missedBy,
            isConversion: false
        },
        range:      {start: rangeStart,      end: rangeEnd},
    };
}

export const convertFilterClientsAlerts = (dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
        .map(dateStr => moment(dateStr).format("YYYY-MM-DD"));
    return {
        range:             {start: rangeStart,      end: rangeEnd},
    };
}

export const convertFilterRequestDataIsBreakdown = (filters, dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
        .map(dateStr => moment(dateStr).format("YYYY-MM-DD"));
    return {
        filters: {
            scorecards:         R.pluck("id", filters.scorecards),
            campaigns:          R.pluck("id", filters.campaigns),
            groups:             R.pluck("id", filters.groups),
            agents:             R.pluck("id", filters.agents),
            QAs:                R.pluck("id", filters.QAs),
            teamLeads:          R.pluck("id", filters.teamLeads),
            missedItems:        R.pluck("id", filters.missedItems),
            failedOnly:         filters.failedOnly,
            badCallsOnly:       filters.badCallsOnly,
            passedOnly:         filters.passedOnly,
            filterByReviewDate: false,
            isConversion:       false,
            reviewType:         filters.reviewType,
            missedBy:           filters.missedBy,
            pendingOnly:        filters.pendingOnly,
            callType:           filters.callType,
            answerIds:          filters.answerIds,
            commentIds:         filters.commentIds,
            compositeAnswerIds: filters.compositeAnswerIds,
            compositeCommentIds:filters.compositeCommentIds,
            conversionFilters:  filters.conversionFilters,
            salesType:          filters.salesType,
            filterBy:           filters.filterBy,
        },
        range:      {start: rangeStart,      end: rangeEnd},
    };
}

export const convertNIFilterRequestData = (filters, dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
        .map(dateStr => moment(dateStr).format("YYYY-MM-DD"));
    return {
        filters: {
            scorecards:         R.pluck("id", filters.scorecards),
            campaigns:          R.pluck("id", filters.campaigns),
            groups:             R.pluck("id", filters.groups),
            agents:             R.pluck("id", filters.agents),
            QAs:                R.pluck("id", filters.QAs),
            teamLeads:          R.pluck("id", filters.teamLeads),
            missedItems:        R.pluck("id", filters.missedItems),
            failedOnly:         filters.failedOnly,
            badCallsOnly:       filters.badCallsOnly,
            passedOnly:         filters.passedOnly,
            filterByReviewDate: filters.filterByReviewDate,
            reviewType:         filters.reviewType,
            missedBy:           filters.missedBy,
            pendingOnly:        filters.pendingOnly,
            // callType:           filters.callType,
        },
        range:      {start: rangeStart,      end: rangeEnd},
        comparison: {start: comparisonStart, end: comparisonEnd},
    };
}

export const convertNotificationDateSettings = (dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
    return {
        start: rangeStart,
        end  : rangeEnd,
    }
}

export const convertNotificationFilterRequestData = (filters, dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
        .map(dateStr => moment(dateStr).format("YYYY-MM-DD"));
    return {
        filters: {
            apps:               R.pluck("id", filters.apps),
            supervisors:        R.pluck("id", filters.supervisors),
            scorecards:         R.pluck("id", filters.scorecards),
            QAs:                R.pluck("id", filters.QAs),
            teamLeads:          R.pluck("id", filters.teamLeads),
            calibrators:        R.pluck("id", filters.calibrators),
            filterByDateClosed: filters.filterByDateClosed,
        },
        range:      {start: rangeStart,      end: rangeEnd},
    };
}

export const convertConversionFilterRequestData = (filters, dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
        .map(dateStr => moment(dateStr).format("YYYY-MM-DD"));
    return {
        filters: {
            agents:             R.pluck("id", filters.agents),
            groups:             R.pluck("id", filters.groups),
            scorecards:         R.pluck("id", filters.scorecards),
            campaigns:          R.pluck("id", filters.campaigns),
        },
        range:      {start: rangeStart,      end: rangeEnd},
    };
}

export const convertDateRange = (dateSettings) => {
    let {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    [rangeStart, rangeEnd, comparisonStart, comparisonEnd] = [rangeStart, rangeEnd, comparisonStart, comparisonEnd]
        .map(dateStr => moment(dateStr).format("YYYY-MM-DD"));
    return {
        range:      {start: rangeStart,      end: rangeEnd},
        comparison: {start: comparisonStart, end: comparisonEnd},
    };
}

export const convertUserRole = (userData) => {
    const userRole = userData.UserRole
    const ROLES = {
        'Admin'            : 'ADMIN',
        'Agent'            : 'AGENT',
        'Calibrator'       : 'CALIBRATOR',
        'Center Manager'   : 'CENTER MANAGER',
        'Client'           : 'CLIENT',
        'Client Calibrator': 'CLIENT CALIBRATOR',
        'Inactive'         : 'INACTIVE',
        'Manager'          : 'MANAGER',
        'Partner'          : 'PARTNER',
        'QA'               : 'QA',
        'QA Lead'          : 'QA LEAD',
        'Supervisor'       : 'SUPERVISOR',
        'Tango TL'         : 'TANGO TL'
    }
    let role = ROLES[userRole] || 'NO ROLE'
    return role
}

export const convertUserData = (data) => {
    const getUserLinks = (links) => {
        // TODO: convert user links
        return links
    }
    return {
        PreviousUser: data.PreviousUser || null,
        SpeedInc: parseInt(data.SpeedInc, 10) || 5,
        StartImmediately: data.StartImmediately || false,
        UserName: data.UserName || 'NO USER NAME',
        UserRole: convertUserRole(data),
        UserTitle: data.UserTitle || 'NO TITLE',
        UserLinks: getUserLinks(data.UserLinks),
        modules: data.modules,
        modulesRequired: data.modulesRequired || null,
        scorecards: data.scorecards,
        userClientLogo: data.userClientLogo || null,
        useNewDashboard: data.useNewDashboard,
        reloadTime: data.reloadTime || null,
        apps: data.apps || null,
        appsInfo: data.appsInfo || null,
        version: data.version,
        nonEdit: data.nonEdit || false,
        allowGapSkip: data.allowGapSkip || false,
        minGapLength: data.minGapLength || 5
    }
}

export const convertDashboardFilters = (data) => {
    const conv = typeof data === 'object' ? R.clone(data) :
    {
        QAs: [],
        agents:[],
        campaigns:[],
        groups:[],
        missedItems:[],
        scorecards:[],
        teamLeads:[],
        callType: '',
    }
    if (!conv.missedItems) conv.missedItems = []
    return conv;
}

export const convertNotificationFilters = (data) => {
    const conv = typeof data === 'object' ? R.clone(data) :
    {
        apps: [],
        supervisors: [],
        scorecards: [],
        QAs: [],
        teamLeads: [],
        calibrators: [],
    }
    return conv;
}

export const convertConversionFilters = (data) => {
    const conv = typeof data === 'object' ? R.clone(data) :
    {
        agents: [],
        groups: [],
        scorecards: [],
        campaigns: [],
    }
    return conv;
}

export const convertAverages = (str) => {
    const avgs = str.split(':')
    return {
        average: parseInt(avgs[0], 10) || 0,
        increment: parseInt(avgs[1], 10) || 0
    }
}

export const convertAgents = (items) => {
    return items.map((item, i) => {
        return {
            text: item.text,
            value: item.value,
            selected: item.selected || false
        }
    })
}

export const convertScorePerf = R.map(
    R.unless(R.isNil, R.map(R.converge(R.zipObj, [
        R.always(["avgScore", "callDate"]),
        R.props(["averageScore", "date"])
    ])))
)

export const convertCoachingColumns = (list) => {
    return list.map((item, i) => {
        return {
            id   : item.id,
            Name : item.value
        }
    })
}
