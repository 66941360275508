import * as R from 'ramda'

import { getEmptyFilters } from './filters/filtersUtils'
import { getDefaultColumns, getDefaultColumnsChartType } from './utils'

const initialState = {
    modalOptions               : null,
    filters                    : getEmptyFilters(),
    emptyFilters               : getEmptyFilters(),
    filtersLoading             : false,
    filtersError               : false,
    range: {
        end: '',
        start: ''
    },
    availableFilters           : null,
    availableFiltersLoading    : false,
    availableFiltersError      : false,
    statsData                  : null,
    statsDataLoading           : false,
    statsDataError             : false,
    statsDataTimeoutError      : false,
    performanceChartType       : 0,
    performanceData            : null,
    performanceDataLoading     : false,
    performanceDataError       : false,
    performanceDataTimeoutError: false,
    chartPagination            : {
        pagerows: 5,
        pagenum : 1,
    },
    chartTypeSorting           : {
        sortBy   : 'id',
        sortOrder: 'asc',
    },
    chartType                  : 'agent', // 'agent', 'agent_group', 'campaign', abandoned
    chartData                  : null,
    chartDataLoading           : false,
    chartDataError             : false,
    chartDataTimeoutError      : false,
    exportingChartType         : false,
    chartTypeColumns           : getDefaultColumnsChartType(),
    otherNonQualFilter         : '',
    sortingOpenAlerts          : {
        sortBy   : 'callDate',
        sortOrder: 'desc',
    },
    paginationOpenAlerts       : {
        pagerows: 5,
        pagenum : 1,
    },
    sortingCloseAlerts         : {
        sortBy   : 'callDate',
        sortOrder: 'desc',
    },
    paginationCloseAlerts      : {
        pagerows: 5,
        pagenum : 1,
    },    
    openClientAlerts               : null,
    openClientAlertsLoading        : false,
    openClientAlertsError          : false,
    openClientAlertsTimeoutError   : false,
    closedClientAlerts             : null,
    closedClientAlertsLoading      : false,
    closedClientAlertsError        : false,
    closedClientAlertsTimeoutError : false,
    leadResultLoading              : false,
    leadResultError                : false,
    leadResult                     : null,
    reasonsLoading                 : false,
    reasonsError                   : false,
    reasons                        : null,
    sorting                    : {
        sortBy:    'callDate',
        sortOrder: 'asc',
    },
    pagination                 : {
        pagenum: 1,
        pagerows: 20
    },
    callDetails                : [],
    callDetailsLoading         : true,
    callDetailsInfiniteLoading : false,
    callDetailsLoadingError    : false,
    callDetailsTimeoutError    : false,
    tableColumns               : getDefaultColumns(),
    callDetailsSearchText      : '',
    searching                  : false,
    searchingError             : false,
    exportedCallDetails        : null,
    exportedCallDetailesLoading: false,
    exportedCallDetailesError  : false,
    exportingCallDetails       : false,
    chartTypeColumnFilter      : '',
    otherNonQualFilter         : '',
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case 'SET_CALL_TYPE_PAGE_MODAL_OPTIONS': {
            return {
                ...state,
                modalOptions: action.payload,
            }
        }
        case 'ALERT_MANAGER_FILTERS_LOADING': {
            return {
                ...state,
                filtersLoading: action.payload,
                filtersError  : false
            }
        }
        case 'ALERT_MANAGER_FILTERS_LOADED': {
            return {
                ...state,
                filtersLoading: false,
                filtersError  : false,
                filters       : action.payload
            }
        }
        case 'ALERT_MANAGER_FILTERS_ERROR': {
            return {
                ...state,
                filtersLoading: false,
                filtersError  : action.payload
            }
        }
        case 'ALERT_MANAGER_AVAIBLE_FILTERS_LOADING': {
            return {
                ...state,
                availableFiltersLoading: action.payload,
                availableFiltersError  : false
            }
        }
        case 'ALERT_MANAGER_AVAIBLE_FILTERS_LOADED': {
            return {
                ...state,
                availableFiltersError         : false,
                availableFiltersLoading  : false,
                availableFilters    : action.payload
            }
        }
        case 'ALERT_MANAGER_AVAIBLE_FILTERS_ERROR': {
            return {
                ...state,
                availableFiltersLoading: false,
                availableFiltersError  : action.payload
            }
        }
        case 'ALERT_MANAGER_STATS_FULFILLED': {
            return {
                ...state,
                statsData            : action.payload,
                statsDataLoading     : false,
                statsDataError       : false,
                statsDataTimeoutError: false,
            }
        }
        case 'ALERT_MANAGER_STATS_LOADING': {
            return {
                ...state,
                statsDataLoading     : true,
                statsDataError       : false,
                statsDataTimeoutError: false,
            }
        }
        case 'ALERT_MANAGER_STATS_ERROR': {
            return {
                ...state,
                statsDataError  : true,
                statsDataLoading: false,
            }
        }
        case 'ALERT_MANAGER_STATS_TIMEOUT_ERROR': {
            return {
                ...state,
                statsDataTimeoutError: true,
            }
        }
         case 'ALERT_MANAGER_PERFORMANCE_CHART_TYPE': {
            return {
                ...state,
                performanceChartType: action.payload,
            }
        }
        case 'ALERT_MANAGER_PERFORMANCE_DATA_FULFILLED': {
            return {
                ...state,
                performanceData            : action.payload,
                performanceDataLoading     : false,
                performanceDataError       : false,
                performanceDataTimeoutError: false,
            }
        }
        case 'ALERT_MANAGER_PERFORMANCE_DATA_LOADING': {
            return {
                ...state,
                performanceDataLoading     : true,
                performanceDataError       : false,
                performanceDataTimeoutError: false,
            }
        }
        case 'ALERT_MANAGER_PERFORMANCE_DATA_ERROR': {
            return {
                ...state,
                performanceDataError  : true,
                performanceDataLoading: false,
            }
        }
        case 'ALERT_MANAGER_PERFORMANCE_DATA_TIMEOUT_ERROR': {
            return {
                ...state,
                performanceDataTimeoutError: true,
            }
        }
        case 'ALERT_MANAGER_CHART_DATA_FULFILLED': {
            return {
                ...state,
                chartData            : action.payload,
                chartDataLoading     : false,
                chartDataError       : false,
                chartDataTimeoutError: false,
            }
        }
        case 'ALERT_MANAGER_CHART_ITEMS_TOTAL': {
            return {
                ...state,
                chartDataItemsTotal: action.payload,
            }
        }
        case 'ALERT_MANAGER_CHART_DATA_LOADING': {
            return {
                ...state,
                chartDataLoading     : true,
                chartDataError       : false,
                chartDataTimeoutError: false,
            }
        }
        case 'ALERT_MANAGER_CHART_DATA_ERROR': {
            return {
                ...state,
                chartDataError  : true,
                chartDataLoading: false,
            }
        }
        case 'ALERT_MANAGER_CHART_DATA_TIMEOUT_ERROR': {
            return {
                ...state,
                chartDataTimeoutError: true,
            }
        }
        case 'ALERT_MANAGER_CHART_TYPE': {
            return {
                ...state,
                chartType: action.payload,
            }
        }
        case 'ALERT_MANAGER_CHART_PAGINATION': {
            return {
                ...state,
                chartPagination: action.payload,
            }
        }
        case 'ALERT_MANAGER_CHART_TYPE_SORTING': {
            return {
                ...state,
                chartTypeSorting: action.payload,
            }
        }
        case "ALERT_MANAGER_EXPORT_CHART_TYPE": {
            return {
                ...state,
                exportingChartType: action.payload
            }
        }
        case "ALERT_MANAGER_CHART_TYPE_COLUMN_FILTER": {
            return {
                ...state,
                chartTypeColumnFilter: action.payload
            }
        }
        case 'ALERT_MANAGER_OPEN_CLIENT_ALERTS_FULFILLED': {
            return {
                ...state,
                openClientAlerts            : action.payload,
                openClientAlertsLoading     : false,
                openClientAlertsError       : false,
                openClientAlertsTimeoutError: false,
            }
        }
        case 'ALERT_MANAGER_OPEN_CLIENT_ALERTS_LOADING': {
            return {
                ...state,
                openClientAlertsLoading     : true,
                openClientAlertsError       : false,
                openClientAlertsTimeoutError: false,
            }
        }
        case 'ALERT_MANAGER_OPEN_CLIENT_ALERTS_ERROR': {
            return {
                ...state,
                openClientAlertsError  : true,
                openClientAlertsLoading: false,
            }
        }
        case 'ALERT_MANAGER_OPEN_CLIENT_ALERTS_TIMEOUT_ERROR': {
            return {
                ...state,
                openClientAlertsTimeoutError: true,
            }
        }
        case 'ALERT_MANAGER_CLOSED_CLIENT_ALERTS_FULFILLED': {
            return {
                ...state,
                closedClientAlerts            : action.payload,
                closedClientAlertsLoading     : false,
                closedClientAlertsError       : false,
                closedClientAlertsTimeoutError: false,
            }
        }
        case 'ALERT_MANAGER_CLOSED_CLIENT_ALERTS_LOADING': {
            return {
                ...state,
                closedClientAlertsLoading     : true,
                closedClientAlertsError       : false,
                closedClientAlertsTimeoutError: false,
            }
        }
        case 'ALERT_MANAGER_CLOSED_CLIENT_ALERTS_ERROR': {
            return {
                ...state,
                closedClientAlertsError  : true,
                closedClientAlertsLoading: false,
            }
        }
        case 'ALERT_MANAGER_CLOSED_CLIENT_ALERTS_TIMEOUT_ERROR': {
            return {
                ...state,
                closedClientAlertsTimeoutError: true,
            }
        }
        case 'ALERT_MANAGER_OPEN_CLIENT_ALERTS_PAGINATION': {
            return {
                ...state,
                paginationOpenAlerts: action.payload,
            }
        }
        case 'ALERT_MANAGER_OPEN_CLIENT_ALERTS_SORTING': {
            return {
                ...state,
                sortingOpenAlerts: action.payload,
            }
        }
         case 'ALERT_MANAGER_LEAD_RESULT_LOADING': {
            return {
                ...state,
                leadResultLoading     : true,
                leadResultError       : false,
            }
        }
        case 'ALERT_MANAGER_LEAD_RESULT': {
            return {
                ...state,
                leadResultLoading     : false,
                leadResultError       : false,
                leadResult            : action.payload,
            }
        }
         case 'ALERT_MANAGER_LEAD_RESULT_ERROR': {
            return {
                ...state,
                leadResultLoading     : false,
                leadResultError       : true,
            }
        }
        case 'ALERT_MANAGER_REASONS_LOADING': {
            return {
                ...state,
                reasonsLoading     : true,
                reasonsError       : false,
            }
        }
        case 'ALERT_MANAGER_REASONS': {
            return {
                ...state,
                reasonsLoading     : false,
                reasonsError       : false,
                reasons            : action.payload,
            }
        }
         case 'ALERT_MANAGER_REASONS_ERROR': {
            return {
                ...state,
                reasonsLoading     : false,
                reasonsError       : true,
            }
        }
        case 'ALERT_MANAGER_CLOSE_CLIENT_ALERTS_PAGINATION': {
            return {
                ...state,
                paginationCloseAlerts: action.payload,
            }
        }
        case 'ALERT_MANAGER_CLOSE_CLIENT_ALERTS_SORTING': {
            return {
                ...state,
                sortingCloseAlerts: action.payload,
            }
        }
         case "ALERT_MANAGER_SET_CALL_DETAILS_SEARCH_TEXT": {
            return {
                ...state,
                callDetailsSearchText: action.payload
            }
        }
        case "ALERT_MANAGER_CALL_DETAILS_SEARCHING": {
            return {
                ...state,
                searching: action.payload
            }
        }
        case "ALERT_MANAGER_CALL_DETAILS_SEARCHING_ERROR": {
            return {
                ...state,
                searchingError: action.payload
            }
        }
        case 'ALERT_MANAGER_CALL_DETAILS_TIMEOUT_ERR': {
            return {
                ...state,
                callDetailsTimeoutError: true,
            }
        }
        case "ALERT_MANAGER_CALL_DETAILS_INFINITE_LOADING": {
            return {
                ...state,
                callDetailsLoadingError: false,
                callDetailsInfiniteLoading: action.payload
            }
        }
        case "ALERT_MANAGER_CALL_DETAILS_FULFILLED_INFINITE" : {
            return {
                ...state,
                callDetails: [...state.callDetails, ...action.payload],
                callDetailsLoading: false,
                callDetailsInfiniteLoading: false,
                callDetailsLoadingError: false
            }
        }
        case "ALERT_MANAGER_GET_CALL_DETAILS_FULFILLED" : {
            return {
                ...state,
                callDetails:  action.payload,
                callDetailsLoading: false,
                callDetailsLoadingError: false,
                callDetailsTimeoutError: false,
                callDetailsInfiniteLoading: false,
            }
        }
        case "ALERT_MANAGER_EXPORT_CALL_DETAILS": {
            return {
                ...state,
                exportingCallDetails: action.payload
            }
        }
        case "PAGE_CHANGED": {
            return {
                ...state,
                pagination: action.payload
            }
        }
         case "ALERT_MANAGER_CALL_DETAILS_LOADING": {
            return {
                ...state,
                callDetailsLoadingError: false,
                callDetailsLoading: action.payload,
                callDetailsTimeoutError: false,
                callDetailsInfiniteLoading: false,
            }
        }
    }
    return state
}
