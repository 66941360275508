'use strict'

import numeral from 'numeral'
import classNames from 'classnames'

export default class SimpleComment extends React.Component {
    constructor(props) {
        super(props)

        this.addToFilters = this.addToFilters.bind(this)
        this.iconHovered = this.iconHovered.bind(this)
        this.iconUnhovered = this.iconUnhovered.bind(this)

        this.state = {
            hovered: false,
        }
    }

    iconHovered() {
        this.setState({
            hovered: true,
        })
    }

    iconUnhovered() {
        this.setState({
            hovered: false,
        })
    }

    addToFilters() {
        const {addToFilters, answerText, data, inFilters, isRight} = this.props
        addToFilters(data.commentId, answerText, inFilters, isRight, data.total)
    }

    render() {
        const {data, percent, total, inFilters} = this.props
        const {hovered} = this.state
        const filterClass = classNames({
            'icon-ic-filter': !hovered || !inFilters,
            'icon-ic-close' : hovered && inFilters,
            'filter-icon'   : true,
            'added'         : inFilters,
        })
        return <div class='coment'>
            <div class='coment-text'>
                <div style={{width: 'calc(100% - 16px)'}} dangerouslySetInnerHTML={{__html: data.commentText}} />
                <div
                    onClick={this.addToFilters}
                    onMouseOver={this.iconHovered}
                    onMouseOut={this.iconUnhovered}
                    class={filterClass} />
            </div>
            {
                data.commentId === -666
                ? <div class='numbers'>
                    <div class='calls'>{total}</div>
                </div>
                : <div class='numbers'>
                    <div class='calls'>{data.total} of {total}</div>
                    <div class='percent'>{percent}</div>
                </div>
            }
        </div>
    }
}
