'use strict';

const initialState = {
    fetching          : false,
    fetched           : false,
    getNextCallError  : false,
    listenData        : null,
    scorecard         : null,
    transcript        : null,
    trainingItems     : null,
    trainingMessage   : null,
    trainingMissedItems: null,
    trainingData      : null,
    passed            : null,
    loading           : false,
    getNextCallTimeout: 0,
    getNextCallLoading: false,
    getNextCallError  : false,
    transcriptLoading : false,
    transcriptError   : false,
    chatData          : null,
    chatLoading       : false,
    chatError         : false,
    callsCount        : null,
    callsCountError   : false,
    listenType        : 'regularlisten', // 'newlisten'
    calibrateQAQA     : false,
    scorecardData     : null,
    calibrateQAQATable: null,
    heartbeatInterval : 120000,
    heartbeatUpdater  : false,
    selectedDropdownAnswers: [],
    audioGapInfo: null
}

export default function reducer(state=initialState, action) {

    switch (action.type) {
        case "LISTEN_HEARTBEAT_UPDATE": {
            return {
                ...state,
                heartbeatUpdater: !state.heartbeatUpdater,
            }
        }
        case "SET_LISTEN_TYPE": {
            return {
                ...state,
                listenType: action.payload,
            }
        }
        case "SET_LISTEN_AUDIO_GAP_INFO": {
            return {
                ...state,
                audioGapInfo: action.payload
            }
        }
        case "SET_CALIBRATE_SCORECARD_DATA": {
            return {
                ...state,
                calibrateQAQA: true,
                calibrateQAQATable: null,
                scorecardData: action.payload
            }
        }
        case "UPDATE_CALIBRATE_SCORECARD_DATA": {
            return {
                ...state,
                scorecardData: action.payload
            }
        }
        case "SET_CALIBRATE_TABLE_DATA": {
            return {
                ...state,
                calibrateQAQA: true,
                calibrateQAQATable: action.payload
            }
        }
        case "GET_NEXT_CALL_TIMEOUT_COUNTER": {
            return {
                ...state,
                getNextCallTimeout: action.payload
            }
        }
        case "GET_NEXT_CALL": {
            return {
                ...state,
                getNextCallError: false,
                getNextCallLoading: true
            }
        }
        case "GET_NEXT_CALL_REJECTED": {
            return {
                ...state,
                getNextCallLoading: false,
                getNextCallError: action.payload
            }
        }
        case "GET_NEXT_CALL_FULFILLED": {
            return {
                ...state,
                listenData: action.payload.listenData,
                scorecard: action.payload.scorecard,
                getNextCallLoading: false,
                getNextCallError: false
            }
        }
        case "LOADING_NEXT_CALL": {
            return {
                ...state,
                loading: action.payload
            }
        }
        case "GET_TRAINING_ITEMS": {
            return {
                ...state,
                trainingItems: action.payload
            }
        }
        case "GET_TRAINING_DATA": {
            return {
                ...state,
                trainingData: action.payload
            }
        }
        case "GET_TRAINING_MISSED_ITEMS": {
            return {
                ...state,
                trainingMissedItems: action.payload
            }
        }
        case "GET_TRAINING_MESSAGE": {
            return {
                ...state,
                trainingMessage: action.payload
            }
        }
        case "GET_LISTEN_TRANSCRIPT": {
            return {
                ...state,
                transcript: action.payload,
                transcriptLoading: false,
                transcriptError: false
            }
        }
        case "LISTEN_TRANSCRIPT_LOADING": {
            return {
                ...state,
                transcriptLoading: action.payload,
                transcriptError: false
            }
        }
        case "LISTEN_TRANSCRIPT_ERROR": {
            return {
                ...state,
                transcript: null,
                transcriptLoading: false,
                transcriptError: action.payload
            }
        }
        case "GET_PASSED_TRAININGS": {
            return {
                ...state,
                passed: action.payload
            }
        }
        case "CHAT_INFO_LOADING": {
            return {
                ...state,
                chatLoading: action.payload,
                chatError: false,
            }
        }
        case "GET_CHAT_INFO_FULLFILLED": {
            return {
                ...state,
                chatData: action.payload,
                chatLoading: false,
                chatError: false
            }
        }
        case "CHAT_INFO_ERROR": {
            return {
                ...state,
                chatData: null,
                chatError: action.payload,
                chatLoading: false
            }
        }
        case "GET_LISTEN_CALLS_COUNT": {
            return {
                ...state,
                callsCount: action.payload,
            }
        }
        case "GET_LISTEN_CALLS_COUNT_ERROR": {
            return {
                ...state,
                callsCountError: true,
            }
        }
        case "LISTEN_SET_ANSWERS_FOR_DROPDOWN": {
            const answers = action.payload.answers ? action.payload.answers : null
            return {
                ...state,
                selectedDropdownAnswers: answers
            }
        }
    }

    return state
}
