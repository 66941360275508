import * as R from 'ramda'

const initialState = {
    isConversionActive             : false,
    modalOptions                   : null,
    statsData                      : null,
    statsDataLoading               : false,
    statsDataError                 : false,
    statsDataTimeoutError          : false,
    performanceChartType           : 0,
    performanceData                : null,
    performanceDataLoading         : false,
    performanceDataError           : false,
    performanceDataTimeoutError    : false,
    chartData                      : null,
    chartDataItemsTotal            : null,
    chartDataLoading               : false,
    chartDataError                 : false,
    chartDataTimeoutError          : false,
    chartType                      : 'agent', // 'agent', 'agent_group', 'campaign'
    chartPagination                : {
        pagerows: 5,
        pagenum : 1,
    },
    chartTypeSorting               : {
        sortBy   : 'ID',
        sortOrder: 'desc',
    },
    callDetails                    : null,
    callDetailsLoading             : false,
    callDetailsError               : false,
    callDetailsTimeoutError        : false,
    pagination                     : {
        pagerows: 20,
        pagenum : 1,
    },
    searchText                     : '',
    conversionFilters              : [],
    exportingChartType             : false,
    salesType                      : '',
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case 'SET_CONVERSION_PAGE_ACTIVE': {
            return {
                ...state,
                isConversionActive: action.payload,
            }
        }
        case 'CONVERSION_STATS_FULFILLED': {
            return {
                ...state,
                statsData            : action.payload,
                statsDataLoading     : false,
                statsDataError       : false,
                statsDataTimeoutError: false,
            }
        }
        case 'CONVERSION_STATS_LOADING': {
            return {
                ...state,
                statsDataLoading     : true,
                statsDataError       : false,
                statsDataTimeoutError: false,
            }
        }
        case 'CONVERSION_STATS_ERROR': {
            return {
                ...state,
                statsDataError  : true,
                statsDataLoading: false,
            }
        }
        case 'CONVERSION_STATS_TIMEOUT_ERROR': {
            return {
                ...state,
                statsDataTimeoutError: true,
            }
        }
        case 'CONVERSION_PERFORMANCE_CHART_TYPE': {
            return {
                ...state,
                performanceChartType: action.payload,
            }
        }
        case 'CONVERSION_PERFORMANCE_DATA_FULFILLED': {
            return {
                ...state,
                performanceData            : action.payload,
                performanceDataLoading     : false,
                performanceDataError       : false,
                performanceDataTimeoutError: false,
            }
        }
        case 'CONVERSION_PERFORMANCE_DATA_LOADING': {
            return {
                ...state,
                performanceDataLoading     : true,
                performanceDataError       : false,
                performanceDataTimeoutError: false,
            }
        }
        case 'CONVERSION_PERFORMANCE_DATA_ERROR': {
            return {
                ...state,
                performanceDataError  : true,
                performanceDataLoading: false,
            }
        }
        case 'CONVERSION_PERFORMANCE_DATA_TIMEOUT_ERROR': {
            return {
                ...state,
                performanceDataTimeoutError: true,
            }
        }
        case 'CONVERSION_CHART_DATA_FULFILLED': {
            return {
                ...state,
                chartData            : action.payload,
                chartDataLoading     : false,
                chartDataError       : false,
                chartDataTimeoutError: false,
            }
        }
        case 'CONVERSION_CHART_ITEMS_TOTAL': {
            return {
                ...state,
                chartDataItemsTotal: action.payload,
            }
        }
        case 'CONVERSION_CHART_DATA_LOADING': {
            return {
                ...state,
                chartDataLoading     : true,
                chartDataError       : false,
                chartDataTimeoutError: false,
            }
        }
        case 'CONVERSION_CHART_DATA_ERROR': {
            return {
                ...state,
                chartDataError  : true,
                chartDataLoading: false,
            }
        }
        case 'CONVERSION_CHART_DATA_TIMEOUT_ERROR': {
            return {
                ...state,
                chartDataTimeoutError: true,
            }
        }
        case 'CONVERSION_CHART_TYPE': {
            return {
                ...state,
                chartType: action.payload,
            }
        }
        case 'CONVERSION_CHART_PAGINATION': {
            return {
                ...state,
                chartPagination: action.payload,
            }
        }
        case 'CONVERSION_CALL_DETAILS_FULFILLED': {
            return {
                ...state,
                callDetails            : action.payload,
                callDetailsLoading     : false,
                callDetailsError       : false,
                callDetailsTimeoutError: false,
            }
        }
        case 'CONVERSION_CALL_DETAILS_LOADING': {
            return {
                ...state,
                callDetailsLoading     : true,
                callDetailsError       : false,
                callDetailsTimeoutError: false
            }
        }
        case 'CONVERSION_CALL_DETAILS_ERROR': {
            return {
                ...state,
                callDetailsError  : true,
                callDetailsLoading: false,
            }
        }
        case 'CONVERSION_CALL_DETAILS_TIMEOUT_ERROR': {
            return {
                ...state,
                callDetailsTimeoutError: true,
            }
        }
        case 'SET_CONVERSION_PAGINATION': {
            return {
                ...state,
                pagination: action.payload,
            }
        }
        case 'SET_CONVERSION_MODAL_OPTIONS': {
            return {
                ...state,
                modalOptions: action.payload,
            }
        }
        case 'SET_CONVERSION_SEARCH_TEXT': {
            return {
                ...state,
                searchText: action.payload,
            }
        }
        case 'SET_CONVERSION_FILTERS': {
            return {
                ...state,
                conversionFilters: action.payload,
            }
        }
        case 'CONVERSION_CHART_TYPE_SORTING': {
            return {
                ...state,
                chartTypeSorting: action.payload,
            }
        }
        case "EXPORT_CHART_TYPE": {
            return {
                ...state,
                exportingChartType: action.payload
            }
        }
        case 'SET_SELES_TYPE': {
            return {
                ...state,
                salesType: action.payload,
            }
        }
    }
    return state
}
