'use strict'

import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

import {callShortInfoPropType} from './propTypes'
import CallIDCell from '../../ui/callTableCells/CallIDCell'
import ResultCell from '../../ui/callTableCells/ResultCell'
import ReviewStatusCell from '../../ui/callTableCells/ReviewStatusCell'
import SimpleCell from '../../ui/callTableCells/SimpleCell'
import ScoreCell from '../../ui/callTableCells/ScoreCell'
import NotificationIcons from '../../ui/callTableCells/NotificationIcons'
import Details from './Details'
import NotificationComments from './NotificationComments'
import ReviewComments from './ReviewComments'
import Transcription from './Transcription'
import Tabs from '../../tabs/Tabs'
import MissedItems from './MissedItems'
import Player from '../player/PlayerLayout'

import actions from '../../../actions/dashboardActions'
import playerActions from '../../../actions/playerActions'

import {getCallDetailsColumnLabel} from '../../../utils/globalHelpers'

const titles = [
    {id: 'callType', value:'Type'},
    {id: 'callId', value:'Call ID'},
    {id: 'agentGroup', value:'Group'},
    {id: 'agentName', value:'Agent Name'},
    {id: 'campaign', value:'Campaign'},
    {id: 'scorecardName', value:'Scorecard'},
    {id: 'callFailed', value:'Result'},
    {id: 'agentScore', value:'Score'},
    {id: 'callReviewStatus', value:'Review Status'},
    {id: '', value:'<icons>'}
]

@connect((store) => {
    return {
        userData: store.headerReducer.headerData.userData,
        activeCall: store.dashboardReducer.activeCall,
        shortCallDetails: store.dashboardReducer.shortCallDetails,
        miniReviewTab: store.dashboardReducer.miniReviewTab,
        error: store.dashboardReducer.shortCallDetailsError,
        loading: store.dashboardReducer.shortCallDetailsLoading,
        queuedForReviewCalls: store.headerReducer.queuedForReviewCalls
    }
})

export default class SelectedCallDetails extends React.Component {
    static propTypes = {
        activeCall: PropTypes.bool.isRequired,
        shortCallDetails: callShortInfoPropType.isRequired
    }

    constructor(props) {
        super(props)

        this.close = this.close.bind(this)
        this.stopAudio = this.stopAudio.bind(this)
        this.sendNotification = this.sendNotification.bind(this)
        this.queueForReview = this.queueForReview.bind(this)

        this.state = {
            queued: false
        }
    }

    queueForReview(id) {
        this.props.dispatch(actions.queueForReview(id)).then(() => {
            this.setState({
                queued: true
            })
        })
    }

    sendNotification(props) {
        this.props.dispatch(actions.sendNotification(props))
    }

    close() {
        this.props.dispatch({
            type: 'SHORT_CALL_DETAILS_ERROR',
            payload: false
        })
        this.props.dispatch({
            type: 'SHORT_CALL_DETAILS_LOADING',
            payload: false
        })
        this.props.dispatch({
            type: 'SET_ACTIVE_CALL_REVIEW',
            payload: false
        })
        this.props.dispatch({
            type: 'SET_PLAYER_START_POSITION',
            payload: 0
        })
    }

    stopAudio() {
        this.props.dispatch(playerActions.pause(true))
    }

    renderTitles() {
        return titles.map((item, i) => {
            return (item.value !== '<icons>' || this.commentsPresent()) ?
            <th key={i + Math.random()}>{item.value === '<icons>' ? '' : item.value}</th> :
            null
        })
    }

    commentsPresent() {
        const {
            notificationStatus, // "none" or "notification" or "dispute"
            isNotificationOwner,
            reviewCommentsPresent,
            notificationCommentsPresent
        } =  this.props.shortCallDetails.systemData
        return (notificationStatus !== null && notificationStatus !== 'none')
            || reviewCommentsPresent === true
            || notificationCommentsPresent === true
    }

    renderValues() {
        const {shortCallDetails, queuedForReviewCalls} = this.props
        const isPending = shortCallDetails.systemData.callReviewStatus === 'pending'
        const isTranscOnly = shortCallDetails.systemData.callReviewStatus === 'transcript only'
        const queued = this.state.queued ||
        ((isTranscOnly || isPending) &&
        queuedForReviewCalls.indexOf(shortCallDetails.systemData.xId) !== -1) ?
        true : false
        return titles.map((title, i) => {
            const value = title.value.toUpperCase()
            return value === 'TYPE' ?
            <CallIDCell
                key={i + Math.random()}
                callback={this.stopAudio}
                systemData={shortCallDetails.systemData} /> :
            value === 'RESULT' ?
            <ResultCell
                key={i + Math.random()}
                systemData={shortCallDetails.systemData} /> :
            value === 'REVIEW STATUS' ?
            <ReviewStatusCell
                key={i + Math.random()}
                systemData={shortCallDetails.systemData} /> :
            value === 'SCORE' ?
            <ScoreCell
                key={i + Math.random()}
                systemData={shortCallDetails.systemData} /> :
            value === '<ICONS>' ? isTranscOnly || isPending ?
            <td key={'search-result-call-cell-' + i + shortCallDetails.systemData.xId} class='icons-cell'>
                {
                    queued ?
                    <div class='queued-message'>
                        <div class='queued-message-inner'>
                            <div class='icon icon-ic-check' />
                            Queued
                        </div>
                    </div> :
                    <div
                        class='queue-for-review'
                        onClick={() => this.queueForReview(shortCallDetails.systemData.xId)}>
                        <div>Queue for review</div>
                    </div>
                }
            </td> :
            this.commentsPresent() ?
            <td key={i + Math.random()} class='icons-cell'>
                <NotificationIcons canSetTab={false} data={shortCallDetails.systemData} notification={shortCallDetails.notificationInfo}/>
            </td> : null :
            <SimpleCell
                key={i + Math.random()}
                columnLabel={title.value}
                columnKey={title.id}
                systemData={shortCallDetails.systemData}
                metaData={shortCallDetails.metaData} />
        })
    }

    renderTabsChildren() {
        const {shortCallDetails, userData} = this.props
        let tabsArray = []
        tabsArray.push(
            <Details key={'details-mini-review-tab'}
                dataClass={' call-details-tab'}
                label={'Details'}
                metaData={shortCallDetails.metaData}
                systemData={shortCallDetails.systemData} />
        )
        if (shortCallDetails.systemData.callReviewStatus === 'transcript only') {
            tabsArray.push(
                <Transcription
                    dataClass={' call-details-tab'}
                    key={'transcript-mini-review-tab'}
                    label={'Transcription'} />
            )
        }
        if (shortCallDetails.callMissedItems.length>=1) {
            tabsArray.push(
            <ReviewComments key={'review-mini-review-tab'}
                dataClass={' call-details-tab'}
                data={shortCallDetails}
                label={'Review comments'} />
            )
        }
        if (shortCallDetails.notificationInfo) {
            tabsArray.push(
                <NotificationComments key={'notification-mini-review-tab'}
                    dataClass={' call-details-tab'}
                    label={'Notification comments'}
                    userData={userData}
                    sendNotification={this.sendNotification}
                    callId={shortCallDetails.systemData.callId}
                    data={shortCallDetails.notificationInfo}
                    threads={shortCallDetails.threads} />
            )
        }
        return tabsArray
    }

    renderPlayer() {
        const {shortCallDetails, activeCall, userData} = this.props
        return !shortCallDetails || shortCallDetails.systemData.callType === 'website'
        || shortCallDetails.systemData.callType === 'chat' ? null :
        <Player
            activeAudioLink={shortCallDetails.systemData.callAudioUrl} 
			audioStarted={function() {}}
            // settings={{
            //     speedInc: parseInt(userData.SpeedInc, 10) || 5,
            //     play: true,
            //     volume: parseInt(window.localStorage.playerVolume, 10)/100 || 0.5,
            //     speed: parseInt(window.localStorage.playerSpeed, 10)/100 || 1,
            //     mute: (window.localStorage.mute === 'true') ? true : false,
            //     currentTime: 0
            // }}
            // audioStarted={function() {}}
            // src={shortCallDetails.systemData.callAudioUrl}
            // minGapLength={shortCallDetails.systemData.minGapLength}
        />
    }

    render() {
        const {shortCallDetails, miniReviewTab, error, loading} = this.props
        const transcriptOnly = shortCallDetails.systemData.callReviewStatus === 'transcript only'
        const commentsPresent = shortCallDetails.callMissedItems.length >= 1
        const tab = miniReviewTab === 'review' || transcriptOnly ?
        1 : miniReviewTab === 'notification' ?
        commentsPresent ?
        2 : 1 : 0
        const bottomBlockClass = classNames({
            'player-missed-items-block': true,
            'no-missed-items': !commentsPresent || transcriptOnly
        })
        const tabsClass = classNames({
            'tabs': true,
            'full-height': !commentsPresent || transcriptOnly
        })
        return (
            <div class='call-details-content'>
                <div class='table-wrapper'>
                    <table>
                        <tbody>
                            <tr class='headline-row'>
                                {this.renderTitles()}
                                <th>
                                    <i onClick={this.close} class='icon-ic-close'></i>
                                </th>
                            </tr>
                            <tr class='table-row'>
                                {this.renderValues()}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="call-det-cont">
                    {
                        loading ?
                        <div class='player-loading'>
                            <div class='player-loader-inner'></div>
                            <div class='player-loader-outer'></div>
                        </div> :
                        error ?
                        <div class='player-error'>
                            <div title='Audio loading error' class='icon-ic-error'/>
                            <div class='err-massage'>
                                <div>
                                    Couldn’t load the audio
                                </div>
                                <div>
                                    Try again later.
                                </div>
                            </div>
                        </div> :
                        <Tabs className={tabsClass} setLength={false} selected={tab}>
                            {this.renderTabsChildren()}
                        </Tabs>
                    }
                    <div class={bottomBlockClass}>
                        {
                            !shortCallDetails.callMissedItems.length || transcriptOnly ?
                            null :
                            <MissedItems type={shortCallDetails.systemData.callType} items={shortCallDetails.callMissedItems} />
                        }
                        {loading ? null : this.renderPlayer()}
                    </div>
                </div>
            </div>
        )
    }
}
