import moment from 'moment'
import * as R from 'ramda'

export function getEmptyFilters() {
    return {
        groups      : [],
	    agents      : [],
    	campaigns   : [],
    	scorecards  : [],
    	QAs         : [],
		missedItems : [],
		teamLeads   : [],
	    failedOnly  : true,
	    badCallsOnly: false,
	    passedOnly  : true,
	    missedBy    : 'agent',
		reviewType: '', //dont use
		pendingOnly : true,
		callType    : '', //radio button agen, group, campaing, abandoned
		answerIds: [],
		commentIds: [],
		compositeAnswerIds: [],
		compositeCommentIds: [],
		conversionFilters: [], //dont use
		salesType: '', // sales, servise, patrs
		filterBy: '' //for calldetails filter by chart type
	}
}

export function filtersEmpty(filters) {
    return R.equals(filters, getEmptyFilters())
}