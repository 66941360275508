import * as R from 'ramda'

const initialState = {
    userNotesList        : [],
    userNotesListError   : false,
    userNotesListLoading : false,

}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "NOTES_PAGE_USERS_NOTES_LIST_FULFILLED": {
            return {
                ...state,
                userNotesList   : action.payload,
                userNotesListError  : false,
                userNotesListLoading: false,
            }
        }
        case "NOTES_PAGE_USERS_NOTES_LIST_LOADING": {
            return {
                ...state,
                userNotesListError  : false,
                userNotesListLoading: action.payload,
            }
        }
        case "NOTES_PAGE_USERS_NOTES_LIST_ERROR": {
            return {
                ...state,
                userNotesListError  : action.payload,
                userNotesListLoading: false,
            }
        }
    }
    return state
}
