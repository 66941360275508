'use strict'

import * as R from 'ramda'
import queryString from 'query-string'
import {
    getDefaultBillingData,
    getDefaultApp,
    getDefaultScorecard,
    getDefaultDropdownItem,
    getDefaultFaq,
    getDefaultInstruction,
    getDefaultAnswer,
    getDefaultComment,
    InitialQuestionTypes,
    getDefaultTemplateItem,
    getDefaultCalculatedRuleItem,
    getCalculatedItem,
    getDefaultLinkedRule
} from './utils'

const initialState = {
    settingsQuery             : [],
    questionTypes             : InitialQuestionTypes,
    appListLoading            : false,
    appListError              : false,
    appList                   : [],
    appListLogoLoading        : false,
    appListLogoError          : false,
    appListLogo               : [],
    scorecardListLoading      : false,
    scorecardListError        : false,
    scorecardList             : [],
    dataBy                    : 'app', // or scorecard id
    activeScorecard           : null,
    activeApp                 : null,
    notesListLoading          : false,
    notesListError            : false,
    notesList                 : [],
    saveNoteLoading           : false,
    saveNoteError             : false,
    deleteNoteLoading         : false,
    deleteNoteError           : false,
    applicationSettingLoading : false,
    applicationSettingError   : false,
    applicationSetting        : null,
    billing                   : [],
    billingLoading            : false,
    billingError              : false,
    getNaAffected             : [],
    getNaAffectedLoading      : false,
    getNaAffectedError        : false,
    assigee                   : [],
    assigeeLoading            : false,
    assigeeError              : false,
    configurationProf         : [],
    configurationProfLoading  : false,
    configurationProfError    : false,
    userRolesList             : [],
    notificationSteps         : [],
    riecProf                  : [],
    riecProfLoading           : false,
    riecProfError             : false,
    profileItemsLoading       : false,
    profileItemsError         : false,
    commentProf               : [],
    additionalComments        : [],
    commentProfLoading        : false,
    commentProfError          : false,
    billingData               : null,
    rejectionReasons          : null,
    newBillingData            : getDefaultBillingData(),
    addBillingLoading         : false,
    addBillingError           : false,
    newAppSettings            : null,
    newApp                    : getDefaultApp(),
    newAppLoading             : false,
    newAppError               : false,
    newAppSaved               : false,
    showActiveApp             : true,
    deleteAppLoading          : false,
    deleteAppError            : false,
    duplicateAppLoading       : false,
    duplicateAppError         : false,
    keysList                  : [],
    keysListLoading           : false,
    keysListError             : false,
    addKeyError               : false,
    addKeyLoading             : false,
    getExport                 : [],
    getExportLoading          : false,
    getExportError            : false,
    updateExportError         : false,
    updateExportLoading       : false,
    addExportError            : false,
    addExportLoading          : false,

    //initisl state for scorecards settings
    showActiveScorecards           : true,
    defaultScorecard               : getDefaultScorecard(),
    defaultScorecardLoading        : false,
    defaultScorecardError          : false,
    newScorecardSaved              : false,
    scorecardById                  : null,
    scorecardByIdLoading           : false,
    scorecardByIdError             : false,
    scorecardNotes                 : [],
    scorecardNotesLoading          : false,
    scorecardNotesError            : false,
    updateScorecardByIdLoading     : false,
    updateScorecardByIdError       : false,
    newScorecarsSettings           : null,
    am                             : [],
    amLoading                      : false,
    amError                        : false,
    tl                             : [],
    tlLoading                      : false,
    tlError                        : false,
    tgtl                           : [],
    tgtlLoading                    : false,
    tlError                        : false,
    goldenUser                     : [],
    goldenUserLoading              : false,
    goldenUserError                : false,
    scorecardChanges               : [],
    scorecardChangesLoading        : false,
    scorecardChangesError          : false,
    editableContent                : null,
    dedupeScorecard                : null,
    dedupeScorecardLoading         : false,
    dedupeScorecardError           : false,
    goesToApps                     : false,
    questionList                   : [],
    questionListLoading            : false,
    questionListError               : false,
    questionSections                : [],
    questionSectionsLoading         : false,
    questionSectionsError           : false,
    questionById                    : null,
    questionByIdLoading             : false,
    questionByIdError               : false,
    editedQuestion                  : null,
    editQuestionTabs                : null,
    maxEditQuestionTabs             : 32,
    editQuestionTabsPag             : {
                                        first: 0, 
                                        pageSize: 0,
                                        innerTabsIndent: 168
                                    },
    tooMuchEditTabsOpened           : false,
    dropdownItems                   : [],
    dropdownItemsLoading            : false,
    dropdownItemsError              : false,
    dynamicRules                    : [],
    dynamicRulesLoading             : false,
    dynamicRulesError               : false,
    testDynamicRulesResult          : null,
    editedItem                      : null,
    modalOptions                    : null,
    commentModalOptions             : null,
    defaultDropdownItem             : getDefaultDropdownItem(),
    simpleListQues                  : [],
    simpleListQuesLoading           : false,
    simpleListQuesError             : false,
    answerList                      : [],
    answerListLoading               : false,
    answerListError                 : false,
    commentList                     : [],
    commentListLoading              : false,
    commentListError                : false,
    faqList                         : [],
    faqListLoading                  : false,
    faqListError                    : false,
    defaultFaq                      : getDefaultFaq(),
    instructionsList                : [],
    instructionsListLoading         : false,
    instructionsListError           : false,
    defaultInstruction              : getDefaultInstruction(),
    answerTypes                     : [],
    answerTypesLoading              : false,
    answerTypesError                : false,
    answerListFill                  : [],
    answerListFillLoading           : false,
    answerListFillError             : false,
    history                         : [],
    historyLoading                  : false,
    historyError                    : false,
    defaultAnswer                   : getDefaultAnswer(),
    newAnswer                       : getDefaultAnswer(),
    answerComments                  : [],
    answerCommentsLoading           : false,
    answerCommentsError             : false,
    defaultComment                  : getDefaultComment(),
    updateQuestion                  : null,
    updateQuestionLoading           : false,
    updateQuestionError             : false,
    checkedQuestionList             : [],
    templateDropdownItems           : [],
    templateDropdownItemsLoading    : false,
    templateDropdownItemsError      : false,
    templateItemsShowing            : '',
    defaultTemplateItem             : getDefaultTemplateItem(),
    templateDrItems                 : [],
    templateDrItemsLoading          : false,
    templateDrItemsError            : false,
    calculatedRules                 : [],
    calculatedRulesLoading          : false,
    calculatedRulesError            : false,
    defaultCalculatedRuleItem       : getDefaultCalculatedRuleItem(),
    calculatedRulesItems            : [],
    calculatedRulesItemsLoading     : false,
    calculatedRulesItemsError       : false,
    calculatedRuleTypesList         : [],
    calculatedRuleTypesLisLtoading  : false,
    calculatedRuleTypesListError    : false,
    calculatedRuleOperatorList      : [],
    calculatedRuleOperatorLisLtoading : false,
    calculatedRuleOperatorListError   : false,
    schoolData                      : [],
    schoolDataLoading               : false,
    schoolDataError                 : false,
    callData                        : [],
    callDataLoading                 : false,
    callDataError                   : false,
    otherData                       : [],
    otherDataLoading                : false,
    otherDataError                  : false,
    calculatedRulesEdited           : null,
    editedRule                      : null,
    calculatedItem                  : getCalculatedItem(),
    quesAnwer                       : [],
    quesAnwerLoading                : false,
    quesAnwerError                  : false,
    addedWrapper                    : false,
    closableDropdowns               : {},
    closableDropdownsUpdater        : false,
    defaultLinkedRule               : getDefaultLinkedRule(),
    newLinkedRule                   : null,
    scorecardGeneralDropdowns       : {},

    defaultLinkedRule               : getDefaultLinkedRule(),
    newLinkedRules                  : [],
    appHistory                      : [],
    appHistoryLoading               : false,
    appHistoryError                 : false,

    linkedQuestions                 : [],
    linkedRules                     : [],
    linkedItems                     : [],
    linkedItemsLoading              : false,
    linkedItemsError                : false,
    createdLinkedGroup              : null,
    billingRateEdited               : null,

    newCalculatedRules              : null,   
    
    callHiddenDataElements          : null,
    callHiddenDataSelected          : null,
    schoolHiddenDataElements        : null,
    schoolHiddenDataSelected        : null,
    otherHiddenDataElements         : null,
    otherHiddenDataSelected         : null,
    hiddenDataSelectedLoading       : false,
    hiddenDataElementsLoading       : false,
    otherHiddenDataSelectedLoading  : false,
    otherHiddenDataElementsLoading  : false,

    makeQuestActive                 : null,
    makeQuestActiveLoading          : false,
    makeQuestActiveError            : false,
    makeQuestInActive               : null,
    makeQuestInActiveLoading        : false,
    makeQuestInActiveError          : false,
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        
        case "SETTINGS_PAGE_SET_QUERY": {
            let back = action.payload.back
            window.onpopstate = (e) => {
                if(e.state && !back){
                    window.location.reload(true);
                } else {
                    back = false
                }
            }
            back && delete action.payload.back
            const editQuestionTabs = state.editQuestionTabs
            const tabids =  editQuestionTabs ? R.pluck('id', editQuestionTabs).join(',') || undefined : 
                (state.settingsQuery.tabids || action.payload.tabids || undefined)
            const settingsQuery = R.reject(R.isNil, R.mergeAll([state.settingsQuery, action.payload || {}, {
                tabids: action.payload.tabids === 'remove' ? undefined : tabids
            }]))
            if (settingsQuery.tabids === 'remove') delete settingsQuery.tabids
            const order = ['page', 'app', 'scorecard', 'questions', 'qid', 'tabids'];
            const newSearch = '?' + queryString.stringify(settingsQuery, {
                sort: (m, n) => order.indexOf(m) >= order.indexOf(n),
                encode: false
            });
            const historyWasChanged = !R.equals(history.state, settingsQuery)
            if (back) {
                history.back()
            } else {
                if (historyWasChanged) {
                    history.pushState(settingsQuery, 'settings page', newSearch);
                }
            }
            return {
                ...state,
                settingsQuery: settingsQuery,
                editQuestionTabs: action.payload.tabids === 'remove' ? [] : editQuestionTabs
            }
        }

        case "SETTINGS_PAGE_SET_NEW_LINKED_QUESTIONS": {
            return {
                ...state,
                linkedQuestions: action.payload,
            }
        }
        case "SETTINGS_PAGE_REFRESH_DEFALT_ANSWER": {
            return {
                ...state,
                defaultAnswer: action.payload,
            }
        }
        case "SETTINGS_PAGE_QUESTION_TYPES": {
            return {
                ...state,
                questionTypes: action.payload,
            }
        }

        case "SETTINGS_PAGE_HIDDEN_DATA_ELEMENTS_LOADING": {
            return {
                ...state,
                hiddenDataElementsLoading: action.payload,
            }
        }

        case "SETTINGS_PAGE_CALL_HIDDEN_DATA_ELEMENTS": {
            return {
                ...state,
                callHiddenDataElements: action.payload,
            }
        }
        case "SETTINGS_PAGE_HIDDEN_DATA_SELECTED_LOADING": {
            return {
                ...state,
                hiddenDataSelectedLoading: action.payload,
            }
        }
        case "SETTINGS_PAGE_CALL_HIDDEN_DATA_SELECTED": {
            return {
                ...state,
                callHiddenDataSelected: action.payload,
            }
        }
        case "SETTINGS_PAGE_SCHOOL_HIDDEN_DATA_ELEMENTS": {
            return {
                ...state,
                hiddenDataElementsLoading: false,
                schoolHiddenDataElements: action.payload,
            }
        }
        case "SETTINGS_PAGE_SCHOOL_HIDDEN_DATA_SELECTED": {
            return {
                ...state,
                hiddenDataSelectedLoading: false,
                schoolHiddenDataSelected: action.payload,
            }
        }
        case "SETTINGS_PAGE_OTHER_HIDDEN_DATA_ELEMENTS_LOADING": {
            return {
                ...state,
                otherHiddenDataElementsLoading: action.payload,
            }
        }
        case "SETTINGS_PAGE_OTHER_HIDDEN_DATA_SELECTED_LOADING": {
            return {
                ...state,
                otherHiddenDataSelectedLoading: action.payload,
            }
        }
        case "SETTINGS_PAGE_OTHER_HIDDEN_DATA_ELEMENTS": {
            return {
                ...state,
                otherHiddenDataElements: action.payload,
                otherHiddenDataElementsLoading: false
            }
        }
        case "SETTINGS_PAGE_OTHER_HIDDEN_DATA_SELECTED": {
            let otherElements = R.clone(state.otherHiddenDataElements)
            const selected = R.pluck('value', action.payload)
            let missed = false
            selected.forEach(se => {
                if (otherElements.indexOf(se) === -1) {
                    otherElements.push(se)
                    missed = true
                }
            })
            let stateToUpdate = {
                ...state,
                otherHiddenDataSelected: action.payload,
                otherHiddenDataSelectedLoading: false
            }
            //update other hidden elements list if selected list contains new items 
            if (missed) stateToUpdate.otherHiddenDataElements = otherElements

            return stateToUpdate
        }
        case "GO_ALL_APP": {
            return {
                ...state,
                goesToApps: action.payload,
            }
        }
        case "SETTINGS_PAGE_GET_APP_LIST_LOADING": {
            return {
                ...state,
                appListLoading: action.payload,
                appListError: false,
            }
        }
        case "SETTINGS_PAGE_GET_APP_LIST_ERROR": {
            return {
                ...state,
                appListError: action.payload,
                appListLoading: false,
            }
        }
        case "SETTINGS_PAGE_GET_APP_LIST": {
            return {
                ...state,
                appList: action.payload,
                appListLoading: false,
                appListError: false,
            }
        }
        case "SETTINGS_PAGE_GET_APP_LIST_LOGO_LOADING": {
            return {
                ...state,
                appListLogoLoading: action.payload,
                appListLogoError: false,
            }
        }
        case "SETTINGS_PAGE_GET_APP_LIST_LOGO_ERROR": {
            return {
                ...state,
                appListLogoError: action.payload,
                appListLogoLoading: false,
            }
        }
        case "SETTINGS_PAGE_GET_APP_LOGO_LIST": {
            return {
                ...state,
                appListLogo: action.payload,
                appListLogoLoading: false,
                appListLogoError: false,
            }
        }
        case "SETTINGS_PAGE_GET_SCORECARD_LIST_LOADING": {
            return {
                ...state,
                scorecardListLoading: action.payload,
                scorecardListError: false,
            }
        }
        case "SETTINGS_PAGE_GET_SCORECARD_LIST_ERROR": {
            return {
                ...state,
                scorecardListError: action.payload,
                scorecardListLoading: false,
            }
        }
        case "SETTINGS_PAGE_GET_SCORECARD_LIST": {
            return {
                ...state,
                scorecardList: action.payload,
                scorecardListError: false,
                scorecardListLoading: false,
            }
        }
        case "SET_ACTIVE_APP": {
            return {
                ...state,
                activeApp: action.payload,
            }
        }
        case "SET_SETTINGS_DATA_BY": {
            return {
                ...state,
                dataBy: action.payload,
            }
        }case "SET_ACTIVE_SCORECARD": {
            return {
                ...state,
                activeScorecard: action.payload,
            }
        }
        case "SETTINGS_PAGE_GET_NOTES_LIST_LOADING": {
            return {
                ...state,
                notesListLoading: action.payload,
                notesListError: false,
            }
        }
        case "SETTINGS_PAGE_GET_NOTES_LIST_ERROR": {
            return {
                ...state,
                notesListError: action.payload,
                notesListLoading: false,
            }
        }
        case "SETTINGS_PAGE_GET_NOTES_LIST": {
            return {
                ...state,
                notesList: action.payload,
                notesListError: false,
                notesListLoading: false,
            }
        }
        case "SETTINGS_PAGE_SAVE_NOTE_LOADING": {
            return {
                ...state,
                saveNoteLoading: action.payload,
                saveNoteError: false,
            }
        }
        case "SETTINGS_PAGE_SEVE_NOTE_ERROR": {
            return {
                ...state,
                saveNoteError: action.payload,
                saveNoteLoading: false,
            }
        }
        case "SETTINGS_PAGE_DELETE_NOTE_LOADING": {
            return {
                ...state,
                deleteNoteLoading: action.payload,
                deleteNoteError: false,
            }
        }
        case "SETTINGS_PAGE_DELETE_NOTE_ERROR": {
            return {
                ...state,
                deleteNoteError: action.payload,
                deleteNoteLoading: false,
            }
        }
        case "SETTINGS_PAGE_GET_APPLICATION_SETTINGS_LOADING": {
            return {
                ...state,
                applicationSettingLoading: action.payload,
                applicationSettingError: false,
            }
        }
        case "SETTINGS_PAGE_GET_APPLICATION_SETTINGS": {
            return {
                ...state,
                applicationSetting: action.payload,
                applicationSettingLoading: false,
                applicationSettingError: false,
            }
        }
        case "SETTINGS_PAGE_GET_APPLICATION_SETTINGS_ERROR": {
            return {
                ...state,
                applicationSettingError: action.payload,
                applicationSettingLoading: false,
            }
        }
        case "SETTINGS_PAGE_GET_NEW_SETTINGS_ERROR": {
            return {
                ...state,
                newSettings: action.payload,
            }
        }
        case "SETTINGS_PAGE_GET_BILLING_LOADING": {
            return {
                ...state,
                billingLoading: true,
                billingError: false,
            }
        }
        case "SETTINGS_PAGE_GET_BILLING": {
            return {
                ...state,
                billing: action.payload,
                billingLoading: false,
                billingError: false,
            }
        }
        case "SETTINGS_PAGE_GET_BILLING_ERROR": {
            return {
                ...state,
                billingLoading: false,
                billingError: true,
            }
        }
        case "SETTINGS_PAGE_GET_NA_AFFECTED_LOADING": {
            return {
                ...state,
                getNaAffectedLoading: true,
                getNaAffectedError: false,
            }
        }
        case "SETTINGS_PAGE_GET_NA_AFFECTED": {
            return {
                ...state,
                getNaAffected: action.payload,
                getNaAffectedLoading: false,
                getNaAffectedError: false,
            }
        }
        case "SETTINGS_PAGE_GET_NA_AFFECTED_ERROR": {
            return {
                ...state,
                getNaAffectedLoading: false,
                getNaAffectedError: true,
            }
        }
        case "SETTINGS_PAGE_GET_ASSIGEE_LOADING": {
            return {
                ...state,
                assigeeLoading: true,
                assigeeError: false,
            }
        }
        case "SETTINGS_PAGE_GET_ASSIGEE": {
            return {
                ...state,
                assigee: action.payload,
                assigeeLoading: false,
                assigeeError: false,
            }
        }
        case "SETTINGS_PAGE_GET_ASSIGEE_ERROR": {
            return {
                ...state,
                assigeeLoading: false,
                assigeeError: true,
            }
        }
        case "SETTINGS_PAGE_GET_CONFI_PROF_LOADING": {
            return {
                ...state,
                configurationProfLoading: true,
                configurationProfError: false,
            }
        }
        case "SETTINGS_PAGE_GET_CONFI_PROF": {
            return {
                ...state,
                configurationProf: action.payload,
                configurationProfLoading: false,
                configurationProfError: false,
            }
        }
        case "SETTINGS_PAGE_GET_CONFI_PROF_ERROR": {
            return {
                ...state,
                configurationProfLoading: false,
                configurationProfError: true,
            }
        }
        case "SETTINGS_PAGE_PROF_ITEMS_LOADING": {
            return {
                ...state,
                profileItemsLoading: true,
                profileItemsError: false,
            }
        }
        case "SETTINGS_PAGE_PROF_ITEMS_ERROR": {
            return {
                ...state,
                profileItemsLoading: false,
                profileItemsError: true,
            }
        }
        case "SETTINGS_PAGE_FULFILL_NOTIFICATION_STEPS": {
            return {
                ...state,
                notificationSteps: action.payload,
                profileItemsLoading: false,
                profileItemsError: false,
            }
        }
        case "SETTINGS_PAGE_FULFILL_USER_ROLES": {
            return {
                ...state,
                userRolesList: action.payload,
                profileItemsLoading: false,
                profileItemsError: false,
            }
        }
        case "SETTINGS_PAGE_FULFILL_REJECTION_REASONS": {
            return {
                ...state,
                rejectionReasons: action.payload,
                profileItemsLoading: false,
                profileItemsError: false,
            }
        }
        case "SETTINGS_PAGE_GET_RJEC_PROF_LOADING": {
            return {
                ...state,
                riecProfLoading: true,
                riecProfError: false,
            }
        }
        case "SETTINGS_PAGE_GET_RJEC_PROF": {
            return {
                ...state,
                riecProf: action.payload,
                riecProfLoading: false,
                riecProfError: false,
            }
        }
        case "SETTINGS_PAGE_GET_RJEC_PROF_ERROR": {
            return {
                ...state,
                riecProfLoading: false,
                riecProfError: true,
            }
        }
        case "SETTINGS_PAGE_GET_COMMENT_PROF_LOADING": {
            return {
                ...state,
                commentProfLoading: true,
                commentProfError: false,
            }
        }
        case "SETTINGS_PAGE_GET_COMMENT_PROF": {
            return {
                ...state,
                commentProf: action.payload,
                commentProfLoading: false,
                commentProfError: false,
            }
        }
        case "SETTINGS_PAGE_GET_COMMENT_PROF_ERROR": {
            return {
                ...state,
                commentProfLoading: false,
                commentProfError: true,
            }
        }
        case "SETTINGS_PAGE_FULFILL_ADDITIONAL_COMMENTS": {
            return {
                ...state,
                additionalComments: action.payload,
                profileItemsLoading: false,
                profileItemsError: false,
            }
        }
        case "SETTINGS_PAGE_GET_BILLING_DATA": {
            return {
                ...state,
                billingData: action.payload,
            }
        }
        case "SETTINGS_PAGE_GET_NEW_BILLING_DATA": {
            return {
                ...state,
                newBillingData: action.payload,
            }
        }
        case "SETTINGS_PAGE_ADD_BILLING_LOADING": {
            return {
                ...state,
                addBillingLoading: action.payload,
            }
        }
        case "SETTINGS_PAGE_ADD_BILLING_ERROR": {
            return {
                ...state,
                addBillingError: action.payload,
            }
        }
        case "SETTINGS_PAGE_GET_NEW_APP_SETTINGS": {
            return {
                ...state,
                newAppSettings: action.payload,
            }
        }
        case "SETTINGS_PAGE_ADD_NEW_APP": {
            return {
                ...state,
                newApp: action.payload,
            }
        }
        case "SETTINGS_PAGE_ADD_NEW_APP_LOADING": {
            return {
                ...state,
                newAppLoading: action.payload,
                newAppError: false
            }
        }
        case "SETTINGS_PAGE_ADD_NEW_APP_LOADING_ERROR": {
            return {
                ...state,
                newAppError: action.payload,
                newAppLoading: false
            }
        }
         case "SETTINGS_PAGE_ADD_NEW_APP_SAVED": {
            return {
                ...state,
                newAppSaved: action.payload,
            }
        }
        case "SHOW_ONLY_ACTIVE_APP": {
            return {
                ...state,
                showActiveApp: action.payload,
            }
        }
        case "SETTINGS_PAGE_DELETE_APP_LOADING": {
            return {
                ...state,
                deleteAppLoading: action.payload,
            }
        }
        case "SETTINGS_PAGE_DELETE_APP_ERROR": {
            return {
                ...state,
                deleteAppError: action.payload,
            }
        }
        case "SETTINGS_PAGE_DUPLICATE_APP_LOADING": {
            return {
                ...state,
                duplicateAppLoading: action.payload,
            }
        }
        case "SETTINGS_PAGE_DUPLICATE_APP_ERROR": {
            return {
                ...state,
                duplicateAppError: action.payload,
            }
        }
        case "SETTINGS_PAGE_ADD_KEY_LOADING": {
            return {
                ...state,
                addKeyLoading: action.payload,
            }
        }
        case "SETTINGS_PAGE_ADD_KEY_ERROR": {
            return {
                ...state,
                addKeyError: action.payload,
            }
        }
        case "SETTINGS_PAGE_GET_KEYS_LIST": {
            return {
                ...state,
                keysList: action.payload,
                keysListLoading: false,
                keysListError: false,
            }
        }
        case "SETTINGS_PAGE_GET_KEYS_LIST_LOADING": {
            return {
                ...state,
                keysListLoading: action.payload,
                keysListError: false,
            }
        }
        case "SETTINGS_PAGE_GET_KEYS_LIST_ERROR": {
            return {
                ...state,
                keysListError: action.payload,
                keysListLoading: false,
            }
        }
        case "SETTINGS_PAGE_GET_EXPORT": {
            return {
                ...state,
                getExport: action.payload,
                getExportLoading: false,
                getExportError: false,
            }
        }
        case "SETTINGS_PAGE_GET_EXPORT_LOADING": {
            return {
                ...state,
                getExportLoading: action.payload,
                getExportError: false,
            }
        }
        case "SETTINGS_PAGE_GET_EXPORT_ERROR": {
            return {
                ...state,
                getExportError: action.payload,
                getExportLoading: false,
            }
        }
        case "SETTINGS_PAGE_UPDATE_EXPORT_LOADINGR": {
            return {
                ...state,
                updateExportError: false,
                updateExportLoading: action.payload,
            }
        }
        case "SETTINGS_PAGE_UPDATE_EXPORT_ERROR": {
            return {
                ...state,
                updateExportError: action.payload,
                updateExportLoading: false,
            }
        }
        case "SETTINGS_PAGE_ADD_EXPORT_LOADINGR": {
            return {
                ...state,
                addExportError: false,
                addExportLoading: action.payload,
            }
        }
        case "SETTINGS_PAGE_ADD_EXPORT_ERROR": {
            return {
                ...state,
                addExportError: action.payload,
                addExportLoading: false,
            }
        }
        //reducerd for scorecard settings
        case "SHOW_ONLY_ACTIVE_SCORECARDS": {
            return {
                ...state,
                showActiveScorecards: action.payload,
            }
        }
        case "SETTINGS_PAGE_ADD_NEW_SCORECARD": {
            return {
                ...state,
                defaultScorecard: action.payload,
                defaultScorecardLoading: false,
                defaultScorecardError: false,
            }
        }
        case "SETTINGS_PAGE_ADD_NEW_SCORECARD_FULLFILLED": {
            const scorecard = action.payload
            const scorecardInList = {
                active: scorecard.active,
                id: scorecard.id,
                name: scorecard.short_name
            }
            return {
                ...state,
                scorecardList: [...state.scorecardList, scorecardInList],
                // defaultScorecard: action.payload,
                // defaultScorecardLoading: false,
                // defaultScorecardError: false,
            }
        }
        case "SETTINGS_PAGE_ADD_NEW_SCORECARD_LOADING": {
            return {
                ...state,
                defaultScorecardLoading: action.payload,
                defaultScorecardError: false
            }
        }
        case "SETTINGS_PAGE_ADD_NEW_SCORECARD_ERROR": {
            return {
                ...state,
                defaultScorecardLoading: action.payload,
                defaultScorecardError: false
            }
        }
         case "SETTINGS_PAGE_ADD_NEW_SCORECARD_SAVED": {
            return {
                ...state,
                newScorecardSaved: action.payload,
            }
        }
        case "SETTINGS_PAGE_GET_SCORECARD_BY_ID": {
            return {
                ...state,
                scorecardById: action.payload,
                scorecardByIdLoading: false,
                scorecardByIdError: false,
            }
        }
        case "SETTINGS_PAGE_GET_SCORECARD_BY_ID_LOADING": {
            return {
                ...state,
                scorecardByIdLoading: action.payload,
                scorecardByIdError: false
            }
        }
        case "SETTINGS_PAGE_GET_SCORECARD_BY_ID_ERROR": {
            return {
                ...state,
                scorecardByIdLoading: action.payload,
                scorecardByIdError: false
            }
        }
        case "SETTINGS_PAGE_GET_SC_NOTES": {
            return {
                ...state,
                scorecardNotes: action.payload,
                scorecardNotesLoading: false,
                scorecardNotesError: false,
            }
        }
        case "SETTINGS_PAGE_GET_SC_NOTE_LOADING": {
            return {
                ...state,
                scorecardNotesLoading: action.payload,
                scorecardNotesError: false
            }
        }
        case "SETTINGS_PAGE_GET_SC_NOTE_ERROR": {
            return {
                ...state,
                scorecardNotesLoading: action.payload,
                scorecardNotesError: false
            }
        }
        case "SETTINGS_PAGE_UPDATE_SCORECARD_BY_ID_LOADING": {
            return {
                ...state,
                updateScorecardByIdLoading: action.payload,
                updateScorecardByIdError: false
            }
        }
        case "SETTINGS_PAGE_UPDATE_SCORECARD_BY_ID_ERROR": {
            return {
                ...state,
                updateScorecardByIdLoading: action.payload,
                updateScorecardByIdError: false
            }
        }
        case "SETTINGS_PAGE_GET_NEW_SCORECARD_SETTINGS": {
            return {
                ...state,
                newScorecarsSettings: action.payload,
            }
        }
        case "SETTINGS_PAGE_GET_AM_LOADING": {
            return {
                ...state,
                amLoading: action.payload,
                amError: false
            }
        }
        case "SETTINGS_PAGE_GET_AM": {
            return {
                ...state,
                am: action.payload,
                amLoading: false,
                amError: false
            }
        }
        case "SETTINGS_PAGE_GET_AM_ERROR": {
            return {
                ...state,
                amLoading: false,
                amError: action.payload
            }
        }
        case "SETTINGS_PAGE_GET_TL_LOADING": {
            return {
                ...state,
                tlLoading: action.payload,
                tlError: false
            }
        }
        case "SETTINGS_PAGE_GET_TL": {
            return {
                ...state,
                tl: action.payload,
                tlLoading: false,
                tlError: false
            }
        }
        case "SETTINGS_PAGE_GET_TL_ERROR": {
            return {
                ...state,
                tlLoading: false,
                tlError: action.payload
            }
        }case "SETTINGS_PAGE_GET_TGTL_LOADING": {
            return {
                ...state,
                tgtlLoading: action.payload,
                tgtlError: false
            }
        }
        case "SETTINGS_PAGE_GET_TGTL": {
            return {
                ...state,
                tgtl: action.payload,
                tgtlLoading: false,
                tgtlError: false
            }
        }
        case "SETTINGS_PAGE_GET_TGTL_ERROR": {
            return {
                ...state,
                tgtlLoading: false,
                tgtlError: action.payload
            }
        }
        case "SETTINGS_PAGE_GET_GOLDEN_USER_LOADING": {
            return {
                ...state,
                goldenUserLoading: action.payload,
                goldenUserError: false
            }
        }
        case "SETTINGS_PAGE_GET_GOLDEN_USER": {
            return {
                ...state,
                goldenUser: action.payload,
                goldenUserLoading: false,
                goldenUserError: false
            }
        }
        case "SETTINGS_PAGE_GET_GOLDEN_USER_ERROR": {
            return {
                ...state,
                goldenUserLoading: false,
                goldenUserError: action.payload
            }
        }
        case "SETTINGS_PAGE_GET_SC_CHANGES_LOADING": {
            return {
                ...state,
                scorecardChangesLoading: action.payload,
                scorecardChangesError: false
            }
        }
        case "SETTINGS_PAGE_GET_SC_CHANGES": {
            return {
                ...state,
                scorecardChanges: action.payload,
                scorecardChangesLoading: false,
                scorecardChangesError: false
            }
        }
        case "SETTINGS_PAGE_GET_SC_CHANGES_ERROR": {
            return {
                ...state,
                scorecardChangesLoading: false,
                scorecardChangesError: action.payload
            }
        }
        case "SET_EDITABLE_CONTENT": {
            return {
                ...state,
                editableContent: action.payload
            }
        }
        case "SETTINGS_PAGE_DELETE_SCORECARD_ERROR": {
            return {
                ...state,
                deleteScorecardLoading: false,
                deleteScorecardError: action.payload
            }
        }
        case "SETTINGS_PAGE_DELETE_SCORECARD_LOADING": {
            return {
                ...state,
                deleteScorecardLoading: action.payload,
                deleteScorecardError: false
            }
        }
        case "DEDUPE_SCORECARD_LOADING": {
            return {
                ...state,
                dedupeScorecardLoading: true,
                dedupeScorecardError: false
            }
        }
        case "DEDUPE_SCORECARD": {
            return {
                ...state,
                dedupeScorecard: action.payload,
                dedupeScorecardLoading: false,
                dedupeScorecardError: false
            }
        }
        case "DEDUPE_SCORECARD_ERROR": {
            return {
                ...state,
                dedupeScorecardLoading: false,
                dedupeScorecardError: true
            }
        }
        case "SETTINGS_PAGE_GET_QUESTION_LIST_LOADING": {
            return {
                ...state,
                questionListLoading: true,
                questionListError: false
            }
        }
        case "SETTINGS_PAGE_GET_QUESTION_LIST": {
            return {
                ...state,
                questionList: action.payload,
                questionListLoading: false,
                questionListError: false
            }
        }
        case "SETTINGS_PAGE_GET_QUESTION_LIST_ERROR": {
            return {
                ...state,
                questionListLoading: false,
                questionListError: true
            }
        }
        case " SETTINGS_PAGE_QUESTION_SECTIONS_LOADING": {
            return {
                ...state,
                questionSectionsLoading: true,
                questionSectionsError: false
            }
        }
        case "SETTINGS_PAGE_QUESTION_SECTIONS_LIST": {
            return {
                ...state,
                questionSections: action.payload,
                questionSectionsLoading: false,
                questionSectionsError: false
            }
        }
        case "SETTINGS_PAGE_QUESTION_SECTIONS_ERROR": {
            return {
                ...state,
                questionSectionsLoading: false,
                questionSectionsError: true
            }
        }
        case "SETTINGS_PAGE_GET_QUESTION_BY_ID_LOADING": {
            return {
                ...state,
                questionByIdLoading: true,
                questionByIdError: false
            }
        }
        case "SETTINGS_PAGE_GET_QUESTION_BY_ID": {
            return {
                ...state,
                questionById: action.payload,
                questionByIdLoading: false,
                questionByIdError: false
            }
        }
        case "SETTINGS_PAGE_GET_QUESTION_BY_ID_ERROR": {
            return {
                ...state,
                questionByIdLoading: false,
                questionByIdError: true
            }
        }
        case "SETTINGS_PAGE_SET_EDITED_QUESTION": {
            return {
                ...state,
                editedQuestion: action.payload,
            }
        }
        case "SETTINGS_PAGE_DYNAMIC_RULES_LOADING": {
            return {
                ...state,
                dynamicRulesLoading: true,
                dynamicRulesError: false
            }
        }
        case "SETTINGS_PAGE_DYNAMIC_RULES": {
            return {
                ...state,
                dynamicRules: action.payload,
                dynamicRulesLoading: false,
                dynamicRulesError: false
            }
        }
        case "SETTINGS_PAGE_DYNAMIC_RULES_ERROR": {
            return {
                ...state,
                dynamicRulesLoading: false,
                dynamicRulesError: true
            }
        }
        case "SETTINGS_PAGE_TEST_DYNAMIC_RULES_RESULT": {
            return {
                ...state,
                testDynamicRulesResult: action.payload,
                dynamicRulesLoading: false,
                dynamicRulesError: false
            }
        }
        case "SETTINGS_PAGE_CLEAR_TEST_DYNAMIC_RULES_RESULT": {
            return {
                ...state,
                testDynamicRulesResult: null
            }
        }
        case "SETTINGS_PAGE_DROPDOWN_ITEMS_LOADING": {
            return {
                ...state,
                dropdownItemsLoading: true,
                dropdownItemsError: false
            }
        }
        case "SETTINGS_PAGE_DROPDOWN_ITEMS": {
            return {
                ...state,
                dropdownItems: action.payload,
                dropdownItemsLoading: false,
                dropdownItemsError: false
            }
        }
        case "SETTINGS_PAGE_DROPDOWN_ITEMS_ERROR": {
            return {
                ...state,
                dropdownItemsLoading: false,
                dropdownItemsError: true
            }
        }
        case "SETTINGS_PAGE_SET_EDITED_DROPDOWN_ITEM": {
            return {
                ...state,
                editedItem: action.payload,
            }
        }
        case "SETTINGS_PAGE_SET_QUESTION_MODAL_OPTIONS": {
            return {
                ...state,
                modalOptions: action.payload,
            }
        }
        case "SETTINGS_PAGE_UPDATE_QUESTION_MODAL_OPTIONS": {
            const modalOptions = R.merge(state.modalOptions, action.payload)
            return {
                ...state,
                modalOptions: modalOptions
            }
        }
        case "SETTINGS_PAGE_SET_COMMENT_MODAL_OPTIONS": {
            return {
                ...state,
                commentModalOptions: action.payload,
            }
        }
        case "SETTINGS_PAGE_SET_NEW_DROPDOWN_ITEM": {
            return {
                ...state,
                defaultDropdownItem: action.payload,
            }
        }
        case "SETTINGS_PAGE_SIMPLE_LIST_QUESTIONS_LOADING": {
            return {
                ...state,
                simpleListQuesLoading: true,
                simpleListQuesError: false
            }
        }
        case "SETTINGS_PAGE_SIMPLE_LIST_QUESTIONS_ITEMS": {
            return {
                ...state,
                simpleListQues: action.payload,
                simpleListQuesLoading: false,
                simpleListQuesError: false
            }
        }
        case "SETTINGS_PAGE_SIMPLE_LIST_QUESTIONS_ERROR": {
            return {
                ...state,
                simpleListQuesLoading: false,
                simpleListQuesError: true
            }
        }
        case "SETTINGS_PAGE_LINKED_ANSWER_LIST_LOADING": {
            return {
                ...state,
                answerListLoading: true,
                answerListError: false
            }
        }
        case "SETTINGS_PAGE_LINKED_ANSWER_LIST": {
            return {
                ...state,
                answerList: action.payload,
                answerListLoading: false,
                answerListError: false
            }
        }
        case "SETTINGS_PAGE_LINKED_ANSWER_LIST_ERROR": {
            return {
                ...state,
                answerListLoading: false,
                answerListError: true
            }
        }
        case "SETTINGS_PAGE_ANSWERS_ITEM_EXPAND_TOGGLE": {
            let {item, expanded} = action.payload
            let answers = R.clone(state.answerListFill)
            answers.forEach(answer => {
                if (answer.id === item.id) {
                    answer.popoverExpanded = expanded
                } else {
                    answer.popoverExpanded = false
                }
            })
            return {
                ...state,
                answerListFill: answers
            }
        }
        case "SETTINGS_PAGE_LINKED_COMMENT_LIST_LOADING": {
            return {
                ...state,
                commentListLoading: true,
                commentListError: false
            }
        }
        case "SETTINGS_PAGE_LINKED_COMMENT_LIST_ITEMS": {
            return {
                ...state,
                commentList: action.payload,
                commentListLoading: false,
                commentListError: false
            }
        }
        case "SETTINGS_PAGE_LINKED_COMMENT_LIST_ERROR": {
            return {
                ...state,
                commentListLoading: false,
                commentListError: true
            }
        }
        case "SETTINGS_PAGE_FAQ_LIST_LOADING": {
            return {
                ...state,
                faqListLoading: true,
                faqListError: false
            }
        }
        case "SETTINGS_PAGE_FAQ_LIST_LIST_ITEMS": {
            return {
                ...state,
                faqList: action.payload,
                faqListLoading: false,
                faqListError: false
            }
        }
        case "SETTINGS_PAGE_FAQ_LIST_LIST_ERROR": {
            return {
                ...state,
                faqListLoading: false,
                faqListError: true
            }
        }
        case "SETTINGS_PAGE_INSTRUCTIONS_LIST_LOADING": {
            return {
                ...state,
                instructionsListLoading: true,
                instructionsListError: false
            }
        }
        case "SETTINGS_PAGE_INSTRUCTIONS_LIST_ITEMS": {
            return {
                ...state,
                instructionsList          : action.payload,
                instructionsListLoading   : false,
                instructionsListError     : false,
            }
        }
        case "SETTINGS_PAGE_INSTRUCTIONS_LIST_ERROR": {
            return {
                ...state,
                instructionsListLoading: false,
                instructionsListError: true
            }
        }
        case "SETTINGS_PAGE_ANSWER_TYPES_LOADING": {
            return {
                ...state,
                answerTypesLoading: true,
                answerTypesError: false
            }
        }
        case "SETTINGS_PAGE_ANSWER_TYPES_ITEMS": {
            return {
                ...state,
                answerTypes          : action.payload,
                answerTypesLoading   : false,
                answerTypesError     : false,
            }
        }
        case "SETTINGS_PAGE_ANSWER_TYPES_ERROR": {
            return {
                ...state,
                answerTypesLoading: false,
                answerTypesError: true
            }
        }
        case "SETTINGS_PAGE_ANSWER_LIST_FILL_LOADING": {
            return {
                ...state,
                answerListFillLoading: true,
                answerListFillError: false
            }
        }
        case "SETTINGS_PAGE_ANSWER_LIST_FILL_ITEMS": {
            return {
                ...state,
                answerListFill          : action.payload,
                answerListFillLoading   : false,
                answerListFillError     : false,
            }
        }
        case "SETTINGS_PAGE_ANSWER_LIST_FILL_ERROR": {
            return {
                ...state,
                answerListFillLoading: false,
                answerListFillError: true
            }
        }
        case "SETTINGS_PAGE_CLOSE_TABS_WARNING": {
            return {
                ...state,
                tooMuchEditTabsOpened: false
            }
        }
        case "SETTINGS_PAGE_SET_QUESTIONS_TABS_PAGINATION": {
            return {
                ...state,
                editQuestionTabsPag: action.payload
            }
        }
        case "SETTINGS_PAGE_SET_QUESTIONS_IN_NEW_TABS": {
            return {
                ...state,
                editQuestionTabs: action.payload
            }
        }
        case "SETTINGS_PAGE_OPEN_QUESTION_IN_NEW_TAB": {
            //move dependencies to smart component
            const tabs = state.editQuestionTabs || [],
                  allow = tabs.length < state.maxEditQuestionTabs
            if (!allow) {
                return {
                    ...state,
                    tooMuchEditTabsOpened: true
                }
            } else {
                const alreadyExists = tabs.indexOf(action.payload) !== -1 ? true : false
                if (alreadyExists) {
                    return {
                        ...state
                    }
                } else {
                    return {
                        ...state,
                        editQuestionTabs: [...tabs, action.payload]
                    }
                }
            }
        }
        case "SETTINGS_PAGE_CLOSE_QUESTION_TAB": {
            let editQuestionTabs = state.editQuestionTabs || [],
                index = editQuestionTabs.indexOf(action.payload)
                editQuestionTabs.splice(index, 1)
            return {
                ...state,
                editQuestionTabs: [...editQuestionTabs]
            }
        }
        case "SETTINGS_PAGE_GET_HISTORY_QUESTION_LOADING": {
            return {
                ...state,
                historyLoading: true,
                historyError: false
            }
        }
        case "SETTINGS_PAGE_GET_HISTORY_QUESTION": {
            return {
                ...state,
                history          : action.payload,
                historyLoading   : false,
                historyError     : false,
            }
        }
        case "SETTINGS_PAGE_GET_HISTORY_QUESTION_ERROR": {
            return {
                ...state,
                historyLoading: false,
                historyError: true
            }
        }
        case "SETTINGS_PAGE_SET_NEW_ANSWER": {
            return {
                ...state,
                newAnswer: action.payload,
            }
        }
        case "SETTINGS_PAGE_GET_ANSWER_COMMENTS_LOADING": {
            return {
                ...state,
                answerCommentsLoading: true,
                answerCommentsError: false
            }
        }
        case "SETTINGS_PAGE_GET_ANSWER_COMMENTS": {
            return {
                ...state,
                answerComments          : action.payload,
                answerCommentsLoading   : false,
                answerCommentsError     : false,
            }
        }
        case "SETTINGS_PAGE_GET_ANSWER_COMMENTS_ERROR": {
            return {
                ...state,
                answerCommentsLoading   : false,
                answerCommentsError     : true
            }
        }
        case "SETTINGS_PAGE_SET_CHECKED_QUESTIONS_LIST": {
            return {
                ...state,
                checkedQuestionList    : action.payload,
            }
        }
        case "SETTINGS_PAGE_QUESTION_UPDATE_LOADING": {
            return {
                ...state,
                updateQuestionLoading: true,
                updateQuestionError: false
            }
        }
        case "SETTINGS_PAGE_QUESTION_UPDATE": {
            return {
                ...state,
                updateQuestion          : action.payload,
                updateQuestionLoading   : false,
                updateQuestionError     : false,
            }
        }
        case "SETTINGS_PAGE_QUESTION_UPDATE_ERROR": {
            return {
                ...state,
                updateQuestionLoading   : false,
                updateQuestionError     : true
            }
        }case "SETTINGS_PAGE_GET_TEMPLATE_DROPDOWN_ITEMS_LOADING": {
            return {
                ...state,
                templateDropdownItemsLoading: true,
                templateDropdownItemsError: false
            }
        }
        case "SETTINGS_PAGE_GET_TEMPLATE_DROPDOWN_ITEMS": {
            return {
                ...state,
                templateDropdownItems         : action.payload,
                templateDropdownItemsLoading  : false,
                templateDropdownItemsError    : false,
            }
        }
        case "SETTINGS_PAGE_GET_TEMPLATE_DROPDOWN_ITEMS_ERROR": {
            return {
                ...state,
                templateDropdownItemsLoading   : false,
                templateDropdownItemsError     : true
            }
        }
        case "SETTINGS_PAGE_CHANGE_TEMPLATE_ITEMS_STATUS": {
            return {
                ...state,
                templateItemsShowing         : action.payload,
            }
        }case "SETTINGS_PAGE_GET_TEMPLATE_DRITEMS_LOADING": {
            return {
                ...state,
                templateDrItemsLoading: true,
                templateDrItemsError: false
            }
        }
        case "SETTINGS_PAGE_GET_TEMPLATE_DRITEMS": {
            return {
                ...state,
                templateDrItems         : action.payload,
                templateDrItemsLoading  : false,
                templateDrItemsError    : false,
            }
        }
        case "SETTINGS_PAGE_GET_TEMPLATE_DRITEMS_ERROR": {
            return {
                ...state,
                templateDrItemsLoading   : false,
                templateDrItemsError     : true
            }
        }
        case "SETTINGS_PAGE_GET_CALCULATED_RULES_LOADING": {
            return {
                ...state,
                calculatedRulesLoading: true,
                calculatedRulesError: false
            }
        }
        case "SETTINGS_PAGE_GET_CALCULATED_RULES": {
            return {
                ...state,
                calculatedRules         : action.payload,
                calculatedRulesLoading  : false,
                calculatedRulesError    : false,
            }
        }
        case "SETTINGS_PAGE_GET_CALCULATED_RULES_ERROR": {
            return {
                ...state,
                calculatedRulesLoading   : false,
                calculatedRulesError     : true
            }
        }
        case "SETTINGS_PAGE_GET_CALCULATED_RULES_EDITED": {
            return {
                ...state,
                calculatedRulesEdited       : action.payload,
            }
        }
        case "SETTINGS_PAGE_GET_CALCULATED_RULES_TYPES_LIST_LOADING": {
            return {
                ...state,
                calculatedRuleTypesListLoading: true,
                calculatedRuleTypesListError: false
            }
        }
        case "SETTINGS_PAGE_GET_CALCULATED_RULES_TYPES_LIST": {
            return {
                ...state,
                calculatedRuleTypesList        : action.payload,
                calculatedRuleTypesLisLtoading  : false,
                calculatedRuleTypesListError    : false,
            }
        }
        case "SETTINGS_PAGE_GET_CALCULATED_RULES_TYPES_LIST_ERROR": {
            return {
                ...state,
                calculatedRuleTypesListLoading   : false,
                calculatedRuleTypesListError     : true
            }
        }
         case "SETTINGS_PAGE_GET_CALCULATED_RULES_OPERATOR_LIST_LOADING": {
            return {
                ...state,
                calculatedRuleOperatorListLoading: true,
                calculatedRuleOperatorListError: false
            }
        }
        case "SETTINGS_PAGE_GET_CALCULATED_RULES_OPERATOR_LIST": {
            return {
                ...state,
                calculatedRuleOperatorList        : action.payload,
                calculatedRuleOperatorLisLtoading  : false,
                calculatedRuleOperatorListError    : false,
            }
        }
        case "SETTINGS_PAGE_GET_CALCULATED_RULES_OPERATOR_LIST_ERROR": {
            return {
                ...state,
                calculatedRuleOperatorListLoading   : false,
                calculatedRuleOperatorListError     : true
            }
        }
        case "SETTINGS_PAGE_GET_SCHOOL_DATA_LIST_LOADING": {
            return {
                ...state,
                schoolDataLoading: true,
                schoolDataError: false
            }
        }
        case "SETTINGS_PAGE_GET_SCHOOL_DATA_LIST": {
            return {
                ...state,
                schoolData        : action.payload,
                schoolDataLoading  : false,
                schoolDataError    : false,
            }
        }
        case "SETTINGS_PAGE_GET_SCHOOL_DATA_LIST_ERROR": {
            return {
                ...state,
                schoolDataLoading  : false,
                schoolDataError     : true,
            }
        }
        case "SETTINGS_PAGE_GET_CALL_DATA_LIST_LOADING": {
            return {
                ...state,
                callDataLoading: true,
                callDataError: false
            }
        }
        case "SETTINGS_PAGE_GET_CALL_DATA_LIST": {
            return {
                ...state,
                callData        : action.payload,
                callDataLoading  : false,
                callDataError    : false,
            }
        }
        case "SETTINGS_PAGE_GET_CALL_DATA_LIST_ERROR": {
            return {
                ...state,
               callDataLoading   : false,
               callDataError    : true
            }
        }
        case "SETTINGS_PAGE_GET_OTHER_DATA_LIST_LOADING": {
            return {
                ...state,
                otherDataLoading: true,
                otherDataError: false
            }
        }
        case "SETTINGS_PAGE_GET_OTHER_DATA_LIST": {
            return {
                ...state,
                otherData         : action.payload,
                otherDataLoading  : false,
                otherDataError    : false,
            }
        }
        case "SETTINGS_PAGE_GET_OTHER_DATA_LIST_ERROR": {
            return {
                ...state,
               otherDataLoading   : false,
               otherDataError    : true
            }
        }
        case "SETTINGS_PAGE_GET_CALCULATED_RULE_EDITED": {
            return {
                ...state,
               editedRule   : action.payload,
            }
        }
         case "SETTINGS_PAGE_GET_QUEST_WITH_ANSWERS_LOADING": {
            return {
                ...state,
                quesAnwerLoading: true,
                quesAnwerError: false
            }
        }
        case "SETTINGS_PAGE_GET_QUEST_WITH_ANSWERS": {
            return {
                ...state,
                quesAnwer         : action.payload,
                quesAnwerLoading  : false,
                quesAnwerError    : false,
            }
        }
        case "SETTINGS_PAGE_GET_QUEST_WITH_ANSWERS": {
            return {
                ...state,
               quesAnwerLoading  : false,
               quesAnwerError    : true
            }
        }
        case "SETTINGS_PAGE_GET_ADDED_WRAPPER": {
            return {
                ...state,
               addedWrapper :  action.payload,
            }
        }

        case "SETTINGS_PAGE_REGISTER_CLOSABLE_DROPDOWN": {
            return {
                ...state,
                closableDropdowns:  {
                    ...state.closableDropdowns,
                    [action.payload]: {
                        selectMenuOpen: false
                    }
                }
            }
        }
        case "SETTINGS_PAGE_OPEN_CLOSABLE_DROPDOWN": {
            let  dropdowns = state.closableDropdowns
            for (let iId in dropdowns) {
                if (iId == action.payload) {
                    dropdowns[iId] = {
                        selectMenuOpen: true
                    }
                } else {
                    dropdowns[iId] = {
                        selectMenuOpen: false
                    }
                }
            }
            return {
                ...state,
                closableDropdownsUpdater: !state.closableDropdownsUpdater,
                closableDropdowns: dropdowns
            }
        }
        case "SETTINGS_PAGE_CLOSE_CLOSABLE_DROPDOWN": {
            return {
                ...state,
                closableDropdownsUpdater: !state.closableDropdownsUpdater,
                closableDropdowns:  {
                    ...state.closableDropdowns,
                    [action.payload]: {
                        selectMenuOpen: false
                    }
                }
            }
        }

        case "SETTINGS_PAGE_REMOVE_CLOSABLE_DROPDOWN": {
            let  dropdowns = state.closableDropdowns
            delete dropdowns[action.payload]
            return {
                ...state,
                closableDropdownsUpdater: !state.closableDropdownsUpdater,
                closableDropdowns: dropdowns
            }
        }
        case "SETTINGS_PAGE_SET_NEW_LINKED_RULE": {
            return {
                ...state,
                newLinkedRule: action.payload,
            }
        }
         case "SETTINGS_PAGE_SET_NEW_LINKED_RULES": {
            return {
                ...state,
                newLinkedRules: action.payload,
            }
        }
         case "SETTINGS_PAGE_GET_HISTORY_APP_LOADING": {
            return {
                ...state,
                appHistoryLoading: true,
                appHistoryError  : false,
            }
        }
         case "SETTINGS_PAGE_GET_HISTORY_APP": {
            return {
                ...state,
                appHistory       : action.payload,
                appHistoryLoading: false,
                appHistoryError  : false,
            }
        }
         case "SETTINGS_PAGE_GET_HISTORY_APP_ERROR": {
            return {
                ...state,
                appHistoryLoading: false,
                appHistoryError  : true,
            }
        }
        case "SETTINGS_PAGE_GET_LINKED_ITEMS_LOADING": {
            return {
                ...state,
                linkedItemsLoading: action.payload,
                linkedItemsError  : false,
            }
        }
        case "SETTINGS_PAGE_GET_LINKED_ITEMS": {
            return {
                ...state,
                linkedItems       : action.payload,
                linkedItemsLoading: false,
                linkedItemsError  : false,
            }
        }
        case "SETTINGS_PAGE_GET_LINKED_RULES": {
            return {
                ...state,
                linkedRules       : action.payload,
                linkedItemsLoading: false,
                linkedItemsError  : false,
            }
        }
        case "SETTINGS_PAGE_GET_LINKED_ITEMS_ERROR": {
            return {
                ...state,
                linkedItemsLoading: false,
                linkedItemsError  : true,
            }
        }
        case "SETTINGS_PAGE_SET_NEW_LINKED_GROUP": {
            return {
                ...state,
                createdLinkedGroup: action.payload,
                linkedItemsLoading: false,
                linkedItemsError  : false
            }
        }
        case "SETTINGS_PAGE_EDITED_BILLING_RATE": {
            return {
                ...state,
                billingRateEdited: action.payload,
            }
        }
        case "SETTINGS_PAGE_SET_NEW_RULES": {
            return {
                ...state,
                newCalculatedRules: action.payload,
            }
        }
        case "SETTINGS_PAGE_MAKE_QUESTION_ACTIVE_LOADING": {
            return {
                ...state,
                makeQuestActiveLoading: true,
                makeQuestActiveError  : false,
            }
        }
         case "SETTINGS_PAGE_MAKE_QUESTION_ACTIVE": {
            return {
                ...state,
                makeQuestActive       : action.payload,
                makeQuestActiveLoading: false,
                makeQuestActiveError  : false,
            }
        }
         case "SETTINGS_PAGE_MAKE_QUESTION_ACTIVE_ERROR": {
            return {
                ...state,
                makeQuestActiveLoading: false,
                makeQuestActiveError  : true,
            }
        }
        case "SETTINGS_PAGE_MAKE_QUESTION_INACTIVE_LOADING": {
            return {
                ...state,
                makeQuestInActiveLoading: true,
                makeQuestInActiveError  : false,
            }
        }
         case "SETTINGS_PAGE_MAKE_QUESTION_INACTIVE": {
            return {
                ...state,
                makeQuestInActive       : action.payload,
                makeQuestInActiveLoading: false,
                makeQuestInActiveError  : false,
            }
        }
         case "SETTINGS_PAGE_MAKE_QUESTION_INACTIVE_ERROR": {
            return {
                ...state,
                makeQuestInActiveLoading: false,
                makeQuestInActiveError  : true,
            }
        }
    }
    return state
}
