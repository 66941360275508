import moment from 'moment'
import * as R from 'ramda'

export const ISO_8601_FORMAT = "YYYY-MM-DD";

const MAX_RANGE_DAYS = 1080;

function _standardDates(rangeDurationDays, comparisonMonthsAgo) {
    const rangeStart = moment().subtract(rangeDurationDays-1, 'days');
    const comparisonStart = rangeStart.clone().subtract(comparisonMonthsAgo, 'months');
    return {
        rangeStart: rangeStart.format(ISO_8601_FORMAT),
        rangeEnd:   moment().format(ISO_8601_FORMAT),
        comparisonStart: comparisonStart.format(ISO_8601_FORMAT),
        comparisonEnd:   comparisonStart.clone().add(rangeDurationDays-1, 'days').format(ISO_8601_FORMAT),
    };
}
function _standardCustomComparisonDates(rangeDurationDays, dateSettings) {
    const {comparisonCustomStart, comparisonCustomEnd} = dateSettings;
    return {
        rangeStart: moment().subtract(rangeDurationDays-1, 'days').format(ISO_8601_FORMAT),
        rangeEnd:   moment()                                      .format(ISO_8601_FORMAT),
        comparisonStart: comparisonCustomStart,
        comparisonEnd:   comparisonCustomEnd,
    };
}

const _calibration_ranges = [{
    key: "today",
    label: "Today",
    comparisons: (dateSettings) => {
        const dayDates = comparisonDaysAgo => ({
            rangeStart: moment().format(ISO_8601_FORMAT),
            rangeEnd:   moment().format(ISO_8601_FORMAT),
            comparisonStart: moment().subtract(comparisonDaysAgo, 'days').format(ISO_8601_FORMAT),
            comparisonEnd:   moment().subtract(comparisonDaysAgo, 'days').format(ISO_8601_FORMAT),
        })
        return [{
            key: "yesterday",
            label: "Yesterday",
            dates: R.partial(dayDates, [1]),
        },{
            key: "last-week-day",
            label: `Last ${moment().format("dddd")}`,
            dates: R.partial(dayDates, [7]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [1]),
        }];
    }
},{
    key: "7-days",
    label: "7 days",
    comparisons: (dateSettings) => {
        return [{
            key: "prev-month",
            label: "Previous Month",
            dates: R.partial(_standardDates, [7, 1]),
        },{
            key: "prev-quarter",
            label: "Previous Quarter",
            dates: R.partial(_standardDates, [7, 3]),
        },{
            key: "prev-half-year",
            label: "Previous Half-year",
            dates: R.partial(_standardDates, [7, 6]),
        },{
            key: "prev-year",
            label: "Previous Year",
            dates: R.partial(_standardDates, [7, 12]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [7]),
        }];
    }
},{
    key: "14-days",
    label: "14 days",
    comparisons: (dateSettings) => {
        return [{
            key: "prev-month",
            label: "Previous Month",
            dates: R.partial(_standardDates, [14, 1]),
        },{
            key: "prev-quarter",
            label: "Previous Quarter",
            dates: R.partial(_standardDates, [14, 3]),
        },{
            key: "prev-half-year",
            label: "Previous Half-year",
            dates: R.partial(_standardDates, [14, 6]),
        },{
            key: "prev-year",
            label: "Previous Year",
            dates: R.partial(_standardDates, [14, 12]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [14]),
        }];
    }
},{
    key: "30-days",
    label: "30 days",
    comparisons: (dateSettings) => {
        return [{
            key: "prev-month",
            label: "Previous Month",
            dates: (dateSettings) => {
                const rangeEnd = moment();
                const rangeStart = rangeEnd.clone().subtract(29, 'days');
                return {
                    rangeStart: rangeStart.format(ISO_8601_FORMAT),
                    rangeEnd:   rangeEnd  .format(ISO_8601_FORMAT),
                    comparisonStart: rangeStart.clone().subtract(30, 'days').format(ISO_8601_FORMAT),
                    comparisonEnd:   rangeEnd  .clone().subtract(30, 'days').format(ISO_8601_FORMAT),
                };
            },
        },{
            key: "prev-quarter",
            label: "Previous Quarter",
            dates: R.partial(_standardDates, [30, 3]),
        },{
            key: "prev-half-year",
            label: "Previous Half-year",
            dates: R.partial(_standardDates, [30, 6]),
        },{
            key: "prev-year",
            label: "Previous Year",
            dates: R.partial(_standardDates, [30, 12]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [30]),
        }];
    }
},{
    key: "month",
    label: "Current Month",
    comparisons: (dateSettings) => {
        const monthDates = comparisonMonthsAgo => ({
            rangeStart: moment().startOf('month').format(ISO_8601_FORMAT),
            rangeEnd:   moment()                 .format(ISO_8601_FORMAT),
            comparisonStart: moment().subtract(comparisonMonthsAgo, 'months').startOf('month').format(ISO_8601_FORMAT),
            comparisonEnd:   moment().subtract(comparisonMonthsAgo, 'months')  .endOf('month').format(ISO_8601_FORMAT),
        });
        return [{
            key: "prev-month",
            label: "Previous Month",
            dates: R.partial(monthDates, [1]),
        },{
            key: "prev-quarter",
            label: "Previous Quarter",
            dates: R.partial(monthDates, [3]),
        },{
            key: "prev-half-year",
            label: "Previous Half-year",
            dates: R.partial(monthDates, [6]),
        },{
            key: "prev-year",
            label: "Previous Year",
            dates: R.partial(monthDates, [12]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [moment().diff(moment().startOf('month'), "days")+1]),
        }];
    }
}];

const _ranges = [{
    key: "today",
    label: "Today",
    comparisons: (dateSettings) => {
        const dayDates = comparisonDaysAgo => ({
            rangeStart: moment().format(ISO_8601_FORMAT),
            rangeEnd:   moment().format(ISO_8601_FORMAT),
            comparisonStart: moment().subtract(comparisonDaysAgo, 'days').format(ISO_8601_FORMAT),
            comparisonEnd:   moment().subtract(comparisonDaysAgo, 'days').format(ISO_8601_FORMAT),
        })
        return [{
            key: "yesterday",
            label: "Yesterday",
            dates: R.partial(dayDates, [1]),
        },{
            key: "last-week-day",
            label: `Last ${moment().format("dddd")}`,
            dates: R.partial(dayDates, [7]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [1]),
        }];
    }
},{
    key: "7-days",
    label: "7 days",
    comparisons: (dateSettings) => {
        return [{
            key: "prev-month",
            label: "Previous Month",
            dates: R.partial(_standardDates, [7, 1]),
        },{
            key: "prev-quarter",
            label: "Previous Quarter",
            dates: R.partial(_standardDates, [7, 3]),
        },{
            key: "prev-half-year",
            label: "Previous Half-year",
            dates: R.partial(_standardDates, [7, 6]),
        },{
            key: "prev-year",
            label: "Previous Year",
            dates: R.partial(_standardDates, [7, 12]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [7]),
        }];
    }
},{
    key: "14-days",
    label: "14 days",
    comparisons: (dateSettings) => {
        return [{
            key: "prev-month",
            label: "Previous Month",
            dates: R.partial(_standardDates, [14, 1]),
        },{
            key: "prev-quarter",
            label: "Previous Quarter",
            dates: R.partial(_standardDates, [14, 3]),
        },{
            key: "prev-half-year",
            label: "Previous Half-year",
            dates: R.partial(_standardDates, [14, 6]),
        },{
            key: "prev-year",
            label: "Previous Year",
            dates: R.partial(_standardDates, [14, 12]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [14]),
        }];
    }
},{
    key: "30-days",
    label: "30 days",
    comparisons: (dateSettings) => {
        return [{
            key: "prev-month",
            label: "Previous Month",
            dates: (dateSettings) => {
                const rangeEnd = moment();
                const rangeStart = rangeEnd.clone().subtract(29, 'days');
                return {
                    rangeStart: rangeStart.format(ISO_8601_FORMAT),
                    rangeEnd:   rangeEnd  .format(ISO_8601_FORMAT),
                    comparisonStart: rangeStart.clone().subtract(30, 'days').format(ISO_8601_FORMAT),
                    comparisonEnd:   rangeEnd  .clone().subtract(30, 'days').format(ISO_8601_FORMAT),
                };
            },
        },{
            key: "prev-quarter",
            label: "Previous Quarter",
            dates: R.partial(_standardDates, [30, 3]),
        },{
            key: "prev-half-year",
            label: "Previous Half-year",
            dates: R.partial(_standardDates, [30, 6]),
        },{
            key: "prev-year",
            label: "Previous Year",
            dates: R.partial(_standardDates, [30, 12]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [30]),
        }];
    }
},{
    key: "month",
    label: "Current Month",
    comparisons: (dateSettings) => {
        const monthDates = comparisonMonthsAgo => ({
            rangeStart: moment().startOf('month').format(ISO_8601_FORMAT),
            rangeEnd:   moment()                 .format(ISO_8601_FORMAT),
            comparisonStart: moment().subtract(comparisonMonthsAgo, 'months').startOf('month').format(ISO_8601_FORMAT),
            comparisonEnd:   moment().subtract(comparisonMonthsAgo, 'months')  .endOf('month').format(ISO_8601_FORMAT),
        });
        return [{
            key: "prev-month",
            label: "Previous Month",
            dates: R.partial(monthDates, [1]),
        },{
            key: "prev-quarter",
            label: "Previous Quarter",
            dates: R.partial(monthDates, [3]),
        },{
            key: "prev-half-year",
            label: "Previous Half-year",
            dates: R.partial(monthDates, [6]),
        },{
            key: "prev-year",
            label: "Previous Year",
            dates: R.partial(monthDates, [12]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [moment().diff(moment().startOf('month'), "days")+1]),
        }];
    }
},{
    key: "quarter",
    label: "Current Quarter",
    comparisons: (dateSettings) => {
        const quarterDates = comparisonQuartersAgo => ({
            rangeStart: moment().startOf('quarter').format(ISO_8601_FORMAT),
            rangeEnd:   moment()                   .format(ISO_8601_FORMAT),
            comparisonStart: moment().subtract(comparisonQuartersAgo*3, 'months').startOf('quarter').format(ISO_8601_FORMAT),
            comparisonEnd:   moment().subtract(comparisonQuartersAgo*3, 'months')  .endOf('quarter').format(ISO_8601_FORMAT),
        });
        return [{
            key: "prev-quarter",
            label: "Previous Quarter",
            dates: R.partial(quarterDates, [1]),
        },{
            key: "prev-half-year",
            label: "Previous Half-year",
            dates: R.partial(quarterDates, [2]),
        },{
            key: "prev-year",
            label: "Previous Year",
            dates: R.partial(quarterDates, [4]),
        },{
            key: "custom",
            label: "Custom",
            dates: R.partial(_standardCustomComparisonDates, [moment().diff(moment().startOf('quarter'), "days")+1]),
        }];
    }
},{
    key: "custom",
    label: "Custom",
    comparisons: (dateSettings) => {
        const {rangeCustomStart, rangeCustomEnd} = dateSettings;
        const days = moment(rangeCustomEnd).diff(moment(rangeCustomStart), "days");
        const customDates = (comparisonMonthsAgo, dateSettings) => {
            const {rangeCustomStart, rangeCustomEnd} = dateSettings;
            const days = moment(rangeCustomEnd).diff(moment(rangeCustomStart), "days");
            const comparisonStart = moment(rangeCustomStart).subtract(comparisonMonthsAgo, 'months');
            return {
                rangeStart: rangeCustomStart,
                rangeEnd:   rangeCustomEnd,
                comparisonStart: comparisonStart                  .format(ISO_8601_FORMAT),
                comparisonEnd:   comparisonStart.add(days, "days").format(ISO_8601_FORMAT),
            };
        };
        const comparisons = [{
            key: "prev-month",
            label: "Previous Month",
            dates: R.partial(customDates, [1]),
        },{
            key: "prev-quarter",
            label: "Previous Quarter",
            dates: R.partial(customDates, [3]),
        },{
            key: "prev-half-year",
            label: "Previous Half-year",
            dates: R.partial(customDates, [6]),
        },{
            key: "prev-year",
            label: "Previous Year",
            dates: R.partial(customDates, [12]),
        },{
            key: "custom",
            label: "Custom",
            dates: ({rangeCustomStart, rangeCustomEnd, comparisonCustomStart, comparisonCustomEnd}) => ({
                rangeStart: rangeCustomStart,
                rangeEnd:   rangeCustomEnd,
                comparisonStart: comparisonCustomStart,
                comparisonEnd:   comparisonCustomEnd,
            }),
        }];
        return days <= 28 ? comparisons : days <= MAX_RANGE_DAYS ? R.tail(comparisons) : []
    },
}];

const _comparisons = (dateSettings) => {
    const {     range,      rangeCustomStart,      rangeCustomEnd,
           comparison, comparisonCustomStart, comparisonCustomEnd} = dateSettings;
    return R.find(R.propEq("key", range), _ranges).comparisons(dateSettings);
}

export function getDefaultDateSettings() {
    const defaultRange = "7-days";
    return {
        range: defaultRange,
        rangeCustomStart: null,
        rangeCustomEnd: null,
        comparison: _comparisons({range: defaultRange})[0].key,
        comparisonCustomStart: null,
        comparisonCustomEnd: null,
    };
}

export function getDates(dateSettings) {
    const {     range,      rangeCustomStart,      rangeCustomEnd,
           comparison, comparisonCustomStart, comparisonCustomEnd} = dateSettings;
    return R.find(R.propEq("key", comparison), _comparisons(dateSettings)).dates(dateSettings);
}

export function getCalibrationRanges() {
    return R.map(R.pick(["key", "label"]), _calibration_ranges);
}

export function getRanges() {
    return R.map(R.pick(["key", "label"]), _ranges);
}

export function getComparisons(dateSettings) {
    return R.map(R.pick(["key", "label"]), _comparisons(dateSettings));
}

function _refineCustomComparison(dateSettings) {
    const {     range,      rangeCustomStart,      rangeCustomEnd,
           comparison, comparisonCustomStart, comparisonCustomEnd} = dateSettings;
    const {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    const comparisonDuration = moment(comparisonEnd).diff(moment(comparisonStart), "days");
    const newComparisonEnd = moment.min(moment(comparisonEnd), moment(rangeStart).subtract(1, "days"));
    return R.merge(dateSettings, {
        comparisonCustomStart: newComparisonEnd.clone().subtract(comparisonDuration, "days").format(ISO_8601_FORMAT),
        comparisonCustomEnd: newComparisonEnd.format(ISO_8601_FORMAT),
    });
}

export function updateRange(newRange, newRangeCustomStart, newRangeCustomEnd, dateSettings) {
    const {     range,      rangeCustomStart,      rangeCustomEnd,
           comparison, comparisonCustomStart, comparisonCustomEnd} = dateSettings;
    const {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    let newDateSettings = R.merge(dateSettings, {
        range: newRange,
        rangeCustomStart: newRangeCustomStart || rangeStart,
        rangeCustomEnd: moment.min(
            moment.max(
                moment(newRangeCustomStart || rangeStart),
                moment(newRangeCustomEnd || rangeEnd)
            ),
            moment(newRangeCustomStart || rangeStart).add(MAX_RANGE_DAYS-1, "days")
        ).format(ISO_8601_FORMAT),
        comparisonCustomStart: comparisonStart,
        comparisonCustomEnd: comparisonEnd,
    });
    const newComparisons = R.pluck("key", _comparisons(newDateSettings));
    if (!R.contains(comparison, newComparisons)) {
        newDateSettings = R.merge(newDateSettings, {comparison: newComparisons[0]});
    }
    if (newDateSettings.comparison === "custom") {
        newDateSettings = _refineCustomComparison(newDateSettings);
    }
    return newDateSettings;
}

export function updateComparison(newComparison, newComparisonCustomStart, newComparisonCustomEnd, dateSettings) {
    const {     range,      rangeCustomStart,      rangeCustomEnd,
           comparison, comparisonCustomStart, comparisonCustomEnd} = dateSettings;
    if (!R.contains(newComparison, R.pluck("key", _comparisons(dateSettings)))) {
        throw new Error(`Invalid comparison for this range: ${newComparison}`);
    }
    const {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    let newDateSettings = R.merge(dateSettings, {
        comparison: newComparison,
        comparisonCustomStart: newComparisonCustomStart || comparisonStart,
        comparisonCustomEnd: moment.min(
            moment.max(
                moment(newComparisonCustomStart || comparisonStart),
                moment(newComparisonCustomEnd || comparisonEnd)
            ),
            moment(newComparisonCustomStart || comparisonStart).add(MAX_RANGE_DAYS-1, "days")
        ).format(ISO_8601_FORMAT),
    });
    if (newDateSettings.comparison === "custom") {
        newDateSettings = _refineCustomComparison(newDateSettings);
    }
    return newDateSettings;
}

export function rangeCustomStartValid(newRangeCustomStart, dateSettings) {
    return moment((new Date).toDateString()) >= moment(newRangeCustomStart);
}

export function rangeCustomEndValid(newRangeCustomEnd, dateSettings) {
    const {     range,      rangeCustomStart,      rangeCustomEnd,
           comparison, comparisonCustomStart, comparisonCustomEnd} = dateSettings;
    return moment((new Date).toDateString()) >= moment(newRangeCustomEnd) &&
                moment(newRangeCustomEnd) >= moment(rangeCustomStart) &&
                moment(newRangeCustomEnd).diff(moment(rangeCustomStart), "days") < MAX_RANGE_DAYS;
}

export function comparisonCustomStartValid(newComparisonCustomStart, dateSettings) {
    const {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    return moment(newComparisonCustomStart) < moment(rangeStart);
}

export function comparisonCustomEndValid(newComparisonCustomEnd, dateSettings) {
    const {rangeStart, rangeEnd, comparisonStart, comparisonEnd} = getDates(dateSettings);
    return moment(comparisonStart) <= moment(newComparisonCustomEnd) && moment(newComparisonCustomEnd) < moment(rangeStart);
}






export function getEmptyFilters() {
    return {
        scorecards: [],
        groups: [],
        agents: [],
        campaigns: [],
        QAs: [],
        teamLeads: [],
        missedItems: [],
        failedOnly: true,
        badCallsOnly: false,
        passedOnly: true,
        filterByReviewDate: false,
        reviewType: '',
        missedBy: 'agent',
        pendingOnly: false,
        callType: '',
    }
}

const getEmptyFiltersNoMissedBy = (missedBy) => {
    return {
        scorecards: [],
        groups: [],
        agents: [],
        campaigns: [],
        QAs: [],
        teamLeads: [],
        missedItems: [],
        failedOnly: true,
        badCallsOnly: false,
        passedOnly: true,
        filterByReviewDate: false,
        reviewType: '',
        missedBy: missedBy,
        pendingOnly: false,
    }
}

export function filtersEmptyNoMissedBy(filters) {
    return R.equals(filters, getEmptyFilters(filters.missedBy))
}

export function filtersEmpty(filters) {
    return R.equals(filters, getEmptyFilters())
}

export function getEmptyNotiFilters() {
    return {
        apps: [],
        supervisors: [],
        scorecards: [],
        QAs: [],
        teamLeads: [],
        calibrators: [],
        filterByDateClosed: false
    }
}

export function notiFiltersEmpty(filters) {
    return R.equals(filters, getEmptyNotiFilters())
}
