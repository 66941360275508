'use strict';

import axios from 'axios'
import axiosCancel from 'axios-cancel'
import * as R from 'ramda'
import * as api from './api';

import {sendRequest, sendDataRequest} from '../../APIGateway/gateway'
import listenActions from '../../actions/listenActions'
import playerActions from '../../actions/playerActions'
import {checkVisibility} from './utils'
import {convertScorecardData, convertListenSaveData} from './dataConvertor'

axiosCancel(axios, {
    debug: false
})

const actions = {

    start(store) {
        return dispatch => {
            const userData = R.path(['headerReducer', 'headerData', 'userData'], store.getState())
            if (userData) {
                dispatch(this.getNextCall())
            } else {
                const userDataPromise = new Promise((resolve) => {
                    const unsubscribe = store.subscribe(() => {
                        const userDataFromAPI = R.path(['headerReducer', 'headerData', 'userData'], store.getState())
                        if (userDataFromAPI) {
                            unsubscribe()
                            resolve()
                        }
                    })
                })
                userDataPromise.then(() => {
                    dispatch(this.getNextCall())
                })
            }
        }
    },

    sendHeartbeat(data) {
        return dispatch => {
            dispatch({
                type: 'NEW_LISTEN_HEARTBEAT_UPDATE',
                payload: true
            })
            return sendRequest('heartbeat', {payload: data}, 'RECORDPOST')
                .then(resp => {
                    return
                }, err => console.log('Heartbeat not sent'))
        }
    },

    getNextCall() {
        return (dispatch, getState) => {
            const state = getState()
            const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
            const stopWorking = R.path(['headerReducer', 'stopWorking'], state)
            if (!stopWorking) {
                dispatch({
                    type: 'LISTEN_NEXT_CALL_LOADING',
                    payload: true
                })
                return sendRequest('getNextCall', {payload: ''}, 'RECORDPOST')
                    .then(response => {
                        const listenData = response.data.ListenData
                        const scorecard = response.data.Scorecard
                        if (
                            !listenData
                            || !listenData.X_ID
                            || !scorecard
                            || !scorecard.Sections
                            || !scorecard.Sections.length
                        ) {
                            if (userData.reloadTime) setTimeout(() => {
                                window.location.reload()
                            }, userData.reloadTime)
                            return dispatch({
                                type: 'LISTEN_NEXT_CALL_NODATA',
                                payload: true,
                            })
                        }
                        dispatch({
                            type: 'LISTEN_SET_QA_START',
                            payload: Math.floor(Date.now() / 1000).toString(),
                        })
                        dispatch({
                            type: 'SET_AUDIO_LINK',
                            payload: listenData.audio_link
                        })
                        dispatch({
                            type: 'GET_NEXT_CALL_FULFILLED',
                            payload: {
                                listenData: listenData,
                                scorecard: scorecard,
                            }
                        })
                        dispatch({
                            type: 'SET_ON_HOLD',
                            payload: listenData.onHold,
                        })

                        dispatch({
                            type: 'SET_IS_AUDIO',
                            payload: scorecard.ReviewType.toUpperCase() === 'AUDIO'
                        })

                        dispatch({
                            type: 'LISTEN_NEXT_CALL_FULFILLED',
                            payload: convertScorecardData(response.data),
                        })
                        dispatch(this.setFocusedNoAnsweredQuestion())
                        dispatch(this.setMarkers())
                    }, err => {
                        console.log('NEXT CALL ERROR - ', err.message)
                        dispatch({
                            type: 'LISTEN_NEXT_CALL_ERROR',
                            payload: true
                        })
                    })
            } else return null
        }
    },

    removeDealerships() {
        return (dispatch, getState) => {
            dispatch({
                type: 'LISTEN_SET_NEW_DEALERSHIP_SPEC_QUESTION',
                payload: [],
            })
            dispatch({
                type: 'LISTEN_SET_NEW_AGENTS_DEALERSHIP_SPEC_QUESTION',
                payload: [],
            })
        }
    },

    getRelatedDealerships(value) {
         return dispatch => {
            axios.post(`http://app.callcriteria-dev.com/webApi/review/GetRelatedDealerships?dealershipName=${value}`)
            .then(res => {
                dispatch({
                    type: 'LISTEN_SET_NEW_DEALERSHIP_SPEC_QUESTION',
                    payload: res.data,
                })
            })
        }
    },

    getDealershipEmployees(value) {
        return dispatch => {
            axios.post(`http://app.callcriteria-dev.com/webApi/review/GetDealershipEmployees?dealershipName=${value}`)
            .then(res => {
                dispatch({
                    type: 'LISTEN_SET_NEW_AGENTS_DEALERSHIP_SPEC_QUESTION',
                    payload: res.data,
                })
            })
        }
    },

    setFocusedNextQuestion(sectionId, questionId) {
        return (dispatch, getState) => {
            // debugger
            const state = getState()
            const isAudio = R.path(['newListenReducer', 'isAudio'], state)
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const mountingElement = R.path(['recordReducer', 'mountingElement'], state)
            let id = null
            const activeSection = scorecard.scorecardInfo.sections.find(s => s.sectionInfo.sectionId === sectionId)
            const currentFocused = activeSection.questions.find(q => q.questionId === questionId)
            const nextIndex = activeSection.questions.indexOf(currentFocused) + 1
            let nextQuestion = activeSection.questions.find((q, i) => q.isVisible && i >= nextIndex)
            if (nextQuestion) {
                id = nextQuestion.questionId
            } else {
                const nextSectionIndex = scorecard.scorecardInfo.sections.indexOf(activeSection) + 1
                const nextSection = scorecard.scorecardInfo.sections[nextSectionIndex]
                if (nextSection) {
                    nextQuestion = nextSection.questions.find(q => q.isVisible)
                    if (nextQuestion) id = nextQuestion.questionId
                }
            }
            if (id) {
                const isElementInViewport = (element) => {
                    const rect = element && element.getBoundingClientRect()
                    if (!rect) return
                    const playerHeight = isAudio ? 95 : 0
                    return (
                        rect.top >= 0 &&
                        rect.left >= 0 &&
                        rect.bottom <= (window.innerHeight - playerHeight || document.documentElement.clientHeight - playerHeight) &&
                        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                    );
                }
                const el = document.getElementById('new-listen-question-' + id)
                const parent = document.getElementById(mountingElement)
                const isVisible = isElementInViewport(el)
                if (el && !isVisible) el.scrollIntoView(false)
                if (parent && !isVisible) parent.scrollTop += 150
            }
            dispatch(this.setFocusedQuestion(id))
        }
    },

    setFocusedNoAnsweredQuestion() {
        return (dispatch, getState) => {
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            let id = null
            const sectionWithNoAnswered = scorecard.scorecardInfo.sections.find(s => {
                return s.questions.some(q => !q.isAnswered && q.isVisible)
            })
            if (sectionWithNoAnswered) {
                const notAnsweredQuestion = sectionWithNoAnswered.questions.find(q => !q.isAnswered && q.isVisible)
                if (notAnsweredQuestion) id = notAnsweredQuestion.questionId
            }
            dispatch(this.setFocusedQuestion(id))
        }
    },

    setFocusedQuestion(id) {
        return (dispatch, getState) => {
            const state = getState()
            const focusedQuestion = R.path(['newListenReducer', 'focusedQuestion'], state)
            if (focusedQuestion === id) return
            dispatch({
                type: 'NEW_LISTEN_SET_FOCUSED_QUESTION',
                payload: id,
            })
        }
    },

    setMarkers() {
        return (dispatch, getState) => {
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const audioReady = R.clone(R.path(['playerReducer', 'audioReady'], state))
            const markers = []
            if (!audioReady) return null
            if (
                scorecard
                && scorecard.scorecardInfo
                && scorecard.scorecardInfo.sections
                && scorecard.scorecardInfo.sections.length
            ) {
                scorecard.scorecardInfo.sections.forEach(section => {
                    section.questions.forEach(q => {
                        if (q.isComposite) {
                            q.compositeQuestionInfo.comments.forEach(option => {
                                if (option.checked) markers.push({
                                    position: option.position,
                                    negative: false,
                                    text: q.questionShortName + ': ' + option.optionText,
                                    QID: q.questionId,
                                    onclick: () => console.log(q.questionShortName + ': ' + option.optionText)
                                })
                            })
                        } else {
                            const answer = q.simpleQuestionInfo.answers.find(a => a.isAnswered)
                            if (answer) markers.push({
                                position: answer.position,
                                negative: !answer.answer.isRightAnswer,
                                text: q.questionShortName,
                                QID: q.questionId,
                                onclick: () => console.log(q.questionShortName)
                            })
                        }
                    })
                })
            }
            return dispatch(playerActions.setMarkers(markers))
            // return dispatch({
            //     type: 'PLAYER_MARKERS',
            //     payload: {
            //         markers: markers
            //     }
            // })
        }
    },

    setSimpleAnswer(sectionId, questionId) {
        return (dispatch, getState) => {
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const section = scorecard.scorecardInfo.sections.find(s => s.sectionInfo.sectionId === sectionId)
            if (!section) return
            const question = section.questions.find(q => q.questionId === questionId)
            if (!question) return
            const answer = question.simpleQuestionInfo.answers.find(a => a.answer.isRightAnswer)
            if (!answer) return
            answer.isAnswered = !answer.isAnswered
            question.isAnswered = answer.isAnswered
            answer.customComment = answer.isAnswered ? answer.customComment : ''
            if (answer.isAnswered && answer.answer.answerAlert !== '') {
                alert(`${answer.answer.answerAlert}`)
            }
            const audio = document.getElementById('audio-component')
            if (audio) answer.position = audio.currentTime || 0
            dispatch(this.updateScorecard(scorecard))
        }
    },

    setAnswer(sectionId, questionId, answerId, commentId) {
        return (dispatch, getState) => {
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const section = scorecard.scorecardInfo.sections.find(s => s.sectionInfo.sectionId === sectionId)
            if (!section) return
            const question = section.questions.find(q => q.questionId === questionId)
            if (!question) return
            const answer = question.simpleQuestionInfo.answers.find(a => a.answer.answerId == answerId)
            if (!answer) return
            const comment = answer.comments.find(c => c.commentId == commentId)
            if (!comment) return
            comment.checked = !comment.checked
            if (comment.checked && question.singleComment) answer.comments.forEach(c => {
                if (c.commentId != commentId) c.checked = false
            })
            answer.isAnswered = !((answer.commentRequired && !answer.comments.some(c => c.checked))
            || (answer.customCommentRequired && !answer.customComment)
            || !(answer.comments.some(c => c.checked) || answer.customComment))
            question.isAnswered = answer.isAnswered
            if (answer.isAnswered && answer.answer.answerAlert !== '') {
                alert(`${answer.answer.answerAlert}`)
            }
            const audio = document.getElementById('audio-component')
            if (audio) {
                answer.position = audio.currentTime
                comment.position = audio.currentTime
            }
            dispatch(this.updateScorecard(scorecard))
        }
    },

    setSimpleQuestionCustomComment(sectionId, questionId, answerId, text) {
        return (dispatch, getState) => {
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const section = scorecard.scorecardInfo.sections.find(s => s.sectionInfo.sectionId === sectionId)
            if (!section) return
            const question = section.questions.find(q => q.questionId === questionId)
            if (!question) return
            const answer = question.simpleQuestionInfo.answers.find(a => a.answer.answerId == answerId)
            if (!answer) return
            answer.customComment = text
            answer.isAnswered = answer.customCommentRequired
            ? !!answer.customComment
            : !!(answer.comments.some(c => c.checked) || answer.customComment)
            question.isAnswered = answer.isAnswered
            if (answer.isAnswered && answer.answer.answerAlert !== '') {
                alert(`${answer.answer.answerAlert}`)
            }
            const audio = document.getElementById('audio-component')
            if (audio) {
                const position = answer.position === 0 || answer.position > audio.currentTime
                ? audio.currentTime
                : answer.position
                answer.position = position
                question.position = position
                answer.customCommentPosition = audio.currentTime
            }
            dispatch(this.updateScorecard(scorecard))
        }
    },

    setCompositeQuestionCustomComment(sectionId, questionId, text) {
        return (dispatch, getState) => {
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const section = scorecard.scorecardInfo.sections.find(s => s.sectionInfo.sectionId === sectionId)
            if (!section) return
            const question = section.questions.find(q => q.questionId === questionId)
            if (!question) return
            question.compositeQuestionInfo.customComment = text
            const audio = document.getElementById('audio-component')
            if (audio) question.compositeQuestionInfo.customCommentPosition = audio.currentTime
            dispatch(this.updateScorecard(scorecard))
        }
    },

    setCompositeOption(sectionId, questionId, optionId) {
        return (dispatch, getState) => {
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const section = scorecard.scorecardInfo.sections.find(s => s.sectionInfo.sectionId === sectionId)
            if (!section) return
            const question = section.questions.find(q => q.questionId === questionId)
            if (!question) return
            const comment = question.compositeQuestionInfo.comments.find(c => c.optionId == optionId)
            if (!comment) return
            comment.checked = !comment.checked
            question.isAnswered = question.compositeQuestionInfo.comments.some(c => c.checked)
            const audio = document.getElementById('audio-component')
            if (audio) {
                comment.position = audio.currentTime
                question.position = audio.currentTime
            }
            dispatch(this.updateScorecard(scorecard))
        }
    },

    updateScorecard(scorecard) {
        return dispatch => {
            scorecard.scorecardInfo.sections = checkVisibility(scorecard.scorecardInfo.sections)
            dispatch({
                type: 'LISTEN_SET_ACTIVE_SCORECARD',
                payload: scorecard,
            })
            dispatch(this.setMarkers())
        }
    },

    setCustomComment(sectionId, questionId, answerId, value, DDLValue) {
        //!!! Add ddlValue somewhere here
        return (dispatch, getState) => {
            // debugger
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const section = scorecard.scorecardInfo.sections.find(s => s.sectionInfo.sectionId === sectionId)
            if (!section) return
            const question = section.questions.find(q => q.questionId === questionId)
            if (!question) return
            const answer = question.simpleQuestionInfo.answers.find(a => a.answer.answerId == answerId)
            if (!answer) return
            question.simpleQuestionInfo.answers.map(answer => {
                answer.isAnswered = false
                return answer
            })
            answer.customComment = value === 'No comments' ? '' : value
            answer.isAnswered = !!answer.customComment
            question.isAnswered = answer.isAnswered
            answer.DDLItems && answer.DDLItems.map(item => {
                item.checked = false
                if (item.DDLValue == DDLValue) {
                    item.checked = true
                }
            })
            answer.comments && answer.comments.map(item => {
                item.checked = false
                if (item.commentId == DDLValue) {
                    item.checked = !item.checked
                }
            })
            if (answer.isAnswered && answer.answer.answerAlert !== '') {
                alert(`${answer.answer.answerAlert}`)
            }
            const audio = document.getElementById('audio-component')
            if (audio && !(answer.position || question.position)) {
                answer.position = audio.currentTime
                answer.customCommentPosition = audio.currentTime
                question.position = audio.currentTime
            }
            dispatch(this.updateScorecard(scorecard))
        }
    },

    getAutocomplete(qid, text) {
        axios.cancel('getAutocomplete')
        return dispatch => {
            return sendRequest('getAutocomplete', {
                payload: qid
            }, 'CALIBRATION', false, 'getAutocomplete', 'string_match=' + (text || null)).then(resp => resp, err => 'ERROR')
        }
    },

    saveCall(copyToCali, checkedWorking) {
        return (dispatch, getState) => {
            const audio = document.getElementById('audio-component')
            const callLength = audio && audio.duration && audio.duration.toString() || '0'
            if (audio) {
                dispatch({
                    type: 'SET_IS_AUDIO',
                    payload: false,
                })
                audio.pause()
                document.body.removeChild(audio)
            }
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const listenData = R.clone(R.path(['listenReducer', 'listenData'], state))
            const qaStart = R.path(['newListenReducer', 'qaStart'], state)
            const qaWhisper = R.path(['newListenReducer', 'qaWhisper'], state)
            const clerkData = R.path(['newListenReducer', 'clerkData'], state)
            const generalComment = R.path(['newListenReducer', 'generalComment'], state)
            const data = convertListenSaveData(scorecard, qaWhisper, clerkData, generalComment, copyToCali, qaStart, callLength)
            dispatch(this.setAnswersForDropdown([]))
            listenData.X_ID = null
            dispatch({
                type: 'GET_NEXT_CALL_FULFILLED',
                payload: {
                    listenData: listenData,
                    scorecard: scorecard
                }
            })
            const gapInfo = R.path(['listenReducer', 'audioGapInfo'], state)
            if (gapInfo) {
                data.LD.gapInfo = gapInfo
            }
            dispatch({
                type: 'LISTEN_NEXT_CALL_LOADING',
                payload: true
            })
            return sendDataRequest('saveCall', data, 'RECORDPOST')
                .then(resp => {
                    if (checkedWorking) {
                        window.location = '/home'
                    } else {
                        dispatch(this.getNextCall())
                    }
                }, err => {
                    if (checkedWorking) {
                        window.location = '/home'
                    } else {
                        dispatch(this.getNextCall())
                    }
                    
                })
        }
    },

    calibrateCall(rejectText) {
        return (dispatch, getState) => {
            const audio = document.getElementById('audio-component')
            const callLength = audio && audio.duration && audio.duration.toString() || '0'
            if (audio) {
                dispatch({
                    type: 'SET_IS_AUDIO',
                    payload: false,
                })
                audio.pause()
                document.body.removeChild(audio)
            }
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const qaStart = R.path(['newListenReducer', 'qaStart'], state)
            const qaWhisper = R.path(['newListenReducer', 'qaWhisper'], state)
            const clerkData = R.path(['newListenReducer', 'clerkData'], state)
            const generalComment = R.path(['newListenReducer', 'generalComment'], state)
            const data = convertListenSaveData(scorecard, qaWhisper, clerkData, generalComment, false, qaStart, callLength)
            data.bad_reason = rejectText
            // const aaa = {
            //     Comments: [],
            //     FQR: data.FQR,
            //     FQS: data.FQS,
            //     FQSO: data.FQSO,
            //     LD: data.LD,
            // }
            // aaa.bad_reason = rejectText
            dispatch({
                type: 'LISTEN_NEXT_CALL_LOADING',
                payload: true
            })
            return sendDataRequest('calibrateCall', data, 'RECORDPOST')
                .then(resp => dispatch(listenActions.getNextCalibration()), err => dispatch(listenActions.getNextCalibration()))
        }
    },

    rejectCall(text, checkedWorking) {
        return (dispatch, getState) => {
            const audio = document.getElementById('audio-component')
            const callLength = audio && audio.duration && audio.duration.toString() || '0'
            if (audio) {
                dispatch({
                    type: 'SET_IS_AUDIO',
                    payload: false,
                })
                audio.pause()
                document.body.removeChild(audio)
            }
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const listenData = R.clone(R.path(['listenReducer', 'listenData'], state))
            const qaStart = R.path(['newListenReducer', 'qaStart'], state)
            const qaWhisper = R.path(['newListenReducer', 'qaWhisper'], state)
            const clerkData = R.path(['newListenReducer', 'clerkData'], state)
            const generalComment = R.path(['newListenReducer', 'generalComment'], state)
            const data = convertListenSaveData(scorecard, qaWhisper, clerkData, generalComment, false, qaStart, callLength)
            data.bad_reason = text
            listenData.X_ID = null
            dispatch({
                type: 'GET_NEXT_CALL_FULFILLED',
                payload: {
                    listenData: listenData,
                    scorecard: scorecard
                }
            })
            dispatch({
                type: 'LISTEN_NEXT_CALL_LOADING',
                payload: true
            })
            return sendDataRequest('saveCall', data, 'RECORDPOST')
                .then((resp) => {
                    if (checkedWorking) {
                        window.location = '/home'
                    } else {
                        dispatch(this.getNextCall())
                    }
                }, err => {
                    if (checkedWorking) {
                        window.location = '/home'
                    } else {
                        dispatch(this.getNextCall())
                    }
                })

        }
    },

    changeCallScorecard(data, checkedWorking) {
        return dispatch => {
            dispatch({
                type: 'LISTEN_NEXT_CALL_LOADING',
                payload: true
            })
            return sendRequest('changeCallScorecard', {payload: data}, 'RECORDPOST')
            .then(() => {
                 if (checkedWorking) {
                        return window.location = '/home'
                    } else {
                        return dispatch(this.getNextCall())
                    }
                // return dispatch(this.getNextCall())
            })
        }
    },

    setActiveQuestion(questionId, sectionId) {
        return dispatch => {
            if (!questionId || (!sectionId && typeof sectionId !== 'number')) {
                dispatch({
                    type: 'LISTEN_SET_ACTIVE_QUESTION_SECTION',
                    payload: null,
                })
                return dispatch({
                    type: 'LISTEN_SET_ACTIVE_QUESTION',
                    payload: null,
                })
            }
            dispatch({
                type: 'LISTEN_SET_ACTIVE_QUESTION_SECTION',
                payload: sectionId
            })
            dispatch({
                type: 'LISTEN_SET_ACTIVE_QUESTION',
                payload: questionId,
            })
        }
    },

    departmenServicetQuestion(agentGroup) {
        return dispatch => {
            const query = `accountNumber=${agentGroup}&department=Service`
            return sendRequest('departmenServicetQuestion', null, 'DEPARTMENT', null, null, query)
            .then(resp => {
                if (resp.data.value) {
                    alert("Do Not Transcribe -- Select bad call and DNT as a reason")
                }
            })
        }
    },

    callTypeServiceQuestion(agentGroup, text) {
        return dispatch => {
            const query = `accountNumber=${agentGroup}&department=${text}`
            return sendRequest('departmenServicetQuestion', null, 'DEPARTMENT', null, null, query)
            .then(resp => {
                if (resp.data.value) {
                    alert("Do Not Transcribe -- Select bad call and DNT as a reason")
                }
            })
        }
    },

    setDropdownAnswers(sectionId, questionId, answerId, selectedAnswers) {
        return (dispatch, getState) => {
            const state = getState()
            const scorecard = R.clone(R.path(['newListenReducer', 'activeScorecard'], state))
            const section = scorecard.scorecardInfo.sections.find(s => s.sectionInfo.sectionId === sectionId)
            if (!section) return
            const question = section.questions.find(q => q.questionId === questionId)
            if (!question) return
            question.simpleQuestionInfo.answers.map(a => {
                a.isAnswered = false
                return a
            })
            const answer = question.simpleQuestionInfo.answers.find(a => a.answer.answerId == answerId)
            if (!answer) return
            answer.comments.map(com => {
                selectedAnswers.map(ans => {
                    if (com.commentId === ans.ddlValue) {
                        com.checked = true
                    }
                })
            })           
            answer.customComment = selectedAnswers
            answer.isAnswered = !!answer.customComment
            question.isAnswered = answer.isAnswered
            const audio = document.getElementById('audio-component')
            if (audio && !(answer.position || question.position)) {
                answer.position = audio.currentTime
                answer.customCommentPosition = audio.currentTime
                question.position = audio.currentTime
            }
            dispatch(this.updateScorecard(scorecard))
        }
    },

    setAnswersForDropdown(answers) {
        return dispatch => {
            dispatch({
                type: 'NEW_LISTEN_SET_ANSWERS_FOR_DROPDOWN',
                payload: {
                    answers: answers
                }
            })
        }
    },

    setGeneralComment(comment) {
        return dispatch => {
            dispatch({
                type: 'NEW_LISTEN_SET_GENERAL_COMMENT',
                payload: comment
            })
        }
    },
    
}

export default actions
