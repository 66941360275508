'use strict'

export default class DeleteComment extends React.Component {

    onClick = () => {
        this.props.callback(this.props.data.id)
    }

    render() {
        const {data, cancel} = this.props
        return (
            <div class='tread-new-content'>
                <div class='title'>
                    <div class='title-name'>Edit comment</div>
                    <div class='icon-ic-close' onClick={cancel}/>
                </div>
                <div class='content'>
                    <div>Are you sure you want to delete this comment? - </div>
                    <div>{data.text}</div>
                    <div onClick={this.onClick} class='modal-centered-btn'>DELETE</div>
                </div>
            </div>
        )
    }
}
