'use strict';

export default {
    run: (action) => {
        return function (dispatch) {
            return dispatch({
                type: 'LOADER_INIT',
                payload: action
            })
        }
    }
}
