'use strict'

import { connect } from 'react-redux'

import actions from '../../../../actions/dashboardActions'
import {setTextareaHeight} from '../../../../utils/globalHelpers'

import TreadsDropdown from './TreadsDropdown'

@connect((store) => {
    return {
        loading          : store.dashboardReducer.notificationReasonsLoading,
        error            : store.dashboardReducer.notificationReasonsError,
        reasons          : store.dashboardReducer.notificationReasons,
        shortCallDetails : store.dashboardReducer.shortCallDetails
    }
})

export default class AddNewTread extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            text: '',
            reason: '',
            userRoleName: '',
        }
    }

    setReason = (reason) => {
        this.setState({
            reason: reason
        })
    }

    setDispute = (role) => {
        this.setState({
            userRoleName: role
        })
    }

    onChange = (e) => {
        this.setState({
            text: e.currentTarget.value
        })
    }

    onClick = () => {
        const {text, reason, userRoleName} = this.state
        const {shortCallDetails, cancel, dispatch} = this.props
        const tread = {
            callId: shortCallDetails.systemData.callId,
            text: text,
            assignToRole: {
                userRoleName: userRoleName,
                userRoleId: userRoleName
            },
            action: 'assign',
            threadId: 0,
            startReason: reason
        }
        dispatch(actions.createThread(tread))
        cancel()
    }

    render() {
        const {reasons, cancel, shortCallDetails}= this.props
        const roles = []
        shortCallDetails.notificationInfo.reassignOptions.map(d => {
            if (d.userRoleId !== 'Close It' && d.userRoleId !== 'Notes Only') {
                roles.push(d.userRoleId)
            }
        })
        return (
            <div class='tread-new-content'>
                <div class='title'>
                    <div class='title-name'>Add new Notification</div>
                    <div class='icon-ic-close' onClick={cancel}/>
                </div>
                <div class='content'>
                    <TreadsDropdown reasons={reasons} setReason={this.setReason}/>
                    <textarea
                        onKeyUp={setTextareaHeight}
                        onChange={this.onChange} />
                    <TreadsDropdown reasons={roles} setReason={this.setDispute} role={true}/>
                    <div class='modal-centered-btn' onClick={this.onClick}>SAVE</div>
                </div>
            </div>
        )
    }
}
