import * as R from 'ramda'

const initialState = {
    pagination: {
        pagenum: 1,
        pagerows: 9999 //<- set to 20 for fast loading
    },
    sorting: {
        sortBy:    '',
        sortOrder: 'desc',
    },
    coachingStatistics        : null,
    coachingStatisticsError   : false,
    coachingStatisticsLoading : false,
    byMissedPointsData        : null,
    coachingStatisticsByAgent : [],
    coachingStatisticsByAgentError : false,
    coachingStatisticsByAgentLoading : false,
    byAgentsData              : null,
    byAgentFilters            : null,
    searchAgentFilter         : null,
    searchMissedPointsFilter  : null,
    showBy                    : 'byMissedPoints', //default byMissedPoints, byAgent
    viewBy                    : 'table', // default table, chart
    sessionsFilters           : {
        search: '',
        sortBy: ''
    },
    sessionTab                : 'active', //'closed' // 'drafts'
    activeSessions            : [],
    closedSessions            : [],
    drafts                    : [],
    coachingSessionListError  : false,
    coachingSessionListLoading: false,
    modalOptions              : null,
    userInfoTab               : 'coaching-sessions-tab', //statistcs-tab
    agentCoachingStats        : null,
    agentCoachingStatsError   : false,
    agentCoachingStatsLoading : false,
}

export default function reducer2(state=initialState, action) {
    switch (action.type) {
        case "COACHING_PAGE_SET_HOME_INITIAL": {
            return {
                ...state,
                viewBy: action.payload.view,
                showBy: action.payload.by,
                sessionTab: action.payload.tab
            }
        }
        case "COACHING_PAGE_GET_COACHING_STATS": {
            return {
                ...state,
                coachingStatistics       : action.payload,
                coachingStatisticsError  : false,
                coachingStatisticsLoading: false,
            }
        }
        case "COACHING_PAGE_GET_COACHING_STATS_LOADING": {
            return {
                ...state,
                coachingStatisticsError  : false,
                coachingStatisticsLoading: action.payload,
            }
        }
        case "COACHING_PAGE_GET_COACHING_STATS_ERROR": {
            return {
                ...state,
                coachingStatisticsError  : action.payload,
                coachingStatisticsLoading: false,
            }
        }
        case "COACHING_PAGE_SET_MISSED_POINTS_DATA": {
            return {
                ...state,
                byMissedPointsData       : action.payload
            }
        }
        case "COACHING_PAGE_SET_SESSION_FILTERS": {
            return {
                ...state,
                sessionsFilters: action.payload
            }
        }
        case "COACHING_PAGE_SET_BY_AGENT_FILTERS": {
            return {
                ...state,
                byAgentFilters: action.payload
            }
        }
        case "COACHING_PAGE_SET_SEARCH_AGENT_FILTER": {
            return {
                ...state,
                searchAgentFilter: action.payload
            }
        }
        case "COACHING_PAGE_SET_SEARCH_MISSED_POINTS_FILTER": {
            return {
                ...state,
                searchMissedPointsFilter: action.payload
            }
        }
        case "COACHING_PAGE_GET_COACHING_STATS_BY_AGENT": {
            return {
                ...state,
                coachingStatisticsByAgent: action.payload,
                coachingStatisticsByAgentError  : false,
                coachingStatisticsByAgentLoading: false,
            }
        }
        case "COACHING_PAGE_GET_COACHING_STATS_BY_AGENT_LOADING": {
            return {
                ...state,
                coachingStatisticsByAgentError  : false,
                coachingStatisticsByAgentLoading: action.payload,
            }
        }
        case "COACHING_PAGE_GET_COACHING_STATS_BY_AGENT_ERROR": {
            return {
                ...state,
                coachingStatisticsByAgentError  : action.payload,
                coachingStatisticsByAgentLoading: false,
            }
        }
        case "COACHING_PAGE_SET_BY_AGENTS_DATA": {
            return {
                ...state,
                byAgentsData: action.payload,
            }
        }
        case "COACHING_PAGE_SET_VIEW_BY": {
            return {
                ...state,
                viewBy: action.payload,
            }
        }
        case "COACHING_PAGE_SET_SHOW_BY": {
            return {
                ...state,
                showBy: action.payload,
            }
        }
        case "COACHING_PAGE_SET_ACTIVE_SESSIONS_TAB": {
            return {
                ...state,
                sessionTab: action.payload,
            }
        }
        case "COACHING_PAGE_GET_SCORECARD_ACTIVE_LIST": {
            return {
                ...state,
                activeSessions: action.payload,
                coachingSessionListError  : false,
                coachingSessionListLoading: false,
            }
        }
        case "COACHING_PAGE_GET_SCORECARD_CLOSED_LIST": {
            return {
                ...state,
                closedSessions: action.payload,
                coachingSessionListError  : false,
                coachingSessionListLoading: false,
            }
        }
        case "COACHING_PAGE_GET_SCORECARD_DRAFT_LIST": {
            return {
                ...state,
                drafts: action.payload,
                coachingSessionListError  : false,
                coachingSessionListLoading: false,
            }
        }
        case "COACHING_PAGE_GET_SCORECARD_LIST_LOADING": {
            return {
                ...state,
                coachingSessionListError  : false,
                coachingSessionListLoading: action.payload,
            }
        }
        case "COACHING_PAGE_GET_SCORECARD_LIST_ERROR": {
            return {
                ...state,
                coachingSessionListError  : action.payload,
                coachingSessionListLoading: false,
            }
        }
         case "SET_AGENT_INFO_MODAL_OPTIONS": {
            return {
                ...state,
                modalOptions: action.payload,
            }
        }
        case "COACHING_PAGE_SET_ACTIVE_USER_INFO_TAB": {
            return {
                ...state,
                userInfoTab: action.payload,
            }
        }
        case "COACHING_PAGE_GET_AGENT_COACHING_STATS": {
            return {
                ...state,
                agentCoachingStats: action.payload,
                agentCoachingStatsError  : false,
                agentCoachingStatsLoading: false,
            }
        }
        case "COACHING_PAGE_GET_AGENT_COACHING_STATS_LOADING": {
            return {
                ...state,
                agentCoachingStatsError  : false,
                agentCoachingStatsLoading: action.payload,
            }
        }
        case "COACHING_PAGE_GET_AGENT_COACHING_STATS_ERROR": {
            return {
                ...state,
                agentCoachingStatsError  : action.payload,
                agentCoachingStatsLoading: false,
            }
        }
    }
    return state
}
