'use strict';

export default function reducer(state={
    loaderAction: '',
    fetching: false,
    fetched: false,
    error: null,
  }, action) {
    switch (action.type) {
      case "FETCH_LOADER": {
        return {...state, fetching: true}
      }
      case "LOADER_ERROR": {
        return {...state, fetching: false, error: action.payload}
      }
      case "LOADER_INIT": {
        return {
          ...state,
          fetching: false,
          fetched: true,
          loaderAction: action.payload,
        }
      }
      default: {
          return {...state, fetching: false, error: 'unexpected error'}
      }
    }

    return state
}
