'use strict';

export default {
    initPlayer: (position) => {
        return function(dispatch) {
            return dispatch({
                type: 'PLAYER_INIT', payload: {
                    position: position ? position : 0,
                    play: false
                }
            })
        }
    },
    jumpPos: (position, play) => {
        return function(dispatch) {
            return dispatch({
                type: 'PLAYER_JUMPPOS',
                payload: {
                    position: position,
                    play: play
                }
            })
        }
    },
    audioAnalyzing: (analyzing) => {
        return function(dispatch) {
            return dispatch({
                type: 'AUDIO_ANALYZING',
                payload: analyzing
            })
        }
    },
    setMarkers: (markers) => {
        return function(dispatch) {
            return dispatch({
                type: 'SET_PLAYER_MARKERS',
                payload: markers
            })
            // return dispatch({
            //     type: 'PLAYER_MARKERS',
            //     payload: {
            //         markers: markers
            //     }
            // })
        }
    },
    destroy: (reinit) => {
        return function(dispatch) {
            return dispatch({
                type: 'PLAYER_STOP',
                payload: {
                    destroy: true,
                    reinit: reinit
                }
            })
        }
    },
    pause: (pause) => {
        return function(dispatch) {
            dispatch({
                type: 'PAUSE_PLAYING',
                payload: pause
            })
        }
    },
}
