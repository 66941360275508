'use strict'

import * as R from 'ramda'

export const convertBadCalls = (data) => {
    return data.map((call) => {
        return {
            id: parseInt(call.id, 10),
            agent: call.agent || '',
            call_date: call.call_date || '',
            phone: call.phone || '',
            bad_call_reason: call.bad_call_reason || '',
            audio: call.audio_link || '',
            scorecard: call.scorecard,
            agentGroup: call.agentGroup,
        }
    })
}

export const convertCallDetails = (data, id) => {
    const returnObj = Object.assign({}, data)
    returnObj.id = id
    return returnObj
}

export function getDefaultBadcallsColumns() {
    return [
        {id: "id",              value: "id",        checked: true, isRequired: true,  sortableValue: false, sortable: false, searchable: false},
        {id: "agent",           value: "agent",     checked: true, isRequired: false, sortableValue: true,  sortable: true,  searchable: true},
        {id: "agentGroup",      value: "group",     checked: true, isRequired: false, sortableValue: true,  sortable: true,  searchable: true},
        {id: "call_date",       value: "date",      checked: true, isRequired: false, sortableValue: true,  sortable: true,  searchable: true},
        {id: "scorecard",       value: "scorecard", checked: true, isRequired: false, sortableValue: true,  sortable: true,  searchable: true},
        {id: "phone",           value: "phone",     checked: true, isRequired: false, sortableValue: true,  sortable: true,  searchable: true},
        {id: "bad_call_reason", value: "reason",    checked: true, isRequired: false, sortableValue: true,  sortable: true,  searchable: true},
        {id: "actions",         value: "actions",   checked: true, isRequired: true,  sortableValue: false, sortable: false, searchable: false}
    ]
}

export function getSearchableColumns(columns) {
    const isSearchableChecked = item => item.searchable && item.checked
    const getId = item => item.id
    return R.map(getId, R.filter(isSearchableChecked, columns || getDefaultColumns()))
}

export function getColumns(columns) {
    const defaultColumns = getDefaultBadcallsColumns()
    defaultColumns.forEach(column => {
        const exist = columns.find(item => item.id === column.id)
        if (!exist) columns.push(column)
    })
    return columns
}

export const sortBadCalls = (calls, param, straight) => {
    let items = calls.slice()
    switch (param) {
        case 'call_date':
            items.sort((a, b) => {
                return straight ?
                    new Date(b.call_date) - new Date(a.call_date) :
                    new Date(a.call_date) - new Date(b.call_date)
            })
            break;
        case 'id':
            items.sort((a, b) => {
                return straight ? b.id - a.id : a.id - b.id
            })
            break;
        case 'phone':
            items.sort((a, b) => {
                return straight ? b.phone - a.phone : a.phone - b.phone
            })
            break;
        case 'bad_call_reason':
            items.sort((a, b) => {
                if (a.bad_call_reason < b.bad_call_reason) return straight ? -1 : 1;
                if (a.bad_call_reason > b.bad_call_reason) return straight ? 1 : -1;
                return 0;
            })
            break;
        case 'agent':
            items.sort((a, b) => {
                if (a.agent < b.agent) return straight ? -1 : 1;
                if (a.agent > b.agent) return straight ? 1 : -1;
                return 0;
            })
            break;
        case 'agentGroup':
            items.sort((a, b) => {
                if (a.agentGroup < b.agentGroup) return straight ? -1 : 1;
                if (a.agentGroup > b.agentGroup) return straight ? 1 : -1;
                return 0;
            })
            break;
        case 'scorecard':
            items.sort((a, b) => {
                if (a.scorecard < b.scorecard) return straight ? -1 : 1;
                if (a.scorecard > b.scorecard) return straight ? 1 : -1;
                return 0;
            })
            break;
        default:

    }
    return items
}
