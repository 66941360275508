'use strict'

export const InitialQuestionTypes = [
    {id: 0, Name: 'Regular'},
    {id: 1, Name: 'Count'},
    {id: 2, Name: 'Calculated'},
    {id: 3, Name: 'Dynamic'},
    {id: 4, Name: 'Dropdown'},
    {id: 5, Name: 'YMM'},
    {id: 6, Name: 'DateTime'},
    {id: 7, Name: 'Date'}
]

export const EmptyQuestion = {
    id                         : null,
    section                    : null,
    category                   : 0,
    q_order                    : null,
    question                   : null,
    q_type                     : null,
    q_short_name               : '',
    active                     : true,
    heading                    : 0,
    order                      : 0,
    questionText               : '', // description
    q_percent                  : 0,
    appname                    : null,
    auto_yes                   : false,
    auto_no                    : false,
    agent_display              : '',
    default_answer             : null,
    orig_id                    : null,
    total_points               : 0,
    template                   : '',
    temlate_items              : null,
    linked_question            : null,
    email_wrong                : true,
    campaign_specific          : null,
    scorecard_id               : null,
    qaPoints                   : 0,
    compliance                 : false,
    date_q_added               : null,
    non_billable               : false,
    comments_allowed           : true,
    linked_answer              : 0,
    linked_comment             : 0,
    client_visible             : true,
    client_guideline_visible   : true,
    sectionlessOrder           : 0,
    linkedVisible              : false,
    client_dashboard_visible   : true,
    pinned                     : false,
    pre_production             : true,
    single_comment             : true,
    points_paused              : false,
    points_paused_date         : null,
    full_width                 : false,
    wide_q                     : false,
    require_custom_comment     : false,
    sentence                   : '',
    ddl_type                   : '',
    ddlQuery                   : '',
    options_verb               : '',
    left_column_question       : true,
    sectionName                : null,
    linkedQuestionName         : null,
    linkedAnswerText           : null,
    linkedCommentText          : null
}

export function getDefaultBillingData() {
    return {
        id              : 0,
        appname         : '',
        start_minutes   : 0,
        end_minutes     : 0,
        rate            : 0,
        bill_type       : '',
        scorecard_only  : 0
    }
}

export function getDefaultApp() {
    return {
        naAffect                  : 0,
        showSectionScore          : true,
        budget                    : 0,
        rejectionProfile          : 0,
        id                        : 0,
        appname                   : '',
        contactName               : '',
        contactPhone              : '',
        active                    : true,
        firstNotificationAssigee  : '',
        notificationProfileId     : 0,
        streamOnly                : true,
        logo                      : '',
        smallLogo                 : '',
        recordingUrl              : '',
        recordingUser             : '',
        recordPassword            : '',
        recordFormat              : '',
        recordingDirictories      : ''
    }
}

export function getDefaultScorecard() {
    return {
        account_manager: '',
        active: true,
        admin_selected_role: '',
        allowOtherRejectionReason: false,
        allow_other_set: '',
        allow_others: 0,
        appname: '',
        auto_accept_bad_call: false,
        avg_call_length: 0,
        avg_review_time: 0,
        burn_rate_15: 0,
        burn_rate_60: 0,
        cal_spot_user_role: '',
        calib_percent: 0,
        calib_role: '',
        calibration_floor: 0,
        call_turn_time: 0,
        client_selected_role: '',
        configurationProfileName: '',
        cutoff_count: 0,
        cutoff_percent: 0,
        cutoff_percent_avg: 0,
        date_added: '',
        dedupe: false,
        delete_after_days: 0,
        delete_after_timestamp_days: 0,
        description: '',
        dispute_base_percent: 0,
        email_failed: false,
        end_truncate_time: 0,
        fail_score: 0,
        golden_user: '',
        hide_data: '',
        hide_school_data: '',
        high_priority: false,
        id: 0,
        import_agents: 0,
        import_percent: 0,
        import_type: '',
        isCalibrated: false,
        isQAQACard: false,
        keep_daily_calls: false,
        listen_type: '',
        load_rate_15: 0,
        load_rate_60: 0,
        manager_sees_supervisor: false,
        max_call_length: 0,
        max_per_day: 0,
        max_speed: 0,
        meta_sort: '',
        min_cal: 0,
        min_call_length: 0,
        min_transcript_count: 0,
        monthly_minute_cap: 0,
        ni_scorecard: false,
        no_agent_close: 0,
        no_agent_login: false,
        num_cal_check: 0,
        onhold: false,
        overwrite_group: false,
        pass_percent: 0,
        pay_type: '',
        pending_queue: 0,
        post_import_sp: '',
        qa_pay: '',
        qa_qa_scorecard: 0,
        qa_selected_role: '',
        qaqaCardName: '',
        qless_parent: 0,
        recal_percent: 0,
        recalib_role: '',
        redact: false,
        rejectionProfileName: '',
        rejection_profile: 0,
        required_dispositions: '',
        retain_non_used_calls: 0,
        review_type: '',
        sc_notification_profile: 0,
        sc_notification_score: 0,
        sc_profile: 0,
        sc_sort: '',
        score_type: '',
        scorecard_status: false,
        sectionless: false,
        shift_end: '',
        shift_start: '',
        short_name: '',
        show_custom_questions: false,
        tango_calibrated: false,
        tango_team_lead: '',
        team_lead: '',
        third_party_scorecard: false,
        training_count: 0,
        transcribe: false,
        truncate_time: 0,
        user_cant_dispute: 0,
        website_cost: 0,
        website_display: '',
        whisperStatus: false,
        who_added: '',
        working_team: 0,
    }
}

export function getDefaultExport() {
    return {
        exportSettings: {
            id: 0,
            appname: '',
            field: '',
            sp: ''
        },
        customClumns: {
            columnId: 0,
            columnName: '',
            sortable: true
        }
    }
}

export function getDefaultDropdownItem() {
    return {
        id: 0,
        questionId: 0,
        value: '',
        dateAdded: '',
        active: true,
        dateStart: '',
        dateEnd: '',
        itemOrder: 0
    }
}

export function getDefaultFaq() {
    return {
      id: 0,
      questionId: 0,
      questionText: '',
      questionAnswer: '',
      qOrder: 0,
      dateAdded: ''
    }
}

export function getDefaultInstruction() {
    return {
        id: 0,
        questionId: 0,
        questionText: '',
        answertype: '',
        qOrder: 0,
        dateAdded: ''
    }
}

export function getDefaultAnswer() {
    return {
        id: 0,
        questionId: 0,
        answer: '',
        points: 0,
        answerOrder: 0,
        isAutoFail: false,
        autoSelect: false,
        rightAnswer: false,
        commentRequired: false,
        answerActive: true,
        csText: "",
        csId: 0,
        color: '',
    }
}

export function getDefaultComment() {
    return {
        id: 0,
        comment: '',
        answerId: 0,
        questionId: 0,
        answerPoints: 0,
        fixedPos: 0,
        answerOrder: 0,
        active: true,
        csText: '',
        csId: 0,
        answerStatement: ''
    }
}


export function getDefaultTemplateItem() {
    return {
        id: 0,
        optionText: '',
        qID: 0,
        dateAdded: '',
        dateStart: '',
        dateEnd: '',
        optionOrder: 0
    }
}

export function getDefaultCalculatedRuleItem() {
    return {
        id: 0,
        qID: 0,
        description: '',
        active: true,
        questionAnswerId: 0,
        oldQid: null,
        oldId: null
    }
}


export function getCalculatedItem() {
    return {
        id: 0,
        ruleType: '',
        ruleSource: '',
        ruleOperator: '',
        ruleValue: '',
        qcId: 0,
        qID: 0
    }
}

export function getDefaultLinkedRule() {
    return {
        id: 0,
        linkedParentQuestion: 0,
        linkedToQuestion: 0,
        linkedType: '',
        linkedItemId: 0,
        initialyVisible: true
    }
}
