'use strict';

import {getDefaultBadcallsColumns} from '../utils/badcalls'
const initialStateX = {
    '12345': {}
}
const initialState = {
    recordData               : {},
    error                    : null,
    recordLoading            : false,
    recordError              : false,
    activeCallID             : 0,
    generalCommentHighlighted: false,
    transcript               : null,
    transcriptLoading        : false,
    transcriptError          : false,
    record                   : null,
    scorecard                : null,
    reviewPageTableScrollLeft: 0,
    mountingElement          : 'lasoft-mounting-point',
    allowUserCallDocuments   : false,
    userFiles                : null,
    userFilesLoading         : false,
    availableAgentNames      : null,
    activeTread              : null,
    compareMode              : null,
    notificationReasonsLoading : false,
    notificationReasonsError : false,
    notificationReasons      : null,
    openNewTread             : false,
}

export default function reducer(state=initialState, action) {

    switch (action.type) {
        case "SET_AVAILABLE_AGENT_NAMES_TO_CHANGE": {
            return {
                ...state,
                availableAgentNames: action.payload,
            }
        }
        case "SET_ALLOW_USER_CALL_DOCUMENTS": {
            return {
                ...state,
                allowUserCallDocuments: action.payload,
            }
        }
        case "SET_UPLOADED_USER_FILES": {
            return {
                ...state,
                userFiles       : action.payload,
                userFilesLoading: false,
            }
        }
        case "USER_FILES_LOADING": {
            return {
                ...state,
                userFilesLoading: true,
            }
        }
        case "SET_RECORD_ID": {
            return {
                ...state,
                recordId: action.payload,
            }
        }
        case "REVIEW_MOUNTING_ELEMENT": {
            return {
                ...state,
                mountingElement: action.payload
            }
        }
        case "FETCH_RECORD": {
            return {
                ...state,
                recordLoading: true,
                recordError: false
            }
        }
        case "FETCH_RECORD_REJECTED": {
            return {
                ...state,
                recordLoading: false,
                recordError  : action.payload
            }
        }
        case "FETCH_RECORD_FULFILLED": {
            return {
                ...state,
                recordLoading: false,
                recordError  : false,
                recordData   : action.payload,
            }
        }
        case "FETCH_RECORDID_FULFILLED": {
            return {
                ...state,
                record: action.payload
            }
        }
        case "FETCH_SCORECARDID_FULFILLED": {
            return {
                ...state,
                scorecard: action.payload
            }
        }
        case "FETCH_REVIEW_TRANSCRIPTID_FULFILLED": {
            return {
                ...state,
                transcript       : action.payload,
                transcriptLoading: false,
                transcriptError  : false
            }
        }
        case "REVIEW_TRANSCRIPTID_LOADING": {
            return {
                ...state,
                transcriptLoading: action.payload,
                transcriptError  : false
            }
        }
        case "REVIEW_TRANSCRIPTID_ERROR": {
            return {
                ...state,
                transcript       : null,
                transcriptLoading: false,
                transcriptError  : action.payload
            }
        }
        case "CLERK_DATA_UPDATED": {
            return {
                ...state,
                changedClerked: action.payload
            }
        }
        case "RECORD_DOES_NOT_EXIST": {
            return {
                ...state,
                noData: action.payload
            }
        }
        case "GENERAL_COMMENT_HIGHLIGHTED": {
            return {
                ...state,
                generalCommentHighlighted: action.payload
            }
        }
        case "NOT_MAPPED_ERROR": {
            return {...state, fetching: false, error: action.payload}
        }
        case "REVIEW_PAGE_TABLE_SCROLL_LEFT": {
            return {
                ... state,
                reviewPageTableScrollLeft: action.payload
            }
        }
        case "REVIEW_NOTIFICATIONS_SET_ACTIVE_TREAD": {
            return {
                ... state,
                activeTread: action.payload
            }
        }
        case "REVIEW_NOTIFICATIONS_OPEN_NEW_TREAD": {
            return {
                ... state,
                openNewTread: action.payload
            }
        }
        case "REVIEW_PAGE_COMPARE_MODE": {
            return {
                ...state,
                compareMode: action.payload
            }
        }
        case "REVIEW_PAGE_GET_NOTI_REASONS_LOADING": {
            return {
                ...state,
                notificationReasonsLoading: action.payload,
                notificationReasonsError: false
            }
        }
        case "REVIEW_PAGE_GET_NOTI_REASONS": {
            return {
                ...state,
                notificationReasonsLoading: false,
                notificationReasonsError: false,
                notificationReasons: action.payload
            }
        }
        case "REVIEW_PAGE_GET_NOTI_REASONS_ERROR": {
            return {
                ...state,
                notificationReasonsLoading: false,
                notificationReasonsError: action.payload
            }
        }
    }

    return state
}
