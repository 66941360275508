'use strict'

import * as R from 'ramda'

const initialState = {
    dataBy: 'summary', // 'summary', 'calls'
    pagination: {
        pagenum: 1,
        pagerows: 20, // ask Stace how many rows will return API
    },
    searchText: '',
    sortingScorecards: {
        sortBy: 'scorecardName',
        sortOrder: 'asc',
    },
    sortingUsers: {
        sortBy: 'userName',
        sortOrder: 'asc',
    },
    sortingCalls: {
        sortBy: 'callDate',
        sortOrder: 'asc',
    },
    notificationCalls: {
        notifications: [],
        total: 0,
    },
    notificationCallsLoading          : false,
    notificationCallsError            : false,
    notificationCallsTimeoutErr       : false,
    userTabFilter                     : null, // {userid: String, action: "assignedto" || "closedby" || "openedby"}
    notificationCallsWithFilter       : null,
    userTabScorecard                  : null,
    scorecardTabFilter                : null, // {isInternal: true, isDispute: true}
    notificationFlow                  : [],
    notificationFlowLoading           : false,
    notificationFlowError             : false,
    notificationFlowTimeoutErr        : false,
    notificationDisputes              : [],
    notificationDisputesLoading       : false,
    notificationDisputesError         : false,
    notificationDisputesTimeoutErr    : false,
    summary                           : null,
    summaryLoading                    : false,
    summaryError                      : false,
    summaryTimeoutError               : false,
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "NOTIFICATION_SUMMARY_LOADING": {
            return {
                ...state,
                summaryLoading     : true,
                summaryError       : false,
                summaryTimeoutError: false,
            }
        }
        case "NOTIFICATION_SUMMARY_FULFILLED": {
            return {
                ...state,
                summaryLoading: false,
                summary       : action.payload,
            }
        }
        case "NOTIFICATION_SUMMARY_TIMEOUT_ERR": {
            return {
                ...state,
                summaryTimeoutError: true,
            }
        }
        case "NOTIFICATION_SUMMARY_ERROR": {
            return {
                ...state,
                summaryLoading: false,
                summaryError  : true,
            }
        }
        case "SET_NOTIFICATION_DATA_BY": {
            return {
                ...state,
                dataBy: action.payload,
            }
        }
        case "NOTIFICATION_CALLS_FULFILLED": {
            return {
                ...state,
                notificationCalls: action.payload,
                notificationCallsLoading: false,
                notificationCallsError: false,
            }
        }
        case "NOTIFICATION_CALLS_LOADING": {
            return {
                ...state,
                notificationCallsLoading: true,
                notificationCallsError: false,
            }
        }
        case "NOTIFICATION_CALLS_ERROR": {
            return {
                ...state,
                notificationCallsLoading: false,
                notificationCallsError: true,
            }
        }
        case "NOTIFICATION_CALLS_TIMEOUT_ERR": {
            return {
                ...state,
                notificationCallsTimeoutErr: action.payload,
            }
        }
        case "PAGE_CHANGED": {
            return {
                ...state,
                pagination: action.payload
            }
        }
        case "SET_SCORECARDS_SORTING": {
            return {
                ...state,
                sortingScorecards: action.payload
            }
        }
        case "SET_USERS_SORTING": {
            return {
                ...state,
                sortingUsers: action.payload
            }
        }
        case "SET_CALLS_SORTING": {
            return {
                ...state,
                sortingCalls: action.payload
            }
        }
        case "NOTIFICATION_CALLS_WITH_FILTER_FULFILLED": {
            return {
                ...state,
                notificationCallsWithFilter: action.payload,
                notificationCallsLoading: false,
                notificationCallsError: false,
            }
        }
        case "NOTIFICATION_SET_USER_TAB_FILTER": {
            return {
                ...state,
                userTabFilter: action.payload,
            }
        }
        case "NOTIFICATION_SET_USER_TAB_SCORECARD": {
            return {
                ...state,
                userTabScorecard: action.payload,
            }
        }
        case "NOTIFICATION_SET_SCORECARD_TAB_FILTER": {
            return {
                ...state,
                scorecardTabFilter: action.payload,
            }
        }
        case "NOTIFICATION_FLOW_FULFILLED": {
            return {
                ...state,
                notificationFlow: action.payload,
                notificationFlowLoading: false,
                notificationFlowError: false,
            }
        }
        case "NOTIFICATION_FLOW_LOADING": {
            return {
                ...state,
                notificationFlowLoading: true,
                notificationFlowError: false,
            }
        }
        case "NOTIFICATION_FLOW_ERROR": {
            return {
                ...state,
                notificationFlowLoading: false,
                notificationFlowError: true,
            }
        }
        case "NOTIFICATION_FLOW_TIMEOUT_ERR": {
            return {
                ...state,
                notificationFlowTimeoutErr: action.payload,
            }
        }
        case "NOTIFICATION_SET_SEARCH_TEXT": {
            return {
                ...state,
                searchText: action.payload,
            }
        }
        case "NOTIFICATION_DISPUTES_FULFILLED": {
            return {
                ...state,
                notificationDisputes: action.payload,
                notificationDisputesLoading: false,
                notificationDisputesError: false,
            }
        }
        case "NOTIFICATION_DISPUTES_LOADING": {
            return {
                ...state,
                notificationDisputesLoading: true,
                notificationDisputesError: false,
            }
        }
        case "NOTIFICATION_DISPUTES_ERROR": {
            return {
                ...state,
                notificationDisputesLoading: false,
                notificationDisputesError: true,
            }
        }
        case "NOTIFICATION_DISPUTES_TIMEOUT_ERR": {
            return {
                ...state,
                notificationDisputesTimeoutErr: action.payload,
            }
        }
    }
    return state
}
