'use strict'

import { connect } from 'react-redux'
import * as R from 'ramda'

import appConfig from '../../../appConfig'
import playerActions from '../../../actions/playerActions'
import loader from '../../../actions/loaderActions'
import PlayPause from './PlayPause'
import PlayerVolume from './PlayerVolume'
import PlayerSpeed from './PlayerSpeed'
import PlayerTimeline from './PlayerTimeline'
import PlayerDiagram from './PlayerDiagram'

/*
	Player description

	Player uses html tag 'audio' for playing audio
	It is created in method 'createAudio'
	Then in method 'initAudio' we listen two events 'error' and 'canplaythrough'
	If 'error' show error
	Otherwise in 'isUploaded' is called method 'getAudioData'
	Also, trying to play audio automatically if required in method 'play()' is 
	workaround how to do it without user interraction using playPromise
	1. Audio analyze:
		If audio duration is more then 1 hour or some error on fetching or analyzing step -> timeline is shown
		If evetything is all right next sequence is called:
			fetch audio by source and get 'arrayBuffer'
				* https://developer.mozilla.org/en-US/docs/Web/API/Body/arrayBuffer
			create 'OfflineAudioContext' with min sample rate for better performance
				* https://developer.mozilla.org/en-US/docs/Web/API/OfflineAudioContext
			then decode audio data and get audio buffer for context with arrayBuffer data
				* https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/decodeAudioData
			with buffer get Pulse-code modulation from channel data 'getChannelData'
			depending on channels number (mono/stereo)
				* https://en.wikipedia.org/wiki/Pulse-code_modulation
				* https://developer.mozilla.org/en-US/docs/Web/API/AudioBuffer/getChannelData
			analyze channels data (merge both if stereo) and get loudness peak levels array (maxvals)
			then normalize those levels (Loudness normalization)
			If (maxvals) and audio duration is different add broken audio part as (-1) level
	2. Player diagramm:
		From levels data draw diagram with magnifying glass:
			Create one canvas for the diagramm and get 2d context
			Set fill color (fillStyle) and fillRect for bars drawing
				* https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D
			If 'ctrl' key is pressed hover diagramm crate another canvas for magnifying glass
			Use the same approach for bars drawing
			Mouse wheel changes zoom in/out
			Use pointer lock to lock mouse pointer inside magnifying glass square on click 
			or automatically with 'focus'
			We need it to make glass movement depending on zoom in/out coefficient
				* https://developer.mozilla.org/en-US/docs/Web/API/Pointer_Lock_API

*/

@connect((store) => {
	return {
		userData: store.headerReducer.headerData.userData,
		playerAction: store.playerReducer.playerAction,
		audioAnalyzing: store.playerReducer.audioAnalyzing,
		newSrc: store.playerReducer.newSrc || false,
		startPosition: store.playerReducer.startPosition || 0,
		pause: store.playerReducer.pause,
		markers: store.playerReducer.markers
	}
})

export default class Player extends React.Component {
    constructor(props, context) {
        super(props, context);

		this.isUploaded = this.isUploaded.bind(this)
		this.isEnded = this.isEnded.bind(this)
		this.onTimeUpdate = this.onTimeUpdate.bind(this)
		this.triggerPlay = this.triggerPlay.bind(this)
        this.positionChanged = this.positionChanged.bind(this)

        this.positionChanging = this.positionChanging.bind(this)

        this.setPlaybackRate = this.setPlaybackRate.bind(this)
        this.setVolume = this.setVolume.bind(this)
		this.onAudioCantPlay = this.onAudioCantPlay.bind(this)
		this.isFirstTime = true
		this.noPlayer = false

		this.onloadPosition = 0

		const previousPosition = parseInt(window.localStorage.playerCurrentTime, 10) || 0

        this.state = {
			currentRecord: 0,
			playing: props.settings.play,
			position: props.settings.currentTime || props.playerAction.position || previousPosition,
			hidden: true,
			noPlayer: false,
			playerError: false,
			settings: props.settings,
			audiogramData: null,
			silenceLevel: 0.02, //audio part with level <= 0.02 will be skipped
			minGapLength: props.minGapLength || 5 //ask Ryan about default gap length
		}
    }

    componentWillMount() {
        this.props.dispatch(playerActions.initPlayer())
		this.createAudio()
    }

	componentWillUnmount() {
		if (this.playPromise !== undefined) {
			this.playPromise.then(() => {
				this.audio.pause()
				this.audio.remove()
				this.removeEventListeners()
			})
		}
		this.removeEventListeners()
	}

    onKeyPressed = (e) => {
		const settings = R.clone(this.state.settings)
		const {
			duration,
			position
		} = this.state
		let {
			volume,
			speed
		} = settings
		const code = e.keyCode
		switch(code) {
			case 117:
			case 118:
				e.preventDefault()
				e.stopPropagation()
				if ((code === 117 && volume === 0) || (code === 118 && volume === 1)) return
				const newVolume = code === 117
					? volume - 0.05 < 0
					? 0
					: volume - 0.05
					: volume + 0.05 > 1
					? 1
					: volume + 0.05
				volume = Math.round(newVolume * 100) / 100
				settings.volume = volume
				this.setVolume(volume)
				break
			case 120:
			case 121:
				e.preventDefault()
				e.stopPropagation()
				if ((code === 120 && speed < 0.7) || (code === 121 && speed > 3)) return
				const newSpeed = code === 120
				? speed - 0.1 < 0.7
				? 0.7
				: speed - 0.1
				: speed + 0.1 > 3
				? 3
				: speed + 0.1
				speed = Math.round(newSpeed * 100) / 100
				settings.speed = speed
				this.setPlaybackRate(speed)
				break
				case 112:
				case 113:
				case 114:
				case 115:
					e.preventDefault()
					e.stopPropagation()
					const newPosition = code === 114
					? 0
					: code === 115
					? duration - 15
					: position + (code === 112 ? -5 : 5)
					settings.currentTime = newPosition
					this.positionChanging(newPosition)
					this.positionChanged(newPosition, code)
					break
			case 123:
				e.preventDefault()
				e.stopPropagation()
				this.triggerPlay()
				break
			default:
				break
		}
		return e
    }

	createAudio() {
		let previousAudio = document.getElementById('audio-component')
		if (previousAudio) {
			previousAudio.pause()
			document.body.removeChild(previousAudio)
		}
		this.audio = document.createElement('audio')
		this.audio.id = 'audio-component'
		document.body.appendChild(this.audio)
		this.audio.preload = 'auto'
		this.audio.currentTime = this.state.position
		this.audio.muted = false
	}

	getAudioData() {
		const {dispatch, src: audioSrc} = this.props
		const {duration} = this.state
		//check if audio duration is more then 1 hour
		const maxAnalyzeDuration = 60 * 60
		console.log('<===duration:',duration)
		if (duration >= maxAnalyzeDuration) {
			this.setEmptyGapsInfo()
			return
		}
		const src = this.getSRC(audioSrc)
		dispatch(playerActions.audioAnalyzing(true))
		//Read here
		//https://app.flowmapp.com/projects/12153/sitemap/#info
		fetch(src)
			.then(response => response.ok && response.arrayBuffer()) //get data as arrayBuffer
			.then(audioData => {
				let sampleRate = 3000//44100.0; <-3000 is min sample rate, shuold be 3000 for better performance
				let audioCtx = new window.OfflineAudioContext(2, audioData.byteLength, sampleRate)
				audioCtx.decodeAudioData(audioData, (buffer) => {
					const numberOfChannels = buffer.numberOfChannels
					const bufferDuration = buffer.duration
					//Pulse-code modulation
					let pcmdata,
						pcmdataL,
						pcmdataR
					if (numberOfChannels === 1) {
						pcmdata = buffer.getChannelData(0)
					} else {
						pcmdataL = buffer.getChannelData(0)
						pcmdataR = buffer.getChannelData(1)
					}
					const samplerate = buffer.sampleRate
					let maxvals = []
					
					const interval = 0.5 * 1000
					const step = Math.round( samplerate * (interval/1000) )
					let max = 0
					let counter = 0
					const cLength = numberOfChannels === 1 ? pcmdata.length : pcmdataL.length
					for (let index = 0; index <= cLength; index += step) {
						for(var i = index; i < index + step ; i++) {
							const output = numberOfChannels === 1 ? pcmdata[i] : 0.5 * (pcmdataL[i] + pcmdataR[i])
							max = output > max ? parseFloat(output.toFixed(2)) : max
						}
					
						maxvals[counter] = max
						counter++
						max = 0
						index += step
					}
					pcmdata = null
					pcmdataL = null
					pcmdataR = null
					buffer = null
					audioCtx = null
					const maxLevel = R.reduce(R.max, 0, maxvals)
					if (maxLevel < 0.95) {
						//normalize
						maxvals = maxvals.map(l => l/maxLevel)
					}
					if (duration - 1 > bufferDuration) {
						//audio broken
						const brokenDuration = duration - bufferDuration
						for (let a = 0; a < brokenDuration; a++) {
							maxvals.push(-1)
						}
					}
					this.getGapsInfo(maxvals)
					this.setState({
						audiogramData: maxvals
					})
					dispatch(playerActions.audioAnalyzing(false))
				},
				(e) => { 
					console.log('Error with decoding audio data' + e.err)
					this.setEmptyGapsInfo()
				})
			})
			.catch(err => {
				console.error(err)
				this.setEmptyGapsInfo()
			})
	}

	setEmptyGapsInfo = () => {
		const {setAudioGapsInfo, dispatch} = this.props
		if (typeof setAudioGapsInfo === 'function') {
			setAudioGapsInfo([])
		}
		dispatch(playerActions.audioAnalyzing('error'))
	}

	getGapsInfo(maxvals) {
		const {minGapLength, silenceLevel} = this.state
		const {setAudioGapsInfo} = this.props
		if (typeof setAudioGapsInfo !== 'function') return
		const skipTime = minGapLength || 5
		let skipCounter = 0
		let gapsCount = 0
		let totalGapDuration = 0
		let gaps = []
		const length = maxvals.length
		for(let i = 0; i < length; i++) {
			const posLevel = maxvals[i]
			if (posLevel <= silenceLevel) {
				skipCounter++
			} else {
				if (skipCounter >= skipTime) {
					gaps.push({
						gapDuration: skipCounter,
						startTime: i - 1 - skipCounter,
						endTime: i - 1
					})
					totalGapDuration += skipCounter
					skipCounter = 0
					gapsCount++
				} else {
					skipCounter = 0
				}
			}
		}
		const gapInfo = {
			totalGapDuration: totalGapDuration,
			gapsCount: gapsCount,
			gaps: gaps
		}
		setAudioGapsInfo(gapInfo)
	}

    componentDidMount() {
		let src = this.getSRC(this.props.src)
		this.initAudio(src)
		window.document.addEventListener('keydown', this.onKeyPressed, true)
    }

	componentWillReceiveProps(nextProps) {
		if (!R.equals(nextProps.settings, this.props.settings)) {
			this.setState({
				settings: nextProps.settings
			})
		}
		if (nextProps.pause) {
			this.pause()
			this.setState({
				playing: false
			})
			return
		}
		const actionPosition = nextProps.playerAction.position
		const currentTime = parseInt(this.audio.currentTime, 10)
		const positionChanged = ((typeof actionPosition !== 'undefined')
			&& ((this.onloadPosition != actionPosition) && (currentTime != actionPosition)))
		? true : false
		if (positionChanged) {
			this.ended = false
			this.onloadPosition = actionPosition || 0
			this.audio.currentTime = actionPosition || 0
			const pos = parseInt(nextProps.playerAction.position, 10)
			if (this.props.noPlayAfterComment) {
				this.setState({
					position: pos
				})
			} else {
				this.playPromise = this.audio.play()
				this.setState({
					playing: true,
					position: pos
				})
			}
		}
	}

	getSRC(src) {
		return (src.substring(0, 4) !== 'http')
		? appConfig.productionRoot + src
		: src
	}

	removeEventListeners() {
		this.audio.removeEventListener('canplaythrough', this.isUploaded, false)
		this.audio.removeEventListener('timeupdate', this.onTimeUpdate, false)
		this.audio.removeEventListener('ended', this.isEnded, false)
		this.audio.removeEventListener('error', this.onAudioCantPlay, false)
		window.document.removeEventListener("keydown", this.onKeyPressed, true)
	}

    initAudio(src) {
		this.removeEventListeners()
        this.audio.src = src
		if (appConfig.noPlayerRegexp.test(src) || !src) {
			this.noPlayer = true
			this.setState({
				noPlayer: true
			})
			return
		}
		this.audio.addEventListener('error', this.onAudioCantPlay, false)
        this.audio.addEventListener('canplaythrough', this.isUploaded, false)
    }

	onAudioCantPlay(e) {
		this.setState({
			playerError: true,
			hidden: false
		})
	}

    isUploaded(e) {
		const {settings, startPosition, userData} = this.props

		if (this.ended || this.uploaded) return
		this.props.dispatch(loader.run({action: 'Hide'}))
		let playing = this.uploaded ? !this.audio.paused : userData.StartImmediately
		this.setState({
			position: startPosition,
			playing: playing,
			hidden: false,
			duration: this.audio.duration
		})
		this.getAudioData() //if audion file isn't corrupted
		if (this.props.audioStarted) this.props.audioStarted()
		this.props.dispatch({
            type: 'AUDIO_READY',
            payload: true
        })
		this.uploaded = true
		const volume = settings.volume
		this.audio.currentTime = startPosition
		this.audio.volume = settings.mute ? 0 : volume
		this.audio.playbackRate = settings.speed
		this.audio.addEventListener('timeupdate', this.onTimeUpdate, false)
		this.audio.addEventListener('ended', this.isEnded, false)
        if (playing) this.play()
    }

	onTimeUpdate() {
		const currentTime = this.audio.currentTime
        setTimeout(() => {
			window.localStorage.playerCurrentTime = currentTime
		}, 2000)
		this.positionChanged(currentTime)
	}

	getPositionAndSkipSilence(position, code) {
		const {allowGapSkip} = this.props
		const {audiogramData, minGapLength, silenceLevel} = this.state
		let pos = parseInt(position, 10)
		if (this.checkCorruption(pos)) {
			this.stopAudio(pos)
			return pos - 1
		}
		if (!allowGapSkip || !audiogramData) return pos
		let skipCounter = 0
		const skipTime = minGapLength || 5
		const length = audiogramData.length
		if (code === 112) {
			for(let i = pos; i >= 0; i--) {
				const posLevel = audiogramData[i]
				if (posLevel <= silenceLevel) {
					skipCounter--
				} else {
					break
				}
			}
			pos += skipCounter
			this.audio.currentTime = pos
		} else {
			for(let i = pos; i < length; i++) {
				const posLevel = audiogramData[i]
				if (posLevel <= silenceLevel) {
					skipCounter++
				} else {
					break
				}
			}
			if (skipCounter >= skipTime) {
				pos += skipCounter
				this.audio.currentTime = pos
			}
		}
		if (this.checkCorruption(pos)) {
			this.stopAudio(pos)
			return pos
		} else {
			return pos
		}
	}

	checkCorruption(pos) {
		const {audiogramData} = this.state
		if (!audiogramData) return false
		const level = audiogramData[pos + 1]
		return level === -1
	}

	stopAudio(pos) {
		this.pause()
		// this.audio.currentTime = pos
		this.props.dispatch(playerActions.pause(true))
	}

	positionChanged(position, code) {
		const {playing} = this.state
		const pos = this.getPositionAndSkipSilence(position, code)
		const {playerAction} = this.props

        this.setState({
            position: pos
		})
		if (pos !== playerAction.position) {
			//Little tweak
			//because player architecture isn't proper
			playerAction.playing = playing
			playerAction.position = pos
			this.onloadPosition = pos
		}
	}

    positionChanging(position) {
		if (this.checkCorruption(position + 1)) {
			this.stopAudio(position)
		} else {
			this.audio.currentTime = position
		}
    }

	isEnded() {
		if (this.ended) return
		this.ended = true
		this.setState({
			playing: false
		})
	}

    play() {
		if (this.ended || !this.audio) return
		if (this.props.pause) this.props.dispatch(playerActions.pause(false))
		let waitTime = 150

		setTimeout(function() {
			const isPlaying = this.audio.currentTime > 0 &&
			!this.audio.paused &&
			!this.audio.ended &&
			this.audio.readyState > 2
			if (this.audio.src && !isPlaying) {
				// this.playPromise = this.audio.play()
				// this.playPromise.catch(() => {
                //     this.setState({
                //         playing: false
                //     })
				// })
				this.audio.pause()
				this.playPromise = this.audio.play()
				if (this.playPromise !== undefined) {
					this.playPromise.then(_ => {
						this.setState({
							playing: true,
							hidden: false,
							duration: this.audio.duration,
						})
					}).catch(err => {
						this.setState({
							playing: false,
							hidden: false,
							duration: this.audio.duration
						})
					})
				} else {
					this.setState({
						playing: false,
						hidden: false,
						duration: this.audio.duration
					})
				}
			}
		}.bind(this), waitTime)
    }

    pause() {
		if (this.playPromise !== undefined) {
			this.playPromise.then(() => {
				this.audio.pause()
			})
		}
        // this.audio.pause()
    }

    setVolume(vol) {
		const settings = R.clone(this.state.settings)
		settings.volume = vol
		this.audio.volume = vol
		this.setState({
			settings: settings
		})
	}
	
	setPlaybackRate(speed) {
		const settings = R.clone(this.state.settings)
		settings.speed = speed
		this.audio.playbackRate = speed
		this.setState({
			settings: settings
		})
    }

	triggerPlay() {
		this.ended = false
		this.audio.paused ? this.play() : this.pause()
		this.setState({
			playing: !this.state.playing
		})
	}

    render() {
		const {
			hidden: loading,
			playerError: error,
			playing,
			duration,
			position,
			settings,
			audiogramData
		} = this.state
		const {markers} = this.props
		const {audioAnalyzing: analyzing} = this.props
        return (
            loading ?
            <div class='player-loading'>
                <div class='player-loader-inner'></div>
                <div class='player-loader-outer'></div>
            </div> :
            <div class='light-audio-player-wrapper'>
				{loading ? <div class='player-disabled' /> : null}
				<div class='audio-player'>
	                <PlayPause
						error={error}
						triggerPlay={this.triggerPlay}
						playing={playing} />
					<div class='volume-block'>
						<PlayerVolume
							error={error}
							settings={settings}
							setVolume={this.setVolume} />
						<PlayerSpeed
							error={error}
							settings={settings}
							setPlaybackRate={this.setPlaybackRate} />
					</div>
					{analyzing ? 
						<PlayerTimeline
							analyzing={analyzing}
							audio={this.audio}
							error={error}
							duration={duration}
							positionChanged={this.positionChanging}
							markers={markers}
							position={position} /> 
						:
						<PlayerDiagram
							audiogramData={audiogramData}
							analyzing={analyzing}
							error={error}
							duration={duration}
							playing={playing}
							positionChanged={this.positionChanging}
							markers={markers}
							position={position} />
					}
					<div class='key-tooltip'>
                        <i class="material-icons">
                        keyboard
                        </i>
                        <div class='key-info-tooltip'>
                            <div class='title'>Hotkeys</div>
                            <div class='line'>
                                <div class='info'>Backward 5 secs</div>
                                <div class='key'>F1</div>
                            </div>
                            <div class='line'>
                                <div class='info'>
                                Forward 5 secs</div>
                                <div class='key'>F2</div>
                            </div>
                            <div class='line'>
                                <div class='info'>Go to start</div>
                                <div class='key'>F3</div>
                            </div>
                            <div class='line'>
                                <div class='info'>Go to last 15 secs</div>
                                <div class='key'>F4</div>
                            </div>
                            <div class='line'>
                                <div class='info'>Volume down (5 points)</div>
                                <div class='key'>F6</div>
                            </div>
                            <div class='line'>
                                <div class='info'>Volume up (5 points)</div>
                                <div class='key'>F7</div>
                            </div>
                            <div class='line'>
                                <div class='info'>Speed down (5 points)</div>
                                <div class='key'>F9</div>
                            </div>
                            <div class='line'>
                                <div class='info'>Speed up (5 points)</div>
                                <div class='key'>F10</div>
                            </div>
                            <div class='line'>
                                <div class='info'>Play/pause</div>
                                <div class='key'>F12</div>
                            </div>
							{!analyzing && <>
								<div class='line'>
									<div class='info'>Magnifying glass</div>
									<div class='key'>Ctrl + hover</div>
								</div>
								<div class='line'>
									<div class='info'>Zoom (In/Out)</div>
									<div class='key'>Ctrl + Wheel</div>
								</div>
								<div class='line'>
									<div class='info'>Select marker (glass)</div>
									<div class='key'>Right Click</div>
								</div>
							</>}
                        </div>
                    </div>
				</div>
            </div>
        )
    }
}
