'use strict'

const initialState = {
    isTour: false,
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case 'SET_PAGE_TOUR_STATUS': {
            return {
                ...state,
                isTour: action.payload,
            }
        }
    }
    return state
}
