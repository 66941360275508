'use strict';

import * as R from 'ramda'

import {
    getDefaultColumns,
    getDefaultModules,
    getDefaultCoachingColumns} from '../utils/dashboard'

const statObj = {
    avgAgentScore:        0,
    totalAgents:          0,
    totalBadCalls:        0,
    totalCalls:           0,
    totalCallsSeconds:    0,
    totalFailedCalls:     0,
}

const initialState = {
    exportQueueType:                    null,
    availableColumnsUpdated:            false,
    userSettingsUpdated:                false,
    withRouter:                         false,
    alreadyVisited:                     false,
    recordsLoaded:                      [],
    activeRecord:                       null,
    isCallDetailsOnly:                  false,
    pagination:                         {
                                            pagenum: 1,
                                            pagerows: 20
                                        },
    stats:                              {
                                            rangeStats: R.clone(statObj),
                                            comparisonStats: R.clone(statObj)
                                        },
    averages:                           {
                                            average: 0,
                                            increment: 0
                                        },
    scorePerformance:                   {
                                            rangeTotal: [],
                                            rangeFiltered: null,
                                            comparisonTotal: null,
                                        },
    coachingQueue:                      {
                                            calls: [],
                                            totalNotifications: 0
                                        },
    topMissed:                          [],
    topQAMissed:                        [],
    topCalibratorMissed:                [],
    agentRanking:                       [],
    groupPerformance:                   [],
    campaignPerformance:                [],
    agents:                             [],
    groups:                             [],
    campaigns:                          [],
    scorecards:                         [],
    callsLeft:                          [],
    myPay:                              null,
    websiteStats:                       null,
    sectionScores:                      [],
    calibrationSectionScores:           [],
    calibrationSectionScoresLoading:    true,
    calibrationSectionScoresError:      false,
    calibrationSectionScoresTimeoutError: false,
    statsLoading:                       true,
    topMissedLoading:                   true,
    topQAMissedLoading:                 true,
    topCalibratorMissedLoading:         true,
    agentRankingLoading:                true,
    scoreLoading:                       true, // start with "true" here
    coachingQueueLoading:               true,
    groupPerformanceLoading:            true,
    campaignPerformanceLoading:         true,
    statsLoadingError:                  false,
    topMissedLoadingError:              false,
    topQAMissedLoadingError:            false,
    topCalibratorMissedLoadingError:    false,
    agentRankingLoadingError:           false,
    scoreLoadingError:                  false,
    coachingQueueLoadingError:          false,
    groupPerformanceLoadingError:       false,
    campaignPerformanceLoadingError:    false,
    callDetailsLoading:                 true,
    callDetailsInfiniteLoading:         false,
    callDetailsLoadingError:            false,
    callsLeftLoading:                   true,
    callsLeftError:                     false,
    myPayLoading:                       true,
    myPayError:                         false,
    websiteStatsLoading:                true,
    websiteStatsError:                  false,
    sectionScoresLoading:               true,
    sectionScoresError:                 false,
    pendingCalibration:                 [],
    pendingCalibrationLoading:          true,
    pendingCalibrationError:            false,
    pendingCalibrationTimeoutError:     false,
    pendingCalibrationInt:              [],
    pendingCalibrationIntLoading:       true,
    pendingCalibrationIntError:         false,
    pendingCalibrationIntTimeoutError:  false,
    calls: [],
    tableColumns:                       getDefaultColumns(),
    coachingTableColumns:               getDefaultCoachingColumns(),
    activeCall:                         false,
    loadedCalls:                        {},
    miniReviewTab:                      'details',
    callDetailsSearchText:              '',
    sorting:                            {
                                            sortBy:    'callDate',
                                            sortOrder: 'desc',
                                        },
    topMissedSorting:                   {
                                            sortBy:    'occurrence',
                                            sortOrder: 'asc',
                                        },
    topQAMissedSorting:                 {
                                            sortBy:    'occurrence',
                                            sortOrder: 'asc',
                                        },
    topCalibratorMissedSorting:         {
                                            sortBy:    'occurrence',
                                            sortOrder: 'asc',
                                        },
    agentRankingSorting:                {
                                            sortBy:    'score',
                                            sortOrder: 'desc',
                                        },
    coachingQueueSorting:               {
                                            sortBy:    'score',
                                            sortOrder: 'desc',
                                        },
    groupPerformanceSorting:            {
                                            sortBy:    'score',
                                            sortOrder: 'desc',
                                        },
    campaignPerformanceSorting:         {
                                            sortBy:    'score',
                                            sortOrder: 'desc',
                                        },
    callsLeftSorting:                   {
                                            sortBy:    'score',
                                            sortOrder: 'asc',
                                        },
    scorecardDetailedInfoSorting:       {
                                            sortBy:    'scorecard',
                                            sortOrder: 'asc',
                                        },
    shortCallDetailsError:              false,
    shortCallDetailsLoading:            false,
    searching:                          false,
    searchingError:                     false,
    modules:                            null, //getDefaultModules(),
    //modules timeout errors
    myPayTimeoutError:                  false,
    websiteStatsTimeoutError:           false,
    callsLeftTimeoutError:              false,
    coachingQueueTimeoutError:          false,
    statsTimeoutError:                  false,
    scorePerfTimeoutError:              false,
    topMissedTimeoutError:              false,
    topQAMissedTimeoutError:            false,
    topCalibratorMissedTimeoutError:    false,
    agentRankingTimeoutError:           false,
    groupPerfTimeoutError:              false,
    campaignPerfTimeoutError:           false,
    callDetailsTimeoutError:            false,
    sectionScoresTimeoutError:          false,
    activeQuestion:                     false,
    activeQuestionDetails:              false,
    questions:                          [],
    questionDetailsLoading:             false,
    questionDetailsError:               false,
    questionDetailsTimeoutError:        false,
    exportingWebsiteStats:              false,
    exportingCallDetails:               false,
    exportingAgentRanking:              false,
    exportingTopMissed:                 false,
    exportingTopQaMissed:               false,
    exportingTopCalibratorMissed:       false,
    exportingAgentRanking:              false,
    exportingCoachingQueue:             false,
    exportedCallDetails:                null,
    exportedCallDetailesLoading:        false,
    exportedCallDetailesError:          false,
    exportingGroupPerformance:          false,
    exportingCampaignPerformance:       false,
    exportingCallsLeft:                 false,
    exportingSectionScores:             false,
    exportingCalibrationSectionScores:  false,
    exportingScorecardSummary:          false,
    calibratedOnly:                     false,
    recalibratedOnly:                   false,
    recordReviews:                      [], //[{recordId: 123}, {recordId: 456}],
    activeDashboardTab:                 null,
    avgCallTime:                        [],
    avgCallTimeLoading:                 true,
    avgCallTimeError:                   false,
    avgCallTimeTimeoutError:            false,
    scorecardDetailedInfo:              null,
    scorecardDetailedInfoLoading:       true,
    scorecardDetailedInfoError:         false,
    scorecardDetailedInfoTimeoutError:  false,
    customColumnsQQ:                    [],
    customColumnsQQLoading:             false,
    customColumnsQQError:               false,
    callDetailsPage:                    false,
    activeTread:                        null,
    notificationReasonsLoading:         false,
    notificationReasonsError:           false,
    notificationReasons:                null,
    openNewTread :                      false,
}

export default function reducer(state=initialState, action) {

    switch (action.type) {
        case "SET_CALL_DETAILS_PAGE": {
            return {
                ...state,
                callDetailsPage: true,
            }
        }
        case "USER_SETTINGS_UPDATED": {
            return {
                ...state,
                userSettingsUpdated: action.payload,
            }
        }
        case "AVAILABLE_COLUMNS_UPDATED": {
            return {
                ...state,
                availableColumnsUpdated: action.payload,
            }
        }
        case "LASOFT_WITH_ROUTER": {
            return {
                ...state,
                withRouter: true,
            }
        }
        case "DASHBOARD_PAGE_VISITED": {
            return {
                ...state,
                alreadyVisited: action.payload,
            }
        }
        case "RECORD_REVIEWS_UPDATED": {
            return {
                ...state,
                recordReviews: action.payload,
            }
        }
        case "SET_ACTIVE_DASHBOARD_TAB": {
            return {
                ...state,
                activeDashboardTab: action.payload,
            }
        }
        case "SET_CALL_DETAILS_ONLY_PROPERTY": {
            return {
                ...state,
                isCallDetailsOnly: action.payload
            }
        }
        case "GET_WEBSITE_STATS_FULFILLED": {
            return {
                ...state,
                websiteStats: action.payload,
                websiteStatsLoading: false,
                websiteStatsError: false,
                websiteStatsTimeoutError: false
            }
        }
        case "WEBSITE_STATS_LOADING": {
            return {
                ...state,
                websiteStatsError: false,
                websiteStatsLoading: action.payload,
                websiteStatsTimeoutError: false
            }
        }
        case "WEBSITE_STATS_ERROR": {
            return {
                ...state,
                websiteStatsLoading: false,
                websiteStatsError: action.payload
            }
        }
        case "GET_SECTION_SCORES_FULFILLED": {
            return {
                ...state,
                sectionScores: action.payload,
                sectionScoresLoading: false,
                sectionScoresError: false
            }
        }
        case "SECTION_SCORES_LOADING": {
            return {
                ...state,
                sectionScoresError: false,
                sectionScoresLoading: action.payload
            }
        }
        case "SECTION_SCORES_ERROR": {
            return {
                ...state,
                sectionScoresLoading: false,
                sectionScoresError: action.payload
            }
        }
        case "GET_CALIBRATION_SECTION_SCORES_FULFILLED": {
            return {
                ...state,
                calibrationSectionScores: action.payload,
                calibrationSectionScoresLoading: false,
                calibrationSectionScoresError: false
            }
        }
        case "CALIBRATION_SECTION_SCORES_LOADING": {
            return {
                ...state,
                calibrationSectionScoresError: false,
                calibrationSectionScoresLoading: action.payload
            }
        }
        case "CALIBRATION_SECTION_SCORES_ERROR": {
            return {
                ...state,
                calibrationSectionScoresLoading: false,
                calibrationSectionScoresError: action.payload
            }
        }
        case "MYPAY_LOADING": {
            return {
                ...state,
                myPayError: false,
                myPayLoading: action.payload
            }
        }
        case "MYPAY_ERROR": {
            return {
                ...state,
                myPayLoading: false,
                myPayError: action.payload
            }
        }
        case "GET_MYPAY_FULFILLED": {
            return {
                ...state,
                myPay: action.payload,
                myPayLoading: false,
                myPayError: false
            }
        }
      case "GET_TOP_MISSED_FULFILLED": {
          return {
              ...state,
              topMissed: action.payload,
              topMissedLoading: false,
              topMissedLoadingError: false
          }
      }
      case "TOP_MISSED_LOADING": {
          return {
              ...state,
              topMissedLoadingError: false,
              topMissedLoading: action.payload
          }
      }
      case "TOP_MISSED_LOADING_ERROR": {
          return {
              ...state,
              topMissedLoading: false,
              topMissedLoadingError: action.payload
          }
      }
      case "GET_TOP_QA_MISSED_FULFILLED": {
          return {
              ...state,
              topQAMissed: action.payload,
              topQAMissedLoading: false,
              topQAMissedLoadingError: false
          }
      }
      case "TOP_QA_MISSED_LOADING": {
          return {
              ...state,
              topQAMissedLoadingError: false,
              topQAMissedLoading: action.payload
          }
      }
      case "TOP_QA_MISSED_LOADING_ERROR": {
          return {
              ...state,
              topQAMissedLoading: false,
              topQAMissedLoadingError: action.payload
          }
      }
      case "GET_TOP_CALIBRATOR_MISSED_FULFILLED": {
          return {
              ...state,
              topCalibratorMissed: action.payload,
              topCalibratorMissedLoading: false,
              topCalibratorMissedLoadingError: false
          }
      }
      case "TOP_CALIBRATOR_MISSED_LOADING": {
          return {
              ...state,
              topCalibratorMissedLoadingError: false,
              topCalibratorMissedLoading: action.payload
          }
      }
      case "TOP_CALIBRATOR_MISSED_LOADING_ERROR": {
          return {
              ...state,
              topCalibratorMissedLoading: false,
              topCalibratorMissedLoadingError: action.payload
          }
      }
      case "GET_AGENT_RANKING_FULFILLED": {
          return {
              ...state,
              agentRanking: action.payload,
              agentRankingLoading: false,
              agentRankingLoadingError: false
          }
      }
      case "AGENT_RANKING_LOADING": {
          return {
              ...state,
              agentRankingLoadingError: false,
              agentRankingLoading: action.payload
          }
      }
      case "AGENT_RANKING_LOADING_ERROR": {
          return {
              ...state,
              agentRankingLoading: false,
              agentRankingLoadingError: action.payload
          }
      }
      case "GET_STATS_FULFILLED": {
          return {
              ...state,
              stats: action.payload,
              statsLoading: false,
              statsLoadingError: false
          }
      }
      case "STATS_LOADING": {
          return {
              ...state,
              statsLoadingError: false,
              statsLoading: action.payload
          }
      }
      case "STATS_LOADING_ERROR": {
          return {
              ...state,
              statsLoading: false,
              statsLoadingError: action.payload
          }
      }
      case "GET_SCORE_PERF_FULFILLED": {
          return {
            ...state,
            scorePerformance: action.payload,
            scoreLoading: false,
            scoreLoadingError: false
          }
      }
      case "SCORE_LOADING": {
          return {
              ...state,
              scoreLoadingError: false,
              scoreLoading: action.payload
          }
      }
      case "SCORE_LOADING_ERROR": {
          return {
              ...state,
              scoreLoading: false,
              scoreLoadingError: action.payload
          }
      }
      case "GET_COACHING_QUEUE_FULFILLED": {
          return {
              ...state,
              coachingQueue: action.payload,
              coachingQueueLoading: false,
              coachingQueueLoadingError: false
          }
      }
      case "COACHING_QUEUE_LOADING": {
          return {
              ...state,
              coachingQueueLoadingError: false,
              coachingQueueLoading: action.payload
          }
      }
      case "COACHING_QUEUE_LOADING_ERROR": {
          return {
              ...state,
              coachingQueueLoading: false,
              coachingQueueLoadingError: action.payload
          }
      }
      case "GET_GROUP_PERFORMANCE_FULFILLED": {
          return {
              ...state,
              groupPerformance: action.payload,
              groupPerformanceLoadingError: false,
              groupPerformanceLoading: false
          }
      }
      case "GROUP_PERFORMANCE_LOADING": {
          return {
              ...state,
              groupPerformanceLoadingError: false,
              groupPerformanceLoading: action.payload
          }
      }
      case "GROUP_PERFORMANCE_LOADING_ERROR": {
          return {
              ...state,
              groupPerformanceLoading: false,
              groupPerformanceLoadingError: action.payload
          }
      }
      case "GET_CAMPAIGN_PERFORMANCE_FULFILLED": {
          return {
              ...state,
              campaignPerformance: action.payload,
              campaignPerformanceLoadingError: false,
              campaignPerformanceLoading: false
          }
      }
       case "CAMPAIGN_PERFORMANCE_LOADING": {
          return {
              ...state,
              campaignPerformanceLoadingError: false,
              campaignPerformanceLoading: action.payload
          }
      }
      case "CAMPAIGN_PERFORMANCE_LOADING_ERROR": {
          return {
              ...state,
              campaignPerformanceLoading: false,
              campaignPerformanceLoadingError: action.payload
          }
      }
      case "GET_CALL_DETAILS_FULFILLED" : {
          return {
              ...state,
              calls: action.payload,
              callDetailsLoading: false,
              callDetailsInfiniteLoading: false,
              callDetailsLoadingError: false
          }
      }
      case "GET_CALL_DETAILS_FULFILLED_INFINITE" : {
          return {
              ...state,
              calls: [...state.calls, ...action.payload],
              callDetailsLoading: false,
              callDetailsInfiniteLoading: false,
              callDetailsLoadingError: false
          }
      }
      case "CALL_DETAILS_LOADING": {
          return {
              ...state,
              callDetailsLoadingError: false,
              callDetailsLoading: action.payload
          }
      }
      case "CALL_DETAILS_INFINITE_LOADING": {
        return {
            ...state,
            callDetailsLoadingError: false,
            callDetailsInfiniteLoading: action.payload
        }
    }
      case "CALL_DETAILS_LOADING_ERROR": {
          return {
              ...state,
              callDetailsLoading: false,
              callDetailsInfiniteLoading: false,
              callDetailsLoadingError: action.payload
          }
      }
      case "TABLE_COLUMNS_CHANGED": {
          return {
              ...state,
              tableColumns: action.payload
          }
      }
      case "COACHING_COLUMNS_CHANGED": {
          return {
              ...state,
              coachingTableColumns: action.payload
          }
      }
      case "PAGE_CHANGED": {
          return {
              ...state,
              pagination: action.payload
          }
      }
      case "SET_ACTIVE_CALL_REVIEW": {
          return {
              ...state,
              activeCall: action.payload
          }
      }
      case "SHORT_CALL_DETAILS": {
          return {
              ...state,
              shortCallDetails: action.payload
          }
      }
      case "SHORT_CALL_DETAILS_ERROR": {
          return {
              ...state,
              shortCallDetailsError: action.payload
          }
      }
      case "SHORT_CALL_DETAILS_LOADING": {
          return {
              ...state,
              shortCallDetailsLoading: action.payload
          }
      }
      case "SET_MINI_REVIEW_ACTIVE_TAB": {
          return {
              ...state,
              miniReviewTab: action.payload
          }
      }
      case "SET_TABLE_SORTING": {
          return {
              ...state,
              sorting: action.payload
          }
      }
      case "SET_SCORECARD_COUNTS_SORTING": {
          return {
              ...state,
              scorecardDetailedInfoSorting: action.payload,
          }
      }
      case "SET_TOP_MISSED_SORTING": {
          return {
              ...state,
              topMissedSorting: action.payload
          }
      }
      case "SET_TOP_QA_MISSED_SORTING": {
          return {
              ...state,
              topQAMissedSorting: action.payload
          }
      }
      case "SET_TOP_CALIBRATOR_MISSED_SORTING": {
          return {
              ...state,
              topCalibratorMissedSorting: action.payload
          }
      }
      case "SET_AGENT_RANKING_SORTING": {
          return {
              ...state,
              agentRankingSorting: action.payload
          }
      }
      case "SET_COACHING_QUEUE_SORTING": {
          return {
              ...state,
              coachingQueueSorting: action.payload
          }
      }
      case "SET_PERFORMANCE_SORTING": {
          return {
              ...state,
              groupPerformanceSorting: action.payload
          }
      }
      case "SET_CAMPAING_SORTING": {
          return {
              ...state,
              campaignPerformanceSorting: action.payload
          }
      }
      case "SET_CALL_DETAILS_SEARCH_TEXT": {
          return {
              ...state,
              callDetailsSearchText: action.payload
          }
      }
      case "CALL_DETAILS_SEARCHING": {
          return {
              ...state,
              searching: action.payload
          }
      }
      case "CALL_DETAILS_SEARCHING_ERROR": {
          return {
              ...state,
              searchingError: action.payload
          }
      }
      case "SET_DASHBOARD_MODULES_SETTINGS": {
          return {
              ...state,
              modules: action.payload
          }
      }
      case "SET_CALLS_LEFT_SORTING": {
          return {
              ...state,
              callsLeftSorting: action.payload
          }
      }
      case "GET_CALLS_LEFT_FULFILLED": {
          return {
              ...state,
              callsLeft: action.payload,
              callsLeftLoading: false,
              callsLeftError: false
          }
      }
      case "CALLS_LEFT_LOADING": {
          return {
              ...state,
              callsLeftError: false,
              callsLeftLoading: action.payload
          }
      }
      case "CALLS_LEFT_ERROR": {
          return {
              ...state,
              callsLeftLoading: false,
              callsLeftError: action.payload
          }
      }
      case "MYPAY_TIMEOUT_ERR": {
          return {
              ...state,
              myPayTimeoutError: action.payload
          }
      }
      case "WEBSITE_STATS_TIMEOUT_ERR": {
          return {
              ...state,
              websiteStatsTimeoutError: action.payload
          }
      }
      case "CALLS_LEFT_TIMEOUT_ERR": {
          return {
              ...state,
              callsLeftTimeoutError: action.payload
          }
      }
      case "COACHING_QUEUE_TIMEOUT_ERR": {
          return {
              ...state,
              coachingQueueTimeoutError: action.payload
          }
      }
      case "STATS_TIMEOUT_ERR": {
          return {
              ...state,
              statsTimeoutError: action.payload
          }
      }
      case "SCORE_PERF_TIMEOUT_ERR": {
          return {
              ...state,
              scorePerfTimeoutError: action.payload
          }
      }
      case "TOP_MISSED_TIMEOUT_ERR": {
          return {
              ...state,
              topMissedTimeoutError: action.payload
          }
      }
      case "TOP_QA_MISSED_TIMEOUT_ERR": {
          return {
              ...state,
              topQAMissedTimeoutError: action.payload
          }
      }
      case "TOP_CALIBRATOR_MISSED_TIMEOUT_ERR": {
          return {
              ...state,
              topCalibratorMissedTimeoutError: action.payload
          }
      }
      case "AGENT_RANKING_TIMEOUT_ERR": {
          return {
              ...state,
              agentRankingTimeoutError: action.payload
          }
      }
      case "GROUP_PERF_TIMEOUT_ERR": {
          return {
              ...state,
              groupPerfTimeoutError: action.payload
          }
      }
      case "CAMPAIGN_PERFORMANCE_TIMEOUT_ERR": {
          return {
              ...state,
              campaignPerfTimeoutError: action.payload
          }
      }
      case "CALL_DETAILS_TIMEOUT_ERR": {
          return {
              ...state,
              callDetailsTimeoutError: action.payload
          }
      }
      case "SECTION_SCORES_TIMEOUT_ERR": {
          return {
              ...state,
              sectionScoresTimeoutError: action.payload
          }
      }
      case "CALIBRATION_SECTION_SCORES_TIMEOUT_ERR": {
          return {
              ...state,
              calibrationSectionScoresTimeoutError: action.payload
          }
      }
      case "SET_ACTIVE_QUESTION": {
          return {
              ...state,
              activeQuestion: action.payload
          }
      }
      case "SET_ACTIVE_QUESTION_DETAILS": {
          return {
              ...state,
              activeQuestionDetails: action.payload,
              questionDetailsLoading: false,
              questionDetailsError: false
          }
      }
      case "QUESTION_DETAILS_LOADING": {
          return {
              ...state,
              questionDetailsError: false,
              questionDetailsLoading: action.payload
          }
      }
      case "QUESTION_DETAILS_ERROR": {
          return {
              ...state,
              questionDetailsLoading: false,
              questionDetailsError: action.payload
          }
      }
      case "QUESTION_DETAILS_TIMEOUT_ERR": {
          return {
              ...state,
              questionDetailsTimeoutError: action.payload
          }
      }
      case "EXPORT_CALL_DETAILS": {
          return {
              ...state,
              exportingCallDetails: action.payload
          }
      }
      case "EXPORT_AGENT_RANKING": {
          return {
              ...state,
              exportingAgentRanking: action.payload
          }
      }
      case "EXPORT_WEBSITE_STATS": {
          return {
              ...state,
              exportingWebsiteStats: action.payload
          }
      }
      case "EXPORT_GROUP_PERFORMANCE": {
          return {
              ...state,
              exportingGroupPerformance: action.payload
          }
      }
      case "EXPORT_CALLS_LEFT": {
          return {
              ...state,
              exportingCallsLeft: action.payload
          }
      }
      case "EXPORT_CAMPAIGN_PERFORMANCE": {
          return {
              ...state,
              exportingCampaignPerformance: action.payload
          }
      }
      case "EXPORT_TOP_MISSED": {
          return {
              ...state,
              exportingTopMissed: action.payload
          }
      }
      case "EXPORT_TOP_CALIBRATOR_MISSED": {
          return {
              ...state,
              exportingTopQaMissed: action.payload
          }
      }
      case "EXPORT_TOP_QA_MISSED": {
          return {
              ...state,
              exportingTopCalibratorMissed: action.payload
          }
      }
      case "EXPORT_MY_PAY": {
          return {
              ...state,
              exportingAgentRanking: action.payload
          }
      }
      case "EXPORT_COACHING_QUEUE": {
          return {
              ...state,
              exportingCoachingQueue: action.payload
          }
      }
      case "EXPORTING_SECTION_SCORES": {
          return {
            ...state,
            exportingSectionScores: action.payload
          }
      }
      case "EXPORTING_CALIBRATION_SECTION_SCORES": {
          return {
            ...state,
            exportingCalibrationSectionScores: action.payload
          }
      }
      case "EXPORTING_SCORECARD_SUMMARY": {
          return {
            ...state,
            exportingScorecardSummary: action.payload
          }
      }
      case "EXPORT_QUEUE_LOADING": {
          return {
              ...state,
              exportedCallDetailesError: false,
              exportedCallDetailesLoading: action.payload
          }
      }
      case "EXPORT_QUEUE_FULFILLED": {
          return {
              ...state,
              exportedCallDetails: action.payload,
              exportedCallDetailesLoading: false,
              exportedCallDetailesError: false
          }
      }
      case "EXPORT_QUEUE_ERROR" : {
          return {
              ...state,
              exportedCallDetailesLoading: false,
              exportedCallDetailesError: action.payload
          }
      }
      case "CALIBRATED_ONLY_CALLS" : {
          return {
              ...state,
              calibratedOnly: action.payload,
              recalibratedOnly: false,
          }
      }
      case "RECALIBRATED_ONLY_CALLS" : {
          return {
              ...state,
              recalibratedOnly: action.payload,
              calibratedOnly: false,
          }
      }
      case "GET_PENDING_CALIBRATION": {
          return {
              ...state,
              pendingCalibration: action.payload,
              pendingCalibrationLoading: false,
              pendingCalibrationError: false
          }
      }
      case "PENDING_CALIBRATION_LOADING": {
          return {
              ...state,
              pendingCalibrationError: false,
              pendingCalibrationLoading: action.payload
          }
      }
      case "PENDING_CALIBRATION_ERROR": {
          return {
              ...state,
              pendingCalibrationLoading: false,
              pendingCalibrationError: action.payload
          }
      }
      case "PENDING_CALIBRATION_TIMEOUT_ERR": {
          return {
              ...state,
              pendingCalibrationTimeoutError: action.payload
          }
      }
      case "PENDING_CALI_INT": {
          return {
              ...state,
              pendingCalibrationInt: action.payload,
              pendingCalibrationIntLoading: false,
              pendingCalibrationIntError: false
          }
      }
      case "PENDING_CALI_INT_LOADING": {
          return {
              ...state,
              pendingCalibrationIntError: false,
              pendingCalibrationIntLoading: action.payload
          }
      }
      case "PENDING_CALI_INT_ERROR": {
          return {
              ...state,
              pendingCalibrationIntLoading: false,
              pendingCalibrationIntError: action.payload
          }
      }
      case "PENDING_CALI_INT_TIMEOUT_ERR": {
          return {
              ...state,
              pendingCalibrationIntTimeoutError: action.payload
          }
      }
      case "AVG_CALL_TIME": {
          return {
              ...state,
              avgCallTime: action.payload,
              avgCallTimeLoading: false,
              avgCallTimeError: false
          }
      }
      case "AVG_CALL_TIME_LOADING": {
          return {
              ...state,
              avgCallTimeError: false,
              avgCallTimeLoading: action.payload
          }
      }
      case "AVG_CALL_TIME_ERROR": {
          return {
              ...state,
              avgCallTimeLoading: false,
              avgCallTimeError: action.payload
          }
      }
      case "AVG_CALL_TIME_TIMEOUT_ERR": {
          return {
              ...state,
              avgCallTimeTimeoutError: action.payload
          }
      }
      case "SCORECARD_DETAILED_INFO_FULFILLED": {
          return {
              ...state,
              scorecardDetailedInfo: action.payload,
              scorecardDetailedInfoLoading: false,
              scorecardDetailedInfoError: false
          }
      }
      case "SCORECARD_DETAILED_INFO_LOADING": {
          return {
              ...state,
              scorecardDetailedInfoError: false,
              scorecardDetailedInfoLoading: action.payload
          }
      }
      case "SCORECARD_DETAILED_INFO_ERROR": {
          return {
              ...state,
              scorecardDetailedInfoLoading: false,
              scorecardDetailedInfoError: action.payload
          }
      }
      case "SCORECARD_DETAILED_INFO_TIMEOUT_ERR": {
          return {
              ...state,
              scorecardDetailedInfoTimeoutError: action.payload
          }
      }
      case "GET_CUSTOM_COLUMNS": {
          return {
              ...state,
              customColumnsQQ: action.payload,
              customColumnsQQLoading: false,
              customColumnsQQError: false
          }
      }
      case "GET_CUSTOM_COLUMNS_LOADING": {
          return {
              ...state,
              customColumnsQQError: false,
              customColumnsQQLoading: action.payload
          }
      }
      case "GET_CUSTOM_COLUMNS_ERROR": {
          return {
              ...state,
              customColumnsQQLoading: false,
              customColumnsQQError: action.payload
          }
      }
      case "EXPORT_QUEUE_SHOWN_TYPE": {
          return {
            ...state,
            exportQueueType: action.payload,
          }
      }
      case "DASHBOARD_SET_ACTIVE_TREAD": {
          return {
              ... state,
              activeTread: action.payload
          }
      }
      case "DASHBOARD_GET_NOTI_REASONS_LOADING": {
            return {
                ...state,
                notificationReasonsLoading: action.payload,
                notificationReasonsError: false
            }
        }
        case "DASHBOARD_GET_NOTI_REASONS": {
            return {
                ...state,
                notificationReasonsLoading: false,
                notificationReasonsError: false,
                notificationReasons: action.payload
            }
        }
        case "DASHBOARD_GET_NOTI_REASONS_ERROR": {
            return {
                ...state,
                notificationReasonsLoading: false,
                notificationReasonsError: action.payload
            }
        }
        case "DASHBOARD_OPEN_NEW_TREAD": {
            return {
                ... state,
                openNewTread: action.payload
            }
        }
    }
    return state
}
