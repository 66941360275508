'use strict';

export default function reducer(state={
    modalData: {},
    error: null,
    infoModal: false,
    infoModalSuccess: false,
  }, action) {
    switch (action.type) {
      case "FETCH_MODAL": {
        return {...state, fetching: true}
      }
      case "MODAL_ERROR": {
        return {...state, fetching: false, error: action.payload}
      }
      case "MODAL_ACTION": {
        return {
          ...state,
          modalData: action.payload,
        }
      }
      case 'INFO_MODAL': {
          return {
              ...state,
              infoModal: action.payload
          }
      }
      case 'INFO_MODAL_SUCCESS': {
          return {
              ...state,
              infoModalSuccess: action.payload
          }
      }
    }

    return state
}
