'use strict'

import * as R from 'ramda'

const getLinkedComment = (id, sections) => {
    let comment = null
    sections.forEach(s => {
        s.questions.forEach(q => {
            if (!q.isComposite) {
                q.simpleQuestionInfo.answers.forEach(a => {
                    if (a.isAnswered && a.comments && a.comments.length) a.comments.forEach(c => {
                        if (
                            c.commentId === id
                            && c.checked
                            && isVisible(q, sections).isVisible
                        ) comment = c
                    })
                })
            } else {
                const match = q.compositeQuestionInfo.comments.find(c => c.optionId === id && c.checked)
                if (match && isVisible(q, sections).isVisible) comment = match
            }
        })
    })
    return comment
}

const getLinkedAnswer = (id, sections) => {
    let answer = null
    sections.forEach(s => {
        s.questions.forEach(q => {
            if (!q.isComposite) {
                const match = q.simpleQuestionInfo.answers.find(a => a.answer.answerId === id)
                if (match) answer = match.isAnswered && isVisible(q, sections).isVisible && match
            } else {
                const match = q.compositeQuestionInfo.answers.find(a => a.answerId === id)
                if (match) answer = !isVisible(q, sections).isVisible
                || (match.isRightAnswer && q.compositeQuestionInfo.comments.some(c => !c.checked))
                || (!match.isRightAnswer && q.compositeQuestionInfo.comments.every(c => c.checked))
                ? null
                : match
            }
        })
    })
    return answer
}

export const isVisible = (q, sections) => {
    if (q.isLinked) {
        const rules = R.clone(q.linkedRules)
        if (R.all(rule => !rule.GroupInfo.active)(rules)) {
            q.isVisible = true
            return q
        }
        sections.forEach(s => {
            const questions = s.questions
            questions.forEach((quest) => {
                if (quest.isVisible && quest.isAnswered) {
                    const question = quest.isComposite ? quest.compositeQuestionInfo : quest.simpleQuestionInfo
                    const answers = question.answers
                    answers.forEach(answer => {
                        let checked
                        let answerId
                        if (quest.isComposite) {
                            const comments = question.comments
                            const all = R.all(R.propEq('checked', true))(comments)
                            checked = (answer.isRightAnswer && all) || (!answer.isRightAnswer && !all) ? true : false
                            answerId = answer.answerId
                        } else {
                            checked = answer.isAnswered
                            answerId = answer.answer.answerId
                        }
                        if (checked) {
                            rules.forEach(rule => {
                                const groupInfo = rule.GroupInfo
                                if (!groupInfo.active) return
                                const linkedItems = rule.LinkedItems
                                if (linkedItems.every(linkedItem => !linkedItem.LinkedVisible)) return
                                linkedItems.forEach(linkedItem => {
                                    if (!linkedItem.LinkedVisible) {
                                        linkedItem.visible = true
                                    } else {
                                        if (linkedItem.LinkedType === 'Answer') {
                                            if (quest.questionType === 'Dropdown' && !quest.singleComment) {
                                                const ddls = answer.customComment
                                                if (ddls && 
                                                    ddls.length && 
                                                    ddls.some(item => item.answerId === linkedItem.LinkedItemID)) {
                                                    linkedItem.visible = true
                                                }
                                            } else {
                                                if (linkedItem.LinkedItemID === answerId) {
                                                    linkedItem.visible = true
                                                }
                                            }
                                        } else if (linkedItem.LinkedType === 'Comment') {
                                            //ASK how should it work on new listen
                                            if (!quest.isComposite) {
                                                //That logic added for DDLItems dropdown
                                                if (quest.questionType === 'Dropdown' && !quest.singleComment) { 
                                                    const ddls = answer.customComment
                                                    if (ddls && 
                                                        ddls.length && 
                                                        ddls.some(item => item.ddlValue === linkedItem.LinkedItemID)) {
                                                        linkedItem.visible = true
                                                    }
                                                } else {
                                                    answer.comments && answer.comments.forEach(c => {
                                                        if ( (c.checked || c.shadowed)
                                                            && linkedItem.LinkedItemID === c.commentId
                                                        ) {
                                                            linkedItem.visible = true
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                    }
                                })
                            })
                        }
                    })
                }
            })
        })
        const visible = R.any(rule => R.all(R.propEq('visible', true))(rule.LinkedItems))(rules)
        //if q.linkedVisible it inverts linked rules logic
        q.isVisible = q.linkedVisible ? !visible : visible
    } else {
        q.isVisible = true
    }
    return q
}

// export const isVisible = (q, sections) => {
//     if (q.isLinked) {
//         let visible = false
//         const linkedToComment = !!(q.linkedComments && q.linkedComments.length)
//         const linkedToAnswer = !!(q.linkedAnswers && q.linkedAnswers.length)

//         const commentsClone = R.clone(q.linkedComments)
//         const answersClone = R.clone(q.linkedAnswers)
//         const linkedComments = linkedToComment
//         ? commentsClone.map(c => getLinkedComment(c, sections)).filter(fc => fc)
//         : []

//         const linkedAnswers = linkedToAnswer ?
//         answersClone.map(a => getLinkedAnswer(a, sections)).filter(fa => fa)
//         : []

//         visible = (q.linkedVisible && !(linkedComments.length || linkedAnswers.length))
//         || (!q.linkedVisible && (linkedComments.length || linkedAnswers.length))
//         q.isVisible = visible
//     } else {
//         q.isVisible = true
//     }
//     return q
// }

export const checkVisibility = (sections) => {
    sections.forEach(section => {
        return section.questions.map(q => isVisible(q, sections))
    })
    return sections
}
