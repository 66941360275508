import * as R from 'ramda'

const initialState = {
    modalOptions              : null,
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "SET_MODAL_OPTIONS": {
            return {
                ...state,
                modalOptions: action.payload,
            }
        }
    }
    return state
}
