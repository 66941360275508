'use strict'

import {getDefaultBadcallsColumns} from '../utils/badcalls'

const initialState = {
    badCallsListIndex: 0,
    loadedBadCalls: {},
    noBadCalls: false,
    badCallsListIndex: 0,
    paginationChunk: 50,
    activeCall: null,
    badCalls: null,
    sortingType: 'call_date',
    sortStraight: true,
    selected: 0,
    sortedCalls: null,
    columns: getDefaultBadcallsColumns(),
    loading: false,
    error: false
}

export default function reducer(state=initialState, action) {

    switch (action.type) {
        case "BAD_CALLS_LOADING": {
            return {
                ...state,
                loading: true,
                error: false
            }
        }
        case "BAD_CALLS_ERROR": {
            return {
                ...state,
                loading: false,
                error: true
            }
        }
        case "GET_BAD_CALLS_FULFILLED": {
            return {
                ...state,
                badCalls: action.payload
            }
        }
        case "SORTED_BAD_CALLS_FULFILLED": {
            return {
                ...state,
                sortedCalls: action.payload,
                loading: false,
                error: false
            }
        }
        case "BAD_CALLS_SORTING": {
            return {
                ...state,
                sortingType: action.payload.sortingType,
                sortStraight: action.payload.sortStraight
            }
        }
        case "SET_ACTIVE_CALL": {
            return {
                ...state,
                selected: action.payload
            }
        }
        case "BAD_CALLS_COLUMNS_CHANGED": {
            return {
                ...state,
                columns: action.payload
            }
        }
        case "CALL_DETAILS_LOADED": {
            return {
                ...state,
                loadedBadCalls: action.payload
            }
        }
        case "BAD_CALL_DETAILS_LOADED": {
            return {
                ...state,
                activeCall: action.payload
            }
        }
        case "BAD_CALL_LIST_CHANGED": {
            return {
                ...state,
                badCallsListIndex: action.payload,
                selected: 0
            }
        }
        case "NO_BAD_CALLS": {
            return {
                ...state,
                noBadCalls: action.payload
            }
        }
    }

    return state
}
