'use strict';

import * as R from 'ramda'

const initialState ={
    billingInfo          : null,
    loading              : false,
    error                : false,
    activeMonth          : null,
    exportingBillingInfo : false,
    selectedScorecards   : null,
    scorecardsForBilling : null
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "BILLING_SET_ACTIVE_SCORECARDS": {
            const scorecardIds = action.payload.scorecardIds ? action.payload.scorecardIds : null
            return {
                ...state,
                selectedScorecards: scorecardIds
            }
        }
        case "BILLING_SET_SCORECARDS_FOR_BILLING": {
            const scorecardIds = action.payload.scorecardIds ? action.payload.scorecardIds : null
            return {
                ...state,
                scorecardsForBilling: scorecardIds
            }
        }
        case "BILLING_INFO_FULFILLED": {
            return {
                ...state,
                billingInfo: action.payload,
                loading: false,
                error: false,
            }
        }
        case "BILLING_INFO_LOADING": {
            return {
                ...state,
                billingInfo: null,
                loading: true,
                error: false,
            }
        }
        case "BILLING_INFO_ERROR": {
            return {
                ...state,
                billingInfo: null,
                loading: false,
                error: true,
            }
        }
        case "BILLING_PAGE_ACTIVE_MONTH": {
            return {
                ...state,
                activeMonth: action.payload
            }
        }
        case "EXPORT_BILLING_INFO": {
            return {
                ...state,
                exportingTopMissed: action.payload
            }
        }
    }
    return state
}
