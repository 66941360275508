'use strict';

import axios from 'axios'
import axiosCancel from 'axios-cancel'
import * as R from 'ramda'

import {sendRequest, sendDataRequest} from '../APIGateway/gateway'
import loader from './loaderActions'
import listenActions from './listenActions'
import recordActions from './recordActions'
import { showError } from './errorActions'
import {
    convertFilterRequestData,
    convertAverages,
    convertScorePerf,
    convertAgents,
    convertDashboardFilters,
    convertCoachingColumns
} from '../utils/dataConvertor'
import appConfig from '../appConfig'
import {getEmptyFilters, filtersEmpty} from '../utils/filters'
import {
    getDefaultColumns,
    getSearchableColumns,
    getModulesAPI,
    sort,
    sortExportQueue,
    setModules,
    groupCallsLeft,
    convertFiltersToSave,
    getCheckedColumnsIds,
    getDefaultCoachingColumns,
    getCheckedCoachingColumnsIds
} from '../utils/dashboard'
import {convertChat} from '../utils/globalHelpers'

axiosCancel(axios, {
  debug: false
})

const dashboardActions = {

    startWithRouter() {
        return dispatch => {
            dispatch({
                type: 'DASHBOARD_PAGE_VISITED',
                payload: true,
            })
            dispatch(this.getAvailableTableColumns())
            .then(
                () => dispatch(this.getUserSettings())
                .then(() => {
                    dispatch(this.getAvailableFilters())
                    dispatch(this.fetchDashboardData())
                })
            )
        }
    },

    start(store) {
        return dispatch => {
            const userData = R.path(['headerReducer', 'headerData', 'userData'], store.getState())
            if (userData) {
                dispatch(this.startWithUserData())
            } else {
                const userDataPromise = new Promise((resolve) => {
                    const unsubscribe = store.subscribe(() => {
                        const userDataFromAPI = R.path(['headerReducer', 'headerData', 'userData'], store.getState())
                        if (userDataFromAPI) {
                            unsubscribe()
                            resolve()
                        }
                    })
                })
                userDataPromise.then(() => {
                    dispatch(this.startWithUserData())
                })
            }
        }
    },

    startWithUserData() {
        return (dispatch, getState) => {
            const state = getState()
            const availableColumnsUpdated = R.path(['dashboardReducer', 'availableColumnsUpdated'], state)
            const userSettingsUpdated = R.path(['dashboardReducer', 'userSettingsUpdated'], state)
            if (availableColumnsUpdated && userSettingsUpdated) {
                dispatch(this.getAvailableFilters())
                dispatch(this.fetchDashboardData())
                return
            }
            if (availableColumnsUpdated) {
                return dispatch(this.getUserSettings()).then(() => {
                    dispatch(this.getAvailableFilters())
                    dispatch(this.fetchDashboardData())
                })
            }
            dispatch(this.getAvailableTableColumns())
            .then(() => dispatch(this.getUserSettings())
            .then(() => {
                dispatch(this.getAvailableFilters())
                dispatch(this.fetchDashboardData())
            }))
        }
    },

    startCalldetails(store) {
        return dispatch => {
            dispatch({type: 'SET_CALL_DETAILS_PAGE'})
            const userDataPromise = new Promise((resolve) => {
                const unsubscribe = store.subscribe(() => {
                    const userData = R.path(['headerReducer', 'headerData', 'userData'], store.getState())
                    if (userData) {
                        unsubscribe()
                        resolve()
                    }
                })
            })
            userDataPromise
            .then(
                () => dispatch(this.getAvailableTableColumns())
                .then(
                    dispatch(this.getUserSettings())
                    .then(() => {
                        dispatch(this.getAvailableFilters())
                        dispatch(this.fetchCalldetailsData())
                    })
                )
            )
        }
    },

    getAvailableTableColumns() {
        return (dispatch, getState) => {
            const defaultColumns = getDefaultColumns()
            return sendRequest('getAvailableTableColumns', {
                payload: ''
            }, 'DASHBOARDPOST', false, 'getAvailableTableColumns').then(resp => {
                const respColumns = resp.data || []
                respColumns.forEach(c => c.sortable = true)
                const lastColumn = defaultColumns.splice(-1, 1)[0]
                const columns = defaultColumns.concat(respColumns)
                columns.push(lastColumn)
                return dispatch({
                    type: 'TABLE_COLUMNS_CHANGED',
                    payload: columns
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                return dispatch({
                    type: 'TABLE_COLUMNS_CHANGED',
                    payload: defaultColumns
                })
            })
        }
    },

    setReviewDateOption(filterByReviewDate) {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.clone(R.path(['filtersReducer', 'newFilters'], state))
            filters.filterByReviewDate = filterByReviewDate
            dispatch({
                type: 'SET_FILTERS',
                payload: filters
            })
        }
    },

    setDateSettings(dateSettings) {
        return dispatch => dispatch({
            type: 'SET_DATE_SETTINGS',
            payload: dateSettings
        })
    },

    setFilters(filters) {
        return (dispatch, getState) => {
            const state = getState()
            const calibratedOnly = R.path(['dashboardReducer', 'calibratedOnly'], state)
            if ((!filters.missedItems || !filters.missedItems.length) && !calibratedOnly) filters.missedBy = 'agent'
            if (calibratedOnly) dispatch({
                type: 'CALIBRATED_ONLY_CALLS',
                payload: false
            })
            dispatch({
                type: 'SET_FILTERS',
                payload: filters
            })
        }
    },

    refreshFilters() {
        return dispatch => {
            dispatch(this.getAvailableFilters())
        }
    },

    getUserSettings() {
        return (dispatch, getState) => {
            const state = getState()
            const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
            const payload = ['dates', 'filters', 'columns', 'dashboardModules', 'tableSorting']
            const availableColumns = R.clone(R.path(['dashboardReducer', 'tableColumns'], state))
            axios.cancel('getUserSettings')
            return sendRequest('getUserSettings', {
                payload: payload
            }, 'USERAPI', false, 'getUserSettings').then(resp => {
                const converted = R.converge(R.zipObj, [R.pluck('name'), R.pluck('value')])(resp.data)
                const filters = converted.filters ? JSON.parse(converted.filters) : false
                if (filters) {
                    if (!filters.hasOwnProperty('reviewType')) filters.reviewType = ''
                    if (!filters.hasOwnProperty('pendingOnly')) filters.pendingOnly = false
                    dispatch({
                        type: 'SET_INITIAL_FILTERS',
                        payload: filters
                    })
                }
                const columns = converted.columns ? JSON.parse(converted.columns) : false
                if (columns) {
                    availableColumns.forEach(ac => {
                        ac.checked = false
                        const exist = columns.find(c => c.id === ac.id)
                        if (!exist) {
                            columns.push(ac)
                        }
                    })
                    dispatch({
                        type: 'TABLE_COLUMNS_CHANGED',
                        payload: columns
                    })
                }
                const dates = converted.dates ? JSON.parse(converted.dates) : false
                if (dates) dispatch({
                    type: 'SET_DATE_SETTINGS',
                    payload: dates
                })
                const tableSorting = converted.tableSorting ? JSON.parse(converted.tableSorting) : false
                if (tableSorting) dispatch({
                    type: 'SET_TABLE_SORTING',
                    payload: tableSorting
                })
                const modules = converted.dashboardModules ? JSON.parse(converted.dashboardModules) : false
                return dispatch({
                    type: 'SET_DASHBOARD_MODULES_SETTINGS',
                    payload: setModules(modules, userData.modules, userData.modulesRequired)
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                //Error handling here
            })
        }
    },

    getAvailableFilters() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardCalendarCounts')
            axios.cancel('getAvailableDashboardFilters')

            dispatch({
                type: 'AVAILABLE_FILTERS_LOADING',
                payload: true
            })
            dispatch({
                type: 'CALENDAR_COUNTS_LOADING',
                payload: true
            })
            const data = R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            sendRequest('getCalendarCounts', {
                payload: data
            }, 'DASHBOARDPOST', false, 'getDashboardCalendarCounts').then(resp => {
                dispatch({
                    type: 'CALENDAR_COUNTS_LOADING',
                    payload: false
                })
                dispatch({
                    type: 'CALENDAR_COUNTS_LOADED',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'CALENDAR_COUNTS_ERROR',
                    payload: true
                })
            })
            return sendRequest('getAvailableFilters', {
                payload: data
            }, 'DASHBOARDPOST', false, 'getAvailableDashboardFilters').then(resp => {
                const availableFilters = convertDashboardFilters(resp.data)
                return dispatch({
                    type: 'AVAILABLE_FILTERS_LOADED',
                    payload: availableFilters
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'AVAILABLE_FILTERS_LOADING_ERROR',
                    payload: false
                })
            })
        }
    },

    saveFilters() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.clone(R.path(['filtersReducer', 'newFilters'], state))
            const isCallDetailsOnly = R.path(['dashboardReducer', 'isCallDetailsOnly'], state)
            const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
            dispatch({
                type: 'PAGE_CHANGED',
                payload: {
                    pagenum: 1,
                    pagerows: isCallDetailsOnly ? 50 : 20
                }
            })
            if ((filters.missedItems && filters.missedItems.length) || userData.PreviousUser) {
                const emptyPromise = new Promise(resolve => resolve())
                return emptyPromise
            }
            const dates = R.clone(R.path(['filtersReducer', 'dateSettings'], state))
            return Promise.all([
                sendRequest('saveUserSettings', {
                    payload: {
                        name: 'filters',
                        value: JSON.stringify(convertFiltersToSave(filters))
                    }
                }, 'USERAPI'),
                sendRequest('saveUserSettings', {
                    payload: {
                        name: 'dates',
                        value: JSON.stringify(dates)
                    }
                }, 'USERAPI'),
            ]).then(resp => {
                console.log('Filters saved!');
            }, err => {
                console.log('Unfortunately filters were not saved!');
            })
        }
    },

    saveUserSettings(data) {
        return (dispatch, getState) => {
            const state = getState()
            const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
            if (userData.PreviousUser) return
            return sendRequest('saveUserSettings', {
                payload: {
                    name: data.name,
                    value: JSON.stringify(data.value)
                }
            }, 'USERAPI').then(resp => {
                console.log('User settings - ' + data.name + ' - saved!');
            }, err => {
                console.log('Unfortunately settings were not saved!');
            })
        }
    },

    // This is for Dashboard page
    fetchDashboardData() {
        return (dispatch, getState) => {
            const state = getState()
            const modules = R.path(['dashboardReducer', 'modules'], state)
            const apiCalls = getModulesAPI(modules)
            dispatch(this.getCallDetailsDefault())
            apiCalls.forEach(call => dispatch(this[call]()))
        }
    },

    // This is for Call Details page
    fetchCalldetailsData() {
        return dispatch => {
            dispatch({
                type: 'SET_CALL_DETAILS_ONLY_PROPERTY',
                payload: true
            })
            dispatch({
                type: 'PAGE_CHANGED',
                payload: {
                    pagenum: 1,
                    pagerows: 50
                }
            })
            dispatch(this.getCallDetailsDefault())
        }
    },

    getScorecardDetailedInfo() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getScorecardDetailedInfo')

            dispatch({ type: 'SCORECARD_DETAILED_INFO_LOADING', payload: true })

            sendRequest('getScorecardDetailedInfo', {
                payload: '' // R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getScorecardDetailedInfo').then(resp => {
                dispatch({
                    type: 'SCORECARD_DETAILED_INFO_FULFILLED',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'SCORECARD_DETAILED_INFO_TIMEOUT_ERR', payload: true })
                }
                dispatch({
                    type: 'SCORECARD_DETAILED_INFO_ERROR',
                    payload: true
                })
            })
        }
    },

    getAvgCallTime() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getAvgCallTime')

            dispatch({ type: 'AVG_CALL_TIME_LOADING', payload: true })

            sendRequest('getAvgCallTime', {
                payload: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getAvgCallTime').then(resp => {
                dispatch({
                    type: 'AVG_CALL_TIME',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'AVG_CALL_TIME_TIMEOUT_ERR', payload: true })
                }
                dispatch({
                    type: 'AVG_CALL_TIME_ERROR',
                    payload: true
                })
            })
        }
    },

    getWebsiteStats() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getWebsiteStats')

            dispatch({ type: 'WEBSITE_STATS_LOADING', payload: true })

            sendRequest('getWebsiteStats', {
                payload: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getWebsiteStats').then(resp => {
                dispatch({
                    type: 'GET_WEBSITE_STATS_FULFILLED',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'WEBSITE_STATS_TIMEOUT_ERR', payload: true })
                }
                dispatch({
                    type: 'WEBSITE_STATS_ERROR',
                    payload: true
                })
            })
        }
    },

    getSectionsInfo() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getSectionsInfo')

            dispatch({ type: 'SECTION_SCORES_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'SECTION_SCORES_LOADING', payload: true })

            sendRequest('getSectionsInfo', {
                payload: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getSectionsInfo').then(resp => {
                dispatch({
                    type: 'GET_SECTION_SCORES_FULFILLED',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'SECTION_SCORES_TIMEOUT_ERR', payload: true })
                }
                dispatch({
                    type: 'SECTION_SCORES_ERROR',
                    payload: true
                })
            })
        }
    },

    getCalibrationSectionsInfo() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getCalibrationSectionsInfo')

            dispatch({ type: 'CALIBRATION_SECTION_SCORES_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'CALIBRATION_SECTION_SCORES_LOADING', payload: true })

            sendRequest('getCalibrationSectionsInfo', {
                payload: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getCalibrationSectionsInfo').then(resp => {
                dispatch({
                    type: 'GET_CALIBRATION_SECTION_SCORES_FULFILLED',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'CALIBRATION_SECTION_SCORES_TIMEOUT_ERR', payload: true })
                }
                dispatch({
                    type: 'CALIBRATION_SECTION_SCORES_ERROR',
                    payload: true
                })
            })
        }
    },

    getPendingCalibrationsInternal() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getPendingCalibrationsInternal')

            dispatch({ type: 'PENDING_CALI_INT_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'PENDING_CALI_INT_LOADING', payload: true })

            sendRequest('getPendingCalibrationsInternal', {
                payload: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getPendingCalibrationsInternal').then(resp => {
                dispatch({
                    type: 'PENDING_CALI_INT',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'PENDING_CALI_INT_TIMEOUT_ERR', payload: true })
                }
                dispatch({
                    type: 'PENDING_CALI_INT_ERROR',
                    payload: true
                })
            })
        }
    },

    getPendingCalibrations() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getSectionsInfo')

            dispatch({ type: 'PENDING_CALIBRATION_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'PENDING_CALIBRATION_LOADING', payload: true })

            sendRequest('getPendingCalibrations', {
                payload: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getPendingCalibrations').then(resp => {
                dispatch({
                    type: 'GET_PENDING_CALIBRATION',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'PENDING_CALIBRATION_TIMEOUT_ERR', payload: true })
                }
                dispatch({
                    type: 'PENDING_CALIBRATION_ERROR',
                    payload: true
                })
            })
        }
    },

    getMyPay() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getMyPay')
            dispatch({ type: 'MYPAY_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'MYPAY_LOADING', payload: true })
            return sendRequest('getMyPay', {
                payload: ''
            }, 'DASHBOARDPOST', false, 'getMyPay').then(resp => {
                dispatch({
                    type: 'GET_MYPAY_FULFILLED',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'MYPAY_TIMEOUT_ERR', payload: true })
                }
                dispatch({ type: 'MYPAY_ERROR', payload: true })
            })
        }
    },

    getMyPayForWeek(week) {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getMyPay')
            dispatch({ type: 'MYPAY_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'MYPAY_LOADING', payload: true })
            return sendRequest('getMyPay', {
                payload: "'" + week + "'"
            }, 'DASHBOARDPOST', false, 'getMyPay').then(resp => {
                dispatch({
                    type: 'GET_MYPAY_FULFILLED',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'MYPAY_TIMEOUT_ERR', payload: true })
                }
                dispatch({ type: 'MYPAY_ERROR', payload: true })
            })
        }
    },

    getCallsLeft(dispatch, filters, dateSettings, state) {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardCallsLeft')
            dispatch({ type: 'CALLS_LEFT_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'CALLS_LEFT_LOADING', payload: true })
            return sendRequest('getCallsLeft', {
                payload: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getDashboardCallsLeft').then(resp => {
                dispatch({
                    type: 'GET_CALLS_LEFT_FULFILLED',
                    payload: groupCallsLeft(resp.data)
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'CALLS_LEFT_TIMEOUT_ERR', payload: true })
                }
                dispatch({ type: 'CALLS_LEFT_ERROR', payload: true })
            })
        }
    },
    getCoachingQueue() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardCoachingQueue')
            dispatch({ type: 'COACHING_QUEUE_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'COACHING_QUEUE_LOADING', payload: true })
            const sorting = R.path(['dashboardReducer', 'coachingQueueSorting'], state)

            sendRequest('getAvailableCoachingQueueColumns', {
                payload: ''
            }, 'DASHBOARDPOST', false, 'getAvailableCoachingQueueColumns')
            .then(resp => {
                dispatch({
                    type: 'COACHING_COLUMNS_CHANGED',
                    payload: resp.data
                })
            })
            return sendRequest('getCoachingQueue', {
                payload: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getDashboardCoachingQueue').then(resp => {
                dispatch({
                    type: 'GET_COACHING_QUEUE_FULFILLED',
                    payload: sort(resp.data, sorting, 'coachingQueue')
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'COACHING_QUEUE_TIMEOUT_ERR', payload: true })
                }
                dispatch({ type: 'COACHING_QUEUE_LOADING_ERROR', payload: true })
            })
        }
    },
    getStats() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardStats')
            dispatch({ type: 'STATS_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'STATS_LOADING', payload: true })
            return sendRequest('getStats', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'getDashboardStats').then(resp => {
                const data = {
                    comparisonStats: resp.data.comparisonStats || {
                        avgAgentScore:0,
                        totalAgents:0,
                        totalBadCalls:0,
                        totalCalls:0,
                        totalCallsSeconds:0,
                        totalFailedCalls:0,
                    },
                    rangeStats: resp.data.rangeStats || {
                        avgAgentScore:0,
                        totalAgents:0,
                        totalBadCalls:0,
                        totalCalls:0,
                        totalCallsSeconds:0,
                        totalFailedCalls:0,
                    }
                } // temporary for null
                return dispatch({
                    type: 'GET_STATS_FULFILLED',
                    payload: data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'STATS_TIMEOUT_ERR', payload: true })
                }
                return dispatch({ type: 'STATS_LOADING_ERROR', payload: true })
            })
        }
    },

    getScorePerformance() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardScorePerformance1')
            axios.cancel('getDashboardScorePerformance2')
            axios.cancel('getDashboardScorePerformance3')
            dispatch({ type: 'SCORE_PERF_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'SCORE_LOADING', payload: true })
            const filterRequestData = convertFilterRequestData(filters, dateSettings)

            const firstLineDataPromise = sendRequest('getScorePerformance', {
                payload: {filters: getEmptyFilters(), range: filterRequestData.range}
            }, 'DASHBOARDPOST', false, 'getDashboardScorePerformance1')

            const secondLineDataPromise = sendRequest('getScorePerformance', {
                payload: {filters: filterRequestData.filters, range: filterRequestData.comparison}
            }, 'DASHBOARDPOST', false, 'getDashboardScorePerformance2')

            const thirdLineDataPromise = filtersEmpty(filters) ? false :
            sendRequest('getScorePerformance', {
                payload: {filters: filterRequestData.filters, range: filterRequestData.range}
            }, 'DASHBOARDPOST', false, 'getDashboardScorePerformance3')

            const requests = [firstLineDataPromise, secondLineDataPromise]
            if (thirdLineDataPromise) requests.push(thirdLineDataPromise)

            return Promise.all(requests).then(
                ([firstLineData, secondLineData, thirdLineData]) => dispatch({
                    type: 'GET_SCORE_PERF_FULFILLED',
                    payload: {
                        rangeTotal:      firstLineData.data.averageScores,
                        rangeFiltered:   filtersEmpty(filters) ? null : thirdLineData.data.averageScores,
                        comparisonTotal: secondLineData.data.averageScores,
                    }
                })
            ).catch(err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'SCORE_PERF_TIMEOUT_ERR', payload: true })
                }
                dispatch({ type: 'SCORE_LOADING_ERROR', payload: true })
            })
        }
    },

    getTopMissed() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardTopMissed')
            dispatch({ type: 'TOP_MISSED_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'TOP_MISSED_LOADING', payload: true })
            const sorting = R.path(['dashboardReducer', 'topMissedSorting'], state)
            return sendRequest('getTopMissed', {
                payload: R.pick(["filters", "range", "comparison"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getDashboardTopMissed').then(resp => {
                return dispatch({
                    type: 'GET_TOP_MISSED_FULFILLED',
                    payload: sort(resp.data.missedItems, sorting, 'topMissed')
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'TOP_MISSED_TIMEOUT_ERR', payload: true })
                }
                return dispatch({ type: 'TOP_MISSED_LOADING_ERROR', payload: true })
            })
        }
    },

    getTopQAMissed() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardTopQAMissed')
            dispatch({ type: 'TOP_QA_MISSED_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'TOP_QA_MISSED_LOADING', payload: true })
            const sorting = R.path(['dashboardReducer', 'topQAMissedSorting'], state)
            return sendRequest('getTopQAMissed', {
                payload: R.pick(["filters", "range",  "comparison"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getDashboardTopQAMissed').then(resp => {
                return dispatch({
                    type: 'GET_TOP_QA_MISSED_FULFILLED',
                    payload: sort(resp.data.missedItems, sorting, 'topQAMissed')
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'TOP_QA_MISSED_TIMEOUT_ERR', payload: true })
                }
                return dispatch({ type: 'TOP_QA_MISSED_LOADING_ERROR', payload: true })
            })
        }
    },

    getTopCalibratorMissed(dispatch, filters, dateSettings, state) {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardTopCalibratorMissed')
            dispatch({ type: 'TOP_CALIBRATOR_MISSED_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'TOP_CALIBRATOR_MISSED_LOADING', payload: true })
            const sorting = R.path(['dashboardReducer', 'topCalibratorMissedSorting'], state)
            return sendRequest('getTopCalibratorMissed', {
                payload: R.pick(["filters", "range",  "comparison"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getDashboardTopCalibratorMissed').then(resp => {
                return dispatch({
                    type: 'GET_TOP_CALIBRATOR_MISSED_FULFILLED',
                    payload: sort(resp.data.missedItems, sorting, 'topCalibratorMissed')
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'TOP_CALIBRATOR_MISSED_TIMEOUT_ERR', payload: true })
                }
                return dispatch({ type: 'TOP_CALIBRATOR_MISSED_LOADING_ERROR', payload: true })
            })
        }
    },

    getAgentRanking() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardAgentRanking')
            dispatch({ type: 'AGENT_RANKING_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'AGENT_RANKING_LOADING', payload: true })
            const sorting = R.path(['dashboardReducer', 'agentRankingSorting'], state)
            return sendRequest('getAgentRanking', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'getDashboardAgentRanking').then(resp => {
                return dispatch({
                    type: 'GET_AGENT_RANKING_FULFILLED',
                    payload: sort(resp.data.agents, sorting, 'agentRanking')
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'AGENT_RANKING_TIMEOUT_ERR', payload: true })
                }
                return dispatch({ type: 'AGENT_RANKING_LOADING_ERROR', payload: true })
            })
        }
    },

    getGroupPerformance() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardGroupPerformance')
            dispatch({ type: 'GROUP_PERF_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'GROUP_PERFORMANCE_LOADING', payload: true })
            const sorting = R.path(['dashboardReducer', 'groupPerformanceSorting'], state)
            return sendRequest('getGroupPerformance', {
                payload: R.pick(["filters", "range", "comparison"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getDashboardGroupPerformance').then(resp => {
                return dispatch({
                    type: 'GET_GROUP_PERFORMANCE_FULFILLED',
                    payload: sort(resp.data, sorting, 'groupPerformance')
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'GROUP_PERF_TIMEOUT_ERR', payload: true })
                }
                return dispatch({ type: 'GROUP_PERFORMANCE_LOADING_ERROR', payload: true })
            })
        }
    },

    getCampaignPerformance(dispatch, filters, dateSettings, state) {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getDashboardCampaignPerformance')
            dispatch({ type: 'CAMPAIGN_PERFORMANCE_TIMEOUT_ERR', payload: false })
            dispatch({ type: 'CAMPAIGN_PERFORMANCE_LOADING', payload: true })
            const sorting = R.path(['dashboardReducer', 'campaignPerformanceSorting'], state)
            return sendRequest('getCampaignPerformance', {
                payload: R.pick(["filters", "range", "comparison"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getDashboardCampaignPerformance').then(resp => {
                return dispatch({
                    type: 'GET_CAMPAIGN_PERFORMANCE_FULFILLED',
                    payload: sort(resp.data, sorting, 'campaignPerformance')
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'CAMPAIGN_PERFORMANCE_TIMEOUT_ERR', payload: true })
                }
                return dispatch({ type: 'CAMPAIGN_PERFORMANCE_LOADING_ERROR', payload: true })
            })
        }
    },

    moduleSort(data) {
        return (dispatch, getState) => {
            const state = getState()
            const sorting = R.path(['dashboardReducer', data.sorting], state)
            const items = R.path(['dashboardReducer', data.module], state)
            dispatch({
                type: data.type,
                payload: sort(items, sorting, data.module)
            })
        }
    },

    getNextPage(pagenum, infiniteLoading) {
        return (dispatch, getState) => {
            const state = getState()
            const pagination = R.path(['dashboardReducer', 'pagination'], state)
            dispatch({
                type: 'PAGE_CHANGED',
                payload: {
                    pagenum: pagenum,
                    pagerows: pagination.pagerows,
                    totalCalls: pagination.totalCalls
                }
            })
            dispatch(this.getCallDetailsDefault(infiniteLoading))
        }
    },

    exportCallDetails() {
        axios.cancel('exportCallDetails')
        return (dispatch, getState) => {
            dispatch({
                type: 'EXPORT_CALL_DETAILS',
                payload: true
            })
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const sorting = R.path(['dashboardReducer', 'sorting'], state)
            const columns = R.path(['dashboardReducer', 'tableColumns'], state)
            const searchText = R.path(['dashboardReducer', 'callDetailsSearchText'], state)
            const isConversionActive = R.path(['conversionReducer', 'isConversionActive'], state)
            
           const payload = R.merge(convertFilterRequestData(filters, dateSettings), {
                sorting: sorting,
                columns: getCheckedColumnsIds(columns),
                search: {
                    columns: getCheckedColumnsIds(columns),
                    text: searchText
                }
            })
            if (isConversionActive) {
                const conversionFilters = R.path(['conversionReducer', 'conversionFilters'], state)
                payload.filters.isConversion = true
                payload.filters.conversionFilters = conversionFilters
            }
            return sendRequest('exportCallDetails', {
                payload: payload
            }, 'DASHBOARDPOST', false, 'exportCallDetails').then(response => {
                dispatch({
                    type: 'EXPORT_CALL_DETAILS',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    getExportQoachingQueue(list) {
        axios.cancel('getExportQoachingQueue')
        return (dispatch, getState) => {
            var cheked = getCheckedCoachingColumnsIds(list)
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const sorting = R.path(['dashboardReducer', 'getExportQoachingQueue'], state)
            return sendRequest('getExportQoachingQueue', {
                payload: {
                    // Filter: convertFilterRequestData(filters, dateSettings),
                    // columns: cheked
                    Filter: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings)), columns: cheked
                }
            }, 'DASHBOARDPOST', false, 'getExportQoachingQueue').then(resp => {
                dispatch({
                    type: 'EXPORT_AGENT_RANKING',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    getExportAgentRanking() {
        axios.cancel('getExportAgentRanking')
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const sorting = R.path(['dashboardReducer', 'agentRankingSorting'], state)
            return sendRequest('getExportAgentRanking', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'getExportAgentRanking').then(resp => {
                dispatch({
                    type: 'EXPORT_AGENT_RANKING',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    getExportCallsLeft() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getExportCallsLeft')
            const sorting = R.path(['dashboardReducer', 'getExportCallsLeft'], state)
            return sendRequest('getExportCallsLeft', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'getExportCallsLeft').then(resp => {
                dispatch({
                    type: 'EXPORT_CALLS_LEFT',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    getExportCampaignPerformance() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getExportCampaignPerformance')
            const sorting = R.path(['dashboardReducer', 'getExportCampaignPerformance'], state)
            return sendRequest('getExportCampaignPerformance', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'getExportCampaignPerformance').then(resp => {
                dispatch({
                    type: 'EXPORT_CAMPAIGN_PERFORMANCE',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    getExportGroupPerformance() {
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            axios.cancel('getExportGroupPerformance')
            const sorting = R.path(['dashboardReducer', 'getExportGroupPerformance'], state)
            return sendRequest('getExportGroupPerformance', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'getExportGroupPerformance').then(resp => {
                dispatch({
                    type: 'EXPORT_GROUP_PERFORMANCE',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    getExportWebsiteStats() {
        axios.cancel('getExportWebsiteStats')
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const sorting = R.path(['dashboardReducer', 'agentRankingSorting'], state)
            return sendRequest('getExportWebsiteStats', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'getExportWebsiteStats').then(resp => {
                dispatch({
                    type: 'EXPORT_WEBSITE_STATS',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    getExportTopMissed() {
        axios.cancel('getExportTopMissed')
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const sorting = R.path(['dashboardReducer', 'getExportTopMissed'], state)
            return sendRequest('getExportTopMissed', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'getExportTopMissed').then(resp => {
                dispatch({
                    type: 'EXPORT_TOP_MISSED',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    getExportTopQaMissed() {
        axios.cancel('getExportTopQaMissed')
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const sorting = R.path(['dashboardReducer', 'getExportTopQaMissed'], state)
            return sendRequest('getExportTopQaMissed', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'getExportTopQaMissed').then(resp => {
                dispatch({
                    type: 'EXPORT_TOP_QA_MISSED',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    getExportTopCalibratorMissed() {
        axios.cancel('getExportTopCalibratorMissed')
        return (dispatch, getState) => {
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const sorting = R.path(['dashboardReducer', 'getExportTopCalibratorMissed'], state)
            return sendRequest('getExportTopCalibratorMissed', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'getExportTopCalibratorMissed').then(resp => {
                dispatch({
                    type: 'EXPORT_TOP_CALIBRATOR_MISSED',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },


    getExportMyPay(week) {
        axios.cancel('getExportMyPay')
        return (dispatch, getState) => {
            return sendRequest('getExportMyPay', {
                payload: "'" + week + "'"
            }, 'DASHBOARDPOST', false, 'getExportMyPay').then(resp => {
                dispatch({
                    type: 'EXPORT_MY_PAY',
                    payload: false
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    exportSectionScores() {
        return (dispatch, getState) => {
            dispatch({
                type: 'EXPORTING_SECTION_SCORES',
                payload: true,
            })
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            return sendRequest('exportSectionScores', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'exportSectionScores').then(resp => {
                dispatch({
                    type: 'EXPORTING_SECTION_SCORES',
                    payload: false,
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    exportCalibrationSectionScores() {
        return (dispatch, getState) => {
            dispatch({
                type: 'EXPORTING_CALIBRATION_SECTION_SCORES',
                payload: true,
            })
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            return sendRequest('exportCalibrationSectionScores', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'exportCalibrationSectionScores').then(resp => {
                dispatch({
                    type: 'EXPORTING_CALIBRATION_SECTION_SCORES',
                    payload: false,
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                dispatch({
                    type: 'EXPORTING_CALIBRATION_SECTION_SCORES',
                    payload: false,
                })
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    exportScorecardSummary() {
        return (dispatch, getState) => {
            dispatch({
                type: 'EXPORTING_SCORECARD_SUMMARY',
                payload: true,
            })
            const state = getState()
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            return sendRequest('exportScorecardSummary', {
                payload: convertFilterRequestData(filters, dateSettings)
            }, 'DASHBOARDPOST', false, 'exportScorecardSummary').then(resp => {
                dispatch({
                    type: 'EXPORTING_SCORECARD_SUMMARY',
                    payload: false,
                })
                dispatch({
                    type: 'INFO_MODAL_SUCCESS',
                    payload: 'File has been successfully added to the export queue.'
                })
            },  err => {
                dispatch({
                    type: 'EXPORTING_SCORECARD_SUMMARY',
                    payload: false,
                })
                dispatch({
                    type: 'INFO_MODAL',
                    payload: 'Export file is NOT added to Export Queue'
                })
                throw err
            })
        }
    },

    getQuchingQueueCustomColumns() {
        return (dispatch, getState) => {
            axios.cancel('getQuchingQueueCustomColumns')
            const state = getState()
            dispatch({
                type: 'GET_CUSTOM_COLUMNS_LOADING',
                payload: true
            })
            return sendRequest('getQuchingQueueCustomColumns', {
                payload: {}
            }, 'SETTINGS', false, 'getQuchingQueueCustomColumns')
            .then(resp => {
                dispatch({
                    type: 'GET_CUSTOM_COLUMNS',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'GET_CUSTOM_COLUMNS_ERROR',
                    payload: true
                })
            })
        }
    },

    getExportQueue(name) {
        axios.cancel('getExportQueue')
        return dispatch => {
            dispatch({
                type: 'EXPORT_QUEUE_LOADING',
                payload: true
            })
            dispatch({
                type: 'EXPORT_QUEUE_SHOWN_TYPE',
                payload: name
            })
            return sendRequest('getExportQueue', {
                payload: "'" + name + "'"
            }, 'DASHBOARDPOST', false, 'getExportQueue').then(resp => {
                return dispatch({
                    type: 'EXPORT_QUEUE_FULFILLED',
                    payload: sortExportQueue(resp.data)
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                return dispatch({
                    type: 'EXPORT_QUEUE_ERROR',
                    payload: true
                })
                throw err
            })
        }
    },

    deleteFromExportQueue(id) {
        return (dispatch, getState) => {
            const state = getState()
            const name = R.path(['dashboardReducer', 'exportQueueType'], state)
            return sendRequest('deleteFromExportQueue', {
                payload: {
                    id: id,
                    moduleName: name
                }
            }, 'DASHBOARDPOST', false, 'getExportQueue').then(resp => {
               return dispatch({
                    type: 'EXPORT_QUEUE_FULFILLED',
                    payload: sortExportQueue(resp.data)
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                return dispatch({
                    type: 'EXPORT_QUEUE_ERROR',
                    payload: true
                })
                throw err
            })
        }
    },

    setCallDetailsSearchText(value) {
        return dispatch => dispatch({
            type: 'SET_CALL_DETAILS_SEARCH_TEXT',
            payload: value
        })
    },

    getSearchResults() {
        axios.cancel('getDashboardCallDetails')
        return (dispatch, getState) => {
            dispatch({
                type: 'CALL_DETAILS_SEARCHING_ERROR',
                payload: false
            })
            dispatch({
                type: 'CALL_DETAILS_SEARCHING',
                payload: true
            })
            const state = getState()
            const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const sorting = R.path(['dashboardReducer', 'sorting'], state)
            const searchText = R.path(['dashboardReducer', 'callDetailsSearchText'], state)
            const columns = R.path(['dashboardReducer', 'tableColumns'], state)
            dispatch(this.resetPagination())
            dispatch(this.getCallDetails())
            .then(() => {
                dispatch({
                    type: 'CALL_DETAILS_SEARCHING',
                    payload: false
                })
            }, (err) => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'CALL_DETAILS_SEARCHING',
                    payload: false
                })
                dispatch({
                    type: 'CALL_DETAILS_SEARCHING_ERROR',
                    payload: true
                })
            })
        }
    },

    getCallDetailsDefault(infiniteLoading) {
        return dispatch => {
            axios.cancel('getDashboardCallDetails')
            dispatch({ type: 'CALL_DETAILS_TIMEOUT_ERR', payload: false })
            if (infiniteLoading) {
                dispatch({ type: 'CALL_DETAILS_INFINITE_LOADING', payload: true })
            } else {
                dispatch({ type: 'CALL_DETAILS_LOADING', payload: true })
            }
            dispatch(this.getCallDetails(infiniteLoading))
            .then(() => {}, err => {
                if (axios.isCancel(err)) {
                    return
                }
                if (
                    err.response
                    && err.response.data
                    && err.response.data.ExceptionMessage
                    && err.response.data.ExceptionMessage.toUpperCase().indexOf('TIMEOUT') !== -1
                ) {
                    dispatch({ type: 'CALL_DETAILS_TIMEOUT_ERR', payload: true })
                }
                return dispatch({ type: 'CALL_DETAILS_LOADING_ERROR', payload: true })
            })
        }
    },

    getCallDetails(infiniteLoading) {
        return (dispatch, getState) => {
            const state = getState()
            const isConversionActive = R.path(['conversionReducer', 'isConversionActive'], state)
            const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const pagination = R.path(['dashboardReducer', 'pagination'], state)
            const sorting = R.path(['dashboardReducer', 'sorting'], state)
            const searchText = R.path(['dashboardReducer', 'callDetailsSearchText'], state)
            const columns = R.path(['dashboardReducer', 'tableColumns'], state)
            const answers = R.path(['filtersReducer', 'answers'], state)
            const comments = R.path(['filtersReducer', 'comments'], state)
            const compositeAnswers = R.path(['filtersReducer', 'compositeAnswers'], state)
            const compositeComments = R.path(['filtersReducer', 'compositeComments'], state)
            const chartType = R.path(['conversionReducer', 'chartType'], state)
            const filtersData = convertFilterRequestData(filters, dateSettings)
            if (answers && answers.length) filtersData.filters.answerIds = answers.map(a => a.id)
            if (comments && comments.length) filtersData.filters.commentIds = comments.map(c => c.id)
            if (compositeAnswers && compositeAnswers.length) filtersData.filters.compositeAnswerIds = compositeAnswers.map(a => a.id)
            if (compositeComments && compositeComments.length) filtersData.filters.compositeCommentIds = compositeComments.map(c => c.id)
            const payload = R.merge(filtersData, {
                paging: pagination,
                sorting: sorting,
                search: {
                    text: searchText,
                    columns: getCheckedColumnsIds(columns)
                }
            })
            if (isConversionActive) {
                const conversionFilters = R.path(['conversionReducer', 'conversionFilters'], state)
                payload.filters.isConversion = true
                payload.filters.conversionFilters = conversionFilters
            }
            return sendRequest('getCallDetails', {
                payload: payload
            }, 'DASHBOARDPOST', false, 'getDashboardCallDetails').then(resp => {
                const calls = resp.data.calls
                dispatch({
                    type: 'PAGE_CHANGED',
                    payload: {
                        totalCalls: resp.data.itemsTotal,
                        pagenum: pagination.pagenum,
                        pagerows: pagination.pagerows,
                    }
                })
                if (infiniteLoading) {
                    return dispatch({
                        type: 'GET_CALL_DETAILS_FULFILLED_INFINITE',
                        payload: calls
                    })
                } else {
                    return dispatch({
                        type: 'GET_CALL_DETAILS_FULFILLED',
                        payload: calls
                    })
                }
            }, err => {
                throw err
            })
        }
    },
    getSelectedCallDetails(id) {
        axios.cancel('getDashboardSelectedCallDetails')
        return (dispatch, getState) => dispatch(this.getSelectedCallDetailsRequest(id))
    },
    getSelectedCallDetailsFromCoaching(id) {
        axios.cancel('getDashboardSelectedCallDetails')
        return dispatch => dispatch(this.getSelectedCallDetailsRequest(id))
    },
    setPlayerMarkers(call) {
        const markers = []
        if (call && call.callMissedItems && call.callMissedItems.length) {
            call.callMissedItems.forEach(item => {
                if (
                    item.simpleQuestionAnswer
                    && item.simpleQuestionAnswer.position !== null
                ) markers.push({
                    position: item.simpleQuestionAnswer.position || 0,
                    negative: true,
                    text: item.questionShortName,
                    QID: '',
                    onclick: function() {}
                })
            })
        }
        return dispatch => {
            dispatch({
                type: 'SET_PLAYER_MARKERS',
                payload: markers
            })
        }
    },
    getSelectedCallDetailsRequest(id) {
        return dispatch => {
            dispatch(loader.run({action: 'Show'}))
            dispatch({
                type: 'SHORT_CALL_DETAILS_LOADING',
                payload: true
            })
            return sendRequest('getCallShortInfo', {
                payload: id
            }, 'DASHBOARDPOST', false, 'getDashboardSelectedCallDetails').then(resp => {
                const call = resp.data
                dispatch(this.setPlayerMarkers(resp.data))
                dispatch({
                    type: 'SHORT_CALL_DETAILS',
                    payload: resp.data
                })
                dispatch({
                    type: 'SET_ACTIVE_CALL_REVIEW',
                    payload: true
                })
                dispatch({
                    type: 'SHORT_CALL_DETAILS_LOADING',
                    payload: false
                })
                if (resp.data.threads && resp.data.threads.length !==0) {
                    const aaa = resp.data.threads.find(t => t.threadInfo.currentlyOpen)
                     if (aaa) {
                        dispatch(this.setActiveTread(aaa))
                    } else {
                        dispatch(this.setActiveTread(resp.data.Threads[0]))
                    }
                }
                dispatch(loader.run({action: 'Hide'}))
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'SHORT_CALL_DETAILS_LOADING',
                    payload: false
                })
                dispatch({
                    type: 'SHORT_CALL_DETAILS_ERROR',
                    payload: true
                })
                dispatch(loader.run({action: 'Hide'}))
            })
        }
    },

    setActiveTread(tread) {
         return dispatch => {
            dispatch({
                type: 'DASHBOARD_SET_ACTIVE_TREAD',
                payload: tread
            })
        }
    },

    getNotificationReasons() {
        return (dispatch, getState) => {
            const state = getState()
            dispatch({
                type: 'DASHBOARD_GET_NOTI_REASONS_LOADING',
                payload: true
            })
            return sendRequest('getNotificationReasons', {
                payload: {}
            }, 'CALIBRATION', false, 'getNotificationReasons')
            .then(resp => {
                dispatch({
                    type: 'DASHBOARD_GET_NOTI_REASONS',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'DASHBOARD_GET_NOTI_REASONS_ERROR',
                    payload: true
                })
            })
        }
    },

    openNewTread(prop) {
        return dispatch => {
            dispatch({
                type: 'DASHBOARD_OPEN_NEW_TREAD',
                payload: prop
            })
        }
    },

    createThread(tread) {
        return (dispatch, getState) => {
            const state = getState()
            const id = tread.callId
            return sendRequest('createThread', {
                payload: tread
            }, 'CALIBRATION', false, 'createThread')
            .then(resp => {
                dispatch(this.getSelectedCallDetailsRequest(id))
                dispatch(this.openNewTread(false))
                .then(() => {
                    dispatch({
                        type: 'SET_MINI_REVIEW_ACTIVE_TAB',
                        payload: 'notification'
                    })
                })
            }, err => {
                alert('Something happened! Try again later.')
            })
        }
    },

    deleteComment(data, callId) {
        return (dispatch, getState) => {
            return sendRequest('deleteComment', {payload: data}, 'RECORDPOST')
                .then(resp => {
                   dispatch(this.getSelectedCallDetailsRequest(callId))
                    .then(() => {
                        dispatch({
                            type: 'SET_MINI_REVIEW_ACTIVE_TAB',
                            payload: 'notification'
                        })
                    })
                },
                    err => {
                        alert('Something happened! Try again later.')
                    }
                )
        }
    },

    updateCallComment(data, callId) {
        return (dispatch, getState) => {
            return sendRequest('updateCallComment', {payload: data}, 'RECORDPOST')
                .then(resp => {
                   dispatch(this.getSelectedCallDetailsRequest(callId))
                    .then(() => {
                        dispatch({
                            type: 'SET_MINI_REVIEW_ACTIVE_TAB',
                            payload: 'notification'
                        })
                    })
                }, err => {
                    alert('Something happened! Try again later.')
                })
        }
    },


    sendNotification(options) {
        return (dispatch, getState) => {
            dispatch(loader.run({action: 'Show'}))
            const state = getState()
            sendRequest('sendNotification', {
                payload: options
            }, 'DASHBOARDPOST').then(resp => {
                const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
                const filters = R.path(['filtersReducer', 'newFilters'], state)
                const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
                const pagination = R.path(['dashboardReducer', 'pagination'], state)
                const sorting = R.path(['dashboardReducer', 'sorting'], state)
                const searchText = R.path(['dashboardReducer', 'callDetailsSearchText'], state)
                const columns = R.path(['dashboardReducer', 'tableColumns'], state)
                this.getCallDetailsDefault(dispatch, filters, dateSettings, {
                    pagenum: pagination.pagenum,
                    pagerows: pagination.pagerows
                }, sorting, {
                    text: searchText,
                    columns: getSearchableColumns(columns)
                })
                dispatch(this.getCoachingQueue())
                dispatch(this.getSelectedCallDetailsRequest(options.callId))
                .then(() => {
                    dispatch({
                        type: 'SET_MINI_REVIEW_ACTIVE_TAB',
                        payload: 'notification'
                    })
                })
            }, err => {
                alert('Something happened! Try again later.')
            })
        }
    },
    setActiveCall(call) {
        return dispatch => {
            dispatch({
                type: 'SHORT_CALL_DETAILS',
                payload: call
            })
            dispatch({
                type: 'SET_ACTIVE_CALL_REVIEW',
                payload: true
            })
            listenActions.getTranscript(call.systemData.xId, dispatch)
        }
    },
    closeCallMiniReview() {
        return dispatch => {
            dispatch({
                type: 'SHORT_CALL_DETAILS_ERROR',
                payload: false
            })
            dispatch({
                type: 'SHORT_CALL_DETAILS_LOADING',
                payload: false
            })
            dispatch({
                type: 'SET_ACTIVE_CALL_REVIEW',
                payload: false
            })
            dispatch({
                type: 'SET_PLAYER_START_POSITION',
                payload: 0
            })
            dispatch({
                type: 'SET_PLAYER_MARKERS',
                payload: []
            })
        }
    },
    queueForReview(id) {
        // debugger
        return (dispatch, getState) => {
            return sendRequest('sendCallToStartOfQueue', {
                payload: id
            }, 'DASHBOARDPOST').then(resp => {
                const state = getState()
                const queuedForReviewCalls = R.path(['headerReducer', 'queuedForReviewCalls'], state)
                queuedForReviewCalls.push(id)
                return dispatch({
                    type: 'SET_QUEUED_CALLS_FOR_REVIEW',
                    payload: queuedForReviewCalls
                })
            }, err => {
                alert('Something happened! Try again later.')
            })
        }
    },
    getQuestionInfo(id) {
        axios.cancel('getQuestionInfo')
        return (dispatch, getState) => {
            dispatch({
                type: 'QUESTION_DETAILS_LOADING',
                payload: true
            })
            dispatch({
                type: 'SET_ACTIVE_QUESTION',
                payload: true
            })
            const state = getState()
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            return sendRequest('getQuestionInfo', {
                payload: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getQuestionInfo', ('qid=' + id)).then(resp => {
                dispatch({
                    type: 'SET_ACTIVE_QUESTION_DETAILS',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({type: 'QUESTION_DETAILS_ERROR', payload: true})
            })
        }
    },
    getCalibrationQuestionInfo(id) {
        axios.cancel('getCalibrationQuestionInfo')
        return (dispatch, getState) => {
            dispatch({
                type: 'QUESTION_DETAILS_LOADING',
                payload: true
            })
            dispatch({
                type: 'SET_ACTIVE_QUESTION',
                payload: true
            })
            const state = getState()
            const dateSettings = R.path(['filtersReducer', 'dateSettings'], state)
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            return sendRequest('getCalibrationQuestionInfo', {
                payload: R.pick(["filters", "range"], convertFilterRequestData(filters, dateSettings))
            }, 'DASHBOARDPOST', false, 'getCalibrationQuestionInfo', ('qid=' + id)).then(resp => {
                dispatch({
                    type: 'SET_ACTIVE_QUESTION_DETAILS',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({type: 'QUESTION_DETAILS_ERROR', payload: true})
            })
        }
    },
    setActiveQuestion(data) {
        return dispatch => {
            dispatch({
                type: 'SET_ACTIVE_QUESTION',
                payload: true
            })
            dispatch({
                type: 'SET_ACTIVE_QUESTION_DETAILS',
                payload: data
            })
        }
    },
    closeQuestionDetails() {
        return dispatch => {
            dispatch({
                type: 'SET_ACTIVE_QUESTION',
                payload: false
            })
            dispatch({
                type: 'SET_ACTIVE_QUESTION_DETAILS',
                payload: null
            })
            dispatch({
                type: 'QUESTION_DETAILS_ERROR',
                payload: false
            })
            dispatch({
                type: 'QUESTION_DETAILS_LOADING',
                payload: false
            })
        }
    },
    setCalibratedOnly(value) {
        return dispatch => {
            const text = value ? 'calibrated' : ''
            dispatch({
                type: 'CALIBRATED_ONLY_CALLS',
                payload: value
            })
            const filters = getEmptyFilters()
            filters.missedBy = value ? 'qa' : 'agent'
            dispatch({
                type: 'SET_INITIAL_FILTERS',
                payload: filters
            })
            dispatch(this.setCallDetailsSearchText(text))
            dispatch(this.getSearchResults())
        }
    },

    setRecalibratedOnly(value) {
        return dispatch => {
            const text = value ? 'recalibrated' : ''
            dispatch({
                type: 'RECALIBRATED_ONLY_CALLS',
                payload: value
            })
            const filters = getEmptyFilters()
            filters.missedBy = value ? 'calibrator' : 'agent'
            dispatch({
                type: 'SET_INITIAL_FILTERS',
                payload: filters
            })
            dispatch(this.setCallDetailsSearchText(text))
            dispatch(this.getSearchResults())
        }
    },

    setRecordActiveTab(id) {
        return (dispatch, getState) => {
            const state = getState()
            const reviews = R.clone(R.path(['dashboardReducer', 'recordReviews'], state))
            const exist = reviews.find(r => r.recordId == id)
            if (exist) return dispatch(this.setActiveRecord(exist))
            const data = R.clone(R.path(['recordReducer'], state))
            data.recordId = id
            data.record = null
            data.scorecard = null
            data.chatData = null
            data.transcript = null
            data.loading = true
            dispatch(this.setLoadedReviews(data))
            const payload = {
                ID: id
            }
            const calls = [
                recordActions.getRecordID(payload, dispatch),
                recordActions.getScorecardRecordID(payload, dispatch),
            ]

            Promise.all(calls).then(values => {
                data.record = values[0].payload
                data.scorecard = values[1].payload
                data.loading = false
                if (
                    values[0].payload
                    && values[0].payload.review_type
                    && values[0].payload.review_type.toUpperCase() === 'CHAT'
                ) {
                    return dispatch(recordActions.getChat(values[0].payload.SESSION_ID))
                    .then(() => {
                        data.chatData = R.path(['listenReducer', 'chatData'], state)
                        dispatch(this.setLoadedReviews(data))
                        dispatch(this.setActiveRecord(data))
                    })
                } else if (
                    values[0].payload
                    && values[0].payload.review_type
                    && values[0].payload.review_type.toUpperCase() === 'AUDIO'
                ) {
                    return dispatch(recordActions.getTranscriptID(data))
                    .then(() => {
                        data.transcript = R.path(['recordReducer', 'transcript'], state)
                        dispatch(this.setLoadedReviews(data))
                        dispatch(this.setActiveRecord(data))
                    })
                } else {
                    dispatch(this.setLoadedReviews(data))
                    dispatch(this.setActiveRecord(data))
                }
            } , err => {
                console.log('GET RECORD ERROR - ', err.message)
                dispatch({
                    type: 'FETCH_RECORD_REJECTED',
                    payload: true
                })
            })
        }
    },

    closeReviewTab(id, isCurrentRoute) {
        return (dispatch, getState) => {
            const state = getState()
            const reviews = R.clone(R.path(['dashboardReducer', 'recordReviews'], state))
            const exist = reviews.find(r => r.recordId == id)
            if (exist) reviews.splice(reviews.indexOf(exist), 1)
            if (isCurrentRoute) dispatch({
                type: 'FETCH_RECORDID_FULFILLED',
                payload: null,
            })
            dispatch({
                type: 'RECORD_REVIEWS_UPDATED',
                payload: reviews
            })
        }
    },

    closeReviewAllTabs() {
        return (dispatch, getState) => {
            const state = getState()
            dispatch({
                type: 'FETCH_RECORDID_FULFILLED',
                payload: [],
            })
            dispatch({
                type: 'RECORD_REVIEWS_UPDATED',
                payload: []
            })
        }
    },

    setLoadedReviews(data) {
        return (dispatch, getState) => {
            const state = getState()
            const reviews = R.clone(R.path(['dashboardReducer', 'recordReviews'], state))
            const exist = reviews.find(r => r.recordId == data.recordId)
            if (!exist) {
                reviews.push(data)
            } else {
                reviews[reviews.indexOf(exist)] = data
            }
            dispatch({
                type: 'RECORD_REVIEWS_UPDATED',
                payload: reviews
            })
            dispatch({
                type: 'FETCH_RECORD_FULFILLED',
                payload: {
                    message: 'Data received'
                }
            })
            dispatch(loader.run({action: 'Hide'}))
        }
    },

    setActiveRecord(exist) {
        return dispatch => {
            dispatch({
                type: 'FETCH_RECORDID_FULFILLED',
                payload: exist.record,
            })
            dispatch({
                type: 'FETCH_SCORECARDID_FULFILLED',
                payload: exist.scorecard,
            })
            dispatch({
                type: 'FETCH_REVIEW_TRANSCRIPTID_FULFILLED',
                payload: exist.transcript,
            })
            dispatch({
                type: 'GET_CHAT_INFO_FULLFILLED',
                payload: exist.chatData,
            })
            dispatch(loader.run({action: 'Hide'}))
        }
    },

    getCallMissedItems(callId) {
        axios.cancel('getCallMissedItems' + callId)
        return (dispatch, getState) => {
            const state = getState()
            const calls = R.clone(R.path(['dashboardReducer', 'calls'], state))
            const filters = R.path(['filtersReducer', 'newFilters'], state)
            return sendRequest('getCallMissedItems', {
                payload: {
                    callId: callId,
                    missedBy: filters.missedBy,
                }
            }, 'DASHBOARDPOST', false, 'getCallMissedItems' + callId).then(resp => {
                const exist = calls.find(c => c.systemData.callId === callId)
                if (!exist) return
                exist.callMissedItems = resp.data
                return dispatch({
                    type: 'GET_CALL_DETAILS_FULFILLED',
                    payload: calls
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
            })
        }
    },

    addAnswerToFilters(answer) {
        return dispatch => {
            dispatch(this.resetAnswersAndCommentsInFilters())
            dispatch({
                type: 'SET_ANSWERS_TO_FILTERS',
                payload: answer ? [answer] : []
            })
            dispatch(this.resetPagination())
            dispatch(this.getCallDetailsDefault())
        }
    },

    addCommentToFilters(comment) {
        return dispatch => {
            dispatch(this.resetAnswersAndCommentsInFilters())
            dispatch({
                type: 'SET_ANSWER_COMMENTS_TO_FILTERS',
                payload: comment ? [comment] : []
            })
            dispatch(this.resetPagination())
            dispatch(this.getCallDetailsDefault())
        }
    },

    addCompositeAnswerToFilters(answer) {
        return dispatch => {
            dispatch(this.resetAnswersAndCommentsInFilters())
            dispatch({
                type: 'SET_COMPOSITE_ANSWERS_TO_FILTERS',
                payload: answer ? [answer] : []
            })
            dispatch(this.resetPagination())
            dispatch(this.getCallDetailsDefault())
        }
    },

    addCompositeCommentToFilters(comment) {
        return dispatch => {
            dispatch(this.resetAnswersAndCommentsInFilters())
            dispatch({
                type: 'SET_COMPOSITE_COMMENTS_TO_FILTERS',
                payload: comment ? [comment] : []
            })
            dispatch(this.resetPagination())
            dispatch(this.getCallDetailsDefault())
        }
    },

    addCustomCompositeToFilters(answer, comment) {
        return dispatch => {
            dispatch(this.resetAnswersAndCommentsInFilters())
            dispatch({
                type: 'SET_COMPOSITE_COMMENTS_TO_FILTERS',
                payload: comment ? [comment] : []
            })
            dispatch({
                type: 'SET_COMPOSITE_ANSWERS_TO_FILTERS',
                payload: answer ? [answer] : []
            })
            dispatch(this.resetPagination())
            dispatch(this.getCallDetailsDefault())
        }
    },

    resetAnswersAndCommentsInFilters() {
        return dispatch => {
            dispatch({type: 'RESET_ANSWERS_AND_COMMENTS_IN_FILTERS'})
        }
    },

    resetPagination() {
        return dispatch => dispatch({
            type: 'PAGE_CHANGED',
            payload: {
                pagenum: 1,
                pagerows: 20
            }
        })
    },
}

export default dashboardActions
