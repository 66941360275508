'use strict';

import { getDefaultDateSettings } from '../utils/filters'

const initialState ={
    dateSettings: getDefaultDateSettings(),
    loading: false,
    error: false,
    scorcardLoading: false,
    scorcardError: false,
    calibrationTab: 'details',
    searchingScorcard: false,
    searchingScorcardError: false,
    searchingCalls: false,
    searchingCallsError: false,
    addCalibrationCalls: [],
    calibrationCalls: [],
    calibrationCallsLoading: false,
    calibrationCallsError: false,
    scorecards: null,
    appnames: [],
    scorcardSearchText: '',
    addCallsSearchText: '',
    activeApp: null,
    activeScorecard: null,
    pagination: {
        pagenum: 1,
        pagerows: 40
    },
    sort: {
        sortBy: 'missedItemsCount',
        sortOrder: 'asc'
    },
    nextPageLoading: false,
    noCalibrationCallsOnServer: false,
    history                    : [],
    historyError               : false,
    historyLoading             : false,
    historySorting             : {
        'sortBy': 'actionDate',
        'sortOrder': 'desc'
    },
    historyPagination           : {
        pagenum: 1,
        pagerows: 10
    },
    historySearch              : {
        text: '',
        columns: [
            'actionDate',
            'ipAddress',
            'userName',
            'scorecardName',
            'action',
            'isExternal',
        ]
    },
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "CALI_PAGE_SET_ACTIVE_APP": {
            return {
                ...state,
                activeApp: action.payload
            }
        }
        case "CALI_PAGE_SET_ACTIVE_SCORECARD": {
            return {
                ...state,
                activeScorecard: action.payload
            }
        }
        case "CALI_PAGE_SCORECARD_LOADING": {
          return {
              ...state,
              scorcardError: false,
              scorcardLoading: action.payload
          }
        }
        case "CALI_PAGE_SCORECARD_ERROR": {
            return {
                ...state,
                scorcardLoading: false,
                scorcardError: action.payload
            }
        }
        case "CALI_PAGE_SCORECARD_LOADED": {
            return {
                ...state,
                scorcardLoading: false,
                scorcardError: false,
                scorecards: action.payload
            }
        }
        case "CALIBRATION_LOADING": {
            return {
                ...state,
                error: false,
                loading: action.payload
            }
        }
        case "CALIBRATION_ERROR": {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        case "CALI_APPNAMES_LOADED": {
            return {
                ...state,
                loading: false,
                error: false,
                appnames: action.payload
            }
        }
        case "CALIBRATION_CALLS_LOADING": {
            return {
                ...state,
                calibrationCallsLoading: action.payload,
                calibrationCallsError: false
            }
        }
        case "CALIBRATION_CALLS_LOADING_ERROR": {
            return {
                ...state,
                calibrationCallsLoading: false,
                calibrationCallsError: true
            }
        }
        case "CALIBRATION_CALLS_LOADED": {
            return {
                ...state,
                calibrationCalls: action.payload,
                calibrationCallsLoading: false,
                calibrationCallsError: false
            }
        }
        case "SET_CALI_PAGE_SCORECARD_SEARCH_TEXT": {
            return {
                ...state,
                scorcardSearchText: action.payload
            }
        }
        case "SET_CALI_PAGE_ADD_CALLS_SEARCH_TEXT": {
            return {
                ...state,
                addCallsSearchText: action.payload,
                addCalibrationCalls: [],
                pagination: initialState.pagination,
                noCalibrationCallsOnServer: false
            }
        }
        case "CALI_PAGE_SCORECARD_SEARCHING": {
            return {
                ...state,
                searchingScorcard: action.payload,
                searchingScorcardError: false
            }
        }
        case "CALI_PAGE_SCORECARD_SEARCHING_ERROR": {
            return {
                ...state,
                searchingScorcard: false,
                searchingScorcardError: true
            }
        }
        case "CALI_PAGE_CALLS_SEARCHING": {
            return {
                ...state,
                searchingCalls: action.payload,
                searchingCallsError: false
            }
        }
        case "CALI_PAGE_CALLS_SEARCHING_ERROR": {
            return {
                ...state,
                searchingCalls: false,
                searchingCallsError: action.payload
            }
        }
        case "ADD_CALIBRATION_CALLS_LOADED": {
            return {
                ...state,
                addCalibrationCalls: action.payload,
                searchingCalls: false,
                searchingCallsError: false
            }
        }
        case "CALIBRATION_PAGE_NUMBER_CHANGED": {
            return {
                ...state,
                pagination: action.payload,
            }
        }
        case "CALIBRATION_NEXT_PAGE_LOADING": {
            return {
                ...state,
                nextPageLoading: action.payload,
            }
        }
        case "SET_ADD_CALIBRATION_DATE_SETTINGS": {
            return {
                ...state,
                dateSettings: action.payload,
                noCalibrationCallsOnServer: false
            }
        }
        case "NO_CALIBRATION_CALLS_ON_SERVER": {
            return {
                ...state,
                noCalibrationCallsOnServer: action.payload
            }
        }
        case "SET_CALIBRATION_CALLS_SORT": {
            return {
                ...state,
                sort: action.payload,
                pagination: initialState.pagination
            }
        }
        case "SET_CALIBRATION_CALLS_DEFAULT_SORT": {
            return {
                ...state,
                sort: initialState.sort
            }
        }
        case "GET_HISTORY_LOADING": {
            return {
                ...state,
                historyError: false,
                historyLoading: action.payload
            }
        }
        case "GET_HISTORY": {
            return {
                ...state,
                history: action.payload,
                historyError: false,
                historyLoading: false
            }
        }
        case "GET_HISTORY_ERROR": {
            return {
                ...state,
                historyError: action.payload,
                historyLoading: false
            }
        }
        case "SET_HISTORY_SORTING": {
            return {
                ...state,
                historySorting: action.payload
            }
        }
        case "SET_HISTORY_SEARCH": {
            return {
                ...state,
                historySearch: {
                    text: action.payload,
                    columns: state.historySearch.columns
                }
            }
        }
        case "SET_HISTORY_PAGINATION": {
            return {
                ...state,
                historyPagination: {
                    pagenum: action.payload,
                    pagerows: state.historyPagination.pagerows
                }
            }
        }
    }
    return state
}
