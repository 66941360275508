'use strict'

import {connect} from 'react-redux'
import numeral from 'numeral'
import classNames from 'classnames'

import Answer from './Answer'
import CompositeOption from './CompositeOption'
import SimpleComment from './SimpleComment'

import dashboardActions from '../../../actions/dashboardActions'

@connect(store => {
    return {
        answers: store.filtersReducer.compositeAnswers,
        comments: store.filtersReducer.compositeComments
    }
})

export default class CompositeQuestion extends React.Component {
    constructor(props) {
        super(props)

        this.addAnswerToFilters = this.addAnswerToFilters.bind(this)
        this.addCommentToFilters = this.addCommentToFilters.bind(this)
        this.addCustomToFilters = this.addCustomToFilters.bind(this)
    }

    addAnswerToFilters(id, inFilters, isRight, total) {
        const {data, dispatch} = this.props
        if (inFilters || !id) return dispatch(dashboardActions.addCompositeAnswerToFilters(null))
        dispatch(dashboardActions.addCompositeAnswerToFilters({
            questionText: data.questionName,
            id          : id,
            isRight     : isRight,
            total       : total,
        }))
    }

    addCommentToFilters(id, answerText, inFilters, total) {
        const {data, dispatch} = this.props
        if (inFilters || !id) return dispatch(dashboardActions.addCompositeCommentToFilters(null))
        dispatch(dashboardActions.addCompositeCommentToFilters({
            id          : id,
            answerText  : answerText,
            questionText: data.questionName,
            total       : total,
        }))
    }

    addCustomToFilters(id, answerText, inFilters, isRight, total) {
        const {data, dispatch} = this.props
        if (inFilters || !id) return dispatch(dashboardActions.addCustomCompositeToFilters(null))
        dispatch(dashboardActions.addCustomCompositeToFilters({
            questionText: data.questionName,
            id          : isRight
            ? data.compositeQuestionStat.rightAnswerInfo.answerId
            : data.compositeQuestionStat.wrongAnswerInfo.answerId,
            isRight     : isRight,
            total       : total,
        }, {
            id          : id,
            answerText  : answerText,
            questionText: data.questionName,
            isRight     : isRight,
            total       : total,
        }))
    }

    renderComments() {
        const {data, comments} = this.props
        const {compositeQuestionStat, total} = data
        const {totalRight, wrongAnswerInfo} = compositeQuestionStat
        return compositeQuestionStat.comments.map((c, i) => {
            const inFilters = !!comments.find(fc => fc.id === c.commentId)
            return <CompositeOption
                key={'simple-comment-' + c.commentId + i}
                data={c}
                answerText={wrongAnswerInfo.answerText}
                addToFilters={this.addCommentToFilters}
                inFilters={inFilters}
                total={total}
                percent={numeral(total ? c.total/total : 0).format('0.00%')} />
        })
    }
    renderWrongAnswer() {
        const {answers, comments, data} = this.props
        const {compositeQuestionStat, total} = data
        const {totalRight, wrongAnswerInfo} = compositeQuestionStat
        const inFilters = !!answers.find(fa => fa.id === wrongAnswerInfo.answerId)
        const customInFilters = inFilters && !!comments.find(fc => fc.id === -666)
        return (
            <div class='answer-section'>
                <div class='answer-title'>
                    <Answer
                        id={wrongAnswerInfo.answerId}
                        answerText={wrongAnswerInfo.answerText}
                        total={total-totalRight}
                        isRight={false}
                        answerClass={'answer is-wrong'}
                        addToFilters={this.addAnswerToFilters}
                        inFilters={inFilters} />
                    <div class='numbers'>
                        <div class='calls'>{total - totalRight} of {total}</div>
                        <div class='percent'>{numeral((total - totalRight)/total).format('0.00%')}</div>
                    </div>
                </div>
                <div class='comments'>
                    {this.renderComments()}
                    {
                        wrongAnswerInfo.totalCustomComments > 0
                        ? <SimpleComment
                            data={{
                                commentId: -666,
                                commentText: 'Custom comments',
                                total: wrongAnswerInfo.totalCustomComments
                            }}
                            answerText={wrongAnswerInfo.answerText}
                            isRight={false}
                            addToFilters={this.addCustomToFilters}
                            inFilters={customInFilters}
                            total={wrongAnswerInfo.totalCustomComments}
                            percent={null} />
                        : null
                    }
                </div>
            </div>
        )
    }
    renderRightAnswer() {
        const {answers, comments, data} = this.props
        const {compositeQuestionStat, total} = data
        const {totalRight, rightAnswerInfo} = compositeQuestionStat
        const inFilters = !!answers.find(fa => fa.id === rightAnswerInfo.answerId)
        const customInFilters = inFilters && !!comments.find(fc => fc.id === -666)
        return (
            <div class='answer-section'>
                <div class='answer-title'>
                    <Answer
                        id={rightAnswerInfo.answerId}
                        answerText={rightAnswerInfo.answerText}
                        total={totalRight}
                        isRight={true}
                        answerClass={'answer'}
                        addToFilters={this.addAnswerToFilters}
                        inFilters={inFilters} />
                    <div class='numbers'>
                        <div class='calls'>{totalRight} of {total}</div>
                        <div class='percent'>{numeral(totalRight/total).format('0.00%')}</div>
                    </div>
                </div>
                {
                    rightAnswerInfo.totalCustomComments > 0
                    ? <div class='comments'>
                        <SimpleComment
                            data={{
                                commentId: -666,
                                commentText: 'Custom comments',
                                total: rightAnswerInfo.totalCustomComments
                            }}
                            answerText={rightAnswerInfo.answerText}
                            isRight={true}
                            addToFilters={this.addCustomToFilters}
                            inFilters={customInFilters}
                            total={rightAnswerInfo.totalCustomComments}
                            percent={null} />
                    </div>
                    : null
                }
            </div>
        )
    }
    render() {
        return (
            <div class='box-context'>
                {this.renderRightAnswer()}
                {this.renderWrongAnswer()}
            </div>
        )
    }
}
