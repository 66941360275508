'use strict'

import * as R from 'ramda'
import ReactSlider from 'react-slider'

export default class PlayerVolume extends React.Component {
    constructor(props) {
        super(props)
        this.previousVolume = parseInt(props.settings.volume*100, 10) || 50
        this.state = {
            mute: props.settings.mute || props.error,
            volume: props.settings.mute ? 0 : this.previousVolume
        }
    }

    setVolume() {
        const val = this.refs.volume.getValue()
        this.previousVolume = val
        if (val === 0) {
            this.mute()
        } else {
            this.setState({
                volume: val,
                mute: false
            })
            this.props.setVolume(val/100)
            window.localStorage.playerVolume = val
            window.localStorage.mute = false
        }
    }

    changeVolume = (diff) => {
        let val = this.refs.volume.getValue()
        if (diff === -5) {
            val = val > 0 ? val + diff : 0
        } else {
            val = val < 100 ? val + diff : 100
        }
        if (val === 0) {
            this.mute()
        } else {
            this.setState({
                volume: val,
                mute: false
            })
            this.props.setVolume(val/100)
            window.localStorage.playerVolume = val
            window.localStorage.mute = false
        }
    }

    mute() {
        if (!this.previousVolume) {
            this.previousVolume = 50
            window.localStorage.playerVolume = this.previousVolume
        }
        const volume = this.state.mute ? this.previousVolume : 0
        this.refs.volume.setState({
            value: [volume]
        })
        this.setState({
            mute: !this.state.mute,
            volume: volume
        })
        this.props.setVolume(volume/100)
        window.localStorage.mute = !this.state.mute
    }

    componentWillReceiveProps(nextProps) {
        if (!R.equals(nextProps.settings, this.props.settings)) {
            const volume = nextProps.settings.mute ? 0 : parseInt(nextProps.settings.volume*100, 10)
            this.previousVolume = volume
            this.refs.volume.setState({
                value: [volume]
            })
			this.setState({
                volume: volume,
                mute: nextProps.settings.mute * 100
			})
		}
    }

    render() {
        const volume = this.state.volume
        const error = this.props.error
        return (
            <div class={'volume-section' + (error ? ' error' : '')}>
                <div class='volume-options'>
                    <span
                        onClick={this.mute.bind(this)}
                        class='material-icons volume-icon'>
                        {this.state.mute ? 'volume_off' : 'volume_up'}
                    </span>
                    <span class='slider-sign minus' onClick={() => this.changeVolume(-5)}>-</span>
                    <ReactSlider
                        ref='volume'
                        className='volume-slider'
                        onChange={this.setVolume.bind(this)}
                        defaultValue={volume}
                        withBars>
                        <div class='slider-trigger volume-triger'></div>
                    </ReactSlider>
                    <span class='slider-sign plus' onClick={() => this.changeVolume(5)}>+</span>
                    <div class='section-title'>
                        {this.state.mute ? 'Mute' : volume + '%'}
                    </div>
                </div>
                <div class='tooltip-hot-key'>
                    <div>
                        <div class='name'>Volume down (5 points)</div>
                        <div class='key'>F6</div>
                    </div>
                    <div>
                        <div class='name'>Volume up (5 points)</div>
                        <div class='key'>F7</div>
                    </div>
                </div>
            </div>

        )
    }
}
