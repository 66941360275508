import * as R from 'ramda'

import {getDefaultColumns, salesFilters} from './utils'

const initialState = {
    isConversionActive             : false,
    modalOptions                   : null,
    statsData                      : null,
    statsDataLoading               : false,
    statsDataError                 : false,
    statsDataTimeoutError          : false,
    performanceChartType           : 0,
    performanceData                : null,
    performanceDataLoading         : false,
    performanceDataError           : false,
    performanceDataTimeoutError    : false,
    chartData                      : null,
    chartDataItemsTotal            : null,
    chartDataLoading               : false,
    chartDataError                 : false,
    chartDataTimeoutError          : false,
    chartType                      : 'agent', // 'agent', 'agent_group', 'campaign'
    chartPagination                : {
        pagerows: 5,
        pagenum : 1,
    },
    chartTypeSorting               : {
        sortBy   : 'ID',
        sortOrder: 'desc',
    },
    callDetails                    : null,
    callDetailsLoading             : false,
    callDetailsError               : false,
    callDetailsTimeoutError        : false,
    pagination                     : {
        pagerows: 20,
        pagenum : 1,
    },
    searchText                     : '',
    salesFilters                   : salesFilters(),
    availableFilters               : [],
    filtersLoading                 : true,
    filtersError                   : false,

    objections                     : [],
    objectionsLoading              : false,
    objectionsError                : false,
    objectionsSorting              : {
        sortBy:    'percentage',
        sortOrder: 'asc',
    },

    tableColumns:                       getDefaultColumns(),
    commentIds                     : [],
    filterBy                       : null,
    exportingCallDetails       : false,
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case 'SALES_FILTER_BY': {
            return {
                ...state,
                filterBy: action.payload,
            }
        }
        case 'SET_SALES_PAGE_ACTIVE': {
            return {
                ...state,
                isConversionActive: action.payload,
            }
        }
        case "SALES_TABLE_COLUMNS_CHANGED": {
            return {
                ...state,
                tableColumns: action.payload
            }
        }
        case 'SALES_STATS_FULFILLED': {
            return {
                ...state,
                statsData            : action.payload,
                statsDataLoading     : false,
                statsDataError       : false,
                statsDataTimeoutError: false,
            }
        }
        case 'SALES_STATS_LOADING': {
            return {
                ...state,
                statsDataLoading     : true,
                statsDataError       : false,
                statsDataTimeoutError: false,
            }
        }
        case 'SALES_STATS_ERROR': {
            return {
                ...state,
                statsDataError  : true,
                statsDataLoading: false,
            }
        }
        case 'SALES_STATS_TIMEOUT_ERROR': {
            return {
                ...state,
                statsDataTimeoutError: true,
            }
        }
        case 'SALES_PERFORMANCE_CHART_TYPE': {
            return {
                ...state,
                performanceChartType: action.payload,
            }
        }
        case 'SALES_PERFORMANCE_DATA_FULFILLED': {
            return {
                ...state,
                performanceData            : action.payload,
                performanceDataLoading     : false,
                performanceDataError       : false,
                performanceDataTimeoutError: false,
            }
        }
        case 'SALES_PERFORMANCE_DATA_LOADING': {
            return {
                ...state,
                performanceDataLoading     : true,
                performanceDataError       : false,
                performanceDataTimeoutError: false,
            }
        }
        case 'SALES_PERFORMANCE_DATA_ERROR': {
            return {
                ...state,
                performanceDataError  : true,
                performanceDataLoading: false,
            }
        }
        case 'SALES_PERFORMANCE_DATA_TIMEOUT_ERROR': {
            return {
                ...state,
                performanceDataTimeoutError: true,
            }
        }
        case 'SALES_CHART_DATA_FULFILLED': {
            return {
                ...state,
                chartData            : action.payload,
                chartDataLoading     : false,
                chartDataError       : false,
                chartDataTimeoutError: false,
            }
        }
        case 'SALES_CHART_ITEMS_TOTAL': {
            return {
                ...state,
                chartDataItemsTotal: action.payload,
            }
        }
        case 'SALES_CHART_DATA_LOADING': {
            return {
                ...state,
                chartDataLoading     : true,
                chartDataError       : false,
                chartDataTimeoutError: false,
            }
        }
        case 'SALES_CHART_DATA_ERROR': {
            return {
                ...state,
                chartDataError  : true,
                chartDataLoading: false,
            }
        }
        case 'SALES_CHART_DATA_TIMEOUT_ERROR': {
            return {
                ...state,
                chartDataTimeoutError: true,
            }
        }
        case 'SALES_CHART_TYPE': {
            return {
                ...state,
                chartType: action.payload,
            }
        }
        case 'SALES_CHART_PAGINATION': {
            return {
                ...state,
                chartPagination: action.payload,
            }
        }
        case 'SALES_CALL_DETAILS_FULFILLED': {
            return {
                ...state,
                callDetails            : action.payload,
                callDetailsLoading     : false,
                callDetailsError       : false,
                callDetailsTimeoutError: false,
            }
        }
        case 'SALES_CALL_DETAILS_LOADING': {
            return {
                ...state,
                callDetailsLoading     : true,
                callDetailsError       : false,
                callDetailsTimeoutError: false
            }
        }
        case 'SALES_CALL_DETAILS_ERROR': {
            return {
                ...state,
                callDetailsError  : true,
                callDetailsLoading: false,
            }
        }
        case 'SALES_CALL_DETAILS_TIMEOUT_ERROR': {
            return {
                ...state,
                callDetailsTimeoutError: true,
            }
        }
        case 'SET_SALES_PAGINATION': {
            return {
                ...state,
                pagination: action.payload,
            }
        }
        case 'SET_SALES_MODAL_OPTIONS': {
            return {
                ...state,
                modalOptions: action.payload,
            }
        }
        case 'SET_SALES_SEARCH_TEXT': {
            return {
                ...state,
                searchText: action.payload,
            }
        }
        case 'SET_SALES_FILTERS': {
            return {
                ...state,
                salesFilters: action.payload,
            }
        }
        case "SALES_AVAILABLE_FILTERS_LOADED": {
            return {
                ...state,
                availableFilters: action.payload,
                filtersLoading: false,
                filtersError: false
            }
        }
        case "SALES_AVAILABLE_FILTERS_LOADING": {
            return {
                ...state,
                filtersLoading: action.payload
            }
        }
        case "SALES_AVAILABLE_FILTERS_LOADING_ERROR": {
            return {
                ...state,
                filtersLoading: false,
                filtersError: true
            }
        }
        case 'SALES_CHART_TYPE_SORTING': {
            return {
                ...state,
                chartTypeSorting: action.payload,
            }
        }
        case 'SALES_OBJECTION_LOADING': {
            return {
                ...state,
                objectionsLoading: true,
                objectionsError: false,
            }
        }
        case 'SALES_OBJECTION': {
            return {
                ...state,
                objections: action.payload,
                objectionsLoading: false,
                objectionsError: false,
            }
        }
        case 'SALES_STATS_ERROR': {
            return {
                ...state,
                objectionsError: true,
                objectionsLoading: false,
            }
        }
        case "SALES_SET_OBJECTION_SORTING": {
            return {
                ...state,
                objectionsSorting: action.payload
            }
        }
        case "OBJECTION_REASONS_FOR_FILTERS": {
            return {
                ...state,
                commentIds: action.payload
            }
        }
         case "SALES_EXPORT_CALL_DETAILS": {
            return {
                ...state,
                exportingCallDetails: action.payload
            }
        }
    }
    return state
}
