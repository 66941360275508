'use strict'

import { PropTypes } from 'prop-types'

export const scorecardPropType = PropTypes.shape({
    scorecardId                :PropTypes.number.isRequired,
    scorecardName              :PropTypes.string.isRequired
})

export const simpleCommentPropType = PropTypes.shape({
    commentText            :PropTypes.string,
	commentId              :PropTypes.number,
	total                  :PropTypes.number.isRequired
})

export const simpleAnswerPropType = PropTypes.shape({
    comments                   :PropTypes.arrayOf(simpleCommentPropType),
    answerText                 :PropTypes.string,
    isRightAnswer              :PropTypes.bool.isRequired,
    total                      :PropTypes.number.isRequired,
    totalCustomComments        :PropTypes.number.isRequired
})

export const simpleQuestionStatPropType = PropTypes.shape({
    answerList                 :PropTypes.arrayOf(simpleAnswerPropType)
})

export const compositeAnswerInfoPropType = PropTypes.shape({
    totalCustomComments        :PropTypes.number.isRequired,
    answerText                 :PropTypes.string.isRequired
})

export const compositeCommentPropType = PropTypes.shape({
    commentText                :PropTypes.string.isRequired,
	commentId                  :PropTypes.number,
	total                      :PropTypes.number.isRequired
})

export const compositeQuestionStatPropType = PropTypes.shape({
    totalRight                 :PropTypes.number.isRequired,
    rightAnswerInfo            :compositeAnswerInfoPropType,
    wrongAnswerInfo            :compositeAnswerInfoPropType,
    comments                   :PropTypes.arrayOf(compositeCommentPropType)
})

export const questionInfoPropType = PropTypes.shape({
    total                      :PropTypes.number.isRequired,
    scorecard                  :scorecardPropType,
    compositeQuestionStat      :compositeQuestionStatPropType,
    isComposite                :PropTypes.bool.isRequired,
    isLinked                   :PropTypes.bool.isRequired,
    questionId                 :PropTypes.number,
    questionName               :PropTypes.string.isRequired,
    questionType               :PropTypes.string.isRequired,
    sectionName                :PropTypes.string.isRequired,
    simpleQuestionStat         :simpleQuestionStatPropType
})
