export function getDefaultColumns() {
    return [
        // from system data
        {id: "callType",          value: "Type",          checked: true, isRequired: true,  sortableValue: false, sortable: false, searchable: false},
        // {id: "callId",            value: "Call ID",       checked: true, isRequired: false, sortableValue: false, sortable: false, searchable: false},
        {id: "callAudioLength",   value: "Duration",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "scorecardName",     value: "Scorecard",     checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "reviewDate",        value: "Review Date",   checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        // {id: "receivedDate",      value: "Received Date", checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "reviewerName",      value: "Reviewer",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "agentScore",        value: "Score",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "missedItemsCount",  value: "Missed Items",  checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        // from meta data
        {id: "callDate",          value: "Call Date",     checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "agentName",         value: "Agent",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "agentGroup",        value: "Group",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "campaign",          value: "Campaign",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "sessionId",         value: "Session ID",    checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        // {id: "profileId",         value: "Profile ID",    checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "prospectFirstName", value: "First Name",    checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "prospectLastName",  value: "Last Name",     checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "prospectPhone",     value: "Phone",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "prospectEmail",     value: "Email",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        // {id: "agentId",           value: "Agent ID",      checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        // required sortable
        {id: "callReviewStatus",  value: "Review Status", checked: true, isRequired: true, sortableValue: false,  sortable: true, searchable: false},
        {id: "callFailed",        value: "Result",        checked: true, isRequired: true, sortableValue: false,  sortable: true, searchable: false}, //sortable was false for some reason
        // icons
        {id: "icons",             value: "<icons>",       checked: true, isRequired: true, sortableValue: false,  sortable: false, searchable: false}
    ]
}