'use strict'

import classNames from 'classnames'
import { connect } from 'react-redux'

import {systemDataPropType} from './propTypes'

import dashboardActions from '../../../actions/dashboardActions'

const isBad = (status) => {
    return status === 'bad call'
}

const isTranscript = (status) => {
    return status === 'transcript only'
}

const pendingSt = (status) => {
    return status === 'pending'
}

@connect((store) => {
    return {
        queuedForReviewCalls: store.headerReducer.queuedForReviewCalls
    }
})

export default class ReviewStatusCell extends React.Component {
    constructor(props) {
        super(props)

        this.queueForReview = this.queueForReview.bind(this)
        this.state = {
            queued: false
        }
    }
    static propTypes = {
        systemData: systemDataPropType.isRequired,
    }
    queueForReview(e) {
        const {systemData} = this.props
        this.props.dispatch(dashboardActions.queueForReview(systemData.xId)).then(() => {
            this.setState({
                queued: true
            })
        })
        e.preventDefault()
        e.stopPropagation()
    }

    render() {
        const {systemData, globalSearch, queuedForReviewCalls} = this.props
           //debugger
        const bad = isBad(systemData.callReviewStatus)
        const transcript = isTranscript(systemData.callReviewStatus)
        const tScript = 'T-script'
        const pending = pendingSt(systemData.callReviewStatus)
        let queued = this.state.queued
        if (transcript || pending) {
            queued = queuedForReviewCalls.indexOf(systemData.xId) !== -1
        }
        let qa = false
        if (systemData.callReviewStatus.toUpperCase() === 'QA/QA') {
            qa =true
        }
        const qaTitle = 'QA / QA'
        const status = qa ? 'qa' : systemData.callReviewStatus
        const scoreChanged = systemData.scoreChanged
        const iconPosition = classNames({
            positive: scoreChanged > 0,
            negative: scoreChanged < 0
        })
        return (
            <td class='review-status-cell'>
                {
                    (pending || transcript) && globalSearch ?
                    <div class='pending-cell'>
                        <div class={transcript ? 'shortened background-transcript' :'shortened background-' +  status}>
                            {
                                transcript ?
                                tScript :
                                <div>{systemData.callReviewStatus}</div>
                            }
                        </div>
                        {
                            queued ?
                            <i class={'icon-ic-check color-' + systemData.callReviewStatus} /> :
                            <div
                                class={transcript ? 'pending-btn background-transcript' : 'pending-btn background-' + status}
                                onClick={this.queueForReview}>
                                    <div class='icon icon-ic-queue-review'/>
                            </div>
                        }
                    </div> :
                    <div title={
                            bad ? systemData.badCallReason :
                            transcript ? systemData.transcriptReviewStatus ||
                            '' : ''
                            } class={'status-item background-' + status}>
                        {
                            transcript ?
                            tScript :
                            qa ?
                            qaTitle :
                            scoreChanged === null || scoreChanged === 0 ?
                            <div class='status'>{systemData.callReviewStatus}</div> :
                                <div class='status'>
                                <div>{systemData.callReviewStatus}</div>
                                <div class="icon " >
                                {
                                    scoreChanged > 0 ?
                                    <i class="material-icons">trending_up</i> :
                                    <i class="material-icons">trending_down</i>
                                }
                                </div>
                            </div>
                        }
                    </div>
                }
            </td>
        )
    }
}
