'use strict'

export default class PlayPause extends React.Component {
    render() {
        const {triggerPlay, playing, error} = this.props
        return <div class={'player-controls' + (error ? ' error' : '')}>
            <span onClick={triggerPlay} class='material-icons'>{playing ? 'pause' : 'play_arrow'}</span>
            <div class='tooltip-hot-key'>
                <div>
                    <div class='name'>Play</div>
                    <div class='key'>F10</div>
                </div>
            </div>
        </div>
    }
}
