'use strict'

export const getAddressMap = () => {
    return {
        switchUser:                           ['post', '/user/SwitchUser'],
        switchUserBack:                       ['post', '/user/SwitchUserBack'],
        getUserSearchName:                    ['post', '/user/GetUserList'],
        getRecordById:                        ['post', '/GetRecordID'],
        getScorecardRecord:                   ['post', '/GetScorecardRecord'],

        getScorecardRecordID:                 ['post', '/GetScorecardRecordID'],
        getTranscriptID:                      ['post', '/GetTranscriptID'],
        getTranscriptRecordXID:               ['post', '/transcript/GetRecordXID'],
        getSearchResults:                     ['post', '/GetSearchResults'],
        updateQuestion:                       ['post', '/UpdateQuestionArray'],
        updateQAComments:                     ['post', '/UpdateQACommentsArray'],
        getTranscript:                        ['post', '/GetTranscript'],
        getChat:                              ['post', '/GetChat'],
        updateDispute:                        ['post', '/UpdateDispute'],
        updateOptions:                        ['post', '/UpdateOptions'],
        updateClerkedData:                    ['post', '/UpdateClerkData'],
        deleteComment:                        ['post', '/DeleteComment'],
        updateCallComment:                    ['post', '/UpdateCallComment'],
        removeCalibrator:                     ['post', '/RemoveCalibrator'],
        getNextCall:                          ['post', '/GetNextCall'],
        markCallBad:                          ['post', '/MarkCallBad'],
        markCalibrationBad:                   ['post', '/MarkCalibrationBad'],
        changeCallScorecard:                  ['post', '/ChangeCallScorecard'],
        saveCall:                             ['post', '/PostListen'],
        getNextCalibration:                   ['post', '/GetNextCalibration'],
        calibrateCall:                        ['post', '/PostCalibration'],
        updateSpotcheck:                      ['post', '/UpdateSpotcheck'],
        getNextTraining:                      ['post', '/GetNextTraining'],
        postTraining:                         ['post', '/PostTraining'],
        getTrainingReview:                    ['post', '/GetTrainingReview'],
        manipulateAudio:                      ['post', '/ManipulateAudio'],
        uploadFile:                           ['post', '/UploadFile'],
        heartbeat:                            ['post', '/UpdateHeartbeat'],

        //review actions
        getRelatedDealerships:                ['post', '/review/GetRelatedDealerships'],
        getDealershipEmployees:               ['post', '/review/GetDealershipEmployees'],
        updateMetadataInfo:                   ['post', '/review/UpdateMetadataInfo'],
        getNotificationReasons:               ['post', '/review/getNotificationReasons'],
        createThread:                         ['post', '/review/CreateThread'],

        // user actions
        getAllUserSettings:                   ['post', '/user/GetAllUserSettings'],
        getUserSession:                       ['post', '/user/GetSession'],
        getUserSettings:                      ['post', '/user/GetSavedUserSettings'],
        saveUserSettings:                     ['post', '/user/SaveUserSettings'],
        getCallsCount:                        ['post', '/user/getCallsCount'],
        getVerifyUsername:                    ['post', '/user/VerifyUsername'],
        searchCalls:                          ['post', '/dashboard/SearchCalls'],
        sendCallToStartOfQueue:               ['post', '/dashboard/SendPendingCallToStartOfQueue'],

        // dashboard actions
        getAvailableTableColumns:             ['post', '/dashboard/GetAvailableTableColumns'],
        getAvailableCoachingQueueColumns:     ['post', '/dashboard/GetAvailableCoachingQueueColumns'],
        getCallDetailsCustom:                 ['post', '/dashboard/GetCallDetailsCustomColumns'],
        getAvailableFilters:                  ['post', '/dashboard/GetAvailableDashboardFilter'],
        getCalendarCounts:                    ['post', '/dashboard/GetCalendarCounts'],

        getAgentRanking:                      ['post', '/dashboard/GetAgentRanking'],
        getStats:                             ['post', '/dashboard/GetAgregatedStatistics'],
        getScorePerformance:                  ['post', '/dashboard/GetAvgScore'],
        getTopMissed:                         ['post', '/dashboard/GetTopMissedItems'],
        getTopQAMissed:                       ['post', '/dashboard/GetTopQAMissedItems'],
        getTopCalibratorMissed:               ['post', '/dashboard/GetTopCalibratorMissedItems'],
        getCoachingQueue:                     ['post', '/dashboard/GetCoachingQueue'],
        getGroupPerformance:                  ['post', '/dashboard/GetGroupPerformance'],
        getCampaignPerformance:               ['post', '/dashboard/GetCampaignPerformance'],
        getCallDetails:                       ['post', '/dashboard/GetCallDetails'],
        getCallShortInfo:                     ['post', '/dashboard/GetCallShortInfo'],
        sendNotification:                     ['post', '/dashboard/SendNotification'],
        getCallsLeft:                         ['post', '/dashboard/GetCallsLeft'],
        getMyPay:                             ['post', '/dashboard/GetMyPayInfo'],
        getSectionsInfo:                      ['post', '/dashboard/GetSectionsInfo'],
        getWebsiteStats:                      ['post', '/dashboard/GetWebsiteStatistic'],
        getCalibrationSectionsInfo:           ['post', '/dashboard/GetCalibrationSectionsInfo'],
        getAvgCallTime:                       ['post', '/dashboard/GetAvgCallTime'],
        getScorecardDetailedInfo:             ['post', '/dashboard/GetScorecardDetailedInfo'],

        getCalibrationQuestionInfo:           ['post', '/dashboard/GetCalibrationQuestionInfo'],
        getQuestionInfo:                      ['post', '/dashboard/GetQuestionInfo'],
        exportCallDetails:                    ['post', '/dashboard/ExportCallDetails'],
        getExportQueue:                       ['post', '/dashboard/GetExportQueue'],
        deleteFromExportQueue:                ['post', '/dashboard/DeleteFromExportQueue'],
        getPendingCalibrations:               ['post', '/dashboard/GetPendingCalibrations'],
        getPendingCalibrationsInternal:       ['post', '/dashboard/GetPendingCalibrationsInternal'],
        getCallMissedItems:                   ['post', '/dashboard/GetCallMissedItems'],
        getExportAgentRanking:                ['post', '/dashboard/GetExportAgentRanking'],
        getExportTopMissed:                   ['post', '/dashboard/ExportTopMissedPoints'],
        getQuchingQueueCustomColumns:         ['post', '/v2.4/dashboard/GetQuchingQueueCustomColumns'],
        getExportMyPay:                       ['post', '/dashboard/ExportMyPay'],
        getExportQoachingQueue:               ['post', '/dashboard/ExportQoachingQueue'],
        getExportTopQaMissed:                 ['post', '/dashboard/ExportTopQaMissedPoints'],
        getExportTopCalibratorMissed:         ['post', '/dashboard/ExportTopCalibratorMissedPoints'],
        getExportWebsiteStats:                ['post', '/dashboard/ExportWebSiteStatistic'],
        getExportGroupPerformance:            ['post', '/dashboard/ExportGroupPerformance'],
        getExportCampaignPerformance:         ['post', '/dashboard/ExportCampaignPerformance'],
        getExportCallsLeft:                   ['post', '/dashboard/ExportCallsLeft'],
        exportSectionScores:                  ['post', '/dashboard/ExportSections'],
        exportCalibrationSectionScores:       ['post', '/dashboard/ExportCalibrationSections'],
        exportScorecardSummary:               ['post', '/dashboard/ExportScorecardSummary'],

        // calibration page actions
        getCalibrationQueue:                  ['post', '/calibration/GetCalibrationQueue'],
        getCalibrationCalls:                  ['post', '/calibration/GetCalibrationCalls'],
        searchcalls:                          ['post', '/calibration/searchcallsbyAppName'],
        addCallsToCalibration:                ['post', '/calibration/AddCallsToCalibration'],
        deleteCalibration:                    ['post', '/calibration/DeleteReview'],
        completeCalibration:                  ['post', '/calibration/CompleteReview'],
        getCalibrationHistory:                ['post', '/calibration/GetCalibrationHistory'],

        // billing page actions
        getBillingInfo:                       ['post', '/billing/GetBilling'],
        getExportBillingInfo:                 ['post', '/billing/ExportBilling'],

        getBadCalls:                          ['post', '/GetBadCalls'],
        getRecordXID:                         ['post', '/GetRecordXID'],
        acceptAsBad:                          ['post', '/AcceptAsBad'],
        resetCall:                            ['post', '/ResetCallX'],
        updateMetaData:                       ['post', '/UpdateMetaData'],
        // updateMetaData:                    ['post', '/AddExistingSchool'],

        // guidelines page actions
        getActiveScorecard:                   ['post', '/guidelines/GuidelineScorecardInfo'],
        updateGuidelinesMetaData:             ['post', '/guidelines/UpdateMetadataInfo'],
        saveGuidelinesOtherData:              ['post', '/guidelines/SaveOtherDataInfo'],
        removeGuidelinesOtherData:            ['post', '/guidelines/RemoveOtherDataInfo'],
        saveGuidelinesSchool:                 ['post', '/guidelines/SaveSchoolDataInfo'],
        removeGuidelinesSchool:               ['post', '/guidelines/RemoveSchoolDataInfo'],
        resetGuidelinesInfo:                  ['post', '/guidelines/ResetScorecardInfo'],
        updateGuidelinesScorecardStatus:      ['post', '/guidelines/UpdateScorecardStatus'],
        getQuestionsHistory:                  ['post', '/guidelines/GetQuestionsHistory'],
        getQuestionsHistoryByQId:             ['post', '/guidelines/GetQuestionsHistoryByQId'],
        getUserScorecards:                    ['post', '/guidelines/GetScorecardList'],

        // notification page actions
        getNotificationSummary:               ['post', '/notification/GetNotificationSummaryV2'],
        getNotificationCalls:                 ['post', '/notification/GetCallsWithNotifications'],
        getNotificationsByScorecard:          ['post', '/notification/GetNotificationsByScorecard'],
        getNotificationsByUser:               ['post', '/notification/GetNotificationsByUser'],
        getAvailableNotiFilters:              ['post', '/notification/GetAvailableNotificationFilters'],
        getNotificationFlow:                  ['post', '/notification/GetNotificationFlow'],
        getNotificationDisputes:              ['post', '/notification/GetNotificationDisputes'],

        // scheduling page actions
        getSchedulingUserSettings:            ['post', '/scheduling/GetUserSettings'],
        updateUserSettings:                   ['post', '/scheduling/UpdateUserSettings'],
        getAvailablePeriods:                  ['post', '/scheduling/GetAvailablePeriods'],
        setUserWorkingHour:                   ['post', '/scheduling/SetUserWorkingHour'],
        getRequiredQAs:                       ['post', '/scheduling/GetRequiredQAs'],
        updateRequiredQAsByScorecardId:       ['post', '/scheduling/UpdateRequiredQAsByScorecardId'],
        getInitialData:                       ['post', '/scheduling/getInitialInfo'],

        // settings page actions
        getQuestionTypes:                     ['post', '/settings/GetQuestionTypes'],
        getGetAppList:                        ['post', '/settings/GetAppList'],
        getScorecardList:                     ['post', '/settings/GetScorecardList'],
        getNotes:                             ['post', '/settings/GetNotes'],
        insertNote:                           ['post', '/settings/InsertNote'],
        deleteNoteApp:                        ['post', '/settings/DeleteNote'],
        clearAllNotes:                        ['post', '/settings/ClearAllNotesByAppName'],
        getApplicationSetting:                ['post', '/settings/GetApplicationSetting'],
        updateApplication:                    ['post', '/settings/UpdateApplication'],
        getBillingRate:                       ['post', '/settings/GetBillingRate'],
        getNaAffected:                        ['post', '/settings/GetNaAffected'],
        getFirstNotificationAssigee:          ['post', '/settings/GetFirstNotificationAssigee'],
        insertBilingRate:                     ['post', '/settings/UpdateBilingRate'],
        addBilingRate:                        ['post', '/settings/AddBilingRate'],
        deleteBillingRate:                    ['post', '/settings/DeleteBillingRate'],
        createApplication:                    ['post', '/settings/CreateApplication'],
        getAppListWithLogo:                   ['post', '/settings/GetAppListWithLogo'],
        deleteApp:                            ['post', '/settings/DeleteApplication'],
        duplicateApp:                         ['post', '/settings/DuplicateApp'],
        addApiKey:                            ['post', '/settings/AddApiKey'],
        getApiKeys:                           ['post', '/settings/GetApiKeys'],
        updateApiKey:                         ['post', '/settings/UpdateApiKey'],
        deleteKey:                            ['post', '/settings/DeleteApiKey'],
        getExport:                            ['post', '/settings/GetExport'],
        updateExport:                         ['post', '/settings/UpdateExport'],
        addExport:                            ['post', '/settings/AddExport'],
        deleteExport:                         ['post', '/settings/DeleteExport'],
        dedupeScorecard:                      ['post', '/settings/DedupeScorecard'],
        changeQuestionsOrder:                 ['post', '/settings/ChangeQuestionsOrder'],
        changeDropdownItemsOrdering:          ['post', '/settings/CahangeDropDownItemsOrdering'],
        // getConfigurationProfile:              ['post', '/settings/GetConfigurationProfile'],
        getNotificationProfiles:              ['post', '/settings/GetNotificationProfiles'],
        addNewNotificationProfile:            ['post', '/settings/AddNewNotificationProfile'],
        updateNotificationProfile:            ['post', '/settings/UpdateNotificationProfile'],
        cloneNotificationProfile:             ['post', '/settings/CloneNotificationProfile'],
        getUserRoles:                         ['post', '/settings/GetUserRoles'],
        getNotificationSteps:                 ['post', '/settings/GetNotificationSteps'],
        addNotificationStep:                  ['post', '/settings/AddNotificationStep'],
        updateNotificationStep:               ['post', '/settings/UpdateNotificationStep'],
        deleteNotificationStep:               ['post', '/settings/DeleteNotificationStep'],

        getRjectionProfile:                   ['post', '/settings/GetRjectionProfile'],
        addRejectionProfile:                  ['post', '/settings/AddRejectionProfile'],
        сloneRejectionProfile:                ['post', '/settings/CloneRejectionProfile'],
        updateRejectionProfile:               ['post', '/settings/UpdateRejectionProfile'],
        getRejectionReasons:                  ['post', '/settings/GetRejectionReasons'],
        addRejectionReason:                   ['post', '/settings/AddRejectionReason'],
        updateRejectionReason:                ['post', '/settings/UpdateRejectionReason'],
        deleteRejectionReason:                ['post', '/settings/DeleteRejectionReason'],

        getCommentProfiles:                   ['post', '/settings/GetCommentProfiles'],
        addCommentProfile:                    ['post', '/settings/AddCommentProfile'],
        cloneCommentProfile:                  ['post', '/settings/CloneCommentProfile'],
        updateCommentProfile:                 ['post', '/settings/UpdateCommentProfile'],
        deleteCommentProfile:                 ['post', '/settings/DeleteCommentProfile'],
        getAdditionalComments:                ['post', '/settings/GetAdditionalComments'],
        addAdditionalComment:                 ['post', '/settings/AddAdditionalComment'],
        updateAdditionalComment:              ['post', '/settings/UpdateAdditionalComment'],
        deleteAdditionalComment:              ['post', '/settings/DeleteAdditionalComment'],

        // settingsPage Scorecard actions
        addNewScorecard:                      ['post', '/settings/AddNewScorecard'],
        deleteScorecardById:                  ['post', '/settings/DeleteScorecardById'],
        getScorecardSettingsById:             ['post', '/settings/GetScorecardSettingsById'],
        addNewScorecardNote:                  ['post', '/settings/AddNewScorecardNote'],
        getScorecardNotesById:                ['post', '/settings/GetScorecardNotesById'],
        deleteAllScorecardNote:               ['post', '/settings/DeleteAllScorecardNote'],
        deleteScorecardNote:                  ['post', '/settings/DeleteScorecardNote'],
        updateScorecardNote:                  ['post', '/settings/UpdateScorecardNote'],
        updateScorecardSettings:              ['post', '/settings/UpdateScorecardSettings'],
        getAccountManagerUsers:               ['post', '/settings/GetAccountManagerUsers'],
        getTLUsers:                           ['post', '/settings/GetTLUsers'],
        getTangoTLUsers:                      ['post', '/settings/GetTangoTLUsers'],
        getGoldenUsers:                       ['post', '/settings/GetGoldenUsers'],
        getScorecardChanges:                  ['post', '/settings/GetScorecardChanges'],

        // settingsPage Question actions
        getQuestionList:                      ['post', '/settings/GetQuestionList'],
        updateQuestionSettings:               ['post', '/settings/UpdateQuestion'],
        getQuestionSection:                   ['post', '/settings/GetQuestionSection'],
        getQuestionById:                      ['post', '/settings/GetQuestionById'],
        getDynamicRuleItems:                  ['post', '/settings/GetDynamicRuleItems'],
        addDynamicRuleItem:                   ['post', '/settings/AddDynamicRuleItem'],
        updateDynamicRuleItem:                ['post', '/settings/UpdateDynamicRuleItem'],
        deleteDynamicRuleItem:                ['post', '/settings/DeleteDynamicRuleItem'],
        testDynamicRule:                      ['post', '/settings/TestDynamicRule'],
        getDropDownItemList:                  ['post', '/settings/GetDropDownItemList'],
        editDropDownItem:                     ['post', '/settings/EditDropDownItem'],
        deleteDropdownItem:                   ['post', '/settings/DeleteDropdownItem'],
        addDropDownItem:                      ['post', '/settings/AddDropDownItem'],
        getQuestionByIdSimple:                ['post', '/settings/GetQuestionByIdSimple'],
        getAnswerList:                        ['post', '/settings/GetAnswerList'],
        getCommentsList:                      ['post', '/settings/GetCommentsList'],
        getFAQList:                           ['post', '/settings/GetFAQList'],
        deleteFAQ:                            ['post', '/settings/DeleteFAQ'],
        updateFAQ:                            ['post', '/settings/updateFAQ'],
        addFAQ:                               ['post', '/settings/AddFAQ'],
        getInstructionsList:                  ['post', '/settings/GetInstructionsList'],
        deleteInstruction:                    ['post', '/settings/DeleteInstruction'],
        updateInstruction:                    ['post', '/settings/UpdateInstruction'],
        changeFAQOrder:                       ['post', '/settings/ChangeFAQOrder'],
        addInstruction:                       ['post', '/settings/AddInstruction'],
        getAnswerListFull:                    ['post', '/settings/GetAnswerListFull'],
        getAnswerType:                        ['post', '/settings/GetAnswerType'],
        deleteAnswer:                         ['post', '/settings/DeleteAnswer'],
        uploadLogo:                           ['post', '/settings/UploadLogo'],
        uploadSmallLogo:                      ['post', '/settings/UploadLogoSmall'],
        getHistoryOfChanges:                  ['post', '/settings/GetHistoryOfChanges'],
        getAppHistoryOfChanges:               ['post', '/settings/GetAppHistoryOfChanges'],
        changeAnswerOrder:                    ['post', '/settings/ChangeAnswerOrder'],
        addAnswer:                            ['post', '/settings/AddAnswer'],
        updateAnswer:                         ['post', '/settings/UpdateAnswer'],
        getAnswerComments :                   ['post', '/settings/GetAnswerComments'],
        updateAnswerComment:                  ['post', '/settings/UpdateAnswerComment'],
        addAnswerComment:                     ['post', '/settings/AddAnswerComment'],
        deleteAnswerComments:                 ['post', '/settings/DeleteAnswerComments'],
        cloneQuestionToScorecard:             ['post', '/settings/CloneQuestionToScorecard'],
        cloneQuestionsToScorecard:            ['post', '/settings/CloneQuestionsToScorecard'],
        updateMultipleQuestions:              ['post', '/settings/UpdateMultipleQuestions'],
        multipleCloneQuestionsToScorecard:    ['post', '/settings/MultipleCloneQuestionsToScorecard'],
        changeSectionOrder:                   ['post', '/settings/ChangeSectionOrder'],
        updateSections:                       ['post', '/settings/UpdateSections'],
        addMultiplySections:                  ['post', '/settings/AddMultiplySections'],
        addQuestion:                          ['post', '/settings/AddQuestion'],
        changeIstructionOrder:                ['post', '/settings/ChangeIstructionOrder'],
        getTemplateItems:                     ['post', '/settings/GetTemplateItems'],
        addTemplateItem:                      ['post', '/settings/AddTemplateItem'],
        updateTemplateItem:                   ['post', '/settings/UpdateTemplateItem'],
        deleteTemplateItem:                   ['post', '/settings/DeleteTemplateItem'],
        changeTemplateItemOrder:              ['post', '/settings/ChangeTemplateItemOrder'],
        getTemplateDropdownItems:             ['post', '/settings/GetTemplateDropdownItems'],
        getCalculatedRules:                   ['post', '/settings/GetCalculatedRules'],
        addCalculatedRule:                    ['post', '/settings/AddCalculatedRule'],
        deleteCalculatedRule:                 ['post', '/settings/DeleteCalculatedRule'],
        updateCalculatedRule:                 ['post', '/settings/UpdateCalculatedRule'],
        deleteRuleItem:                       ['post', '/settings/DeleteMultipleRuleItems'],
        getRuleItems:                         ['post', '/settings/GetRuleItems'],
        addRuleItem:                          ['post', '/settings/AddMultipleRuleItemsSimple'],
        updateRuleItem:                       ['post', '/settings/UpdateRuleItems'],
        getRuleTypeList:                      ['post', '/settings/GetRuleTypeList'],
        getRuleOperatorList:                  ['post', '/settings/GetRuleOperatorList'],
        getSchoolDataItems:                   ['post', '/settings/GetSchoolDataItems'],
        getCallDataItems:                     ['post', '/settings/GetCallDataItems'],
        getOtherDataItems:                    ['post', '/settings/GetOtherDataItems'],
        getQuestionsWithAnswers:              ['post', '/settings/GetQuestionsWithAnswers'],
        addMultipleRuleItems:                 ['post', '/settings/AddMultipleRuleItems'],
        getLinkedItems:                       ['post', '/settings/GetLinkedItems'],
        deleteLinkedItem:                     ['post', '/settings/DeleteLinkedItem'],
        addLinkedItem:                        ['post', '/settings/AddLinkedItems'],
        updateLinkedItem:                     ['post', '/settings/UpdateLinkedItem'],
        makeQuestionActive:                   ['post', '/Settings/MakeQuestionActive'],
        makeQuestionInactive:                 ['post', '/Settings/MakeQuestionInactive'],
        makeAllRulesInactive:                 ['post', '/Settings/MakeAllRulesInactive'],

        createLinkedGroup:                     ['post', '/settings/CreateLinkedGroup'],
        updateLinkedGroup:                     ['post', '/settings/UpdateLinkedGroup'],
        deleteLinkedGroup:                     ['post', '/settings/DeleteLinkedGroup'],
        deleteLinkedRules:                     ['post', '/settings/DeleteLinkedRules'],
        //addLinkedRules:                        ['post', '/settings/AddLinkedRules'],
        getLinkedRules:                        ['post', '/settings/GetLinkedRules'],
        addLinkedItems:                        ['post', '/settings/AddLinkedItems'],
        deleteLinkedItems:                     ['post', '/settings/DeleteLinkedItems'],

        getQuestionHistory:                   ['post', '/settings/GetQuestionHistory'],
        getCallHiddenDataElements:            ['post', '/settings/GetCallHiddenDataElements'],
        getSchoolHiddenDataElements:          ['post', '/settings/GetSchoolHiddenDataElements'],
        getOtherFormHiddenDataElements:       ['post', '/settings/GetOtherFormHiddenDataElements'],
        getHiddenData:                        ['post', '/settings/GetHiddenData'],
        addHiddenDataArray:                   ['post', '/settings/AddHiddenDataArray'],
        deleteHiddenDataArray:                ['post', '/settings/DeleteHiddenDataArray'],

        // new listen actions
        getAutocomplete:                      ['post', '/listen/GetAutoComplete'],
        getCarQueryData:                      ['post', '/user/GetCarQueryApi'],

        // get/upload/delete user files placed under "listen" controller + get available agent names to change
        uploadUserFile:                       ['post', '/listen/uploaduserfile'],
        deleteUserFile:                       ['post', '/listen/deleteuserfile'],
        getUserFiles:                         ['post', '/list/getUserFiles'], // strange path - ask to change from list to listen
        getAvailableAgentNames:               ['post', '/list/GetAvailableAgentName'],
        changeRecordAgentName:                ['post', '/list/ChangeRecordAgentName'],

        // ClientUserPage
        getUserScorecardWithGroupsList:       ['post', '/clientuser/GetUserOwnedGroupNew'],
        getScorecardWithGroupsList:           ['post', '/clientuser/GetScorecardWithGroupsList'],
        getClientsUserList:                   ['post', '/clientuser/GetUserList'],
        getUserListFiltering:                 ['post', '/clientuser/GetUserListFiltering'],
        getUserInfo:                          ['post', '/clientuser/GetUserInfo'],
        saveUserInfo:                         ['post', '/clientuser/SaveUserInfo'],
        addNewUser:                           ['post', '/clientuser/AddNewUser'],
        addNewClientUserGroup:                ['post', '/clientuser/AddNewClientUserGroup'],
        addUserToMultipleGroup:               ['post', '/clientuser/AddUserToMultipleGroup'],
        getUserGroups:                        ['post', '/clientuser/GetUserOwnedGroup'],
        addUserToGroup:                       ['post', '/clientuser/AddUserToGroup'],
        deleteUserFromGroup:                  ['post', '/clientuser/DeleteUserFromGroup'],
        updateClientUserGroup:                ['post', '/clientuser/UpdateClientUserGroup'],
        changeUserPassword:                   ['post', '/clientuser/ChangeUserPassword'],
        getScorecardGroups:                   ['post', '/clientuser/GetScorecardGroups'],
        getHistoryOfAssigment:                ['post', '/clientuser/GetHistoryOfAssigment'],
        getScorecardSupervisors:              ['post', '/clientuser/GetScorecardSupervisors'],
        getScorecardManagers:                 ['post', '/clientuser/GetScorecardManagers'],
        deleteClientUserGroup:                ['post', '/clientuser/DeleteClientUserGroup'],
        updateOlderData:                      ['post', '/clientuser/UpdateOlderData'],
        exportClientUserList:                 ['post', '/clientuser/ExportClientUserList'],
        getUserForceFilters:                  ['post', '/clientuser/GetUserForceFilters'],
        addForceFilter:                       ['post', '/clientuser/AddForceFilters'],
        updateForceFilter:                    ['post', '/clientuser/UpdateForceFilters'],
        deleteForceFiler:                     ['post', '/clientuser/DeleteForceFilers'],
        getForceFilerTypes:                   ['post', '/clientuser/GetForceFilerTypes'],
        getForceFilterValue:                  ['post', '/clientuser/GetForceFilterValue'],

        // Prospect and Conversion Analysis
        getAvailableConversionFilters:        ['post', '/conversion/GetAvailableConversionFilters'],
        getConversionStatsData:               ['post', '/conversion/GetConversionStatsData'],
        getConversionPerformanceData:         ['post', '/conversion/GetConversionPerformanceData'],
        getConversionChartData:               ['post', '/conversion/GetChartData'],
        // getConversionCallDetails:             ['post', '/conversion/GetConversionCallDetails'],
        getExportChartData:                   ['post', '/conversion/GetExportChartData'],
        getConversionCallDetails:             ['post', '/conversion/GetCallDetails'],

        getSpringSummaryData:                 ['post', '/salePerformance/GetSpringSummaryData'],
        getSalesPerformanceData:              ['post', '/salePerformance/GetSalesPerformanceData'],
        getSalesChartData:                    ['post', '/salePerformance/GetChartData'],
        getSalesAgentRanking:                 ['post', '/salePerformance/GetAgentData'],
        getObjectionData:                     ['post', '/salePerformance/GetObjectionData'],
        getAllSalesFilters:                   ['post', '/salePerformance/GetAllSalesFilters'],
        getCallDetailsSalesPerf:              ['post', '/salePerformance/GetCallDetailsSalesPerf'],
        getSalesExportCallDetails:            ['post', '/salePerformance/ExportCallDetails'],

        departmenServicetQuestion:            ['get', '/listen/checkDNT'],
        
        //Coaching page
        getCoachingStatistics:                ['post', '/coaching/GetCoachingStatistics'],
        getCoachingStatsByAgent:              ['post', '/coaching/GetCoachingStatsByAgent'],
        getSessionList:                       ['post', '/coaching/GetSessionList'],
        getAgents:                            ['post', '/coaching/GetAgents'],
        addNewSession:                        ['post', '/coaching/AddNewSession'],
        updateSession:                        ['post', '/coaching/UpdateSession'],
        deleteSession:                        ['post', '/coaching/DeleteSession'],
        getAgentsWithQuestions:               ['post', '/coaching/GetAgentsWithQuestions'],
        getProgressCharts:                    ['post', '/coaching/GetProgressCharts'],
        getSessionDetailedInfo:               ['post', '/coaching/GetSessionDetailedInfo'],

        updateSessionMissedPoint:             ['post', '/coaching/UpdateSessionMissedPoint'],
        markGoalAsReach:                      ['post', '/coaching/MarkGoalAsReach'],
        getQuestionNotes:                     ['post', '/coaching/GetQuestionNotes'],
        addNewQuestionNote:                   ['post', '/coaching/AddNewQuestionNote'],
        updateQuestionNote:                   ['post', '/coaching/UpdateQuestionNote'],
        deleteQuestionNote:                   ['post', '/coaching/DeleteQuestionNote'],
        closeMultipleSessions:                ['post', '/coaching/CloseMultipleSessions'],
        closeMultipleSessionAgents:           ['post', '/coaching/CloseMultipleSessionAgents'],
        updateSessionName:                    ['post', '/coaching/UpdateSessionName'],

        //Account settings page
        getAccountInfo:                       ['post', '/account/GetAccountInfo'],
        updateAccountData:                    ['post', '/account/UpdateAccountData'],
        changePassword:                       ['post', '/account/ChangePassword'], 
        deleteAccount:                        ['post', '/account/DeleteAccount'],
        getAccountHistory:                    ['post', '/account/GetAccountHistory'],
    
        //notes page
        getUserNotes:                         ['post', '/Notes/GetUserNotes'],
        addNote:                              ['post', '/Notes/AddNote'],
        updateNote:                           ['post', '/Notes/UpdateNote'],
        deleteNote :                          ['post', '/Notes/DeleteNote'],

        //Authorize
        loginUser:                            ['post', '/Authorize/Login'],
        forgotPassword:                       ['post', '/Authorize/ForgotPasswordToken'],
        verifyToken:                          ['post', '/Authorize/VerifyToken'],
        resetPassword :                       ['post', '/Authorize/ResetPassword'],
        getUserInfoAuthorize:                 ['post', '/Authorize/GetUserInfo'],

        // BreakdownReport page
        getAllFilters:                        ['post', '/BreakdownReport/GetAllFilters'],
        getBreakdownStatsData:                ['post', '/BreakdownReport/GetStatsData'],
        getOtherNonQualData:                  ['post', '/BreakdownReport/GetOtherNonQualData'],
        getBreakdownChartData:                ['post', '/BreakdownReport/GetChartData'],
        getBreakdownExportChart:              ['post', '/BreakdownReport/ExportChart'],
        getBreakdownCallDetails:              ['post', '/BreakdownReport/GetCallDetails'],
        getBreakdownExportCallDetails:        ['post', '/BreakdownReport/ExportCallDetails'],
    
        ///AlertReasons
        getAlertReasonsAllFilters:            ['post', '/AlertReasons/GetAllFilters'],
        getAlertReasonsGetChartData:          ['post', '/AlertReasons/GetChartData'],
        getAlertReasonsExportChartData:       ['post', '/AlertReasons/ExportChartData'], 
        getAlertReasonsCallDetails:           ['post', '/AlertReasons/GetCallDetails'],
        getAlertReasonsExportCallDetails:     ['post', '/AlertReasons/ExportCallDetails'],   
    
        //Alert Manager
        getAlertManagerAllFilters:            ['post', '/AlertManager/GetAllFilters'],
        getAlertManagerStatsData:             ['post', '/AlertManager/GetStatsData'],
        getAlertManagerPrformanceTrends:      ['post', '/AlertManager/GetPrformanceTrends'],
        getAlertManagerGetChartData:          ['post', '/AlertManager/GetChartData'],
        getAlertManagerExportChartData:       ['post', '/AlertManager/ExportChartData'],
        getClosedClientAlerts:                ['post', '/AlertManager/GetClosedClientAlerts'],
        getOpenedClientAlerts:                ['post', '/AlertManager/GetOpenedClientAlerts'],
        getLeadResult:                        ['post', '/AlertManager/GetLeadResult'],
        getAlertManagerReason:                ['post', '/AlertManager/GetReason'],
        saveClientAlert:                      ['post', '/AlertManager/SaveClientAlert'],
        getAlertManagerCallDetails:           ['post', '/AlertManager/GetCallDetails'],
        getAlertManagerExportCallDetails:     ['post', '/AlertManager/ExportCallDetails'],
    }
}
