'use strict'

import {stripHTML} from '../../../utils/globalHelpers'

export default class PlayerMarker extends React.Component {
    constructor(props) {
        super(props)

        this.onClickMarker = this.onClickMarker.bind(this)
        this.showText = this.showText.bind(this)
        this.hideText = this.hideText.bind(this)
    }

    onClickMarker(e) {
        const {marker} = this.props
        e.preventDefault()
        if (marker.onclick) marker.onclick(marker.QID, marker.position)
    }

    showText() {
        const {marker} = this.props
        this.props.showHideText(stripHTML(marker.text))
    }

    hideText() {
        this.props.showHideText('')
    }

    render() {
        const {marker, sliderWidth, audioLength} = this.props
        const position = marker.position === 0 ? -3 : marker.position*sliderWidth/audioLength -2 || 0
        return (<div
                    class={'pin-marker' + (marker.negative ? ' negative' : '')}
                    onClick={this.onClickMarker}
                    onContextMenu={this.onClickMarker}
                    onMouseOver={this.showText}
                    onMouseOut={this.hideText}
                    style={{left: position}}>
                    <div class='divider'></div>
                </div>
            )
    }
}
