'use strict';

import * as R from 'ramda'

import {sendRequest, sendDataRequest, sendActionButtonRequest} from '../APIGateway/gateway'
import loader from './loaderActions'
import {showError} from './errorActions'
import {uploadMergeFiles} from './userActions'
import modalActions from './modalActions'
import {chatHTML, convertChat, getDataRecordName} from '../utils/globalHelpers'

const recordActions = {

    start(store, config) {
        return dispatch => {
            const userData = R.path(['headerReducer', 'headerData', 'userData'], store.getState())
            if (userData) {
                dispatch(this.getReviewData(config.record_id))
            } else {
                const userDataPromise = new Promise((resolve) => {
                    const unsubscribe = store.subscribe(() => {
                        const userDataFromAPI = R.path(['headerReducer', 'headerData', 'userData'], store.getState())
                        if (userDataFromAPI) {
                            unsubscribe()
                            resolve()
                        }
                    })
                })
                userDataPromise.then(() => {
                    dispatch(this.getReviewData(config.record_id))
                })
            }
            
        }
    },

    getReviewData(recordId) {
        return (dispatch, getState) => {
            dispatch({
                type: 'FETCH_RECORD',
                payload: true
            })
            const state = getState()
            const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
            const data = {
                ID: recordId
            }

            const calls = [
                this.getRecordID(data, dispatch),
                this.getScorecardRecordID(data, dispatch),
            ]
            // if (R.contains(
            //     userData && userData.UserRole && userData.UserRole.toUpperCase(),
            //     ["Client", "Manager", "Admin"].map(R.invoker(0, "toUpperCase"))
            // ))
            dispatch(this.getAvailableAgentNames(recordId))
            dispatch(this.getTranscriptID(data))
            dispatch(this.getUserFiles(recordId))

            Promise.all(calls).then(values => {
                if (
                    values[0].payload
                    && values[0].payload.review_type
                    && values[0].payload.review_type.toUpperCase() === 'CHAT'
                ) {
                    dispatch(this.getChat(values[0].payload.SESSION_ID))
                }
                return dispatch({
                    type: 'FETCH_RECORD_FULFILLED',
                    payload: {
                        message: 'Data received'
                    }
                })
            } , err => {
                console.log('GET RECORD ERROR - ', err.message)
                dispatch({
                    type: 'FETCH_RECORD_REJECTED',
                    payload: true
                })
            })
        }
    },

    getAvailableAgentNames(id) {
        return dispatch => {
            return sendRequest('getAvailableAgentNames', {payload: id}, 'CALIBRATION')
            .then(resp => {
                dispatch({
                    type   : 'SET_AVAILABLE_AGENT_NAMES_TO_CHANGE',
                    payload: resp.data,
                })
            }, err => console.log('Error loading available agent names'))
        }
    },

    changeAgentName(name) {
        return (dispatch, getState) => {
            const state = getState()
            const recordId = R.path(['recordReducer', 'record', 'F_ID'], state)
            return sendRequest('changeRecordAgentName', {
                payload: {
                    id   : recordId,
                    value: name,
                }
            }, 'CALIBRATION')
            .then(resp => {
                dispatch(this.refreshRecord(recordId))
                dispatch({
                    type   : 'SET_AVAILABLE_AGENT_NAMES_TO_CHANGE',
                    payload: resp.data,
                })
            }, err => console.log('Error loading available agent names'))
        }
    },

    refreshRecord(id) {
        return (dispatch, getState) => {
            dispatch(loader.run({action: 'Show'}))
            const state = getState()
            const recordId = R.path(['recordReducer', 'record', 'F_ID'], state)
            let records,
            exist = false,
            withRouter = R.path(['dashboardReducer', 'withRouter'], state)
            if (withRouter) {
                records = R.path(['dashboardReducer', 'recordReviews'], state)
                exist = records && records.length && records.find(r => r.recordId == id)
                if (!exist) {
                    withRouter = false
                }
            }
            // const record_id = appCache.get('record_id')
            const data = {
                ID: id
            }
            return this.getRecordID(data, dispatch, withRouter, records, recordId).then(() => {

                dispatch(loader.run({action: 'Hide'}))
                dispatch({
                    type: 'FETCH_RECORD_FULFILLED',
                    payload: {
                        message: 'Record updated'
                    }
                })
            }, err => showError(err, dispatch, 'FETCH_RECORD_REJECTED')
            )
        }
    },

    getRecordID(data, dispatch, withRouter, records, currentId) {
        return sendRequest('getRecordById', {payload: data}, 'RECORDPOST')
        .then(resp => {
            const record = resp.data
            if (withRouter && records && records.length) {
                const exist = records.find(r => r.recordId == record.F_ID)
                if (exist) {
                    records[records.indexOf(exist)].record = record
                    dispatch({
                        type: 'RECORD_REVIEWS_UPDATED',
                        payload: records
                    })
                }
            }
            if (!record.scorecard || !record.F_ID || !record.review_ID) {
                return dispatch({
                    type: 'RECORD_DOES_NOT_EXIST',
                    payload: 'No data'
                })
            }
            if (resp.data.Threads && resp.data.Threads.length !==0) {
                const treads = []
                resp.data.Threads.map(t => {
                    treads.unshift(t)
                })
                const aaa = treads.find(t => t.thread.currently_open === 'True')
                if (aaa) {
                    dispatch(this.setActiveTread(aaa))
                } else {
                    dispatch(this.setActiveTread(resp.data.Threads[resp.data.Threads.length-1]))
                }
                
            }
            return dispatch({
                type: 'FETCH_RECORDID_FULFILLED',
                payload: record
            })
        })
    },

    getUserFiles(recordId) {
        return (dispatch, getState) => {
            const state = getState()
            const id = recordId ? recordId : R.path(['recordReducer', 'record', 'F_ID'], state)
            return sendRequest('getUserFiles', {payload: id}, 'CALIBRATION')
            .then(resp => {
                const {allowUserCallDocuments, userDocs} = resp.data
                dispatch({
                    type   : 'SET_ALLOW_USER_CALL_DOCUMENTS',
                    payload: allowUserCallDocuments,
                })
                dispatch({
                    type: 'SET_UPLOADED_USER_FILES',
                    payload: allowUserCallDocuments ? userDocs : null,
                })
            }, err => console.log('Error uploading user files'))
        }
    },

    uploadUserFile(fileInfo) {
        return (dispatch, getState) => {
            dispatch({
                type: 'USER_FILES_LOADING'
            })
            const state = getState()
            const id = R.path(['recordReducer', 'record', 'F_ID'], state)

            const data = new FormData()
            data.append('file', fileInfo.file)
            data.append('description', fileInfo.description)
            data.append('f_id', id)
            return sendDataRequest('uploadUserFile', data, 'CALIBRATION')
            .then(resp => {
                dispatch(this.getUserFiles())
            }, err => console.log('Error uploading file'))
        }
    },

    deleteUserFile(data) {
        return dispatch => {
            dispatch({
                type: 'USER_FILES_LOADING'
            })
            return sendRequest('deleteUserFile', {payload: data}, 'CALIBRATION')
            .then(resp => {
                dispatch(this.getUserFiles())
            }, err => console.log('Error uploading file'))
        }
    },

    getScorecardRecordID(data, dispatch) {
        return sendRequest('getScorecardRecordID', {payload: data}, 'RECORDPOST').then(resp => {
            const scorecard = resp.data
            if (!scorecard ||
                !scorecard.Sections ||
                !scorecard.Sections.length) {
                    return dispatch({
                        type: 'RECORD_DOES_NOT_EXIST',
                        payload: 'No data'
                    })
                }
            return dispatch({
                type: 'FETCH_SCORECARDID_FULFILLED',
                payload: scorecard
            })
        })
    },

    getChat(id) {
        return dispatch => {
            dispatch({
                type: 'CHAT_INFO_LOADING',
                payload: true
            })
            const data = {session_id: id}
            return sendDataRequest('getChat', data, 'CDSERVICE')
                .then(resp => {
                    return dispatch({
                        type: 'GET_CHAT_INFO_FULLFILLED',
                        payload: convertChat(R.clone(resp.data.d))
                    })
                }, err => {
                    console.log('Chat error: ' + (err.response.data.ExceptionDetail && err.response.data.ExceptionDetail.Message))
                    return dispatch({
                        type: 'CHAT_INFO_ERROR',
                        payload: true
                    })
                })
        }
    },

    getTranscriptID(data) {
        return dispatch => {
            dispatch({
                type: 'REVIEW_TRANSCRIPTID_LOADING',
                payload: true
            })
            return sendDataRequest('getTranscriptID', data, 'CDSERVICE')
            .then(resp => {
                return dispatch({
                    type: 'FETCH_REVIEW_TRANSCRIPTID_FULFILLED',
                    payload: resp.data.d
                })
            }, err => {
                return dispatch({
                    type: 'REVIEW_TRANSCRIPTID_ERROR',
                    payload: true
                })
                alert('Transcript error: ' + (err.response.data.ExceptionDetail && err.response.data.ExceptionDetail.Message))
                return
            })
        }
    },

    updateQuestionData(options) {
        const {updateQAComments, updateQuestion, updateOptions} = options
        return (dispatch, getState) => {
            const state = getState()
            const id = R.path(['recordReducer', 'record', 'F_ID'], state)
            dispatch(loader.run({action: 'Show'}))
            if (updateQuestion.length) {
                return dispatch(this.updateQuestionReq(updateQuestion))
                .then(resp => {
                    if (updateOptions.length) {
                        return dispatch(this.updateOptions(updateOptions))
                        .then(resp => {
                            if (updateQAComments.length) return dispatch(this.updateQAComments(updateQAComments))
                            .then(resp => {
                                return dispatch(this.finishUpdateQuestion(id))
                            })

                        })
                    } else if (updateQAComments.length) {
                        return dispatch(this.updateQAComments(updateQAComments))
                        .then(resp => {
                            return dispatch(this.finishUpdateQuestion(id))
                        })
                    }
                    return dispatch(this.finishUpdateQuestion(id))
                })
            } else if (updateOptions.length) {
                return dispatch(this.updateOptions(updateOptions))
                .then(resp => {
                    if (updateQAComments.length) return dispatch(this.updateQAComments(updateQAComments))
                    .then(resp => {
                        return dispatch(this.finishUpdateQuestion(id))
                    })
                    return dispatch(this.finishUpdateQuestion(id))
                })
            } else if (updateQAComments.length) {
                return dispatch(this.updateQAComments(updateQAComments))
                .then(resp => {
                    dispatch(this.finishUpdateQuestion(id))
                })
            }
        }
    },

    finishUpdateQuestion(id) {
        return dispatch => {
            dispatch(this.refreshRecord(id))
            dispatch(loader.run({action: 'Hide'}))
            dispatch(modalActions.modal('close'))
            dispatch({
                type: 'GENERAL_COMMENT_HIGHLIGHTED',
                payload: true
            })
        }
    },

    updateQuestionReq(data) {
        return dispatch => {
            return sendRequest('updateQuestion', {payload: data}, 'RECORDPOST')
        }
    },

    updateQAComments(data) {
        return dispatch => {
            return sendDataRequest('updateQAComments', data, 'RECORDPOST')
        }
    },

    updateOptions(data) {
        return dispatch => {
            return sendDataRequest('updateOptions', data, 'RECORDPOST')
        }
    },

    updateDispute(data) {
        return (dispatch, getState) => {
            const state = getState()
            const id = R.path(['recordReducer', 'record', 'F_ID'], state)
            return sendRequest('updateDispute', {payload: data}, 'RECORDPOST')
                .then(resp => dispatch(this.refreshRecord(id)),
                    err => showError(err, dispatch)
                )
        }
    },

    updateSpotcheck(data) {
        return (dispatch, getState) => {
            const state = getState()
            const id = R.path(['recordReducer', 'record', 'F_ID'], state)
            dispatch(loader.run({action: 'Show', text: 'Updating SpotCheck'}))
            return sendRequest('updateSpotcheck', {payload: data}, 'RECORDPOST')
                .then(resp => {
                    dispatch(loader.run({action: 'Success', text: 'UPDATED!'}))
                    window.location = '/spotcheck'
                    return
                }, err => showError(err, dispatch))
        }
    },

    actionButtonRequest(endpoint, badReason) {
        return (dispatch, getState) => {
            const state = getState()
            const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
            const record = R.path(['recordReducer', 'record'], state)
            const data = {
                ID: record.F_ID,
                username: userData.UserName
            }

            if (badReason) {
                data.bad_reason = badReason
            }

            dispatch(loader.run({action: 'Show', text: 'Sending a request'}))
            return sendActionButtonRequest(endpoint, data)
            .then(resp => {
                dispatch(loader.run({action: 'Hide'}))
                if (endpoint === 'ResetCall') dispatch(this.actionButtonRequest('RecreateCall'))
                return resp.data
            }, err => showError(err, dispatch))
        }
    },

    updateClerkedData(data) {
        return dispatch => {
            dispatch(loader.run({action: 'Show', text: 'Sending a request'}))
            return sendRequest('updateClerkedData', {payload: data}, 'RECORDPOST')
                .then(resp => {
                    dispatch(loader.run({action: 'Hide'}))
                    return dispatch(modalActions.modal('close'))
                }, err => showError(err, dispatch))
        }
    },

    removeCalibrator(data) {
        return (dispatch, getState) => {
            const state = getState()
            const id = R.path(['recordReducer', 'record', 'F_ID'], state)
            return sendRequest('removeCalibrator', {payload: data}, 'RECORDPOST')
            .then(resp => dispatch(this.refreshRecord(id)),
                err => showError(err, dispatch)
            )
        }
    },

    manipulateAudio(options, addedToSend) {
        return (dispatch, getState) => {
            const state = getState()
            const id = R.path(['recordReducer', 'record', 'F_ID'], state)
            return uploadMergeFiles(options, addedToSend, dispatch)
                .then(resp => {
                    dispatch(modalActions.modal('close'))
                    dispatch(this.refreshRecord(id))
                    if (resp.data.IsSuccess && resp.data.Audio) {
                        dispatch(this.actionButtonRequest('RecreateCall'))
    					dispatch(modalActions.modal({
                            title: 'Merged successfully',
                            modalContent: <div class='default-content'>Audio merged successfully!</div>,
                        }))
    					return dispatch(this.refreshRecord(id))
    				} else {
    					dispatch(modalActions.modal({
                            title: 'Merge error',
                            modalContent: <div class='default-content'>{resp.data.Message || ''}</div>,
                        }))
                        return dispatch(this.refreshRecord(id))
    				}
                }, err => {
                    dispatch(modalActions.modal('close'))
                    dispatch(this.refreshRecord(id))
                    return dispatch(modalActions.modal({
                        title: 'Merge error',
                        modalContent: <div class='default-content'>Audio mering failed!</div>,
                    }))
                })
        }
    },

    deleteComment(data) {
        return (dispatch, getState) => {
            const state = getState()
            const id = R.path(['recordReducer', 'record', 'F_ID'], state)
            dispatch(loader.run({action: 'Show', text: 'Sending a request'}))
            return sendRequest('deleteComment', {payload: data}, 'RECORDPOST')
                .then(resp => {
                    dispatch(loader.run({action: 'Hide'}))
                    dispatch(modalActions.modal('close'))
                    return dispatch(this.refreshRecord(id))
                },
                    err => showError(err, dispatch)
                )
        }
    },

    updateCallComment(data) {
        return (dispatch, getState) => {
            const state = getState()
            const id = R.path(['recordReducer', 'record', 'F_ID'], state)
            dispatch(loader.run({action: 'Show', text: 'Sending a request'}))
            return sendRequest('updateCallComment', {payload: data}, 'RECORDPOST')
                .then(resp => {
                    dispatch(loader.run({action: 'Hide'}))
                    dispatch(modalActions.modal('close'))
                    return dispatch(this.refreshRecord(id))
                }, err => showError(err, dispatch))
        }
    },

    updateMetaData(data) {
        return (dispatch, getState) => {
            const state = getState()
            const id = R.path(['recordReducer', 'record', 'F_ID'], state)
            return sendRequest('updateMetaData', {payload: {
                    UMDIList: data,
                    removeother: []
                }
            }, 'RECORDPOST').then(resp => {
                //we don't know why recreate call
                //was called for edit agent name functionallity
                //ask Stace about it!!!
                //makes side effect
                //temporary commented
                // dispatch(this.actionButtonRequest('RecreateCall'))
                return dispatch(this.refreshRecord(id))
            }, err => {
                return dispatch(this.refreshRecord(id))
            })
        }
    },

    updateMetadataInfo(data) {
        return (dispatch, getState) => {
            const state = getState()
            const xId = R.path(['recordReducer', 'record', 'X_ID'], state)
            const id = R.path(['recordReducer', 'record', 'F_ID'], state)
            const payload = {
                name: name = getDataRecordName(data.name),
                value: data.value
            }
            return sendRequest('updateMetadataInfo', {payload: {
                    metaDataItems: [payload],
                    id: xId
                }
            }, 'CALIBRATION').then(resp => {
                return dispatch(this.refreshRecord(id))
            }, err => {
                return dispatch(this.refreshRecord(id))
            })
        }
    },
    //job_5367354

    setActiveTread(tread) {
         return dispatch => {
            dispatch({
                type: 'REVIEW_NOTIFICATIONS_SET_ACTIVE_TREAD',
                payload: tread
            })
        }
    },

    openNewTread(prop) {
        return dispatch => {
            dispatch({
                type: 'REVIEW_NOTIFICATIONS_OPEN_NEW_TREAD',
                payload: prop
            })
        }
    },

    getNotificationReasons() {
        return (dispatch, getState) => {
            const state = getState()
            dispatch({
                type: 'REVIEW_PAGE_GET_NOTI_REASONS_LOADING',
                payload: true
            })
            return sendRequest('getNotificationReasons', {
                payload: {}
            }, 'CALIBRATION', false, 'getNotificationReasons')
            .then(resp => {
                dispatch({
                    type: 'REVIEW_PAGE_GET_NOTI_REASONS',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'REVIEW_PAGE_GET_NOTI_REASONS_ERROR',
                    payload: true
                })
            })
        }
    },

    createThread(tread) {
        return (dispatch, getState) => {
            const state = getState()
            const id = tread.callId
            dispatch(loader.run({action: 'Show', text: 'Sending a request'}))
            return sendRequest('createThread', {
                payload: tread
            }, 'CALIBRATION', false, 'createThread')
                .then(resp => {
                    dispatch(loader.run({action: 'Hide'}))
                    dispatch(this.openNewTread(false))
                    return dispatch(this.refreshRecord(id))
                }, err => showError(err, dispatch))
        }
    },

}

export default recordActions
