'use strict'

import {PropTypes} from 'prop-types'
import moment from 'moment'
import {systemDataPropType, metaDataPropType} from './propTypes'

import {fromSecsToMinsAndSecs} from '../../../utils/globalHelpers'

export default class SimpleCell extends React.Component {
    static propTypes = {
        systemData: systemDataPropType.isRequired,
        metaData: metaDataPropType.isRequired,
        columnLabel: PropTypes.string.isRequired
    }

    getValue(displVal) {
        const {searchText, searchable, columnKey} = this.props
        if (columnKey === 'prospectPhone') {
            const searchPhone = searchText.replace(/[^a-zA-Z0-9 ]/g, '')//what we got from input without symbols
            const matchPhone = displVal.replace(/[^a-zA-Z0-9 ]/g, '')//what we have in call data without symbols
            const startI = matchPhone.indexOf(searchPhone)
            if (matchPhone.indexOf(searchPhone) !== -1) {
                return [
                    <span key={'phone-search-' + displVal + 1}>{matchPhone.substring(0, startI)}</span>,
                    <span key={'phone-search-' + displVal + 2} class='highlighted'>{matchPhone.substring(startI, startI + searchPhone.length)}</span>,
                    <span key={'phone-search-' + displVal + 3}>{matchPhone.substring(startI + searchPhone.length)}</span>
                ]
            } else {
                return displVal
            }
        }
        const text = searchText.toUpperCase()
        const str = displVal.toString()
        const valueUpper = displVal.toString().toUpperCase()
        const startIndex = valueUpper.indexOf(text)
        return searchable ?
        ((columnKey === 'reviewDate' || columnKey === 'callDate') && displVal === moment(searchText).format('MMM D, YYYY')) ?
        <span class='highlighted'>{displVal}</span> :
        startIndex !== -1 ?
        [
            <span key={'search-match-' + displVal + 1}>{str.substring(0, startIndex)}</span>,
            <span key={'search-match-' + displVal + 2} class='highlighted'>{str.substring(startIndex, startIndex + text.length)}</span>,
            <span key={'search-match-' + displVal + 3}>{str.substring(startIndex + text.length)}</span>
        ] : displVal : displVal
    }

    render() {
        const {systemData, metaData, searchable, columnLabel, searchText, columnKey} = this.props
        const web = systemData.callType === 'website' || systemData.callType === 'chat' ? true : false
        const value = systemData[columnKey] || metaData[columnKey] || ''
        const displVal = (columnKey === 'reviewDate' || columnKey === 'callDate' || columnKey === 'receivedDate') ?
        moment(value).format("MMM D, YYYY") :
        (columnKey === 'callAudioLength') ? web ? '' : fromSecsToMinsAndSecs(Math.round(value)) :
        (columnKey === 'agentScore') ? value + '%' : value
        return (
            <td class='simple-cell'>
                <div
                    class='simple-cell-item'
                    title={columnLabel +
                        ': ' +
                        (columnKey === 'reviewDate' ||
                        columnKey === 'callDate' ||
                        columnKey === 'receivedDate' ?
                        moment(value).format("YYYY-DD-MM") :
                    value)}>
                    {
                        (searchText && displVal && searchable) ? this.getValue(displVal) : displVal
                    }
                </div>
            </td>
        )
    }
}
