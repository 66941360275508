'use strict'

class Tabs extends React.Component {

    constructor(props) {
        super(props)
        this.displayName = 'Tabs'
        this.labels = this.labels.bind(this)
        this.state = {
            selected: this.props.selected,
            callback: this.props.callback || function() {}
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            selected: nextProps.selected
        })
    }
    handleClick(index, event) {
        event.preventDefault();
        this.setState({
            selected: index
        });
        this.state.callback(index)
    }
    labels(child, index) {
        const {setLength} = this.props
        let length = this.props.children.length
        let activeClass = (this.state.selected === index ? 'active ' : '');
        return (
            <li
                key={index}
                style={setLength ? {width: (100/length) + '%'} : {}}
                onClick={this.handleClick.bind(this, index)}
                title={child.props.title || ''}
                class={activeClass + child.props.dataClass}>
                <a href="#">
                    {child.props.label}
                </a>
            </li>
        );
    }
    renderTitles() {
        let labelsEls = this.props.children.map(this.labels)
        return (
            <ul class={this.props.titlesClass || "tabs-container"}>
                {labelsEls}
            </ul>
        );
    }
    renderContent() {
        let content = this.props.children.length
        ? this.props.children[this.state.selected]
        : this.props.children
        return (
            <div class="tabs-content">
                {content}
            </div>
        )
    }
    render() {
        const {title, titlesClass, className} = this.props
        return (
            <div class={className || "tabs"}>
                {title ? <div class='tabs-title'>{title}</div> : ''}
                {this.renderTitles()}
                {this.renderContent()}
            </div>
        )
    }
}
Tabs.defaultProps = {
    selected: 0
}
export default Tabs
