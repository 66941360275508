'use strict'

import classNames from 'classnames'

export default class TreadsDropdown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectName: null,
            selectMenuOpen: false
        }
    }

    selectName = (e, name) => {
        this.setState({
            selectName: name,
            selectMenuOpen: false
        })
        this.props.setReason(name)
    }

    openMenu = () => {
        const {selectMenuOpen} = this.props
        this.setState({
            selectMenuOpen: !selectMenuOpen,
        })
    }

    closeMenu = () => {
        this.setState({
            selectMenuOpen: false,
        })
    }

    render() {
        const {reasons, role} = this.props
        const {selectName, selectMenuOpen} = this.state
        const openedMenu = classNames({
            'select-menu' : true,
            'opened'      : selectMenuOpen
        })

        return (
            <div class='drop-long'>
                <div class='top-drop' onClick={this.openMenu}>
                    <div class='selected-item'>
                        {
                            selectName ?
                            <div title={selectName}>{selectName}</div> :
                            <div class='need-select' title='Click to select'>
                                {
                                    role ?
                                    'Select role' :
                                    'Select reason'
                                }
                            </div>
                        }
                    </div>
                    <div class='arror' title='Click to change'>
                        <div class='icon-ic-arrow-drop-down'/>
                    </div>
                </div>
                <div class={openedMenu} onMouseLeave={this.closeMenu}>
                {
                    reasons && reasons.map((reason, index) => {
                        return (
                            <div
                                class='option'
                                onClick={(e) => this.selectName(e, reason)}
                                key={index + reason}
                                title={reason}>{reason}</div>
                        )
                    })
                }
                </div>
            </div>
        )
    }
}
