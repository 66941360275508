'use strict'

import { connect } from 'react-redux'
import classNames from 'classnames'
import {getDOMCoords, filterJoin} from '../../../utils/globalHelpers'


import playerActions from '../../../actions/playerActions'

@connect((store) => {
    return {
        mountingElement: store.recordReducer.mountingElement
    }
})

export default class MissedItem extends React.Component {
    constructor(props) {
        super(props)

        this.jumpPos = this.jumpPos.bind(this)
        this.scrollHandler = this.scrollHandler.bind(this)
        this.showTooltip = this.showTooltip.bind(this)
        this.hideTooltip = this.hideTooltip.bind(this)
        this.mouseOver = this.mouseOver.bind(this)
        this.mouseOut = this.mouseOut.bind(this)
        this.state = {
            tooltipShown: false,
            coords: null,
        }
    }

    componentDidMount() {
        const el = document.getElementById(this.props.mountingElement)
        if (!el) return
        el.addEventListener('scroll', this.scrollHandler, false)
    }

    componentWillUnmount() {
        const el = document.getElementById(this.props.mountingElement)
        if (!el) return
        el.removeEventListener('scroll', this.scrollHandler, false)
    }

    mouseOver() {
        this.setState({
            remove: true
        })
    }

    mouseOut() {
        this.setState({
            remove: false
        })
    }

    showTooltip() {
        const el = this.refs.fortooltip
        const coords = getDOMCoords(el)
        this.setState({
            tooltipShown: true,
            coords: coords,
        })
    }

    scrollHandler() {
        this.hideTooltip()
    }

    hideTooltip() {
         this.setState({
            tooltipShown: false,
            coords: null,
        })
    }

     renderTooltipText(data) {
        const dataLength = data.length
        const {tooltipShown, coords} = this.state
        if (!coords) return
        const {
            questionId,
            questionShortName,
            questionSectionName,
            questionType, // "simple" or "dynamic" or "composite"
            isRightAnswer,
            isComposite,
            isLinked,
            simpleQuestionAnswer, // for "simple" or "dynamic"
            compositeQuestionAnswer
        } = data
        const leftAdditional = coords.left - 167 + ((coords.right - coords.left) / 2)
        const left = leftAdditional < 0 ?
        20 : leftAdditional
        const tooltipStyles = tooltipShown ? {
            left: left,
            bottom: window.innerHeight - coords.top + 12,
        } : {
            visibility: 'hidden',
            width: 0,
            height: 0,
            opacity: 0,
        }
        const containerType = classNames({
            'container'                 : leftAdditional > 0,
            'container-displacement'    : leftAdditional < 0,
        })
        return (
            <div class='wrapper' style={tooltipStyles}>
               <div class={containerType}>
                <div class='question-name'>
                    {data.questionShortName}:
                    <div class='short-ans'>
                        {
                            !data.isComposite ?
                            data.simpleQuestionAnswer.answerText :
                            data.isRightAnswer ? 'YES' : 'NO'
                        }
                    </div>
                </div>
                {
                    isComposite ?
                    <div>
                        {
                            !compositeQuestionAnswer.customComment ? null :
                            <div class='comments'>
                                {compositeQuestionAnswer.customComment || 'No Comments'}
                            </div>
                        }
                    </div> :
                    <div>
                    {
                        (simpleQuestionAnswer.comments && simpleQuestionAnswer.comments.length) || simpleQuestionAnswer.customComment ?
                        <div class='comments-container'>
                            {
                                (simpleQuestionAnswer.comments && simpleQuestionAnswer.comments.length) ?
                                simpleQuestionAnswer.comments.map((c, i) => {
                                    return <div
                                        key={i + '-' + c.commentId}
                                        dangerouslySetInnerHTML={{__html: c.commentText}}
                                        class='comments' />
                                }) : null
                            }
                            {
                                !simpleQuestionAnswer.customComment ? null :
                                <div class='comments'>{simpleQuestionAnswer.customComment}</div>
                            }
                        </div> :
                        null
                    }
                    </div>
                }
               </div>
            </div>
        )
    }


    jumpPos() {
        const {data} = this.props
        const position = data.simpleQuestionAnswer ? data.simpleQuestionAnswer.position : false
        if (position !== null) this.props.dispatch(playerActions.jumpPos(position))
    }

    render() {
        const {data, type} = this.props
        // const website
        const web = type === 'website' ? true : false
        return (
            <div onClick={this.jumpPos}
                class='missed-item'>
                {web ? null : <i class='icon-ic-play-arrow'></i>}
                <div class='missed-item-description'
                    onMouseOver={this.showTooltip}
                    onMouseOut={this.hideTooltip}
                    ref='fortooltip'>
                    {data.questionShortName}
                    {this.renderTooltipText(data)}
                </div>
            </div>
        )
    }
}
