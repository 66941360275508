'use strict'

import loader from './loaderActions'
import modalActions from './modalActions'

export const showError = (err, dispatch, type) => {
    dispatch(loader.run({
        action: 'Error',
        text: err.message
    }))
    return dispatch({
        type: type || 'NOT_MAPPED_ERROR',
        payload: err
    })
}
