'use strict'

const envConfig = process.env.NODE_ENV
const hostArr = location.host.split('.')
const ccWideLogo = '/app/logos/callcriteria/wideLogo.png'
const ccLogo = '/app/logos/callcriteria/logo.png'
const logoUrl = hostArr.length > 1 ?
'/app/logos/' + hostArr[hostArr.length - 2].toLowerCase() + '/logo.png' :
ccLogo
const wideLogo = hostArr.length > 1 ?
'/app/logos/' + hostArr[hostArr.length - 2].toLowerCase() + '/wideLogo.png' :
ccWideLogo
const productionRoot = envConfig === 'development'
? 'http://app.callcriteria-dev.com'
: ''
const recordPost = '/CCInternalAPI.svc/json'
const recordPostFile = '/CCInternalAPI.svc'
const dashboardUrl = '/cd2.aspx'
const newDashboardUrl = '/home/'
const dashboardApi = '/webApi/v2.4'
const calibrationApi = '/webApi'
const transcriptApi = '/webApi'
const userApi = '/webApi/v1'
const cdservice = '/CDService.svc'
const assetsUrl = envConfig === 'development' ? '.' : 'https://lasoft-review-app.herokuapp.com'
const editQuestionError = 'You must select or type in a comment before saving'
const noChanges = 'Nothing changed'
const passedTraining = 'You have already passed the training'
const noPlayerRegexp = new RegExp(/point1sec\.mp3$/i)
const notificationApi = '/webApi/v1.0'
const notificationApi2 = '/webApi/v2.0'
//const settingsApi = ''

export default {
    newDashboardUrl:      productionRoot + newDashboardUrl,
    productionRoot:       productionRoot,
    dashboardApi:         productionRoot + dashboardApi,
    calibrationApi:       productionRoot + calibrationApi,
    transcriptApi:        productionRoot + transcriptApi,
    userApi:              productionRoot + userApi,
    recordPost:           productionRoot + recordPost,
    recordPostFile:       productionRoot + recordPostFile,
    cdservice:            productionRoot + cdservice,
    assetsUrl:            assetsUrl,
    loadUrl:              productionRoot,
    dashboardUrl:         productionRoot + dashboardUrl,
    editQuestionError:    editQuestionError,
    noChanges:            noChanges,
    noPlayerRegexp:       noPlayerRegexp,
    passedTraining:       passedTraining,
    logoUrl:              logoUrl,
    wideLogo:             wideLogo,
    ccWideLogo:           ccWideLogo,
    ccLogo:               ccLogo,
    notificationApi:      productionRoot + notificationApi,
    notificationApi2:     productionRoot + notificationApi2,
    schedulingApi:        productionRoot + notificationApi,
    settingsApi:          productionRoot + calibrationApi,
    salesApi:             productionRoot + calibrationApi,
    client:               productionRoot + notificationApi,
    prospectConversion:   productionRoot + notificationApi,
    reports:              productionRoot + notificationApi,
}
