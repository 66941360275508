'use strict';

import axios from 'axios'
import axiosCancel from 'axios-cancel'
import * as R from 'ramda'

import {sendRequest, sendDataRequest} from '../../APIGateway/gateway'

axiosCancel(axios, {
    debug: false
})

const actions = {

    loginUser(data) {
        return (dispatch, getState) => {
            axios.cancel('loginUser')
            const state = getState()
            const query = `userName=${data.userName}&password=${data.password}`
            dispatch({
                type: 'LOGIN_PAGE_LOGIN_USER_LOADING',
                payload: true
            })
            return sendRequest('loginUser', {
                payload: {}
            }, 'SETTINGS', false, 'loginUser', query)
            .then(resp => {
                dispatch({
                    type: 'LOGIN_PAGE_LOGIN_USER',
                    payload: resp.data
                })
                if (resp.data.Status) {
                    return window.location = '/home'
                }
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'LOGIN_PAGE_LOGIN_USER_ERROR',
                    payload: true
                })
            })
        }
    },

    forgotPassword(userName) {
        return (dispatch, getState) => {
            axios.cancel('forgotPassword')
            const state = getState()
            const query = `userName=${userName}`
            dispatch({
                type: 'LOGIN_PAGE_FORGOT_PASSOWRD_LOADING',
                payload: true
            })
            return sendRequest('forgotPassword', {
                payload: userName
            }, 'SETTINGS', false, 'forgotPassword', query)
            .then(resp => {
                dispatch({
                    type: 'LOGIN_PAGE_FORGOT_PASSOWRD',
                    payload: resp.data
                })
            }, err => {
                if (axios.isCancel(err)) {
                    return
                }
                dispatch({
                    type: 'LOGIN_PAGE_FORGOT_PASSOWRD_ERROR',
                    payload: true
                })
            })
        }
    },

}

export default actions
