'use strict'

import { PropTypes } from 'prop-types'
import classNames from 'classnames'

import {generateComment} from '../../../utils/globalHelpers'

const test = []

export default class ReviewComments extends React.Component {

    renderComment(item) {
        const {
            questionId,
        	questionShortName,
        	questionSectionName,
            questionType, // "simple" or "dynamic" or "composite"
            isRightAnswer,
            isComposite,
            isLinked,
            simpleQuestionAnswer, // for "simple" or "dynamic"
        	compositeQuestionAnswer
        } = item
        if (!isComposite
            && ((simpleQuestionAnswer.comments && simpleQuestionAnswer.comments.length)
                || simpleQuestionAnswer.customComment)) {
                    return (
                        <div class='comments-container'>
                            {
                                (simpleQuestionAnswer.comments && simpleQuestionAnswer.comments.length) ?
                                simpleQuestionAnswer.comments.map((c, i) => {
                                    return <div
                                        key={i + '-' + c.commentId}
                                        dangerouslySetInnerHTML={{__html: c.commentText}}
                                        class='comments' />
                                }) : null
                            }
                            {
                                !simpleQuestionAnswer.customComment ? null :
                                <div class='comments'>{simpleQuestionAnswer.customComment}</div>
                            }
                        </div>
                    )
        } else if (isComposite) {
            return <div class='comments-container'>
                {
                    !compositeQuestionAnswer.customComment ? null :
                    <div class='comments'>
                        {compositeQuestionAnswer.customComment || 'No Comments'}
                    </div>
                }
            </div>
        } else {
            return <div class='comments'>
                No Comments
            </div>
        }
    }

    renderQuestion(callMissedItems) {
        const sections = {}
        const sectionKeys = []
        callMissedItems.forEach(item => {
            const section = item.questionSectionName
            if (!sections[section]) {
                sections[section]=[]
                sectionKeys.push(section)
            }
            sections[section].push(item)
        })
        return Object.keys(sections).map((s,i)=>{
            const items = sections[s].map(sectionItem => {
                return <div key={sectionItem.questionShortName} class='question'>
                    <div class='name-of-comments'>
                        {
                            sectionItem.questionType === 'dynamic' ?
                            <div class='icon icon-ic-dynamic-question'/> :
                            sectionItem.questionType === 'dynamic' && sectionItem.isLinked === true ?
                            <div class='icon icon-ic-linked-dynamic'/> :
                            sectionItem.isLinked === true ?
                            <div class='icon icon-ic-linked-question'/> :
                            null
                        }
                        {sectionItem.questionShortName}: <div class='short-ans'>{
                            !sectionItem.isComposite ?
                            sectionItem.simpleQuestionAnswer.answerText :
                            sectionItem.isRightAnswer ? 'YES' : 'NO'
                        }</div>
                    </div>
                    {this.renderComment(sectionItem)}
                </div>
                        })
            return (
                <div key={'section-' + s} class="section">
                    <div class='name-of-section'>{sections[s][0].questionSectionName}</div>
                    {items}
                </div>
            )
        })
    }

    render() {
        const {
            systemData,
            metaData,
            callMissedItems,
        } = this.props.data;
        return (
            <div class='review-comments'>
                {this.renderQuestion(callMissedItems)}
            </div>
        )
    }
}
