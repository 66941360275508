import * as R from 'ramda'

const initialState = {
    passwordResetLoading    : false,
    passwordResetError      : false,
    passwordReset           : null,
    params                  : null,
    userInfoLoading         : false,
    userInfoError           : false,
    userInfo                : null,
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "PASSWORD_RESET_GET_PAGE_LOADING": {
            return {
                ...state,
                passwordResetLoading   : action.payload,
                passwordResetError     : false
            }
        }
        case "PASSWORD_RESET_GET_PAGE_ERROR": {
            return {
                ...state,
                passwordResetLoading   : false,
                passwordResetError     : action.payload
            }
        }
        case "PASSWORD_RESET_GET_PAGE": {
            return {
                ...state,
                passwordResetLoading   : false,
                passwordResetError     : false,
                passwordReset          : action.payload        
            }
        }
        case 'PASSWOR_RESET_TOKEN': {
            return {
                ...state,
                params: action.payload,
            }
        }
         case "PASSWORD_RESET_GET_USER_INFO_LOADING": {
            return {
                ...state,
                userInfoLoading   : action.payload,
                userInfoError     : false
            }
        }
        case "PASSWORD_RESET_GET_USER_INFO_ERROR": {
            return {
                ...state,
                userInfoLoading   : false,
                userInfoError     : action.payload
            }
        }
        case "PASSWORD_RESET_GET_USER_INFO": {
            return {
                ...state,
                userInfoLoading   : false,
                userInfoError     : false,
                userInfo          : action.payload        
            }
        }
    }
    return state
}
