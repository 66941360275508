import * as R from 'ramda'
import {getDefaultUserInfo, multipleGroupInfo} from './utils'

const initialState = {
    clentsUserListLoading      : false,
    clentsUserListError        : false,
    clentsUserList             : [],
    pagination                 : {
        pagenum: 1,
        pagerows: 20, // ask Stace how many rows will return API
    },
    scorecardGroupList         : null,
    scorecardGroupListLoading  : false,
    scorecardGroupListError    : false,
    rightBlockTab              : 'scorecards',
    activeGroup                : null, // this should be removed (Alex)
    activeGroups               : [],
    activeScorecard            : null, // this should be removed (Alex)
    activeScorecards           : [],
    searchingText              : null,
    hideInactive               : true,
    filters: {
        filterGroupIds:     [],
        filterScorecards: [],
    },
    userInfo                   : null,
    userInfoLoading            : false,
    userInfoError              : false,
    defaultUserInfo            : getDefaultUserInfo(),
    initialInfo                : null,
    modalOptions               : null,
    addNewGroupLoading         : false,
    addNewGroupError           : false,
    multipleGroupInfo          : multipleGroupInfo(),
    scorecardsInGroups         : [],
    addUserToGroupLoaded       : false,
    addUserToGroupError        : false,
    addUserToGroup             : null,
    usersGroups                : [],
    groupsForEdd               : [],
    groupsForDelete            : [],
    userInitialDataLoading     : false,
    userInitialDataError       : false,
    userInitialData            : null,
    newUserName                : null,
    sorting                    : {
        sortBy   : '',
        sortOrder: '',
    },
    userScorecards             : null,
    scorecardWithGroupsList    : null,
    scorecardWithGroupsError   : false,
    scorecardWithGroupsLoading : false,
    history                    : [],
    historyError               : false,
    historyLoading             : false,
    historySorting             : {
        'sortBy': 'dateAssigned',
        'sortOrder': 'asc'
    },
    historyPagination           : {
        pagenum: 1,
        pagerows: 10
    },
    historySearch              : {
        text: '',
        columns: [
            'dateAssigned',
            'assignedTo',
            'assignedToId',
            'action',
            'userName',
            'assigner',
            'scorecardName',
            'scorecardId',
            'ipAddress'
        ]
    },
    scorecardsSupervisors      : [],
    scorecardsSupervisorsError : false,
    scorecardsSupervisorsLoading: false,
    scorecardsManagers         : [],
    scorecardsManagersError    : false,
    scorecardsManagersLoading  : false,
    checkAll                   : false,
    verifyForUserName          : null,
    verifyUserNameLoading      : false,
    verifyUserNameError        : false,
    verifyUserName             : null,
    exportingClientList        : false,
    userForsedFiltersLoading   : false,
    userForsedFiltersError     : false,
    userForsedFilters          : [],
    userForsedFiltersTypesLoading : false,
    userForsedFiltersTypesError: false,
    userForsedFiltersTypes     : [],
    userForsedFiltersValuesLoading: false,
    userForsedFiltersValuesError  : false ,
    userForsedFiltersValues    : [],
    newFilters                 : [],
    removedFilters             : [],
    editedFilters              : [],

}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "SELECT_ALL_GROUPS_FOR_USER": {
            return {
                ...state,
                checkAll   : action.payload,
            }
        }
        case "SCORECARD_WITH_GROUPS_LIST_FULFILLED": {
            return {
                ...state,
                scorecardWithGroupsList   : action.payload,
                scorecardWithGroupsError  : false,
                scorecardWithGroupsLoading: false,
            }
        }
        case "GET_SCORECARD_WITH_GROUPS_LIST_LOADING": {
            return {
                ...state,
                scorecardWithGroupsError  : false,
                scorecardWithGroupsLoading: action.payload,
            }
        }
        case "SCORECARD_WITH_GROUPS_LIST_ERROR": {
            return {
                ...state,
                scorecardWithGroupsError  : action.payload,
                scorecardWithGroupsLoading: false,
            }
        }
        case "SET_USER_SCORECARD_WITH_GROUPS_LIST": {
            return {
                ...state,
                userScorecards: action.payload,
            }
        }
        case "CLIENT_USERS_TABLE_SORTING": {
            return {
                ...state,
                sorting: action.payload,
            }
        }
        case "CLIENT_USER_INITIAL_DATA_LOADING": {
            return {
                ...state,
                userInitialDataLoading: true,
                userInitialDataError  : false,
            }
        }
        case "CLIENT_USER_INITIAL_DATA_FULFILLED": {
            return {
                ...state,
                userInitialDataLoading: false,
                userInitialDataError  : false,
                userInitialData       : action.payload,
            }
        }
        case "CLIENT_USER_INITIAL_DATA_ERROR": {
            return {
                ...state,
                userInitialDataLoading: false,
                userInitialDataError  : true,
            }
        }
        case "GET_CLIENTS_USER_LIST_LOADING": {
            return {
                ...state,
                clentsUserListLoading: action.payload,
                clentsUserListError: false,
            }
        }
        case "GET_CLIENTS_USER_LIST_ERROR": {
            return {
                ...state,
                clentsUserListError: action.payload,
                clentsUserListLoading: false,
            }
        }
        case "GET_CLIENTS_USER_LIST": {
            return {
                ...state,
                clentsUserList: action.payload,
                clentsUserListLoading: false,
                clentsUserListError: false,
            }
        }
        case "PAGE_CHANGED": {
            return {
                ...state,
                pagination: action.payload
            }
        }
        case "GET_SCORECARD_GROUP_LIST_LOADING": {
            return {
                ...state,
                scorecardGroupListLoading: action.payload,
                scorecardGroupListError: false,
            }
        }
        case "SCORECARD_GROUP_LIST_FULFILLED": {
            return {
                ...state,
                scorecardGroupList: action.payload,
                scorecardGroupListLoading: false,
                scorecardGroupListError: false,
            }
        }
        case "SCORECARD_GROUP_LIST_ERROR": {
            return {
                ...state,
                scorecardGroupListLoading: false,
                scorecardGroupListError: action.payload,
            }
        }
        case "SET_CLIENT_USERS_RIGHT_BLOCK_TAB": {
            return {
                ...state,
                rightBlockTab: action.payload,
            }
        }
        case "SET_CLIENT_USERS_ACTIVE_GROUP": {
            return {
                ...state,
                activeGroup: action.payload,
                activeScorecard: null,
            }
        }
        case "SET_CLIENT_USERS_ACTIVE_SCORECARD": {
            return {
                ...state,
                activeScorecard: action.payload,
            }
        }
        case "SET_SEARCHING_TEXT": {
            return {
                ...state,
                searchingText: action.payload,
            }
        }
        case "HIDE_INACTIVE_USERS": {
            return {
                ...state,
                hideInactive: action.payload,
            }
        }
        case "SET_CLIENT_USERS_FILTERS": {
            return {
                ...state,
                filters: action.payload,
            }
        }
         case "GET_USER_INFO_LOADING": {
            return {
                ...state,
                userInfoLoading: action.payload,
                userInfoError: false,
            }
        }
        case "GET_USER_INFO": {
            return {
                ...state,
                userInfo: action.payload,
                userInfoLoading: false,
                userInfoError: false,
            }
        }
        case "GET_USER_INFO_ERROR": {
            return {
                ...state,
                userInfoLoading: false,
                userInfoError: action.payload,
            }
        }
        case "CHANGE_USER_INFO": {
            return {
                ...state,
                userInfo: action.payload,
            }
        }
        case "CHANGE_DEFAULT_USER_INFO": {
            return {
                ...state,
                defaultUserInfo: action.payload,
            }
        }
        case "GET_INITIAL_INFO": {
            return {
                ...state,
                initialInfo: action.payload,
            }
        }
        case "SET_CLIENT_USERS_MODAL_OPTIONS": {
            return {
                ...state,
                modalOptions: action.payload,
            }
        }
        case "ADD_NEW_GROUP_LOADING": {
            return {
                ...state,
                addNewGroupLoading: action.payload,
            }
        }
        case "ADD_NEW_GROUP_ERROR": {
            return {
                ...state,
                addNewGroupError: action.payload,
            }
        }
        case "SET_CLIENT_USERS_ACTIVE_SCORECARDS": {
            return {
                ...state,
                activeScorecards: action.payload,
            }
        }
        case "SET_CLIENT_USERS_ACTIVE_GROUPS": {
            return {
                ...state,
                activeGroups: action.payload,
            }
        }
        case "SET_SCORECARDS_IN_GROUPS": {
            return {
                ...state,
                scorecardsInGroups: action.payload,
            }
        }
        case "ADD_USER_TO_GROUP_LOADING": {
            return {
                ...state,
                addUserToGroupLoaded: action.payload,
                addUserToGroupError: false,
            }
        }
        case "ADD_USER_TO_GROUP": {
            return {
                ...state,
                addUserToGroupLoaded: false,
                addUserToGroupError: false,
                addUserToGroup: action.payload
            }
        }
        case "ADD_USER_TO_GROUP_ERROR": {
            return {
                ...state,
                addUserToGroupLoaded: false,
                addUserToGroupError: action.payload,
            }
        }
        case "SET_USERS_GROUPS": {
            return {
                ...state,
                usersGroups: action.payload,
            }
        }
        case "SET_GROUPS_FOR_DELETE": {
            return {
                ...state,
                groupsForDelete: action.payload,
            }
        }
        case "SET_GROUPS_FOR_ADD": {
            return {
                ...state,
                groupsForEdd: action.payload,
            }
        }
        case "SET_NEW_USERNAME": {
            return {
                ...state,
                newUserName: action.payload
            }
        }
        case "GET_HISTORY_LOADING": {
            return {
                ...state,
                historyError: false,
                historyLoading: action.payload
            }
        }
        case "GET_HISTORY": {
            return {
                ...state,
                history: action.payload,
                historyError: false,
                historyLoading: false
            }
        }
        case "GET_HISTORY_ERROR": {
            return {
                ...state,
                historyError: action.payload,
                historyLoading: false
            }
        }
        case "SET_HISTORY_SORTING": {
            return {
                ...state,
                historySorting: action.payload
            }
        }
        case "SET_HISTORY_SEARCH": {
            return {
                ...state,
                historySearch: {
                    text: action.payload,
                    columns: state.historySearch.columns
                }
            }
        }
        case "SET_HISTORY_PAGINATION": {
            return {
                ...state,
                historyPagination: {
                    pagenum: action.payload,
                    pagerows: state.historyPagination.pagerows
                }
            }
        }
        case "GET_SCORECARD_SUPEVISORS_LOADING": {
            return {
                ...state,
                scorecardsSupervisorsError: false,
                scorecardsSupervisorsLoading: action.payload
            }
        }
        case "GET_SCORECARD_SUPEVISORS": {
            return {
                ...state,
                scorecardsSupervisors: action.payload,
                scorecardsSupervisorsError: false,
                scorecardsSupervisorsLoading: false
            }
        }
        case "GET_SCORECARD_SUPEVISORS_ERROR": {
            return {
                ...state,
                scorecardsSupervisorsError: action.payload,
                scorecardsSupervisorsLoading: false
            }
        }
        case "GET_SCORECARD_MANAGER_LOADING": {
            return {
                ...state,
                scorecardsManagersError: false,
                scorecardsManagersLoading: action.payload
            }
        }
        case "GET_SCORECARD_MANAGER": {
            return {
                ...state,
                scorecardsManagers: action.payload,
                scorecardsManagersError: false,
                scorecardsManagersLoading: false
            }
        }
        case "GET_SCORECARD_MANAGER_ERROR": {
            return {
                ...state,
                scorecardsManegersError: action.payload,
                scorecardsManegersLoading: false
            }
        }
        case "CALIBRATION_PAGE_SET_USER_NAME_FOR_VERIFY": {
            return {
                ...state,
                verifyForUserName: action.payload,
            }
        }
        case "GET_VERIFY_USER_NAME_LOADING": {
            return {
                ...state,
                verifyUserNameLoading: action.payload,
                verifyUserNameError: false
            }
        }
        case "GET_VERIFY_USER_NAME_ERROR": {
            return {
                ...state,
                verifyUserNameLoading: false,
                verifyUserNameError: action.payload
            }
        }
        case "GET_VERIFY_USER_NAME": {
            return {
                ...state,
                verifyUserNameLoading: false,
                verifyUserNameError: false,
                verifyUserName: action.payload,
            }
        }
        case "EXPORT_CLIENT_LIST": {
            return {
                ...state,
                exportingClientList: action.payload
            }
        }
        case "GET_USER_FORSED_FILTERS_LOADING": {
            return {
                ...state,
                userForsedFiltersLoading: action.payload,
                userForsedFiltersError: false
            }
        }
        case "GET_USER_FORSED_FILTERS_ERROR": {
            return {
                ...state,
                userForsedFiltersLoading: false,
                userForsedFiltersError: action.payload
            }
        }
        case "GET_USER_FORSED_FILTERS": {
            return {
                ...state,
                userForsedFiltersLoading: false,
                userForsedFiltersError: false,
                userForsedFilters: action.payload,
            }
        }
        case "GET_USER_FORSED_FILTERS_TYPES_LOADING": {
            return {
                ...state,
                userForsedFiltersTypesLoading: action.payload,
                userForsedFiltersTypesError: false
            }
        }
        case "GET_USER_FORSED_FILTERS_TYPES_ERROR": {
            return {
                ...state,
                userForsedFiltersTypesLoading: false,
                userForsedFiltersTypesError: action.payload
            }
        }
        case "GET_USER_FORSED_FILTERS_TYPES": {
            return {
                ...state,
                userForsedFiltersTypesLoading: false,
                userForsedFiltersTypesError: false,
                userForsedFiltersTypes: action.payload,
            }
        }
        case "GET_USER_FORSED_VALUES_TYPES_LOADING": {
            return {
                ...state,
                userForsedFiltersValuesLoading: action.payload,
                userForsedFiltersValuesError: false
            }
        }
        case "GET_USER_FORSED_FILTERS_VALUES_ERROR": {
            return {
                ...state,
                userForsedFiltersValuesLoading: false,
                userForsedFiltersValuesError: action.payload
            }
        }
        case "GET_USER_FORSED_FILTERS_VALUES": {
            return {
                ...state,
                userForsedFiltersValuesLoading: false,
                userForsedFiltersValuesError: false,
                userForsedFiltersValues: action.payload,
            }
        }
        case "SET_FILTERS_NEW": {
            return {
                ...state,
                newFilters: action.payload
            }
        }
        case "SET_FILTERS_REMOVED": {
            return {
                ...state,
                removedFilters: action.payload
            }
        }
        case "SET_FILTERS_EDITED": {
            return {
                ...state,
                editedFilters: action.payload
            }
        }
    }
    return state
}
