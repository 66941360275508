'use strict'

// import moment from 'moment'
import moment from '../vendor/moment-timezone.min'
import * as R from 'ramda'



// setup Momentjs
moment.updateLocale('en', {
    week: {dow: 0} // week starts on Sunday
});

export const processUsingTemplate = (text, listenData) => {

    const newText = text.replace(/<(\w+)(\[(\d+)\])?:(\w+)>/ig, function (match, prefix, p2, index, field) {
        const prefixUpper = prefix.toUpperCase()
        let data = {
            "LD": listenData,
            "OD": listenData.OtherData,
            "SD": listenData.SchoolData
        }[prefixUpper] || {};

        let value;
        if (prefixUpper === "LD") {
            let dataKey = Object.keys(data).find(key => key.toUpperCase() === field.toUpperCase())
            value = dataKey && data[dataKey] ? data[dataKey] : ''
        }
        else if (prefixUpper === "OD") {
            if (data && data.length) {
                let odItem = data.find(x => x.key.toUpperCase() === field.toUpperCase())
                value = odItem && odItem.value ? odItem.value : ''
            }
        }
        else if (prefixUpper === "SD") {
            index = Number(index)
            data = data[index - 1]
            let dataKey = Object.keys(data).find(key => key.toUpperCase() === field.toUpperCase())
            value = dataKey && data[dataKey] ? data[dataKey] : ''
        }

        if (value) {
            return '<span class=""  title="this comes from ' + match + '"><i>' + value + '</i><span>';
        } else {
            let encoded = match.replace(/</g, '&lt;').replace(/>/g, '&gt;')
            return '<span class="faq-field-unavailable" title="this is information is unavailable">' + encoded + '</span>';
        }
    })
    return newText;
}



// Just like R.zip but can take any number of lists, not only two.
export function zip(...lists) {
  return R.reduce(
    (a, l) => {
      if (a) {
        return R.zipWith((a, b) => R.append(b, a), a, l);
      } else {
        return R.map(el => [el], l);
      }
    },
    null,
    lists
  );
}

export function hasRole(userRole, ...roles) {
    return R.contains(
        userRole,
        [...roles]
    );
}


// Allusion to Clojure Threading Macros
// (https://clojure.org/guides/threading_macros)
export function thread(o) {
    return (...fns) => R.pipe(...fns)(o);
}




// month: zero-based year month (0-11)
// day: zero-based weekday starting from Sunday (0-6)
export function generateMonthData(year, month) {
    const today = moment();
    const first = moment({year, month, day: 1});
    const firstMonth = first.month();
    function getDateFlags(date) {
        const month = date.month();
        const day = date.day()
        return {
            m: date,
            month, day,
            date: date.date(),
            isLastInMonth: date.daysInMonth() === date.date(),
            isToday: Number(date) === Number(today),
            isFuture: date - today > 0,
            isWeekend: R.contains(day, [6, 0]),
            isPrevMonth: month < firstMonth,
            isActiveMonth: month === firstMonth,
            isNextMonth: month > firstMonth,
        };
    }
    const startDate = first.clone().startOf('week');
    const endDate = first.clone().endOf('month').endOf('week');

    return thread(R.range(0, endDate.diff(startDate, 'days')+1))(
        R.map(i => startDate.clone().add(i, 'days')),
        R.map(getDateFlags),
        R.splitEvery(7)
    );
}

export function allowedDate(mode, value, dateSettings, rangeStart, rangeEnd, comparisonStart, comparisonEnd) {
    let returnValue = false
    if (
        (
            (mode === 'rangeEnd' && moment(value).diff(rangeStart, 'minutes') >= 0)
            || (mode === 'comparisonEnd' && moment(value).diff(comparisonStart, 'minutes') >= 0)
            || mode === 'rangeStart'
            || mode === 'comparisonStart'
        )
        && moment(value).isValid()
        && moment() - moment(value) > 0
    ) {

        const getValue = () => {
            switch (mode) {
                case "rangeStart":      return value;
                case "rangeEnd":        return moment(rangeStart) - moment(value) > 0
                                        ? rangeStart : value;
                case "comparisonStart": return moment(rangeStart).add(-1, 'days') - moment(value) > 0
                                        ? value : moment(rangeStart).add(-1, 'days').format('YYYY-MM-DD');
                case "comparisonEnd":   return moment(comparisonStart) - moment(value) > 0
                                        ? comparisonStart
                                        : moment(value) - moment(rangeStart).add(-1, 'days') > 0
                                        ? moment(rangeStart).add(-1, 'days').format('YYYY-MM-DD')
                                        : value;
                default:                throw new Error();
            }
        }
        returnValue = getValue()
    }
    return returnValue
}


export function plural(count, single, plural) {
    return count === 1 ? `${count} ${single}` : `${count} ${plural || single+"s"}`;
}

export function filterJoin(separator, array) {
    return thread(array)(
        R.reject(R.either(R.isNil, R.isEmpty)),
        R.join(separator)
    );
}

export function getStyle(el, styleProp) {
    var value, defaultView = (el.ownerDocument || document).defaultView;
    // W3C standard way:
    if (defaultView && defaultView.getComputedStyle) {
        // sanitize property name to css notation
        // (hypen separated words eg. font-Size)
        styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
        return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    } else if (el.currentStyle) { // IE
        // sanitize property name to camelCase
        styleProp = styleProp.replace(/\-(\w)/g, function(str, letter) {
        return letter.toUpperCase();
    });
    value = el.currentStyle[styleProp];
    // convert other units to pixels on IE
    if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
        return (function(value) {
            var oldLeft = el.style.left, oldRsLeft = el.runtimeStyle.left;
            el.runtimeStyle.left = el.currentStyle.left;
            el.style.left = value || 0;
            value = el.style.pixelLeft + "px";
            el.style.left = oldLeft;
            el.runtimeStyle.left = oldRsLeft;
            return value;
        })(value);
    }
    return value;
    }
}

export const isEven = (n) => {
    return n % 2 == 0
}

export const animate = (elem, style, unit, from, to, time, prop) => {
    if (!elem) {
        return
    }
    const start = new Date().getTime()
    const timer = setInterval(function () {
            const step = Math.min(1, (new Date().getTime() - start) / time)
            if (prop) {
                elem[style] = (from + step * (to - from))+unit
            } else {
                elem.style[style] = (from + step * (to - from))+unit
            }
            if (step === 1) {
                clearInterval(timer)
            }
        }, 25)
    if (prop) {
          elem[style] = from+unit;
    } else {
          elem.style[style] = from+unit
    }
}

export const getCallData = (record) => {
    const {HiddenData} = record
    const callHiddenElements = HiddenData && HiddenData.callHiddenElements
    const callHidden = callHiddenElements && R.pluck('value', callHiddenElements).map(e => e.toUpperCase())
    function getAddress(record) {
        const {address, City, State, Zip} = record
        if (!address && !City && !State && !Zip) return false
        if (!address && (City || State || Zip)) {
            return City + ' ' + State + ' ' + Zip
        } else if (address && (City || State || Zip)) {
            return address + '<br/>' + City + ' ' + State + ' ' + Zip
        } else {
            return address
        }
    }
    function show(obj) {
        if (!callHidden) return obj
        const o = Object.keys(obj)[0]
        if (callHidden.indexOf(o.toUpperCase()) === -1) return obj
        return null
    }

    const fulfilled = [
        show({'First Name': record.First_Name || ''}),
        show({'Last Name': record.Last_Name || ''}),
        show({'Email': record.Email || ''}),
        show({'Phone': record.phone || ''}),
        show({'Website': record.website || ''}),
        show({'Education level': record.EducationLevel || ''}),
        show({'High school grad year': record.HighSchoolGradYear || ''}),
        show({'Degree start timeframe': record.DegreeStartTimeframe || ''}),
        show({'Campaign': record.CAMPAIGN || ''}),
        show({'callType': record.CALL_TYPE || ''}),
        show({'Disposition': record.DISPOSITION || ''}),
        show({'Notes': record.disposition || ''}),
        show({'Session ID': record.SESSION_ID || ''}),
        show({'Compliance Sheet': record.compliance_sheet || ''}),
        show({'Review Date': record.review_date || ''}),
        show({'Call date': record.call_date || ''}),
        show({'Profile ID': record.profile_id || ''}),
        show({'Address': getAddress(record)}),
        show({'ANI': record.ANI || ''}),
        show({'DNIS': record.DNIS || ''}),
        show({'Scorecard name': record.scorecard_name || ''}),
        show({'Agent': record.AGENT || record.agent || '' }),
        show({'Client Name': record.LIST_NAME || ''}),
        show({'Group': record.AGENT_GROUP || ''}),
    ]
    return fulfilled.filter(item => !!item)
}

export const getSchoolData = (record, school) => {
    const {HiddenData} = record
    const schoolHiddenElements = HiddenData && HiddenData.schoolHiddenElements
    const schoolHidden = schoolHiddenElements && R.pluck('value', schoolHiddenElements).map(e => e.toUpperCase())
    let knownFields = {
        DegreeOfInterest: 'DEGREE',
        AOI1: 'AREA 1',
        AOI2: 'AREA 2',
        L1_SubjectName: 'SUBJECT 1',
        L2_SubjectName: 'SUBJECT 2',
        Portal: 'PORTAL',
        TCPA: 'TCPA'
    }
    let schoolData = []
    Object.keys(school).forEach((key) => {
        if (key === 'School') return
        let schoolItem = {}
        let result = knownFields[key] || key
        if (!schoolHidden || schoolHidden.indexOf(result.toUpperCase()) === -1) {
            schoolItem[result] = school[key]
            schoolData.push(schoolItem)
        }
    })
    return schoolData
}

export const getEditSchoolData = (schools) => {
    const returnArray = []
    if (!schools || !schools.length) return returnArray
    schools.forEach((school, index) => {
        returnArray.push(Object.assign({}, school))
    })
    return returnArray
}

export const getEditOtherData = (data) => {
    const returnArray = []
    if (!data || !data.length) return returnArray
    data.forEach((item, index) => {
        let el = document.createElement('div')
        el.innerHTML = item.value
        let itemObj = {}
        itemObj.value = el.innerText
        itemObj.key = item.key
        itemObj.id = item.id
        returnArray.push(itemObj)
    })
    return returnArray
}

export const getTextFromHTML= (str) => {
    let el = document.createElement('div')
    el.innerHTML = str
    return el.innerText
}

export const isUrl = (s) => {
    return (s.indexOf('.') >= 0 && s.indexOf('/') >= 0);
}

export const getEditDetails = (record) => {
    return [
        {'First_Name': record.First_Name || ''},
        {'Last_Name': record.Last_Name || ''},
        {'Email': record.Email || ''},
        {'phone': record.phone || ''},
        {'website': record.website || ''},
        {'EducationLevel': record.EducationLevel || ''},
        {'HighSchoolGrad year': record.HighSchoolGradYear || ''},
        {'DegreeStartTimeframe': record.DegreeStartTimeframe || ''},
        {'CAMPAIGN': record.CAMPAIGN || ''},
        {'CALL_TYPE': record.CALL_TYPE || ''},
        {'DISPOSITION': record.DISPOSITION || ''},
        {'disposition': record.disposition || ''},
        {'SESSION_ID': record.SESSION_ID || ''},
        {'compliance_sheet': record.compliance_sheet || ''},
        {'review_date': record.review_date || ''},
        {'call_date': record.call_date || ''},
        {'profile_id': record.profile_id || ''},
        {'address': record.address},
        {'City': record.City},
        {'State': record.State},
        {'Zip': record.Zip},
        {'ANI': record.ANI || ''},
        {'DNIS': record.DNIS || ''},
        {'scorecard_name': record.scorecard_name || ''},
        {'AGENT': record.AGENT || record.agent || '' },
        {'LIST_NAME': record.LIST_NAME || ''}
        // {'scorecard': record.scorecard || ''}
    ]
}

export const getDataDisplayText = (name) => {
    const displayTexts = {
        First_Name: 'First Name',
        Last_Name: 'Last Name',
        Email: 'Email',
        phone: 'Phone',
        website: 'Website',
        EducationLevel: 'Education level',
        HighSchoolGradYear: 'High school grad year',
        DegreeStartTimeframe: 'Degree start timeframe',
        CAMPAIGN: 'Campaign',
        CALL_TYPE: 'Call type',
        DISPOSITION: 'Disposition',
        disposition: 'Notes',
        SESSION_ID: 'Session ID',
        compliance_sheet: 'Compliance Sheet',
        review_date: 'Review Date',
        call_date: 'Call date',
        profile_id: 'Profile ID',
        Zip: 'ZIP',
        State: 'State',
        City: 'City',
        address: 'Address',
        ANI: 'ANI',
        DNIS: 'DNIS',
        scorecard_name: 'Scorecard name',
        agent: 'Agent',
        AGENT: 'Agent',
        LIST_NAME: 'Client Name',
        // other data keys
        BestTimeToContact: 'Best time to contact',
        BirthYear: 'Birth Year',
        MilitaryStatus: 'Military Status',
        USMilitaryAffiliation: 'US Military Affiliation',
        StartMonth: 'Start Month',
        StartRange: 'Start Range',
        TeachingLicense: 'Teaching License',
        USCitizen: 'US Citizen',
        RNLicense: 'RN License',
        LevelOfEducation: 'Level Of Education',
        YearObtainedHSGED: 'Year Obtained HSGED',
        // schools keys
        DegreeOfInterest: 'DEGREE',
        AOI1: 'AREA 1',
        AOI2: 'AREA 2',
        L1_SubjectName: 'SUBJECT 1',
        L2_SubjectName: 'SUBJECT 2',
        Portal: 'PORTAL',
        TCPA: 'TCPA'
    }
    return displayTexts[name] || name
}

export const getDataRecordName = (name) => {
    switch (name) {
        case 'First Name':
            name = 'First_Name'
            break;
        case 'Last Name':
            name = 'Last_Name'
            break;
        case 'Email':
            name = 'Email'
            break;
        case 'Phone':
            name = 'phone'
            break;
        case 'Website':
            name = 'website'
            break;
        case 'Education level':
            name = 'EducationLevel'
            break;
        case 'High school grad year':
            name = 'HighSchoolGradYear'
            break;
        case 'Degree start timeframe':
            name = 'DegreeStartTimeframe'
            break;
        case 'Campaign':
            name = 'CAMPAIGN'
            break;
        case 'callType':
            name = 'CALL_TYPE'
            break;
        case 'Disposition':
            name = 'DISPOSITION'
            break;
        case 'Notes':
            name = 'DISPOSITION'
            break;
        case 'Session ID':
            name = 'SESSION_ID'
            break;
        case 'Compliance Sheet':
            name = 'compliance_sheet'
            break;
        case 'Review Date':
            name = 'review_date'
            break;
        case 'Call date':
            name = 'call_date'
            break;
        case 'Profile ID':
            name = 'profile_id'
            break;
        case 'Address':
            name = 'getAddress'
            break;
        case 'ANI':
            name = 'ANI'
            break;
        case 'DNIS':
            name = 'DNIS'
            break;
        case 'Scorecard name':
            name = 'scorecard_name'
            break;
        case 'Agent':
            name = 'AGENT'
            break;
        case 'Client Name':
            name = 'LIST_NAME'
            break;
        case 'Group':
            name = 'AGENT_GROUP'
            break;
        default:
    }
    return name
}

export const getDOMCoords = (elem) => {
    const box = elem.getBoundingClientRect()

    const body = document.body
    const docEl = document.documentElement

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

    const clientTop = docEl.clientTop || body.clientTop || 0
    const clientLeft = docEl.clientLeft || body.clientLeft || 0

    // const top  = box.top +  scrollTop - clientTop
    const top  = box.top
    const left = box.left + scrollLeft - clientLeft
    const right = box.right

    return { top: Math.round(top), left: Math.round(left), right: Math.round(right)}
}

export const formatDate = (date, format) => {
    // moment.tz.add("America/Los_Angeles|PST PDT PWT PPT|80 70 70 70|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261q0 1nX0 11B0 1nX0 SgN0 8x10 iy0 5Wp1 1VaX 3dA0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1a00 1fA0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|15e6");
    // const a = moment.tz(date, "M/D/YYYY h:mm:ss A", "America/Los_Angeles");
    moment.tz.add("America/Denver|MST MDT|70 60|01010101010101010101010|1GI90 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0|26e5");
    const a = moment.tz(date, "M/D/YYYY h:mm:ss A", "America/Denver");
    return {
        a: a,
        b: a.format(format),
        utc: a.clone().utc().format(format),
        local: a.clone().local().format(format),
        fromNow: a.clone().local().fromNow()
    }
}

export const fromSecsToHoursMinsSecs = (time) => {
    const hours = Math.floor(time / 3600) + ''
    const mins = Math.floor((time - hours * 3600) / 60)
    const secs = time - hours * 3600 - mins * 60
    const str_pad_left = (string, pad, length) => {
        return (new Array(length + 1).join(pad) + string).slice(-length)
    }
    const displayHours = hours.length > 3
    ? hours.substring(0, hours.length-3) + ',' + hours.substring(hours.length-3, hours.length)
    : hours
    return displayHours + ':' + str_pad_left(mins, '0', 2) + ':'+str_pad_left(secs, '0', 2)
}

export const fromSecsToMinsAndSecs = (time) => {
    const mins = Math.floor(time / 60)
    const secs = time - mins * 60
    const str_pad_left = (string, pad, length) => {
        return (new Array(length + 1).join(pad) + string).slice(-length)
    }

    return str_pad_left(mins, '0', 2)+':'+str_pad_left(secs, '0', 2)
}

export const arrDiff = (a1, a2) => {

    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}

export const generateComment = (checkedArray, notCheckedArray, optionVerb) => {

    const insertion = optionVerb ? optionVerb : 'verified'

    let checkedText = '',
        notCheckedText = '',
        checked = false,
        checkedComments = '',
        notCheckedComments = ''

    if (checkedArray.length !== 0) {
        checked = true
        checkedComments = checkedArray.map((comment, index) => {
            return '<b class="composite-success">' + (comment.text || comment.Item) + '</b>'
        })
        if (checkedArray.length === 1 && !(checkedArray[0].text || checkedArray[0].Item)) {
            checkedText = ''
        } else {
            checkedText = 'Agent ' + insertion + ' '
            + [checkedComments.slice(0, -1).join(', '), checkedComments.slice(-1)[0]]
                .join(checkedComments.length < 2 ? '' : ' and ')
        }
    }

    if (notCheckedArray.length !== 0) {
        notCheckedComments = notCheckedArray.map((comment, index) => {
            return '<b>' + (comment.text || comment.Item) + '</b>'
        })
        if (notCheckedArray.length === 1 && !(notCheckedArray[0].text || notCheckedArray[0].Item)) {
            notCheckedText = ''
        } else {
            notCheckedText = (checked ? ' but not ' : 'Agent has not ' + insertion + ' ')
            + [notCheckedComments.slice(0, -1).join(', '), notCheckedComments.slice(-1)[0]]
                .join(notCheckedComments.length < 2 ? '' : ' and ')
        }
    }

    return {
        checkedText: checkedText,
        notCheckedText: notCheckedText
    }
}

export const getClassName = (str) => {
    let lowStr = str.toLowerCase()
    switch (lowStr) {
        case 'yes':
            return 'Yes'
            break;
        case 'no':
            return 'No'
            break;
        case 'n/a':
        case 'na':
            return 'NA'
            break;
        case 'not verbatim':
            return 'NA'
            break;
        case 'no chance':
            return 'NA'
            break;
        default:
            return 'NA'
    }
}

export const setTextareaHeight = (e) => {
    const target = e.currentTarget
    const pTop = window.getComputedStyle(target, null).getPropertyValue('padding-top')
    const pBot = window.getComputedStyle(target, null).getPropertyValue('padding-bottom')
    target.style.height = 0
    const height = 14 + target.scrollHeight
        - pTop.substr(0, pTop.indexOf('px'))
        - pBot.substr(0, pBot.indexOf('px'))
    target.style.overflow = 'hidden'
    target.style.height = height + 'px'
}

// detect '<' symbols if they are not tags and return atring with replacer instead '<'
export const improveString = (str, replacer) => {
    var reg = /<[^<]*>/g;
    var start = 0;

    str.replace(reg, function(fStr, fIndex) {
        str = str.replace(/</g, function(sStr, sIndex) {
            if (sIndex >= fIndex || start >= sIndex) {
                return sStr;
            } else {
                return replacer;
            }
        });
        start = fIndex;
        return fStr;
    });
    str = str.replace(/</g, function(sStr, sIndex){
        if (start >= sIndex) {
            return sStr;
        } else {
            return replacer;
        }
    });
    return str;
}

export const mapMatch = (selected, all, scorecards) => {
    const arr = []
    selected.forEach((item) => {
        const matchItem = all.find((match) => {
            return match.value === item
        })
        if (matchItem) {
            if (scorecards) {
                arr.push(matchItem)
            } else {
                arr.push(item)
            }
        }
    })
    return arr
}

export const getCallDetailsColumnLabel = (val) => {
    let value = val
    switch (val.toUpperCase()) {
        case 'AGENT':
            value = 'Agent Name'
            break;
        case 'GROUP':
            value = 'Agent Group'
            break;
        case 'CALLID':
            value = 'Call ID'
            break;
        case 'SESSIONID':
            value = 'Session ID'
            break;
        case 'PROSPECTFIRSTNAME':
            value = 'First name'
            break;
        case 'PROSPECTLASTNAME':
            value = 'Last name'
            break;
        case 'PROSPECTEMAIL':
            value = 'Email'
            break;
        case 'PROSPECTPHONE':
            value = 'Phone'
            break;
        case 'PROSPECTADDRESS':
            value = 'Address'
            break;
        case 'REVIEWDATE':
            value = 'Review date'
            break;
        case 'CALLDATE':
            value = 'Call date'
            break;
        case 'PROFILEID':
            value = 'Profile ID'
            break;
        case 'REVIEWERNAME':
            value = 'Reviewer'
            break;
        case 'CALLAUDIOLENGTH':
            value = 'Duration'
            break;
        case '<ICONS>':
            value = ''
            break;
        default:
    }
    return value
}

export const getDashboardCellKey = (val) => {
    let value = val
    switch (val) {
        case 'Type':
            value = 'callType'
            break;
        case 'Duration':
            value = 'callAudioLength'
            break;
        case 'Scorecard':
            value = 'scorecardName'
            break;
        case 'Review Date':
            value = 'reviewDate'
            break;
        case 'Reviewer':
            value = 'reviewerName'
            break;
        case 'Score':
            value = 'agentScore'
            break;
        case 'Missed Items':
            value = 'missedItemsCount'
            break;
        case 'Call Date':
            value = 'callDate'
            break;
        case 'Agent':
            value = 'agentName'
            break;
        case 'Group':
            value = 'agentGroup'
            break;
        case 'Campaign':
            value = 'campaign'
            break;
        case 'Session ID':
            value = 'sessionId'
            break;
        case 'Profile ID':
            value = 'profileId'
            break;
        case 'First Name':
            value = 'prospectFirstName'
            break;
        case 'Last Name':
            value = 'prospectLastName'
            break;
        case 'Phone':
            value = 'prospectPhone'
            break;
        case 'Email':
            value = 'prospectEmail'
            break;
        case 'Review Status':
            value = 'callReviewStatus'
            break;
        case 'Result':
            value = 'callFailed'
            break;
        default:
    }
    return value
}

export const getPages = (number, count) => {
	var arr = []
	if (number>9) {
		arr.push(1)
		if (count-3>1) arr.push('left')
		if (count-2>1) arr.push(count-2)
		if (count-1>1) arr.push(count-1)
		if (count>1) arr.push(count)
		if (count+1<number) arr.push(count+1)
		if (count+2<number) arr.push(count+2)
		if (count+3<number) arr.push('right')
		if (arr.indexOf(number) === -1) arr.push(number)
    } else {
		for (var i=1; i<=number; i++) {
			arr.push(i)
		}
    }
	return arr
}

export const getPageNums = (pagination, total) => {
    const {pagenum, pagerows} = pagination
    const pages = Math.ceil(total/pagerows)
    const leftClick = pagenum - 5 < 2 ? 2 : pagenum - 5
    const rightClick = pages - pagenum - 5 < 2 ? pages - 1 : pagenum + 5
	const arr = []
	if (pages>9) {
		arr.push({
            text: 1,
            number: 1
        })
		if (pagenum-3>1) arr.push({
            text: '...',
            number: leftClick
        })
		if (pagenum-2>1) arr.push({
            text: pagenum-2,
            number: pagenum-2
        })
		if (pagenum-1>1) arr.push({
            text: pagenum-1,
            number: pagenum-1
        })
		if (pagenum>1) arr.push({
            text: pagenum,
            number: pagenum
        })
		if (pagenum+1<pages) arr.push({
            text: pagenum+1,
            number: pagenum+1
        })
		if (pagenum+2<pages) arr.push({
            text: pagenum+2,
            number: pagenum+2
        })
		if (pagenum+3<pages) arr.push({
            text: '...',
            number: rightClick
        })
		if (!arr.find(p => p.number === pages)) arr.push({
            text: pages,
            number: pages
        })
    } else {
		for (var i=1; i<=pages; i++) {
			arr.push({
                text: i,
                number: i
            })
		}
    }
	return arr
}

export const convertChat = (data) => {
    data.forEach((m, i) => {
        m.id = i
        m.delay = (m.type.toUpperCase() === 'AGENT' && data[i-1] && data[i-1].type.toUpperCase() === 'VISITOR')
        ? m.date - data[i-1].date
        : null
    })
    // const getRespTimeouts = (arr) => {
    //     const visitorItem = arr.find(m => m.type.toUpperCase() === 'VISITOR')
    //     if (!visitorItem) return
    //     const newArr = arr.slice(arr.indexOf(visitorItem))
    //     const agentItem = newArr.find(m => m.type.toUpperCase() === 'AGENT')
    //     if (!agentItem) return
    //     const itemWithDelay = data.find(m => m.id === agentItem.id)
    //     itemWithDelay.delay = agentItem.date - visitorItem.date
    //     getRespTimeouts(newArr.slice(newArr.indexOf(agentItem)))
    // }
    // const dataClone = R.clone(data)
    // getRespTimeouts(dataClone)
    return data
}

export const chatHTML = (dataNotFormatted) => {
    const data = convertChat(dataNotFormatted)
    const container = document.createElement('div')
    const header = document.createElement('h3')
    header.innerText = 'CHAT'
    header.style.textAlign = 'center'
    container.appendChild(header)
    container.style.fontFamily = 'sans-serif'
    if (data && data.length && data.splice) {
        data.forEach(item => {
            const el = document.createElement('div')
            const name = document.createElement('div')
            const message = document.createElement('div')
            name.innerText = (item.name || '') + ' (' + item.type + (item.delay ? ' ' + item.delay + 's' : '') + '):'
            message.innerText = item.message
            name.style.padding = '4px'
            name.style.color = item.type === 'agent' ? '#2c3e50' : '#149bdf'
            name.style.fontWeight = 'bold'
            if (item.date) {
                const date = document.createElement('div')
                date.innerText = moment(item.date*1000).format('YYYY-MM-DD HH:mm:ss')
                date.style.float = 'right'
                date.style.color = 'grey'
                date.style.fontWeight = 400
                date.style.marginRight = '30px'
                name.appendChild(date)
            }
            message.style.padding = '8px'
            message.style.backgroundColor = item.type === 'agent'
            ? 'rgba(149, 158, 167, 0.5)'
            : 'rgba(20, 155, 223, 0.3)'
            message.style.borderRadius = '4px'
            message.style.marginTop = '4px'
            message.style.marginBottom = '4px'
            message.style.display = 'inline-block'
            message.style.marginRight = '30px'
            message.style.marginLeft = item.type === 'agent'
            ? '30px' : '60px'
            el.appendChild(name)
            el.appendChild(message)
            container.appendChild(el)
        })
    }
    return container
}

export const stripHTML = (html) => {
    var tmp = document.createElement('div')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
}

export const isHTML = (str) => {
    var a = document.createElement('div')
    a.innerHTML = str;
  
    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true; 
    }
  
    return false;
  }

export const strongPasswordValidation = (password) => {
    function sequentialTest(s) {
        // Check for sequential numerical characters
        for(var i in s) 
            if (+s[+i+1] == +s[i]+1 && 
                +s[+i+2] == +s[i]+2) return false;
        // Check for sequential alphabetical characters
        for(var i in s) 
            if (String.fromCharCode(s.charCodeAt(i)+1) == s[+i+1] && 
                String.fromCharCode(s.charCodeAt(i)+2) == s[+i+2]) return false;
        return true;
    }
    function repeatingTest(s) {
        // Check for repeating symbols
        return !s.match(/(.)\1{2,}/)
    }
    let strongCounter = 0
    let validated = {
        enough: false,
        digit: false,
        lowerCase: false,
        upperCase: false,
        specialChar: false,
        threeGroups: false,
        notRepeated: false,
        notSequential: false,
        alreadyInUse: false
    }
    password.length >= 8 && (validated.enough = true); 
    (/\d/.test(password)) && (validated.digit = true) && strongCounter++
    (/[a-z]/.test(password)) && (validated.lowerCase = true) && strongCounter++
    (/[A-Z]/.test(password)) && (validated.upperCase = true) && strongCounter++
    (/[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(password)) && (validated.specialChar = true) && strongCounter++
    validated.notRepeated = repeatingTest(password)
    validated.notSequential = sequentialTest(password)
    validated.threeGroups = strongCounter >= 3
    return {
        valid: validated.enough && validated.notRepeated && validated.notSequential && validated.threeGroups,
        fields: validated
    }
}
