'use strict'

import {setTextareaHeight} from '../../utils/globalHelpers'

export default class AutoExpandableTextarea extends React.Component {
    constructor(props) {
        super(props)

        this.callback = props.callback || function() {}
        this.setValue = this.setValue.bind(this)
        this.focusCallback = this.focusCallback.bind(this)
    }

    focusCallback() {
        if (this.props.focusCallback) this.props.focusCallback()
    }

    setValue() {
        this.callback(this.refs.textarea.value)
    }

    render() {
        const {placeholder, defaultValue} = this.props
        return (
            <textarea
                id='myTextField'
                onKeyUp={setTextareaHeight}
                onChange={this.setValue}
                onFocus={this.focusCallback}
                ref='textarea'
                class='auto-expandable-comment-text'
                placeholder={placeholder}
                defaultValue={defaultValue} />
        )
    }
}
