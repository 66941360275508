'use strict'

import moment from 'moment'
import * as R from 'ramda'

import { getEmptyFilters, getDefaultDateSettings } from '../utils/filters'

const initialState = {
    dateSettings        : getDefaultDateSettings(),
    setFilters          : getEmptyFilters(),
    newFilters          : getEmptyFilters(),
    filtersLoading      : true,
    filtersError        : false,
    availableFilters    : null,
    filtersFormVisible  : false,
    dayCalls            : [],
    dayCallsLoading     : false,
    dayCallsLoadingError: false,
    answers             : [],
    comments            : [],
    compositeAnswers    : [],
    compositeComments   : [],
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "SET_FILTERS": {
            return {
                ...state,
                newFilters: R.clone(action.payload)
            }
        }
        case "SET_DATE_SETTINGS": {
            return {
                ...state,
                dateSettings: action.payload
            }
        }
        case "SET_INITIAL_FILTERS": {
            return {
                ...state,
                newFilters: R.clone(action.payload),
                setFilters: R.clone(action.payload)
            }
        }
        case "AVAILABLE_FILTERS_LOADED": {
            return {
                ...state,
                availableFilters: action.payload,
                filtersLoading: false,
                filtersError: false
            }
        }
        case "AVAILABLE_FILTERS_LOADING": {
            return {
                ...state,
                filtersLoading: action.payload
            }
        }
        case "AVAILABLE_FILTERS_LOADING_ERROR": {
            return {
                ...state,
                filtersLoading: false,
                filtersError: true
            }
        }
        case "SHOW_HIDE_FILTERS_FORM": {
            return {
                ...state,
                filtersFormVisible: action.payload
            }
        }
        case "CALENDAR_COUNTS_LOADED": {
            return {
                ...state,
                dayCalls: action.payload,
                dayCallsLoadingError: false
            }
        }
        case "CALENDAR_COUNTS_LOADING": {
            return {
                ...state,
                dayCallsLoading: action.payload,
                dayCallsLoadingError: false
            }
        }
        case "CALENDAR_COUNTS_ERROR": {
            return {
                ...state,
                dayCallsLoading: false,
                dayCallsLoadingError: action.payload
            }
        }
        case "SET_ANSWERS_TO_FILTERS": {
            return {
                ...state,
                answers: action.payload,
            }
        }
        case "SET_ANSWER_COMMENTS_TO_FILTERS": {
            return {
                ...state,
                comments: action.payload,
            }
        }
        case "SET_COMPOSITE_ANSWERS_TO_FILTERS": {
            return {
                ...state,
                compositeAnswers: action.payload,
            }
        }
        case "SET_COMPOSITE_COMMENTS_TO_FILTERS": {
            return {
                ...state,
                compositeComments: action.payload,
            }
        }
        case "RESET_ANSWERS_AND_COMMENTS_IN_FILTERS": {
            return {
                ...state,
                answers          : [],
                comments         : [],
                compositeAnswers : [],
                compositeComments: [],
            }
        }
    }
    return state
}
