'use strict'

import { connect } from 'react-redux'

import Modal from 'react-modal'

const customStyles = {
    content : {
        top                   : 'calc(100% - 50px)',
        left                  : '35%',
        right                 : 'auto',
        bottom                : 'auto',
        display               : 'flex',
        justifyContent        : 'center',
        flexDirection         : 'column',
        textAlign             : 'center',
        width                 : '600px',
        height                : '48px',
        backgroundColor       : '#5fb402',
        boxSizing             : 'border-box',
        borderRadius          : '2px',
        padding               : '0px 16px',
        color                 : 'white',
        opacity               : 0.94,
    },
    overlay: {
        zIndex                : 2,
        backgroundColor       : 'transparent',
        position              : 'inherit'
    }
}

@connect((store) => {
    return {
        infoModalSuccess: store.modalReducer.infoModalSuccess
    }
})

export default class InfoModalSuccess extends React.Component {
    constructor(props) {
        super(props)

        this.close = this.close.bind(this)
    }

    close() {
        this.props.dispatch({
            type: 'INFO_MODAL_SUCCESS',
            payload: false
        })
    }

    render() {
        const {infoModalSuccess} = this.props
        return (
            <div>
                <Modal
                    isOpen={!!infoModalSuccess}
                    closeTimeoutMS={setTimeout(this.close, 10000)}
                    onRequestClose={this.close}
                    contentLabel='Info modal success'
                    shouldCloseOnOverlayClick={true}
                    style={customStyles}>
                    <div class='info-modal-success-wrapper'>
                        <div class='icon'>
                            <div class='icon-ic-check' />
                        </div>
                        <div class='info-modal-content'>
                        {infoModalSuccess}
                        </div>
                        <div class='info-modal-btn icon' onClick={this.close}>
                            <div class='icon-ic-close' />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
