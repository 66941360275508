'use strict'

import * as R from 'ramda'
import moment from 'moment'
import classNames from 'classnames'
import { connect } from 'react-redux'
import {SimpleSelect} from "react-selectize"

import Comments from './comments/Comments'
import AutoExpandableTextarea from '../../ui/AutoExpandableTextarea'
import NotificationIcons from '../../ui/callTableCells/NotificationIcons'

import actions from '../../../actions/dashboardActions'

@connect((store) => {
    return {
        activeTread  : store.dashboardReducer.activeTread,
        openNewTread : store.dashboardReducer.openNewTread,
    }
})

export default class NotificationComments extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            textMessage: '',
            action: 'comment',
            textError: false
        }
    }

    setValue = (value) => {
        this.setState({
            textMessage: value
        })
    }

    setAction = ({label, value}) => {
        this.setState({
            action: label
        })
    }

    showError() {
        this.setState({
            textError: true
        })
    }

    hideError = () => {
        this.setState({
            textError: false
        })
    }

    sendData = () => {
        const {
            assignedTo,
            threadId,
            reassignOptions,
        } = this.props.data
        const {threads, activeTread, openNewTread, userData} = this.props
        const {UserRole} = userData
        var opened 
        activeTread && activeTread.notificationComments && activeTread.notificationComments.map((comment, index) => {
            if (!comment.closedDate) {
                opened = comment
            }
        })
        const action = this.state.action
        const text = this.state.textMessage
        const comment = action === 'Notes Only'
        const close = action === 'Close It'
        if (!text) {
            return this.showError()
        }
        const name = UserRole.charAt(0).toUpperCase() + UserRole.slice(1).toLowerCase()
        const role = comment || close ? opened && opened.notificationRole : reassignOptions.find(i => i.userRoleName === action)
        if (close) {
            const data = {
                callId: this.props.callId,
                threadId: opened && opened.threadId,
                text: text,
                 assignToRole: {
                    userRoleName: '',
                    userRoleId: ''
                },
                action: 'close' 
            }
            this.props.sendNotification(data)
        } else if (!openNewTread) {
            const data = {
                callId: this.props.callId,
                threadId: opened && opened.threadId,
                text: text,
                assignToRole: role,
                action: comment ? 'comment' : close ? 'close' : 'assign'
            }
            this.props.sendNotification(data)
        } else {
            const tread = {
                callId: this.props.callId,
                text: text,
                threadId: 0,
                assignToRole: {
                    userRoleName: role,
                    userRoleId: role
                },
                action: 'assign',
                startReason: `${name} Created`
            }
            this.props.dispatch(actions.createThread(tread))
        }
        
    }

    isClientSide(role) {
        return R.contains(
            role.toUpperCase(),
            ["Client", "Manager", "Supervisor"].map(R.invoker(0, "toUpperCase"))
        )
    }

    renderSelectMenu() {
        const {userData, data, openNewTread, activeTread} = this.props
        const {UserRole} = userData
        const {
            assignedTo,
            reassignOptions,
            notificationStatus,
            notificationComments,
            canClose
        } = data
        const assignText = !assignedTo ?
        'Assign to ' : 'Reassign to '
        var options = []
        var options2 = []
       
        const isClientSideUser = this.isClientSide(UserRole)

        const openedNoti = activeTread && activeTread.threadInfo.currentlyOpen
        const internalOnly = activeTread && activeTread.threadInfo.hasBeenInternal
        if (internalOnly) {
            if (openedNoti) {
                reassignOptions.forEach(item => {
                    if (
                        assignedTo &&
                        assignedTo.userRoleName &&
                        item.userRoleName === assignedTo.userRoleName &&
                        item.userRoleName !== 'Client' &&
                        item.userRoleName !== 'Supervisor' &&
                        item.userRoleName !== 'Manager' &&
                        item.userRoleName !== 'Agent' ) {
                        options.push({
                            label: item.userRoleName,
                            value: item.userRoleId
                        })
                    }
                })
            } else if (!openedNoti) {
                reassignOptions.forEach(item => {
                    if (
                        item.userRoleName !== 'Close It' &&
                        item.userRoleName !== 'Client' &&
                        item.userRoleName !== 'Supervisor' &&
                        item.userRoleName !== 'Manager' &&
                        item.userRoleName !== 'Agent' ) {
                        options.push({
                            label: item.userRoleName,
                            value: item.userRoleId
                        })
                    }
                })
            }
        } 
        if (!openNewTread && openedNoti) {
            reassignOptions.forEach(item => {
                if (assignedTo &&
                    assignedTo.userRoleName &&
                    item.userRoleName === assignedTo.userRoleName) return
                options.push({
                    label: item.userRoleName,
                    value: item.userRoleId
                })
            })
        } else if (!openNewTread && !openedNoti && !internalOnly) {
            reassignOptions.forEach(item => {
                if (
                    item.userRoleName !== 'Close It') {
                    options.push({
                        label: item.userRoleName,
                        value: item.userRoleId
                    })
                }
            })
        } else {
            reassignOptions.forEach(item => {
                if (
                    item.userRoleName !== 'Close It' && item.userRoleName !== 'Notes Only') {
                    options2.push({
                        label: item.userRoleName,
                        value: item.userRoleId
                    })
                }
            })
        }
        const renderOption = ({label, value}) => {
            if (value === 'Notes Only') {
                return <div class='action-option'>
                    <div class='icon-ic-comment' />
                    <div>{label}</div>
                </div>
            } else if (value === 'Close It') {
                return <div class='action-option'>
                    <div class='icon-ic-close'/>
                    <div>{label}</div>
                </div>
            } else {
                const roleText = label.toUpperCase() === UserRole.toUpperCase() ?
                label + ' (my role)' : label
                const qa = label.toUpperCase() === 'QA' ? ' qa' : ''
                const isClientSideRole = this.isClientSide(label)
                const typeOfNotification = classNames({
                    'notification': isClientSideRole === isClientSideUser,
                    'dispute': qa,
                    'pending-dispute': isClientSideRole !== isClientSideUser && !qa,
                    'notification-opacity': label.toUpperCase() !== UserRole.toUpperCase()
                })
                const iconClass = classNames({
                    'icon-ic-1-dispute': isClientSideRole === isClientSideUser,
                    'icon-ic-2-dispute': isClientSideRole !== isClientSideUser && !qa,
                    'icon-ic-3-dispute': qa
                })
                return <div title={assignText + label} class='action-option notification-icons'>
                    <div class={typeOfNotification}><div class={iconClass} /></div>
                    <div class='assign-text'>{assignText + roleText}</div>
                </div>
            }
        }
        const renderValue = ({label, value}) => {
            return <div class='action-option'>
                <div>{label}</div>
            </div>
        }
        const value = options.find(i => i.label === this.state.action)
        const value2 = options2.find(i => i.label === this.state.action)
        return <SimpleSelect
            onValueChange={this.setAction}
            onFocus={this.hideError}
            value={openNewTread ? value2 : value}
            renderValue={renderValue}
            options={openNewTread ? options2 : options}
            renderOption={renderOption}
            placeholder='Select action' />
    }

    getButton() {
        const {
            assignedTo,
            notificationStatus,
            notificationComments,
            reassignOptions,
            systemComments,
            canClose
        } = this.props.data
        const {openNewTread} = this.props
        const comment = this.state.action === 'comment'
        const close = this.state.action === 'close'
        const action = this.state.action
        const text = this.state.textMessage
        const started = !!assignedTo
        const typeAssignedBtn = classNames({
            'post-comment-btn'         : comment,
            'start-notification-btn'   : !(comment || close) && !started || openNewTread,
            'update-notification-btn'  : !(comment || close) && started && !openNewTread,
            'close-btn'                : close,
            'no-send'                  : text === '' && action === 'comment' || text !== '' && action === 'comment',
        })
        const btnText = openNewTread ? 'Create Notification' : close ? 'Close notification' : comment ? 'Post Comment' :
        started ? 'Reassign Notification' : 'Start Notification'

        return (
            <button
                class={'notification-btn ' + typeAssignedBtn}
                onClick={text !== '' && action !== 'comment' ? this.sendData : null}>
                    {btnText}
            </button>
        )
    }

    render() {
        const {UserRole} = this.props.userData
        const {
            assignedTo,
            notificationStatus,
            notificationComments,
            reassignOptions,
            systemComments,
            canClose, 
        } = this.props.data
        const {threads, activeTread} = this.props
        var opened 
        activeTread && activeTread.notificationComments && activeTread.notificationComments.map((comment, index) => {
            if (!comment.closedDate) {
                opened = comment
            }
        })
        const isNotificationOwner = opened
            && opened.notificationRole.userRoleId
            && opened.notificationRole.userRoleId.toUpperCase() === UserRole.toUpperCase()
            ? true
            : false
        const typeOfNotification = classNames({
            'no-notification': notificationStatus === null || notificationStatus === 'none' || !notificationStatus,
            'notification': notificationStatus === 'notification',
            'dispute': notificationStatus === 'dispute',
            'pending-dispute': notificationStatus === 'pending dispute',
            'closed': notificationStatus === 'notification closed',
            'notification-opacity': !isNotificationOwner,
        })
        const notificationIcon = classNames({
            'notification-icon': true,
            'icon-ic-1-dispute': notificationStatus === 'notification' || notificationStatus === 'notification closed',
            'icon-ic-3-dispute': notificationStatus === 'dispute',
            'icon-ic-2-dispute': notificationStatus === 'pending dispute',
        })
        const textError = this.state.textError
        return (
            <div class='notification-comments'>
                <div class='notification-comment-header'>
                    {
                        opened && opened.notificationRole.userRoleId?
                        <div class='assigned-to-message'>
                            <div class='title'>Assigned to:</div>
                            <div class='assigned-name'>
                                {
                                    isNotificationOwner ?
                                    'me' : opened.notificationRole.userRoleId
                                }
                            </div>
                            <div class='notification-icons'>
                                <div class={typeOfNotification}>
                                    <div class={notificationIcon}/>
                                </div>
                            </div>
                        </div> : null
                    }
                    <div class='assigned'>
                        <div class={'assigned-to ' + (textError ? 'text-error' : '')}>
                            <AutoExpandableTextarea placeholder={'Type a comment...'} focusCallback={this.hideError} callback={this.setValue} />
                            {textError ? <div class='error-message'>Please, enter your comment</div> : null}
                        </div>
                        <div class='assigned-buttons'>
                            {this.renderSelectMenu()}
                            <div class='assigned-btn'>
                                {this.getButton()}
                            </div>
                        </div>
                    </div>
                </div>
                <div class='notification-comments-list'>
                    <Comments
                        systemComments={systemComments}
                        threads={threads} />
                </div>
            </div>
        )
    }
}
