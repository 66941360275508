'use strict'

import CommentsHeader from './CommentsHeader'
import CommentsList from './CommentsList'
import SistemCommentsList from './SistemCommentsList'
import NotificationsHeader from './NotificationsHeader'

export default class Comments extends React.Component {

    render() {
        const {systemComments, threads} = this.props
        return (
            <div id='review-comments-block' class='comments-block'>
                <CommentsHeader />
                <SistemCommentsList  systemComments={systemComments}/>
                <NotificationsHeader/>
                <CommentsList
                    threads={threads} />
            </div>
        )
    }
}
