'use strict';

const initialState = {
    headerData: {},
    version: null,
    stopWorking: false,
    fetching: false,
    fetched: false,
    error: null,
    uploadProgress: {
        error: false,
        uploading: false,
        message: '',
        percent: 0
    },
    searching: false,
    searchResults: null,
    searchText: null,
    searchingError: false,
    queuedForReviewCalls: [],
    changeUserSearchText: '',
    userSearchListLoading: false,
    userSearchListError: false,
    userSearchList: [],
    leftPanelExpanded: window.localStorage.leftPanelCollapsed ? !JSON.parse(window.localStorage.leftPanelCollapsed) : true
}

export default function reducer(state=initialState, action) {

    switch (action.type) {
      case "GET_HEADER_DATA": {
        return {...state, fetching: true}
      }
      case "GET_HEADER_DATA_REJECTED": {
        return {...state, fetching: false, error: action.payload}
      }
      case "GET_HEADER_DATA_BUNDLE_VERSION": {
        return {
          ...state,
          version: action.payload,
        }
      }
      case "GET_HEADER_DATA_STOP_WORKING": {
        return {
          ...state,
          stopWorking: action.payload,
        }
      }
      case "GET_HEADER_DATA_FULFILLED": {
        return {
          ...state,
          headerData: action.payload,
        }
      }
      case "GET_SEARCH_RESULTS_FULFILLED": {
          return {
              ...state,
              searchResults: action.payload
          }
      }
      case 'SEARCHING': {
          return {
              ...state,
              searching: action.payload
          }
      }
      case 'SEARCHING_ERROR': {
          return {
              ...state,
              searchingError: action.payload
          }
      }
      case 'UPLOAD_PROGRESS': {
          return {
              ...state,
              uploadProgress: action.payload
          }
      }
      case 'SET_SEARCH_TEXT': {
          return {
              ...state,
              searchText: action.payload
          }
      }
      case 'EXPAND_LEFT_PANEL': {
          return {
              ...state,
              leftPanelExpanded: action.payload
          }
      }
      case 'SET_QUEUED_CALLS_FOR_REVIEW': {
          return {
              ...state,
              queuedForReviewCalls: action.payload
          }
      }
      case 'SET_CHANGE_USER_SEARCH_TEXT': {
          return {
              ...state,
              changeUserSearchText: action.payload
          }
      }
      case 'USER_SEARCH_LIST_LOADING': {
          return {
              ...state,
              userSearchListLoading: action.payload,
              userSearchListError: false
          }
      }
      case 'USER_SEARCH_LIST_ERROR': {
          return {
              ...state,
              userSearchListLoading: false,
              userSearchListError: action.payload
          }
      }
      case 'USER_SEARCH_LIST_FULFILLED': {
          return {
              ...state,
              userSearchListLoading: false,
              userSearchListError: false,
              userSearchList: action.payload,
          }
      }
    }

    return state
}
