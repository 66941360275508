'use strict'

import { connect } from 'react-redux'

import appConfig from '../../../appConfig'
import Player from './Player'
import ShowHide from './ShowHide'

@connect((store) => {
    return {
        userData: store.headerReducer.headerData.userData
    }
})

export default class PlayerLayout extends React.Component {
    constructor(props) {
        super(props)
        const {activeAudioLink} = props
        if (appConfig.noPlayerRegexp.test(activeAudioLink) || !activeAudioLink) {
            this.noPlayer = true
        }
        this.state = {
            hide: false
        }
    }
    hide = () => {
        this.setState({
            hidden: !this.state.hidden
        })
    }
    getShowHide() {
        return this.state.noPlayer ? false : <ShowHide hide={this.hide} hidden={this.state.hidden} />
    }
    render() {
        const {
            userData, 
            activeAudioLink, 
            noPlayAfterComment,
            allowGapSkip,
            minGapLength,
            setAudioGapsInfo
        } = this.props
        if (!activeAudioLink) return null
        const showHide = this.getShowHide()
        return (
            <div class={'new-player-container' + (this.state.hidden ? ' player-hidden' : '')} >
                <div class='new-player-inner'>
                    {showHide}
                    {!this.noPlayer && <Player
                        settings={{
                            speedInc: parseInt(userData.SpeedInc, 10) || 5,
                            play: true,
                            volume: parseInt(window.localStorage.playerVolume, 10)/100 || 0.5,
                            speed: parseInt(window.localStorage.playerSpeed, 10)/100 || 1,
                            mute: (window.localStorage.mute === 'true') ? true : false,
                            currentTime: 0
                        }}
                        audioStarted={this.props.audioStarted || (() => {})}
                        src={activeAudioLink}
                        noPlayAfterComment={noPlayAfterComment}
                        allowGapSkip={allowGapSkip}
                        minGapLength={minGapLength}
                        setAudioGapsInfo={setAudioGapsInfo}
                    />}
                </div>
            </div>
        )
    }
}
