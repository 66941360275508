import * as R from 'ramda'

import { getEmptyFilters } from './filters/filtersUtils'
import { getDefaultColumns } from './utils'

const initialState = {
    modalOptions               : null,
    filters                    : getEmptyFilters(),
    emptyFilters               : getEmptyFilters(),
    filtersLoading             : false,
    filtersError               : false,
    range: {
        end: '',
        start: ''
    },
    availableFilters           : null,
    availableFiltersLoading    : false,
    availableFiltersError      : false,
    statsData                  : null,
    statsDataLoading           : false,
    statsDataError             : false,
    statsDataTimeoutError      : false,
    otherNonQualError          : false,
    otherNonQualsLoading       : false,
    otherNonQual               : null,
    chartPagination            : {
        pagerows: 5,
        pagenum : 1,
    },
    chartTypeSorting           : {
        sortBy   : 'id',
        sortOrder: 'asc',
    },
    chartType                  : 'agent', // 'agent', 'agent_group', 'campaign', abandoned
    chartData                  : null,
    chartDataLoading           : false,
    chartDataError             : false,
    chartDataTimeoutError      : false,
    exportingChartType         : false,
    sorting                    : {
        sortBy:    'callDate',
        sortOrder: 'desc',
    },
    pagination                 : {
        pagenum: 1,
        pagerows: 20
    },
    callDetails                : [],
    callDetailsLoading         : true,
    callDetailsInfiniteLoading : false,
    callDetailsLoadingError    : false,
    callDetailsTimeoutError    : false,
    tableColumns               : getDefaultColumns(),
    callDetailsSearchText      : '',
    searching                  : false,
    searchingError             : false,
    exportedCallDetails        : null,
    exportedCallDetailesLoading: false,
    exportedCallDetailesError  : false,
    exportingCallDetails       : false,
    chartTypeColumnFilter      : '',
    otherNonQualFilter         : '',
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case 'SET_CALL_TYPE_PAGE_MODAL_OPTIONS': {
            return {
                ...state,
                modalOptions: action.payload,
            }
        }
        case 'BREAKDOWN_REPORT_FILTERS_LOADING': {
            return {
                ...state,
                filtersLoading: action.payload,
                filtersError  : false
            }
        }
        case 'BREAKDOWN_REPORT_FILTERS_LOADED': {
            return {
                ...state,
                filtersLoading: false,
                filtersError  : false,
                filters       : action.payload
            }
        }
        case 'BREAKDOWN_REPORT_FILTERS_ERROR': {
            return {
                ...state,
                filtersLoading: false,
                filtersError  : action.payload
            }
        }
        case 'BREAKDOWN_REPORT_AVAIBLE_FILTERS_LOADING': {
            return {
                ...state,
                availableFiltersLoading: action.payload,
                availableFiltersError  : false
            }
        }
        case 'BREAKDOWN_REPORT_AVAIBLE_FILTERS_LOADED': {
            return {
                ...state,
                availableFiltersError         : false,
                availableFiltersLoading  : false,
                availableFilters    : action.payload
            }
        }
        case 'BREAKDOWN_REPORT_AVAIBLE_FILTERS_ERROR': {
            return {
                ...state,
                availableFiltersLoading: false,
                availableFiltersError  : action.payload
            }
        }
        case 'BREAKDOWN_REPORT_STATS_FULFILLED': {
            return {
                ...state,
                statsData            : action.payload,
                statsDataLoading     : false,
                statsDataError       : false,
                statsDataTimeoutError: false,
            }
        }
        case 'BREAKDOWN_REPORT_STATS_LOADING': {
            return {
                ...state,
                statsDataLoading     : true,
                statsDataError       : false,
                statsDataTimeoutError: false,
            }
        }
        case 'BREAKDOWN_REPORT_STATS_ERROR': {
            return {
                ...state,
                statsDataError  : true,
                statsDataLoading: false,
            }
        }
        case 'BREAKDOWN_REPORT_STATS_TIMEOUT_ERROR': {
            return {
                ...state,
                statsDataTimeoutError: true,
            }
        }
        case 'BREAKDOWN_REPORT_OTER_NONQUAL_LOADING': {
            return {
                ...state,
                otherNonQualLoading: action.payload,
                otherNonQualError  : false
            }
        }
        case 'BREAKDOWN_REPORT_OTER_NONQUAL_LOADED': {
            return {
                ...state,
                otherNonQualError     : false,
                otherNonQualsLoading  : false,
                otherNonQual          : action.payload
            }
        }
        case 'BREAKDOWN_REPORT_OTER_NONQUAL_ERROR': {
            return {
                ...state,
                otherNonQualLoading: false,
                otherNonQualError  : action.payload
            }
        }
        case 'BREAKDOWN_REPORT_CHART_DATA_FULFILLED': {
            return {
                ...state,
                chartData            : action.payload,
                chartDataLoading     : false,
                chartDataError       : false,
                chartDataTimeoutError: false,
            }
        }
        case 'BREAKDOWN_REPORT_CHART_ITEMS_TOTAL': {
            return {
                ...state,
                chartDataItemsTotal: action.payload,
            }
        }
        case 'BREAKDOWN_REPORT_CHART_DATA_LOADING': {
            return {
                ...state,
                chartDataLoading     : true,
                chartDataError       : false,
                chartDataTimeoutError: false,
            }
        }
        case 'BREAKDOWN_REPORT_CHART_DATA_ERROR': {
            return {
                ...state,
                chartDataError  : true,
                chartDataLoading: false,
            }
        }
        case 'BREAKDOWN_REPORT_CHART_DATA_TIMEOUT_ERROR': {
            return {
                ...state,
                chartDataTimeoutError: true,
            }
        }
        case 'BREAKDOWN_REPORT_CHART_TYPE': {
            return {
                ...state,
                chartType: action.payload,
            }
        }
        case 'BREAKDOWN_REPORT_CHART_PAGINATION': {
            return {
                ...state,
                chartPagination: action.payload,
            }
        }
        case 'BREAKDOWN_REPORT_CHART_TYPE_SORTING': {
            return {
                ...state,
                chartTypeSorting: action.payload,
            }
        }
        case "BREAKDOWN_REPORT_EXPORT_CHART_TYPE": {
            return {
                ...state,
                exportingChartType: action.payload
            }
        }
        case "BREAKDOWN_REPORT_GET_CALL_DETAILS_FULFILLED" : {
            return {
                ...state,
                callDetails:  action.payload,
                callDetailsLoading: false,
                callDetailsLoadingError: false,
                callDetailsTimeoutError: false,
                callDetailsInfiniteLoading: false,
            }
        }

        case "BREAKDOWN_REPORT_CALL_DETAILS_LOADING": {
            return {
                ...state,
                callDetailsLoadingError: false,
                callDetailsLoading: action.payload,
                callDetailsTimeoutError: false,
                callDetailsInfiniteLoading: false,
            }
        }

        case "BREAKDOWN_REPORT_CALL_DETAILS_LOADING_ERROR": {
            return {
                ...state,
                callDetailsLoading: false,
                callDetailsLoadingError: action.payload,
                callDetailsTimeoutError: false,
                callDetailsInfiniteLoading: false,
            }
        }
        case "PAGE_CHANGED": {
            return {
                ...state,
                pagination: action.payload
            }
        }
        case "BREAKDOWN_REPORT_CHART_TYPE_COLUMN_FILTER": {
            return {
                ...state,
                chartTypeColumnFilter: action.payload
            }
        }
        case "BREAKDOWN_REPORT_OTER_NON_QUAL_FILTER": {
            return {
                ...state,
                otherNonQualFilter: action.payload
            }
        }
        case "BREAKDOWN_REPORT_TABLE_COLUMNS_CHANGED": {
            return {
                ...state,
                tableColumns: action.payload
            }
        }
        case "BREAKDOWN_REPORT_SET_CALL_DETAILS_SEARCH_TEXT": {
            return {
                ...state,
                callDetailsSearchText: action.payload
            }
        }
        case "BREAKDOWN_REPORT_CALL_DETAILS_SEARCHING": {
            return {
                ...state,
                searching: action.payload
            }
        }
        case "BREAKDOWN_REPORT_CALL_DETAILS_SEARCHING_ERROR": {
            return {
                ...state,
                searchingError: action.payload
            }
        }
        case 'BREAKDOWN_REPORT_CALL_DETAILS_TIMEOUT_ERR': {
            return {
                ...state,
                callDetailsTimeoutError: true,
            }
        }
        case "BREAKDOWN_REPORT_CALL_DETAILS_INFINITE_LOADING": {
            return {
                ...state,
                callDetailsLoadingError: false,
                callDetailsInfiniteLoading: action.payload
            }
        }
        case "BREAKDOWN_REPORT_CALL_DETAILS_FULFILLED_INFINITE" : {
            return {
                ...state,
                callDetails: [...state.callDetails, ...action.payload],
                callDetailsLoading: false,
                callDetailsInfiniteLoading: false,
                callDetailsLoadingError: false
            }
        }
        case "BREAKDOWN_REPORT_EXPORT_CALL_DETAILS": {
            return {
                ...state,
                exportingCallDetails: action.payload
            }
        }
    }
    return state
}
