'use strict'

import classNames from 'classnames'

export default class Answer extends React.Component {
    constructor(props) {
        super(props)

        this.addToFilters = this.addToFilters.bind(this)
        this.iconHovered = this.iconHovered.bind(this)
        this.iconUnhovered = this.iconUnhovered.bind(this)

        this.state = {
            hovered: false,
        }
    }

    addToFilters() {
        const {addToFilters, id, isRight, inFilters, total} = this.props
        addToFilters(id, inFilters, isRight, total)
    }

    iconHovered() {
        this.setState({
            hovered: true,
        })
    }

    iconUnhovered() {
        this.setState({
            hovered: false,
        })
    }

    render() {
        const {answerClass, inFilters, answerText} = this.props
        const {hovered} = this.state
        const filterClass = classNames({
            'icon-ic-filter': !hovered || !inFilters,
            'icon-ic-close' : hovered && inFilters,
            'filter-icon'   : true,
            'added'         : inFilters,
        })
        return <div class={answerClass}>
            {answerText}
            <div
                onMouseOver={this.iconHovered}
                onMouseOut={this.iconUnhovered}
                onClick={this.addToFilters}
                class={filterClass} />
        </div>
    }
}
