'use strict'

export default class DataError extends React.Component {
    render() {
        const {timeout, text} = this.props
        return (
            <div class='data-loading'>
            	<div title={timeout ? 'Timeout Error' : ''} class="icon-error">
            		<i class="icon-ic-error"/>
                </div>
                {
                    text
                    ? <div class='massage-search'>{text}</div>
                    : <div class="massage-search"><div>Something happened.</div><div>Try again later.</div></div>
                }
            </div>
        )
    }
}
