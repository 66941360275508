'use strict';

import { applyMiddleware, createStore } from "redux"

import { createLogger } from "redux-logger"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"

import reducer from "../reducers"

const middleware = applyMiddleware(promise(), thunk, createLogger())

// if (module.hot) {
//     console.log(module)
//     module.hot.accept(reducer, () =>{
//             console.log("==========>")
//         }
//     )
// }

export default createStore(reducer, middleware)
