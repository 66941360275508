'use strict'

import * as R from 'ramda'
import { connect } from 'react-redux'

import {fromSecsToMinsAndSecs} from '../../../utils/globalHelpers'
import ReactSlider from 'react-slider'
import PlayerMarker from './PlayerMarker'

const idealDistance = 100 // px
const possibleBreaks = [1, 2, 5, 10, 15, 30, 60, 120, 60*5, 60*10] // seconds

@connect((store) => {
    return {
        leftPanelExpanded: store.headerReducer.leftPanelExpanded,
    }
})

export default class PlayerTimeline extends React.Component {
    constructor(props) {
        super(props)

        this.changePosition1 = this.changePosition1.bind(this)
        this.changePosition2 = this.changePosition2.bind(this)
        this.setSliderWidth = this.setSliderWidth.bind(this)

        this.state = {
            position: props.position || 0,
            sliderWidth: 500,
            audioLength: 300,
            text: ''
        }
    }

    componentDidMount() {
        this.setSliderWidth()
        window.addEventListener('resize', this.setSliderWidth, false)
        setTimeout(() => {
            this.imitateResize()
        }, 500)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setSliderWidth, false)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.leftPanelExpanded !== this.props.leftPanelExpanded) {
            setTimeout(() => {
                this.imitateResize()
            }, 300)
        }
        if (nextProps.error) return
        this.refs.timeline.setState({
            value: [nextProps.position || 0]
        })
        this.refs.timeline2.setState({
            value: [nextProps.position || 0]
        })
        this.setSliderWidth()
    }

    imitateResize() {
        let event = new Event('resize')
        window.dispatchEvent(event)
    }

    setSliderWidth() {
        let slider = document.getElementsByClassName('range-slider')[0]
        if (!slider || !slider.clientWidth) return
        this.setState({
            sliderWidth: slider.clientWidth || ReactDOM.findDOMNode(this.refs.timeline).clientWidth,
            audioLength: this.props.duration
        })
    }

    getTicksEls() {
        const ticks = this.getTicks(this.state.sliderWidth, this.props.duration || this.state.audioLength)
        if (!ticks) return false
        return ticks.map((tick, index) => {
            const time = fromSecsToMinsAndSecs(parseInt(tick.seconds, 10))
            return <div
                        key={index}
                        class='time-tick'
                        style={{left: tick.pixels}}>
                        <div class={'tick-marker'}></div>
                        <span>{time}</span>
                    </div>
        })
    }

    getTicks(sliderWidth, audioLength) {
        if (!sliderWidth || !audioLength) return
    	const diffs = R.call(R.pipe(
        	R.map(function (b) {
                return sliderWidth/(audioLength/b)
            }, R.__),
            R.map(R.subtract(R.__, idealDistance), R.__),
            R.map(Math.abs, R.__)
        ), possibleBreaks);
        const pairs = R.zip(diffs, possibleBreaks);
        const period = R.reduce(function (p1, p2) {
        	if (p1[0] < p2[0]) {
                return p1
            } else {
                return p2
            }
        }, pairs[0], pairs)[1];
    	const ticks = R.map(
        	function (i) {
                return {
                    seconds: period*i,
                    pixels: sliderWidth*period*i/audioLength
                }
            },
        	R.range(1, audioLength/period));
        if (ticks.length && (sliderWidth - ticks[ticks.length - 1].pixels < 30)) ticks.splice(-1, 1)
        ticks.push(
            {pixels: 0, seconds: 0},
            {pixels: sliderWidth, seconds: audioLength}
        )
    	return ticks
    }

    getMarkers() {
        const sliderWidth = this.state.sliderWidth
        const audioLength = this.state.audioLength
        const markers = this.props.markers
        if (!sliderWidth || !audioLength || !markers.length) return
        return markers.map((marker, index) => {
            return <PlayerMarker
                marker={marker}
                showHideText={this.showHideText}
                key={index}
                sliderWidth={sliderWidth}
                audioLength={audioLength} />
        })
    }

    showHideText = (text) => {
        this.setState({
            text: text
        })
    }

    changePosition1() {
        const position = this.refs.timeline.getValue()
        this.props.positionChanged(position)
    }

    changePosition2() {
        const position = this.refs.timeline2.getValue()
        this.props.positionChanged(position)
    }

    getPosition() {
        return fromSecsToMinsAndSecs(this.props.position || 0)
    }

    formatDuration() {
        return fromSecsToMinsAndSecs(parseInt(this.props.duration || 0, 10))
    }

    render() {
        const {position, duration, error, analyzing} = this.props
        const pos = this.getPosition()
        const durationFormated = this.formatDuration()
        const ticks = this.getTicksEls()
        const markers = this.getMarkers()
        const withTicks = duration < 60*60

        return(
            error ? 
            <div class='player-error'>
                <div title='Audio loading error' class='icon-ic-error'/>
                <div class='err-massage'>
                    Couldn’t load the audio. Try again later.
                </div>
            </div>
            :
            <div class='timeline-section'>
                {analyzing && analyzing !== 'error' &&
                    <div class='player-error analyzing'>
                        <div class='analyzing-progress'>
                            <div class='dashboard-loader-inner'></div>
                            <div class='dashboard-loader-outer'></div>
                        </div>
                        <div class='err-massage'>
                            Audio is analyzing now...
                        </div>
                    </div>
                }
                <div class='player-position'>{pos}</div>
                <div class='range-options old-slider'>
                    <div class='second-slider'>
                        <ReactSlider
                            ref='timeline2'
                            min={0}
                            max={duration || 100}
                            className='range-slider'
                            onChange={this.changePosition2}
                            defaultValue={position || 0}
                            withBars>
                            <div class='slider-trigger range-triger'>
                            </div>
                        </ReactSlider>
                    </div>
                    {withTicks && ticks}
                    <div class='markers-container timeline-markers'>
                        {markers}
                    </div>
                    <div class='first-slider'>
                        <ReactSlider
                            ref='timeline'
                            min={0}
                            max={duration || 100}
                            className='range-slider'
                            onChange={this.changePosition1}
                            defaultValue={position || 0}
                            withBars>
                            <div class='slider-trigger range-triger'>
                            </div>
                        </ReactSlider>
                    </div>
                    <span class='marker-text'>{this.state.text}</span>
                </div>
                <div class='section-title'>
                    <span class='player-timer'>{durationFormated}</span>
                </div>
            </div>
        )
    }
}
