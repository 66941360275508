import * as R from 'ramda'

const initialState = {
    loginUserLoading           : false,
    loginUserError             : false,
    loginUser                  : null,
    loginForgotPasswordLoading : false,
    loginForgotPasswordError   : false,
    loginForgotPassword        : null,
    emailMessage               : null,
    resetMessage               : null,
    resetMessageLoading        : false,
}

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case "LOGIN_PAGE_LOGIN_USER_LOADING": {
            return {
                ...state,
                loginUserLoading       : action.payload,
                loginUserError         : false
            }
        }
        case "LOGIN_PAGE_LOGIN_USER": {
            return {
                ...state,
                loginUserLoading       : false,
                loginUserError         : false,
                loginUser              : action.payload,
            }
        }
        case "LOGIN_PAGE_LOGIN_USER_ERROR": {
            return {
                ...state,
                loginUserLoading       : false,
                loginUserError         : action.payload,
            }
        }
        case "LOGIN_PAGE_FORGOT_PASSOWRD_LOADING": {
            return {
                ...state,
                loginForgotPasswordLoading       : action.payload,
                loginForgotPasswordError         : false
            }
        }
        case "LOGIN_PAGE_FORGOT_PASSOWRD": {
            return {
                ...state,
                loginForgotPasswordLoading       : false,
                loginForgotPasswordError         : false,
                loginForgotPassword              : action.payload,
            }
        }
        case "LOGIN_PAGE_FORGOT_PASSOWRD_ERROR": {
            return {
                ...state,
                loginForgotPasswordLoading       : false,
                loginForgotPasswordrError        : action.payload,
            }
        }
        case "SET_EMAIL_MESSAGE": {
            return {
                ...state,
                emailMessage                    : action.payload,
            }
        }
        case "LOGIN_PAGE_RESET_MESSAGE": {
            return {
                ...state,
                resetMessage                    : action.payload,
                resetMessageLoading             : false,
            }
        }
        case "LOGIN_PAGE_RESET_MESSAGE_LOADING": {
            return {
                ...state,
                resetMessageLoading             : action.payload,
            }
        }
    }
    return state
}
