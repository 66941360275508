'use strict'

import {connect} from 'react-redux'

import DataLoading from '../ui/componentStates/DataLoading'
import DataError from '../ui/componentStates/DataError'

import actions from './actions'

@connect((store) => {
    return {
    	loginUser    : store.loginPageReducer.loginUser,
    	loading      : store.loginPageReducer.loginUserLoading,
		error        : store.loginPageReducer.loginUserError,
		emailMessage : store.loginPageReducer.emailMessage,
		forgotLoading: store.loginPageReducer.loginForgotPasswordLoading,
		forgotError  : store.loginPageReducer.loginForgotPasswordError,
		loginForgotPassword: store.loginPageReducer.loginForgotPassword,
    }
})

export default class Layout extends React.Component {
	constructor(props) {
        super(props)
        this.state = {
            userName      : '',
            password      : '',
            rememberMe    : false,
            errorMessage  : false,
            forgotPassword: false,
            hostName      : null, 
        }
    }

    componentDidMount() {
        var host = location.hostname.split('.')
        let hostName = host[host.length - 2].toUpperCase()
        this.setState({
            hostName: hostName,
        })
    }

	setUserName = (event) => {
		const value = this.refs.userName.value
		this.setState({
            userName: value,
        })
        this.setState({
            errorMessage: false,
        })
	}

	setPassword = (event) => {
		const value = this.refs.password.value
		this.setState({
            password: value,
        })
	}

	loginUser = () => {
		const {password, userName} = this.state
		const data = {
			userName: userName,
			password: password,
		}
		this.props.dispatch(actions.loginUser(data))
	}

	rememberMe = () => {
		const {password, userName, rememberMe} = this.state
		const remember = !rememberMe
		document.cookie = `userName=${userName}`
		document.cookie = `password=${password}`
		document.cookie = `rememberMe=${remember}`
		this.setState({
            rememberMe: remember,
        })
	}

	getCookie() {
		var cookie = document.cookie
		const cook = cookie.split(';')
		const bbb = cook.map(c => {
			const item = c.split("=")
			return item
		})
		const data = {
			userName: bbb[0],
			password: bbb[1],
			rememberMe: bbb[2],
		}
		return(data)
	}

	forgotPassword = () => {
		const {forgotPassword} = this.state
		this.setState({
            forgotPassword: !forgotPassword,
        })
	}

	sendEmail = () => {
		const {userName, errorMessage} = this.state
		if (userName === '') {
			this.setState({
	            errorMessage: true,
	        })
		} else {
			this.props.dispatch(actions.forgotPassword(userName))
		}
		
	}

	preventEventDefaults(e) {
        e.preventDefault()
        e.stopPropagation()
    }

	onKeyPressed = (e) => {
		if (e.keyCode === 13 || e.charCode === 13) {
            this.loginUser()
            this.preventEventDefaults(e)
        }
	}

	onKeyLoginPressed = (e) => {
		if (e.keyCode === 13 || e.charCode === 13) {
            this.loginUser()
            this.preventEventDefaults(e)
        }
	}

    render() {
    	const {
    		loginUser,
    		loading, 
    		error,
    		loginForgotPassword,
			forgotLoading,
			forgotError,
    	} = this.props
    	const {rememberMe, errorMessage, forgotPassword, hostName} =this.state
    	const userName = this.getCookie()
        return (
            <div class='login-page-layout'>
				<div class='logo-conteiner'>
					<div class='background' id='background'>
						{
							hostName === 'ANOMALYSQUARED' ?
							<img src="http://www.CallCriteria.com/GIF/anomaly.svg"/> :
							hostName === 'EDUMAXIMIZER' ?
							<img src="http://www.CallCriteria.com/GIF/eduMax.png"/> :
        					hostName === 'CALLSOURCE' ?
        					<img src="http://www.CallCriteria.com/GIF/logoSour.svg"/> :
							hostName === 'INQUIRYCOMPLIANCE' ?
							<img src="http://www.CallCriteria.com/GIF/logoedu.svg"/> :
   							hostName === 'SCORECALLERS' ?
   							<img src="http://www.CallCriteria.com/GIF/logoScorecallers.png"/> :
							hostName === 'ENGINE' ?
							<img src="http://www.CallCriteria.com/GIF/logoEngine.png"/> :
							hostName === 'CO' ?
							<img src="http://www.CallCriteria.com/GIF/logoDragoninsurance.svg"/> :
						    <img src="http://www.CallCriteria.com/GIF/CC_background_logo.svg"/>
						}
					</div>
				</div>
				<div class='login-form'>
					{
						!forgotPassword ?
						<div class='form'>
							<div class='form-name'>Sign In</div>
							<div class='input-block'>
								<label for="login">Login</label>
								<input
									onKeyPress={this.onKeyLoginPressed}
									type="text"
									id="login"
									ref='userName'
	                                onChange={this.setUserName}
	                                placeholder="User name..."
	                                required 
	                                autoComplete="on"/>
							</div>
							<div class='input-block'>
								<label for="password">Password</label>
								<input
									onKeyPress={this.onKeyPressed}
									type="password"
									id="password"
									ref='password'
	                                onChange={this.setPassword}
	                                placeholder="Password..."
	                                required
	                                autoComplete="on" />
							</div>
							<div class='options'>
								<div class='check-input' onClick={this.rememberMe}>
								</div>
								<div class='link' onClick={this.forgotPassword} style={{cursor: 'pointer'}}>Forgot password?</div>
							</div>
							<div class='button' onClick={this.loginUser} style={{cursor: 'pointer'}}>Sign In</div>
							{
								loading ? <DataLoading /> :
								error ? <DataError/> :
								loginUser && loginUser.Status ? 
								<div class='email-message'>
									<div>{loginUser && loginUser.Message}</div>
								</div> :
								loginUser && !loginUser.Status ? 
								<div class='error-message'>
									<div>{loginUser && loginUser.Message}</div>
								</div> : null
							}
							{
								errorMessage ?
								<div class='error-message'>
									<div>Need to type username</div>
								</div> : null
							}
						</div> :
						<div class='form'>
							<div class='form-name'>Forgot password</div>
							<div class='input-block'>
								<label for="login">User name</label>
								<input
									type="text"
									id="login"
									ref='userName'
	                                onChange={this.setUserName}
	                                placeholder="User name..."
	                                required 
	                                autoComplete="on"/>
							</div>
							<div class='options'>
								
							</div>
							<div class='button' onClick={this.sendEmail}>Send email</div>
							{
								forgotLoading ? <DataLoading /> :
								forgotError ? <DataError/> :
								loginForgotPassword && !loginForgotPassword.Status ? 
								<div class='error-message'>
									<div>{loginForgotPassword.Message}</div>
								</div> : 
								loginForgotPassword && loginForgotPassword.Status ?
								<div class='email-message'>
									<div>{loginForgotPassword.Message}</div>
								</div> :
								null
							}
							{
								errorMessage ?
								<div class='error-message'>
									<div>Need to type username</div>
								</div> : null
							}
						</div>
					}
					
				</div>
			</div>
        )
    }
}
