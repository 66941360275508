'use strict';

import axios from 'axios'
import axiosCancel from 'axios-cancel'

import * as R from 'ramda'
import {sendRequest, sendDataRequest} from '../APIGateway/gateway'
import loader from './loaderActions'
import modalActions from './modalActions'
import {uploadMergeFiles} from './userActions'
import {showError} from './errorActions'
import newListenActions from '../components/newListenPage/actions'
import {convertScorecardData} from '../components/newListenPage/dataConvertor'

import {chatHTML} from '../utils/globalHelpers'

axiosCancel(axios, {
    debug: false
})

const listenActions = {

    startWithRouter(calibration) {
        return dispatch => {
            return calibration ? dispatch(this.getNextCalibration()) : dispatch(this.getNextCall())
        }
    },

    start(store, calibration) {
        return dispatch => {
            const userData = R.path(['headerReducer', 'headerData', 'userData'], store.getState())
            if (userData) {
                return calibration ? dispatch(this.getNextCalibration()) : dispatch(this.getNextCall())
            } else {
                const userDataPromise = new Promise((resolve) => {
                    const unsubscribe = store.subscribe(() => {
                        const userDataFromAPI = R.path(['headerReducer', 'headerData', 'userData'], store.getState())
                        if (userDataFromAPI) {
                            unsubscribe()
                            resolve()
                        }
                    })
                })
                userDataPromise.then(() => {
                    return calibration ? dispatch(this.getNextCalibration()) : dispatch(this.getNextCall())
                })
            }
        }
    },

    fetchNextCall() {
        return dispatch => dispatch(this.getNextCall())
    },

    getNextCall() {
        return (dispatch, getState) => {
            const state = getState()
            const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
            const withRouter = R.path(['dashboardReducer', 'withRouter'], state)
            const stopWorking = R.path(['headerReducer', 'stopWorking'], state)
            const getNextCallTimeout = R.path(['listenReducer', 'getNextCallTimeout'], state)
            if (!stopWorking) {
                    dispatch({
                    type: 'GET_NEXT_CALL',
                    payload: true
                })
                return sendRequest('getNextCall', {payload: ''}, 'RECORDPOST')
                    .then(response => {
                    const listenData = response.data.ListenData
                    const scorecard = response.data.Scorecard
                    dispatch({
                        type: 'GET_NEXT_CALL_TIMEOUT_COUNTER',
                        payload: 0
                    })
                    dispatch({
                        type: 'SET_LISTEN_TYPE',
                        payload: scorecard && scorecard.ListenType && scorecard.ListenType === 'newlisten' ? 'newlisten' : 'regularlisten'
                    })
                    if (
                        !listenData
                        || !listenData.X_ID
                        || !scorecard
                        || !scorecard.Sections
                        || !scorecard.Sections.length
                    ) {
                        if (!withRouter && userData.reloadTime) setTimeout(() => {
                            window.location.reload()
                        }, userData.reloadTime)
                        // for new listen
                        dispatch({
                            type: 'LISTEN_NEXT_CALL_NODATA',
                            payload: true,
                        })
                        // end
                        return dispatch({
                            type: 'GET_NEXT_CALL_FULFILLED',
                            payload: {
                                listenData: null,
                                scorecard: null,
                            }
                        })
                    }
                    // for new listen
                    dispatch({
                        type: 'LISTEN_SET_QA_START',
                        payload: Math.floor(Date.now() / 1000).toString(),
                    })
                    dispatch({
                        type: 'SET_AUDIO_LINK',
                        payload: listenData.audio_link
                    })
                    dispatch({
                        type: 'GET_NEXT_CALL_FULFILLED',
                        payload: {
                            listenData: listenData,
                            scorecard: scorecard,
                        }
                    })
                    dispatch({
                        type: 'SET_ON_HOLD',
                        payload: listenData.onHold,
                    })

                    dispatch({
                        type: 'SET_IS_AUDIO',
                        payload: scorecard.ReviewType.toUpperCase() === 'AUDIO'
                    })

                    dispatch({
                        type: 'LISTEN_NEXT_CALL_FULFILLED',
                        payload: convertScorecardData(response.data),
                    })
                    dispatch(newListenActions.setFocusedNoAnsweredQuestion())
                    dispatch(newListenActions.setMarkers())
                    // end
                    if (userData.UserRole === 'QA') dispatch(this.getCallsCount())
                    dispatch(this.getTranscript(response.data.ListenData.X_ID))
                    // dispatch(this.getRelatedDealerships())
                    // dispatch(this.getDealershipEmployees())
                    dispatch({
                        type: 'GET_NEXT_CALL_FULFILLED',
                        payload: {
                            listenData: listenData,
                            scorecard: scorecard,
                        }
                    })
                    if (
                        scorecard
                        && scorecard.ReviewType
                        && scorecard.ReviewType.toUpperCase() === 'CHAT'
                    ) {
                        dispatch(this.getChat(listenData.SESSION_ID))
                    } else if (
                        scorecard
                        && scorecard.ReviewType
                        && scorecard.ReviewType.toUpperCase() === 'AUDIO'
                    ) {
                        dispatch(this.getTranscript(response.data.ListenData.X_ID))
                    }
                }, err => {
                    console.log('NEXT CALL ERROR - ', err.message)
                    const data = err.response.data
                    //Check if timeout error
                    const isTimeout = data.indexOf("The exception message is 'Timeout'.") !== -1
                    if (getNextCallTimeout > 2 || !isTimeout) {
                        dispatch({
                            type: 'GET_NEXT_CALL_REJECTED',
                            payload: true
                        })
                        dispatch({
                            type: 'LISTEN_NEXT_CALL_ERROR',
                            payload: true
                        })
                    } else {
                        setTimeout(() => {
                            dispatch({
                                type: 'GET_NEXT_CALL_TIMEOUT_COUNTER',
                                payload: getNextCallTimeout + 1
                            })
                            dispatch(this.getNextCall())
                        }, 300)
                    }
                })
            } else null
            
        }
    },

    getChat(id) {
        const chat = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=1200,top=100,left=100")
        return dispatch => {
            dispatch({
                type: 'CHAT_INFO_LOADING',
                payload: true
            })
            const data = {session_id: id}
            return sendDataRequest('getChat', data, 'CDSERVICE')
                .then(resp => {
                    if (chat && chat.document) {
                        chat.document.body.style.backgroundColor = '#fafafa'
                        chat.document.body.appendChild(chatHTML(resp.data.d))
                    }
                    dispatch({
                        type: 'GET_CHAT_INFO_FULLFILLED',
                        payload: resp.data.d
                    })
                }, err => {
                    chat.document.body.innerHTML = '<div>CHAT ERROR</div>'
                    console.log('Chat error: ' + (err.response.data.ExceptionDetail && err.response.data.ExceptionDetail.Message))
                    dispatch({
                        type: 'CHAT_INFO_ERROR',
                        payload: true
                    })
                })
        }
    },

    getTranscript(id) {
        return dispatch => {
            dispatch({
                type: 'LISTEN_TRANSCRIPT_LOADING',
                payload: true
            })
            const transcriptData = {xcc_id: id}
            return sendDataRequest('getTranscript', transcriptData, 'CDSERVICE')
                .then(resp => {
                    dispatch({
                        type: 'GET_LISTEN_TRANSCRIPT',
                        payload: resp.data.d
                    })
                }, err => {
                    console.log('Transcript error: ' + (err.response.data.ExceptionDetail && err.response.data.ExceptionDetail.Message))
                    dispatch({
                        type: 'LISTEN_TRANSCRIPT_ERROR',
                        payload: true
                    })
                })
        }
    },

    markCallBad(options, page, data) {
        return dispatch => {
            dispatch({
                type: 'GET_NEXT_CALL_FULFILLED',
                payload: {
                    listenData: null,
                    scorecard: null
                }
            })
            dispatch(loader.run({action: 'Show'}))
            this.saveCallRequest(data).then(() => {
                this.markCallBadRequest(options).then(() => {
                    dispatch(loader.run({action: 'Hide'}))
                    return dispatch(this.getNextCall())
                }, () => {
                    dispatch(loader.run({action: 'Hide'}))
                    return dispatch(this.getNextCall())
                })
            })
        }
    },

    markCallBadRequest(options) {
        return sendRequest('markCallBad', {payload: options}, 'RECORDPOST')
    },

    changeCallScorecard(data, page, stopWorking) {
        return dispatch => {
            dispatch(loader.run({action: 'Show'}))
            return sendRequest('changeCallScorecard', {payload: data}, 'RECORDPOST')
            .then(() => {
                // dispatch(loader.run({action: 'Hide'}))
                if (stopWorking) {
                    dispatch(loader.run({action: 'Show'}))
                    return window.location = '/home'
                } else {
                    return dispatch(this.getNextCall())
                }
                // return dispatch(this.getNextCall())
            })
        }
    },

    saveCallRequest(options) {
        console.log(options, JSON.stringify(options))
        return sendDataRequest('saveCall', options, 'RECORDPOST')
    },

    stopWorking(data) {
        return dispatch => {
            return dispatch({
                type: 'GET_HEADER_DATA_STOP_WORKING',
                payload: data
            })
        }
    },

    setGapInfo(gapInfo) {
        return dispatch => {
            return dispatch({
                type: 'SET_LISTEN_AUDIO_GAP_INFO',
                payload: gapInfo
            })
        }
    },

    saveCall(options, stopWorking) {
        return (dispatch, getState) => {
            dispatch(loader.run({action: 'Show'}))
            dispatch({
                type: 'GET_NEXT_CALL_FULFILLED',
                payload: {
                    listenData: null,
                    scorecard: null
                }
            })
            const state = getState()
            const gapInfo = R.path(['listenReducer', 'audioGapInfo'], state)
            if (gapInfo) {
                options.LD.gapInfo = gapInfo
            }
            this.saveCallRequest(options).then(response => {
                dispatch(loader.run({action: 'Hide'}))
                localStorage.removeItem(options.LD.review_ID)
                if (stopWorking) {
                    dispatch(loader.run({action: 'Show'}))
                    return window.location = '/home'
                } else {
                    return dispatch(this.getNextCall())
                }
            }, err => {
                dispatch(loader.run({action: 'Hide'}))
                if (stopWorking) {
                    dispatch(loader.run({action: 'Show'}))
                    return window.location = '/home'
                } else {
                    return dispatch(this.getNextCall())
                }
            })
        }
    },

    fetchNextCalibration() {
        return dispatch => dispatch(this.getNextCalibration())
    },

    getNextCalibration() {
        return (dispatch, getState) => {
            const state = getState()
            const withRouter = R.path(['dashboardReducer', 'withRouter'], state)
            const userData = R.path(['headerReducer', 'headerData', 'userData'], state)
            dispatch({
                type: 'GET_NEXT_CALL',
                payload: true
            })

            return sendRequest('getNextCalibration', {payload: ''}, 'RECORDPOST')
                .then(response => {
                    const listenData = response.data.ListenData
                    const scorecard = response.data.Scorecard
                    dispatch({
                        type: 'SET_LISTEN_TYPE',
                        payload: scorecard && scorecard.ListenType && scorecard.ListenType === 'newlisten' ? 'newlisten' : 'regularlisten'
                    })
                    if (
                        !listenData
                        || !listenData.X_ID
                        || !scorecard
                        || !scorecard.Sections
                        || !scorecard.Sections.length
                    ) {
                        if (!withRouter && userData.reloadTime) setTimeout(() => {
                            window.location.reload()
                        }, userData.reloadTime)
                        // for new listen
                        dispatch({
                            type: 'LISTEN_NEXT_CALL_NODATA',
                            payload: true,
                        })
                        // end
                        return dispatch({
                            type: 'GET_NEXT_CALL_FULFILLED',
                            payload: {
                                listenData: null,
                                scorecard: null,
                            }
                        })
                    }
                    // for new listen
                    if (scorecard.ListenType === 'newlisten') {
                        dispatch({
                            type: 'LISTEN_SET_QA_START',
                            payload: Math.floor(Date.now() / 1000).toString(),
                        })
                        dispatch({
                            type: 'SET_AUDIO_LINK',
                            payload: listenData.audio_link
                        })
                        dispatch({
                            type: 'GET_NEXT_CALL_FULFILLED',
                            payload: {
                                listenData: listenData,
                                scorecard: scorecard,
                            }
                        })
                        dispatch({
                            type: 'SET_ON_HOLD',
                            payload: listenData.onHold,
                        })

                        dispatch({
                            type: 'SET_IS_AUDIO',
                            payload: scorecard.ReviewType.toUpperCase() === 'AUDIO'
                        })

                        dispatch({
                            type: 'LISTEN_NEXT_CALL_FULFILLED',
                            payload: convertScorecardData(response.data),
                        })
                        dispatch(newListenActions.setFocusedNoAnsweredQuestion())
                        dispatch(newListenActions.setMarkers())
                    }
                    // end
                    dispatch({
                        type: 'GET_NEXT_CALL_FULFILLED',
                        payload: {
                            listenData: listenData,
                            scorecard: scorecard,
                        }
                    })
                    if (
                        scorecard
                        && scorecard.ReviewType
                        && scorecard.ReviewType.toUpperCase() === 'CHAT'
                    ) {
                        dispatch(this.getChat(listenData.SESSION_ID))
                    } else if (
                        scorecard
                        && scorecard.ReviewType
                        && scorecard.ReviewType.toUpperCase() === 'AUDIO'
                    ) {
                        dispatch(this.getTranscript(response.data.ListenData.X_ID))
                    }

                    //!!!temporary hardcoded
                    // if (response.data.ScorecardData )response.data.QAQACalibration = true //<-remove this row
                    if (response.data.QAQACalibration && response.data.ScorecardData) {
                        dispatch(this.setCalibrateQAQA(response.data.ScorecardData))
                    }
                    //......................
                }, err => {
                    console.log('NEXT CALIBRATION ERROR - ', err.message)
                    dispatch({
                        type: 'GET_NEXT_CALL_REJECTED',
                        payload: true
                    })
                })
        }
    },

    setCalibrateQAQATable(tableData) {
        return dispatch => {
            return dispatch({
                type: 'SET_CALIBRATE_TABLE_DATA',
                payload: tableData
            })
        }
    },

    setCalibrateQAQA(scorecardData) {
        //5397886
        return dispatch => {
            return dispatch({
                type: 'SET_CALIBRATE_SCORECARD_DATA',
                payload: scorecardData
            })
        }
    },

    updateCalibrateScorecardData(scorecardData) {
        return dispatch => {
            return dispatch({
                type: 'UPDATE_CALIBRATE_SCORECARD_DATA',
                payload: scorecardData
            })
        }
    },

    calibrateCall(options, stopWorking) {
        return dispatch => {
            dispatch(loader.run({action: 'Show'}))
            dispatch({
                type: 'GET_NEXT_CALL_FULFILLED',
                payload: {
                    listenData: null,
                    scorecard: null,
                }
            })
            return sendDataRequest('calibrateCall', options, 'RECORDPOST')
                .then(response => {
                    dispatch(loader.run({action: 'Hide'}))
                    return dispatch(this.getNextCalibration())
                }, err => {
                    dispatch(loader.run({action: 'Hide'}))
                    return dispatch(this.getNextCalibration())
                })
        }
    },

    markCalibrationBad(options, stopWorking) {
        return dispatch => {
            dispatch({
                type: 'GET_NEXT_CALL_FULFILLED',
                payload: {
                    listenData: null,
                    scorecard: null,
                }
            })
            dispatch(loader.run({action: 'Show'}))
            return sendRequest('markCalibrationBad', {payload: options}, 'RECORDPOST')
                .then(response => {
                    // dispatch(loader.run({action: 'Hide'}))
                    if (stopWorking) {
                        dispatch(loader.run({action: 'Show'}))
                        return window.location = '/home'
                    } else {
                        dispatch(loader.run({action: 'Hide'}))
                        return dispatch(this.getNextCalibration())
                    }
                    // return dispatch(this.getNextCalibration())
                }, err => {
                    // dispatch(loader.run({action: 'Hide'}))
                    // return dispatch(this.getNextCalibration())
                    if (stopWorking) {
                        dispatch(loader.run({action: 'Show'}))
                        return window.location = '/home'
                    } else {
                        dispatch(loader.run({action: 'Hide'}))
                        return dispatch(this.getNextCalibration())
                    }
                })
        }
    },

    manipulateAudio(options, addedToSend) {
        return dispatch => {
            return uploadMergeFiles(options, addedToSend, dispatch)
                .then(resp => {
                    dispatch(modalActions.modal('close'))
                    if (resp.data.IsSuccess && resp.data.Audio) {
    					dispatch(modalActions.modal({
                            title: 'Merged successfully',
                            modalContent: <div class='default-content'>Audio merged successfully!</div>,
                        }))
    					return dispatch({
    		                type: 'GET_NEW_AUDIO',
    		                payload: resp.data.Audio
    		            })
    				} else {
    					return dispatch(modalActions.modal({
                            title: 'Merge error',
                            modalContent: <div class='default-content'>{resp.data.Message || ''}</div>,
                        }))
    				}
                }, err => {
                    dispatch(modalActions.modal('close'))
                    return dispatch(modalActions.modal({
                        title: 'Merge error',
                        modalContent: <div class='default-content'>Audio mering failed!</div>,
                    }))
                })
        }
    },

    sendHeartbeat(data) {
        return dispatch => {
            dispatch({
                type: 'LISTEN_HEARTBEAT_UPDATE',
                payload: true
            })
            return sendRequest('heartbeat', {payload: data}, 'RECORDPOST')
                .then(resp => {
                    return
                }, err => console.log('Heartbeat not sent'))
        }
    },

    getCallsCount() {
        return dispatch => {
            return sendRequest('getCallsCount', {payload: ''}, 'USERAPI')
                .then(resp => {
                    dispatch({
                            type: 'GET_LISTEN_CALLS_COUNT',
                            payload: resp.data
                        })
                    }, err => {
                        console.log('getCallsCount error')
                        dispatch({
                            type: 'GET_LISTEN_CALLS_COUNT_ERROR',
                        })
                    })
        }
    },

    getAutocomplete(qid, text) {
        axios.cancel('getAutocomplete')
        return dispatch => {
            return sendRequest('getAutocomplete', {
                payload: qid
            }, 'CALIBRATION', false, 'getAutocomplete', 'string_match=' + (text || null)).then(resp => resp, err => {
                if (axios.isCancel(err)) {
                    return
                }
                return 'ERROR'
            })
        }
    },

    setAnswersForDropdown(answers) {
        return dispatch => {
            dispatch({
                type: 'LISTEN_SET_ANSWERS_FOR_DROPDOWN',
                payload: {
                    answers: answers
                }
            })
        }
    },

    getRelatedDealerships(DDLValue) {
        // debugger
        //  return dispatch => {
        //     axios.get(`http://app.callcriteria-dev.com/webApi/review/GetRelatedDealerships?dealershipName=${DDLValue}`)
        //     // return sendRequest('getRelatedDealerships', {
        //     //     payload: {}
        //     // }, 'CALIBRATION', false, 'getRelatedDealerships')
        //     // .then(resp => {
        //     //    console.log(resp)
        //     // }, err => err)
        // }
    },

    getDealershipEmployees(DDLValue) {
        // return dispatch => {
        //     // return sendRequest('getDealershipEmployees', {
        //     //     payload: {}
        //     // }, 'CALIBRATION', false, 'getDealershipEmployees')
        //     // .then(resp => {
        //     //    console.log(resp)
        //     // }, err => err)
        // }
    },
}

export default listenActions
