'use strict'

import MissedItem from './MissedItem'

export default class MissedItems extends React.Component {
    renderMissedItems() {
        const {items, type} = this.props
        return items.map((item, i) => {
            if (!item.questionShortName) return
            return <MissedItem key={i + Math.random()} type={type} data={item} />
        })
    }

    render() {
        const {items} = this.props
        return (
            <div class='missed-items-block'>
                <div class='missed-items-headline'>Missed items ({items.length})</div>
                {this.renderMissedItems()}
            </div>
        )
    }
}
