import * as R from 'ramda'
import { combineReducers } from 'redux'
import queryString from 'query-string'

import homeReducer from './homeReducer'
import sessionReducer from './sessionReducer'
import progressReducer from './progressReducer'

const initialState = {
    coachingQuery: [],
    activePage: 'home',
    activeScorecard: null,
    dateSettings: null,
    loadingState: false,
    modalOptions: null
}

function rootReducer(state=initialState, action) {
    switch (action.type) {
        case "COACHING_PAGE_SET_QUERY": {
            window.onpopstate = (e) => {
                if(e.state){
                    window.location.reload(true);
                }
            }
            // const editQuestionTabs = state.editQuestionTabs
            // const tabids =  editQuestionTabs ? R.pluck('id', editQuestionTabs).join(',') || undefined : 
            //     (state.settingsQuery.tabids || action.payload.tabids || undefined)
            const coachingQuery = R.reject(R.isNil, R.mergeAll([state.coachingQuery, action.payload || {}, {
                // tabids: action.payload.tabids === 'remove' ? undefined : tabids
            }]))
            // if (settingsQuery.tabids === 'remove') delete settingsQuery.tabids
            const order = [
                'page', 
                'sub', 
                'scorecard', 'range', 
                'tab', 'by', 'view', 
                'agents', 'points', 
                'sessionId', 'agentId', 'pointId'
            ];

            const newSearch = '?' + queryString.stringify(coachingQuery, {
                sort: (m, n) => order.indexOf(m) >= order.indexOf(n),
                encode: false
            });
            const historyWasChanged = !R.equals(history.state, coachingQuery)
            // if (back) {
            //     history.back()
            // } else {
                if (historyWasChanged) {
                    history.pushState(coachingQuery, 'settings page', newSearch);
                }
            // }
            // debugger
            return {
                ...state,
                coachingQuery: coachingQuery,
                // editQuestionTabs: action.payload.tabids === 'remove' ? [] : editQuestionTabs
            }
        }

        case "COACHING_PAGE_SET_ROOT_INITIAL": {
            const range = action.payload.range
            const rangeArray = range ? range.split(',') : null
            const dateSettings = range ? {
                start: rangeArray[0],
                end: rangeArray[1]
            } : null
            return {
                ...state,
                activePage: action.payload.sub,
                activeScorecard: action.payload.scorecard,
                dateSettings: dateSettings
            }
        }

        case "COACHING_PAGE_SET_MODAL_OPTIONS": {
            return {
                ...state,
                modalOptions: action.payload,
            }
        }
        case "COACHING_PAGE_SET_ACTIVE_PAGE": {
            return {
                ...state,
                activePage: action.payload,
            }
        }
        case "COACHING_PAGE_SET_ACTIVE_SCORECARD": {
            return {
                ...state,
                activeScorecard: action.payload,
            }
        }
        case "COACHING_PAGE_SET_ACTIVE_DATES": {
            return {
                ...state,
                dateSettings: action.payload,
            }
        }
    }
    return state
}

export default combineReducers({
    root: rootReducer, 
    home: homeReducer, 
    session: sessionReducer, 
    progress: progressReducer
})
