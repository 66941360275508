'use strict'

export function getDefaultColumns() {
    return [
        {id: "callType",          value: "Type",              checked: true, isRequired: true,  sortableValue: false, sortable: false, searchable: false},
        {id: "callAudioLength",   value: "Duration",          checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "rebuttalAttempts",  value: "Rebuttal Attempts", checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "reasons",           value: "Reasons",           checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "callDate",          value: "Call Date",         checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: false},
        {id: "agentName",         value: "Agent",             checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "agentGroup",        value: "Group",             checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
        {id: "callReviewStatus",  value: "Review Status",     checked: true, isRequired: true, sortableValue: false,  sortable: true, searchable: false},
        {id: "callFailed",        value: "Result",            checked: true, isRequired: true, sortableValue: false,  sortable: true, searchable: false},
        {id: "prospectPhone",     value: "Phone",             checked: true, isRequired: false, sortableValue: true,  sortable: true, searchable: true},
    ]
}

export const sortItems = (items, sorting) => {
    items.sort((a, b) => {
        return sorting.sortOrder === 'asc' ?
        b[sorting.sortBy] - a[sorting.sortBy] :
        a[sorting.sortBy] - b[sorting.sortBy]
    })
    
    return items
}


export const salesFilters = () => {
    return {
        scorecards: [],
        groups: [],
        agents: [],
        campaigns: [],
        QAs: [],
        teamLeads: [],
        missedItems: [],
        failedOnly: true,
        badCallsOnly: false,
        passedOnly: true,
        filterByReviewDate: false,
        reviewType: '',
        missedBy: 'agent',
        pendingOnly: false,
        callType: '',
        commentIds: [],
        filterBy: '',
        isConversion: false,
    }
}
