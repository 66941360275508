'use strict'

export default class DataLoading extends React.Component {
    render() {
        return (
            <div class='data-loading'>
                <div class='dashboard-loader-inner'></div>
                <div class='dashboard-loader-outer'></div>
            </div>
        )
    }
}
