'use strict'

import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import * as R from 'ramda'

import Content from './Content'
import DataLoading from '../componentStates/DataLoading'
import DataError from '../componentStates/DataError'

import dashboardActions from '../../../actions/dashboardActions'

@connect((store) => {
    return {
        activeQuestion: store.dashboardReducer.activeQuestionDetails,
        error: store.dashboardReducer.questionDetailsError,
        loading: store.dashboardReducer.questionDetailsLoading,
        filters: R.clone(store.filtersReducer.newFilters),
        dateSettings: store.filtersReducer.dateSettings
    }
})

export default class SelectedQuestionDetails extends React.Component {
    constructor(props) {
        super(props)

        this.addToFilters = this.addToFilters.bind(this)
        this.hoverIcon = this.hoverIcon.bind(this)
        this.unhoverIcon = this.unhoverIcon.bind(this)
        this.removeFromFilters = this.removeFromFilters.bind(this)

        this.state = {
            iconHovered: false
        }
    }

    hoverIcon() {
        this.setState({
            iconHovered: true
        })
    }

    unhoverIcon() {
        this.setState({
            iconHovered: false
        })
    }

    getCount() {
        const {activeQuestion} = this.props
        if (!activeQuestion) return
        const {isComposite, compositeQuestionStat, simpleQuestionStat} = activeQuestion
        if (isComposite) return activeQuestion.total - compositeQuestionStat.totalRight
        const wrong = simpleQuestionStat.answerList.find(answ => !answ.isRightAnswer)
        if (wrong) return wrong.total
        return 0
    }

    addToFilters() {
        const {activeQuestion, filters, dateSettings, closeModal} = this.props
        filters.missedItems.push({
            count: this.getCount(),
            id: activeQuestion.questionID,
            name: activeQuestion.questionName,
            scorecard: activeQuestion.scorecard.scorecardName
        })
        this.props.dispatch({
            type: 'SET_FILTERS',
            payload: filters
        })
        this.props.dispatch(dashboardActions.refreshFilters(filters, dateSettings))
        this.applyFilters()
    }

    applyFilters() {
        this.props.dispatch(
            dashboardActions.fetchDashboardData()
        )
        this.props.dispatch({
            type: 'SET_INITIAL_FILTERS',
            payload: this.props.filters
        })
    }

    removeFromFilters(e) {
        const {activeQuestion, filters, dateSettings, closeModal} = this.props
        const itemToRemove = filters.missedItems.find(item => item.id === activeQuestion.questionID)
        if (itemToRemove) filters.missedItems.splice(filters.missedItems.indexOf(itemToRemove), 1)
        this.props.dispatch({
            type: 'SET_FILTERS',
            payload: filters
        })
        this.props.dispatch(dashboardActions.refreshFilters(filters, dateSettings))
        this.applyFilters()
    }

    render() {
        const {closeModal, activeQuestion, loading, error, filters} = this.props
        const questionIcon = activeQuestion ? classNames({
            'icon-width'                  : activeQuestion.questionType === 'dynamic' || activeQuestion.isLinked === true,
            'icon-ic-dynamic-question'    : activeQuestion.questionType === 'dynamic',
            'icon-ic-linked-dynamic'      : activeQuestion.questionType === 'dynamic' && activeQuestion.isLinked === true,
            'icon-ic-linked-question'     : activeQuestion.isLinked === true,
        }) : ''

        const {iconHovered} = this.state
        const count = this.getCount()
        const added = !(loading || error || !activeQuestion) &&
        filters.missedItems &&
        filters.missedItems.length ?
        !!filters.missedItems.find(item => item.id === activeQuestion.questionID) : false
        const filterIcon = classNames({
            'icon'               : true,
            'added-to-filters'   : added,
            'icon-ic-filter'     : count && (!added || (added && !iconHovered)),
            'icon-ic-close'      : added && iconHovered
        })
        return (
            <div class='question-box'>
                <div class='question-box-title'>
                    {
                        loading || error || !activeQuestion ?
                        <div class='name-block'>
                            {error ? '' : 'Loading question...'}
                        </div> :
                        <div class='name-block'>
                            <div class='type-question'>
                                <div class={'icon' + ' ' + questionIcon}/>{activeQuestion.questionName}
                                <div
                                    onClick={added ? this.removeFromFilters : this.addToFilters}
                                    onMouseOver={this.hoverIcon}
                                    onMouseOut={this.unhoverIcon}
                                    class="add-to-filters">
                                </div>
                            </div>
                            <div class='scorecad-name'>
                                {activeQuestion.sectionName + ' - ' + activeQuestion.scorecard.scorecardName}
                            </div>
                        </div>
                    }
                    <div class='close-icon'>
                        <div onClick={closeModal} class='icon icon-ic-close' />
                    </div>
                </div>
                <div class='question-details-content'>
                    {
                        loading ?
                        <DataLoading /> :
                        error ?
                        <DataError /> :
                        activeQuestion ?
                        <Content addToFilters={this.addToFilters} activeQuestion={activeQuestion} /> : null
                    }
                </div>
                <div class='question-box-footer'></div>
            </div>
        )
    }
}
